<template>
  <div class="box">
    <div class="mainform g-3 pb-4">
      <h3>Video call settings</h3>
      <OnBoarding4 type="step"/>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import OnBoarding4 from "@/components/i/OnBoarding4.vue"

@Component({
  components: {OnBoarding4},
})
export default class Step1 extends Vue {
  protected created() {

    let stage = 'Test';
    if(window.location.host == 'gmf-event.com'){
      stage = 'Production';
    }
    this.$gtm.trackEvent({
      pageCategory: 'Networking',
      PageName: 'Video Call Settings',
      PageType: 'Webapp',
      Stage:stage,
    });
  }
}
</script>