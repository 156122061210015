<template>
  <div class="box">
    <MeetingModal :connection="connection" :toggle_modal="toggle_modal" />
    <JitsiCall type="member" v-if="$store.state.members.currentCall" />
    <div class="row g-3" v-if="ContactEntry">

      <div class="col-md-5 col-lg-3 mb-3 sm-center">
        <div class="circular_image_large">
          <img :src="`/files/members/${ContactEntry.objvalueid}/foto.jpg`" width="250" onerror="this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';" alt="">
        </div>
      </div>

      <div class="col-md-7 col-lg-9 mb-3 text-left ipadfx">
        <h3 class="pb-3 pt-0"> {{ContactEntry.firstname}} {{ContactEntry.lastname}}</h3>
        <div v-if="ContactEntry.position">Position: {{title(ContactEntry.position)}}</div>
        <div v-if="ContactEntry.orgname">Organization: {{title(ContactEntry.orgname)}}</div>
        <div v-if="ContactEntry.show_email && ContactEntry.email">E-Mail adress: <a :href="`mailto:${ContactEntry.email}`" >{{ContactEntry.email}}</a></div>
        <div v-if="ContactEntry.phone">Phone: {{ContactEntry.phone}}</div>
        <div v-if="ContactEntry.country" class="mb-4">Country: {{countrylist[ContactEntry.country]}}</div>

        <div class="row">
          <div class="col-12 col-md-3" v-if="ContactEntry.linkedin || ContactEntry.twitter || ContactEntry.facebook">
            <span class="Role copydetailxs"><br>
                <span class="headline_light text-center">Follow me</span>
              <br>
              <a  v-if="ContactEntry.twitter" :href=ContactEntry.twitter class="buttonshare" target="_blank"><i class="fab fa-2x fa-x-twitter"></i>
                    <span class="sr-only">X (Twitter)</span></a>
              <a  v-if="ContactEntry.linkedin" :href=ContactEntry.linkedin class="buttonshare" target="_blank"><i class="fab fa-2x fa-linkedin-in"></i>
                  <span class="sr-only">LinkedIn</span></a>
              <a  v-if="ContactEntry.facebook" :href=ContactEntry.facebook class="buttonshare" target="_blank"><i class="fab fa-2x fa-facebook-f"></i>
                  <span class="sr-only">Facebook</span></a>
            </span>
            <br>
          </div>

          <span class="Role copydetailxs col-12 col-md-3" v-if="ContactEntry.show_vcard">
            <br>
              <span class="headline_light text-center">VCARD DOWNLOAD</span><br>
              <a class="buttonshare" @click="ExportvCard(ContactEntry.objvalueid)"><i class="fal fa-address-card"></i></a>
          </span>

          <span class="Role copydetailxs col-12 col-md-6" v-if="ContactEntry.accept_networking && ContactEntry.objvalueid != $store.state.config.me.objvalueid">
            <br>
              <span class="headline_light text-center">Networking</span><br>
                 <button class="btn btn-primary m-1"  v-if="!ConnectionEntry1" @click.prevent="createConnection(ContactEntry)">
                      <span class="smartphone"><i class="fal fa-user-plus"></i></span>
                      <span class="nsmartphone">Send contact request</span>
                 </button>
                 <button class="btn btn-outline-primary m-1" v-if="ConnectionEntry2" >
                     <span class="smartphone"><i class="fal fa-user-plus"></i></span>
                      <span class="nsmartphone">Contact request sent</span>
                 </button>
                 <button class="btn btn-outline-primary m-1" v-if="ConnectionEntry3" >
                      <span class="smartphone"><i class="fal fa-user-plus"></i></span>
                      <span class="nsmartphone">Contact request received</span>
                 </button>
                 <button class="btn btn-primary m-1" v-if="ConnectionEntry4" @click.prevent="pickuserchat(ContactEntry)">
                      <span class="smartphone"><i class="fal fa-comment"></i></span>
                      <span class="nsmartphone">Chat</span>
                 </button>
                 <button class="btn btn-primary m-1" v-if="ConnectionEntry4" @click.prevent="pickusercall(ContactEntry)">
                      <span class="smartphone"><i class="fal fa-video"></i></span>
                      <span class="nsmartphone">Call</span>
                 </button>
                 <button class="btn btn-primary m-1" v-if="ConnectionEntry4" @click.prevent="meeting(ContactEntry)">
                      <span class="smartphone"><i class="fal fa-calendar-edit"></i></span>
                      <span class="nsmartphone">Appointment request</span>
                 </button>
          </span>
          <br>

        </div>

      </div>

      <hr>

      <div v-if="ContactEntry.objvalueid != $store.state.config.me.objvalueid">
        <h4 class="bold mb-3">Matching result</h4>
        <div class="mb-2">
          <span class="badge badge-pill">  {{ ContactEntry.match }} %</span>
        </div>
        <div class="mb-2">
          <b>My Expertise</b><br>
          <span v-for="(e, ei) in networking_question1"><span :title="[is_selected1(ei) ? 'Match' : 'No match']" :class="{'badge badge-no-match': true, 'badge-light' : is_selected1(ei)}">{{networkinglist[ei]}}</span></span>
        </div>
        <div class="mb-2">
          <b>My interests</b><br>
          <span v-for="(e, ei) in networking_question2"><span  :title="[is_selected2(ei) ? 'Match' : 'No match']" :class="{'badge badge-no-match': true, 'badge-light' : is_selected2(ei)}">{{networkinglist[ei]}}</span></span>
        </div>
        <div class="mb-2">
          <b>My Calendar</b><br>
          <span v-for="(e, ei) in fav_agenda_items"><span  :title="[is_selected3(ei) ? 'Match' : 'No match']" :class="{'badge badge-no-match': true, 'badge-light' : is_selected3(ei)}">{{title(AgendaEntryTitle(ei))}}</span></span>
          <p v-if="fav_agenda_items.length === 0">This participant did not bookmark any program items.</p>
        </div>
        <hr>
      </div>

      <div v-if="ContactEntry.cv">
        <h4 class="bold mb-3">Resume</h4>
        <div class="cv" v-html="ContactEntry.cv"></div>
        <hr>
      </div>

    </div>
  </div>
</template>


<script lang="ts">
import {Component, Vue, Watch} from 'vue-property-decorator';
import JitsiCall from "@/components/jitsi/JitsiCall.vue";
import MeetingModal from "@/components/i/MeetingModal.vue";
import {JitsiCallRequest, JitsiVideoSettings} from "@/store/member/types";
import {AgendaPoint, ConnectionNetworking, ContactsNetworking} from "@/store/i/types";
import _ from "lodash";
import {AppNetworkErrorOther, networkErrorText} from "@/crud";
import {TranslatedText} from "@/store/types";
import {NavigationGuardNext, Route} from "vue-router";

@Component({
  components: {JitsiCall,MeetingModal},
  beforeRouteLeave(to, from, next) {
    if ((this as any).navguard) {
      (this as any).navguard(to, from, next)
    } else {
      next()
    }
  }
})
export default class SpeakerDetail extends Vue {



  protected title(title: string): string {
    title = _.replace(title, /&#34;/g, '"')
    title = _.replace(title, /&#39;/g, "'")
    return title
  }

  get ContactEntry(): ContactsNetworking|undefined {
    return _.find(
        this.$store.state.i.contacts_networking,
        (e: ContactsNetworking) => `${e.objvalueid}` === `${this.$route.params.contactid}`
    )
  }

  get ConnectionEntry1(): ConnectionNetworking|undefined {
    return _.find(
        this.$store.state.i.connections_networking,
        (e: ConnectionNetworking) => (`${e.sender}` === `${this.$route.params.contactid}` || `${e.receiver}` === `${this.$route.params.contactid}`) && e.dbstate != -1
    )
  }
  get ConnectionEntry2(): ConnectionNetworking|undefined {
    return _.find(
        this.$store.state.i.connections_networking,
        (e: ConnectionNetworking) => `${e.receiver}` === `${this.$route.params.contactid}` && e.status == 1 && e.dbstate != -1
    )
  }
  get ConnectionEntry3(): ConnectionNetworking|undefined {
    return _.find(
        this.$store.state.i.connections_networking,
        (e: ConnectionNetworking) => `${e.sender}` === `${this.$route.params.contactid}` && e.status == 1 && e.dbstate != -1
    )
  }
  get ConnectionEntry4(): ConnectionNetworking|undefined {
    return _.find(
        this.$store.state.i.connections_networking,
        (e: ConnectionNetworking) => (`${e.sender}` === `${this.$route.params.contactid}` || `${e.receiver}` === `${this.$route.params.contactid}`) && e.status == 2 && e.dbstate != -1
    )
  }

  get countrylist() {
    return this.$store.state.i.me.countrylist;
  }

  get networkinglist() {
    return this.$store.state.i.me.networkinglist;
  }

  get networking_question1() {

    if(this.ContactEntry){
      return this.ContactEntry.networking_question1
    }

    return 0
  }
  get networking_question2() {

    if(this.ContactEntry){
      return this.ContactEntry.networking_question2
    }

    return 0
  }

  get fav_agenda_items() {

    if(this.ContactEntry){
      return this.ContactEntry.fav_agenda_items
    }

    return 0
  }

  get AgendaEntry(): AgendaPoint[] {
    return _.filter(
        this.$store.getters['i/orderedAgendaPoints'],
        (ap: AgendaPoint) => ap.dbstate > -1
    )
  }

  protected AgendaEntryTitle(ei: string){

    let Entry = _.find(
        this.AgendaEntry,
        (e: AgendaPoint) => `${e.objvalueid}` === ei
    )

    if(Entry){
      return Entry.title
    }else{
      return ''
    }

  }

  protected is_selected1(ei: string){
    return _.includes(_.keys(this.$store.state.i.me.networking_question2), ei)
  }

  protected is_selected2(ei: string){
    return _.includes(_.keys(this.$store.state.i.me.networking_question1), ei)
  }

  protected is_selected3(ei: string){
    return _.includes(_.keys(this.$store.state.i.me.fav_agenda_items), ei)
  }

  protected async ExportvCard(objvalueid: any) {
    window.location.href = '/projects/dw/gmf/sources/scripts/portal.php?&action=ExportvCard&objvalueid='+objvalueid;
  }



  protected errorstring: TranslatedText = {'de': '', 'en': ''}
  protected successtring: TranslatedText = {'de': '', 'en': ''}

  protected async createConnection(c: ContactsNetworking) {
    try {
      this.errorstring = {de: '', en:''}
      this.successtring = {de: '', en:''}
      const dpr = await this.$store.dispatch("i/createConnection", c)
      setTimeout(() => { this.reset_message()}, 2000);
      if (dpr) {
        this.successtring = {
          de: "Contact request sent",
          en: "Contact request sent"
        }
      } else {
        this.errorstring = {
          de: "Contact request could not be created",
          en: "Contact request could not be created"
        }
      }
    } catch (e) {
      this.errorstring = networkErrorText(e as AppNetworkErrorOther)
    }
  }

  protected reset_message(){
    this.errorstring = {de: '', en:''}
    this.successtring = {de: '', en:''}
  }

  get currentCall(): JitsiCallRequest|undefined {
    return this.$store.state.members.currentCall
  }

  protected navguard(to: Route, from: Route, next:NavigationGuardNext<Vue>) {
    if (this.$store.state.members.currentCall && !this.$store.getters['i/forcedToPlenum'] && !this.$store.getters['i/redirectToPlenum']) {
      const confirmres = window.confirm(this.$store.getters.i18n({
        de: "If you are leaving the booth now, your call will be ended.",
        en: "If you are leaving the booth now, your call will be ended."
      }))
      if (confirmres) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  }


  protected pickuserchat(cp: ContactsNetworking) {
    const uc = _.find(
        this.$store.state.i.contacts_networking,
        (c) => c.fbuid === cp.fbuid
    )
    if (uc) {
      this.$store.commit('OpenSideNav', 'chat')
      this.$store.commit('i/setChatboxContact', uc)
    }
  }


  protected async pickusercall(cp: ContactsNetworking) {
    if (cp.fbuid !== this.$store.state.config.fbuid) {
      if (this.currentCall) {
        this.$store.commit("i/addToast", {
          header: {de: "Fehler", en: "Error"},
          msg: {
            de: 'Sie sind bereits im Gespräch.',
            en: 'You are already in a call.'
          },
          validforroom: "event-general",
          got: new Date(),
          hidden: false,
          showfor: 10000,
          alert_type: 'error'
        })
      } else {

        let c =   {
          "fbuid":  cp.fbuid,
          "objvalueid":  cp.objvalueid,
          "firstname":  cp.firstname,
          "lastname": cp.lastname,
          "orgname": cp.orgname,
        };
        this.$store.commit("members/setContact", c)
        await this.$store.dispatch("members/startCall", cp.objvalueid)

        this.$store.commit("i/addToast", {
          header: {de: "Hinweis", en: "Warning"},
          msg: {
            de: 'Bitte haben Sie einen Moment Geduld, bis Ihr Ansprechpartner Ihren Anruf entgegen nimmt. Wenn Sie das Gespräch beenden, ohne dass Ihr Ansprechpartner den Anruf entgegen genommen hat, wird dieser per Chat-Nachricht über Ihren Kontaktversuch informiert.',
            en: 'Bitte haben Sie einen Moment Geduld, bis Ihr Ansprechpartner Ihren Anruf entgegen nimmt. Wenn Sie das Gespräch beenden, ohne dass Ihr Ansprechpartner den Anruf entgegen genommen hat, wird dieser per Chat-Nachricht über Ihren Kontaktversuch informiert.',
          },
          validforroom: "event-general",
          got: new Date(),
          hidden: false,
          showfor: 10000,
          alert_type: 'error'
        })

      }

    }
  }

  protected connection: number = 0
  protected toggle_modal: boolean = false

  protected meeting(cp: ContactsNetworking) {
    this.toggle_modal = !this.toggle_modal;
    this.connection = cp.objvalueid;
  }

  protected created() {

    let stage = 'Test';
    if(window.location.host == 'gmf-event.com'){
      stage = 'Production';
    }
    this.$gtm.trackEvent({
      pageCategory: 'Contact',
      PageName: 'Detailpage',
      PageType: 'Webapp',
      Stage:stage,
    });
  }


}

</script>

<style scoped>

</style>