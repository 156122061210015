<template>
  <div class="row mb-4">

      <div v-if="errorstring.de !== ''" class="alert alert-danger mb-2">
        {{ $store.getters.i18n(errorstring) }}
      </div>

      <div class="col-md-7 col-sm-12">
        <div class="game bg-dark container d-inline-block ml-2 mr-1 " @click="wrongClick">
          <div class="rounded text-center clicker" ref="clicker" @mouseover="escape"  @click="points" type="button" >
            <img src="/projects/dw/gmf/assets/images/reaction-logo.png" class="background img-competition" >
          </div>
        </div>
      </div>

    <div class="col-md-5 col-sm-12">


      <p>
        My highest score: <b>{{ $store.state.i.me.score_reaction }}</b>
      </p>
      <p>
        Score: <b>{{point}}</b><br>
        Level: <b>{{level}}</b><br>
        Timer: <b>{{sec}}</b>
      </p>

      <p v-if="btn1">
        <span class="btn btn-primary btn-lg" @click="StartCompetition()">Start</span>
      </p>

      <p v-if="btn2">
        <span class="btn btn-primary btn-lg" @click="StartCompetition()">Repeat</span>
      </p>

    </div>


  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import _ from "lodash";
import {TranslatedText} from "@/store/types";
import {AppNetworkErrorOther, networkErrorText} from "@/crud";

@Component({
  components: {},
})
export default class Competition extends Vue {

  protected accepted = false
  protected errorstring: TranslatedText = {'de': '', 'en': ''}

  protected sec = 20;
  protected point = 0;
  protected level = 1;
  protected nextLevel = 5;
  protected click = 0;
  protected speedmoving = 280;
  protected speedrotate = 3000;
  protected rotation = 0;
  protected gamestart = false;
  protected stopper = 0;
  protected d = 0;
  protected score_reaction = 0;

  protected btn1  = true;
  protected btn2  = false;


  protected StartCompetition() {
    if (!this.gamestart) {
      this.gamestart = true;
      this.btn1 = false;
      this.btn2 = false;
      this.stopper = setInterval(this.chrono, 1000);
      this.d = setInterval(this.rotating,10);
    }
  }

  protected async SaveData() {
    try {
      this.errorstring = {de: '', en:''}
      const dpr = await this.$store.dispatch("i/setDataCompetition", {score_reaction: this.score_reaction})
      if (dpr.sv) {

      }else{
        this.errorstring = {
          de: "Feld konnte nicht gesetzt werden.",
          en: "Could not set Field."
        }
      }
    } catch (e) {
      this.errorstring = networkErrorText(e as AppNetworkErrorOther)
    }
  }


  protected chrono() {
    if (this.sec > 0) {
      this.sec--;

      if(this.sec % 2 == 0){
        this.escape()
      }

    } else  {
      clearInterval(this.stopper);
      clearInterval(this.d);
      this.gamestart = false;
      (this.$refs.clicker as HTMLElement).style.top = 0 +"px";
      (this.$refs.clicker as HTMLElement).style.left = 0 +"px";
      (this.$refs.clicker as HTMLElement).style.transform = "rotate("+ 0 +"deg)" ;
      this.setDataToSave()
      this.btn1 = false;
      this.btn2 = true;
      this.sec = 20;
      this.point = 0;
      this.level = 1;
      this.nextLevel = 5;
      this.click = 0;
      this.speedmoving = 280;
      this.speedrotate = 3000;
      this.rotation = 0;
      this.gamestart = false;
      this.stopper = 0;
      this.d = 0;
    }
  }

  protected setDataToSave() {

    this.$store.dispatch('i/setDataRankingE', 5)
    if(this.point > this.score_reaction){
      this.score_reaction = this.point;
      this.SaveData()
    }
  }

  protected points() {

    if (this.gamestart){
      if (this.sec != 0) {

        this.click++;
        this.point += (this.level * 6);
        this.nextLevel = 5 - (this.click % 5);

        if (this.click % 5 == 0 && this.click != 0) {

          this.level++;

          if(this.speedmoving > 30){
            this.speedmoving -= 30;
          }

          if(this.speedrotate > 300){
            this.speedrotate -= 300;
          }

        }

      }
    }
  }

  protected wrongClick(){
    if (this.gamestart && this.sec != 0){
      if(this.point > this.level){
        this.point -= this.level;
      }
    }
  }

  protected escape(){
    if (this.gamestart){
      setTimeout(this.moving,this.speedmoving);
    }
  }

  protected moving() {
    (this.$refs.clicker as HTMLElement).style.top = Math.random() * 255 + "px";
    (this.$refs.clicker as HTMLElement).style.left = Math.random() * 255 + "px";
  }

  protected rotating(){
    this.rotation += 3600/this.speedrotate;
    (this.$refs.clicker as HTMLElement).style.transform = "rotate(" + this.rotation +"deg)";
  }

  protected beforeDestroy() {
    clearInterval(this.stopper);
    clearInterval(this.d);
  }

}
</script>