<template>
  <div class="col-lg-9 col-md-12" :class="{'col-md-6 col-lg-4': agendatype =='entry'}">

    <div v-show="errorstring.de !== ''" class="alert alert-danger mb-5">
      {{ $store.getters.i18n(errorstring) }}
    </div>
    
  <div class="card mb-3">
   <div :class="{'card-header': true,'meeting-accepted': ae.status == 2 && agendatype !='entry', 'meeting-declined': ae.status == 3 && agendatype !='entry', 'meeting-canceled': ae.status == 4 && agendatype !='entry'}">
      <small class="text-muted" style="font-size: 1rem;"><i class="fal fa-calendar-alt"></i> {{formatedDate}} CEST</small>
   </div>

    <div :class="{'card-body card-body-expanded': true}">

      <div class="mb-4" v-if="agendatype !='entry'">
        <button v-if="ae.status != 2 && ae.status != 4 && ae.receiver == this.$store.state.config.me.objvalueid" class="btn btn-primary me-2"  @click.prevent="acceptMeeting" >Accept</button>
        <button v-if="ae.status != 3 && ae.status != 4 && ae.receiver == this.$store.state.config.me.objvalueid" class="btn btn-primary"  @click.prevent="declineMeeting" >Decline</button>
        <button v-if="ae.status != 4 && ae.sender == this.$store.state.config.me.objvalueid" class="btn btn-primary"  @click.prevent="removeMeeting" >Cancel</button>
        <button v-if="ae.status == 2" class="btn btn-primary m-1 float-end"  @click.prevent="call">Call</button>
      </div>
      <a class="pointer" @click="navigateMeeting">
          <p class="card-title bold" v-html="ae.meeting_title"></p>
          <p class="card-title">{{ae.long_summary}}</p>
      </a>
    </div>

    <div class="card-footer">
      <small class="text-muted pointer" @click="navigateMember">
        <i class="fal fa-user"></i>
        {{ae.firstname}} {{ae.lastname}}
      </small>
    </div>
  </div>

  </div>

</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import {Meetings} from "@/store/i/types";
import {DateTime} from "luxon";
import _ from 'lodash';
import {AppNetworkErrorOther, networkErrorText} from "@/crud";
import {TranslatedText} from "@/store/types";

@Component({
  components: {},
})
export default class MeetingEntry extends Vue {
  @Prop() protected ae!: Meetings;
  @Prop() protected agendatype: string | undefined;


  protected call() {
    if (this.ae) {
      this.$emit('call', this.ae)
    }
  }

  get formatedDate(): string {
      return DateTime.fromISO(this.ae.startdate, {locale: 'en-us'}).weekdayLong+', '+DateTime.fromISO(this.ae.startdate).toFormat('h:mm a')+" - "+DateTime.fromISO(this.ae.enddate).toFormat('h:mm a')
  }

  protected navigateMeeting() {
    this.$router.push({name: 'MeetingDetail', params: { meetingid: `${this.ae.objvalueid}` }})
  }

  protected navigateMember() {
    if (this.ae.receiver == this.$store.state.config.me.objvalueid) {
      this.$router.push({name: 'ContactDetail', params: { contactid: `${this.ae.sender}` }})
    }else{
      this.$router.push({name: 'ContactDetail', params: { contactid: `${this.ae.receiver}` }})
    }
  }

  protected errorstring: TranslatedText = {'de': '', 'en': ''}
  
  protected async removeMeeting() {
    try {
      this.errorstring = {de: '', en:''}
      const dpr = await this.$store.dispatch("i/removeMeeting", this.ae)
      setTimeout(() => { this.reset_message()}, 2000);
      if (!dpr) {
        this.errorstring = {
          de: "Could not remove Appointment.",
          en: "Could not remove Appointment."
        }
      }
    } catch (e) {
      this.errorstring = networkErrorText(e as AppNetworkErrorOther)
    }
  }

  protected async acceptMeeting() {
    try {
      this.errorstring = {de: '', en:''}
      const dpr = await this.$store.dispatch("i/acceptMeeting", this.ae)
      setTimeout(() => { this.reset_message()}, 2000);
      if (!dpr) {
        this.errorstring = {
          de: "Could not accept Appointment.",
          en: "Could not accept Appointment."
        }
      }
    } catch (e) {
      this.errorstring = networkErrorText(e as AppNetworkErrorOther)
    }
  }

  protected async declineMeeting() {
    try {
      this.errorstring = {de: '', en:''}
      const dpr = await this.$store.dispatch("i/declineMeeting", this.ae)
      setTimeout(() => { this.reset_message()}, 2000);
      if (!dpr) {
        this.errorstring = {
          de: "Could not decline Appointment.",
          en: "Could not decline Appointment."
        }
      }
    } catch (e) {
      this.errorstring = networkErrorText(e as AppNetworkErrorOther)
    }
  }

  protected reset_message(){
    this.errorstring = {de: '', en:''}
  }

}
</script>