<template>
    <div :class="{'chat-list':true}">
        <VideoChatEntry :chattype="chattype" v-for="(msg, index) in msgs" :cE="msg" :key="'chat_entry' + index + '_' + msg.msgid" />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import VideoChatEntry from "@/components/i/VideoChatEntry.vue";
import {Message} from "@/store/i/types";

@Component({
    components: {
        VideoChatEntry
    }
})
export default class VideoChatList extends Vue {
    @Prop() protected msgs!: Message[]
    @Prop() protected chattype!: "chat"|"questions"

    protected correctScroll: boolean = true

    @Watch('msgs')
    protected async scrollBottom(e?:Event) {
        // Runterscrollen
        if (this.correctScroll) {
            this.$el.removeEventListener('scroll', this.lookIfToHaltAutoscroll)
            await this.$nextTick()
            this.$el.scrollTop = (<HTMLElement>this.$el).scrollHeight - (<HTMLElement>this.$el).clientHeight
            await this.$nextTick()
            this.$el.addEventListener('scroll', this.lookIfToHaltAutoscroll, {passive:true})
        }
    }

    protected lookIfToHaltAutoscroll() {
        const pos = this.$el.scrollTop
        if (pos < 1) {
          this.$emit("reachedtop")
        }
        const desiredPos = (<HTMLElement>this.$el).scrollHeight - (<HTMLElement>this.$el).clientHeight
        this.correctScroll = pos >= desiredPos - 20
    }

    protected mounted() {
        this.scrollBottom()
        window.addEventListener('resize', this.scrollBottom, {passive:true})
        this.$el.addEventListener('scroll', this.lookIfToHaltAutoscroll, {passive:true})
    }

    protected beforeDestroy() {
        window.removeEventListener('resize', this.scrollBottom)
        this.$el.removeEventListener('scroll', this.lookIfToHaltAutoscroll)
    }
}
</script>

<style scoped lang="scss">
  .chat-list {
    margin:0 0 0 0;
  }
</style>