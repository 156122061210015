<template>
  <div class="">

    <div class="col-md-12 col-xl-12">

      <p>
        Please select and save your device settings here:
      </p>


      <form @submit.prevent="setDataOnBoarding4">

        <div class="mb-3" v-if="outputDevices.length > 0">
          <label for="setting_output">Output device</label>
          <select id="setting_output" v-model="vcSettings.output" class="form-select">
            <option v-for="(d, di) in outputDevices" :key="`od-${di}`" :value="d.deviceId">{{ d.label !== '' ? d.label : `Output device ${di+1}` }}</option>
          </select>
        </div>

        <div class="mb-3" v-if="micDevices.length > 0">
          <label for="setting_mic">Microphone</label>
          <select id="setting_mic" v-model="vcSettings.mic" class="form-select">
            <option v-for="(d, di) in micDevices" :key="`md-${di}`" :value="d.deviceId">{{ d.label !== '' ? d.label : `Microphone ${di+1}` }}</option>
          </select>
        </div>

        <div class="mb-3" v-if="camDevices.length > 0">
          <label for="setting_cam">Camera</label>
          <select id="setting_cam" v-model="vcSettings.cam" class="form-select">
            <option v-for="(d, di) in camDevices" :key="`cd-${di}`" :value="d.deviceId">{{ d.label !== '' ? d.label : `Camera ${di+1}` }}</option>
          </select>
        </div>

        <div class="d-flex align-items-center">
          <router-link :to="{name: 'Step2'}" class="btn btn-primary m-1" v-if="this.type == 'step' && $store.state.config.me.tn_type == 8">Back</router-link>
          <router-link :to="{name: 'Step3'}" class="btn btn-primary m-1" v-if="this.type == 'step' && $store.state.config.me.tn_type != 8">Back</router-link>
          <button class="btn btn-primary m-1" type="submit">
            <span v-if="this.type == 'step'">Next</span>
            <span v-else>Save</span>
          </button>
        </div>

      </form>


    </div>

  </div>
</template>
<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {TranslatedText} from "@/store/types";
import {AppNetworkErrorOther, networkErrorText} from "@/crud";
import {JitsiCallRequest, JitsiVideoSettings} from "@/store/member/types";
import _ from 'lodash';

@Component({
  components: {},
})
export default class OnBoarding3 extends Vue {

  @Prop() protected type!: string|undefined

  protected errorstring: TranslatedText = {'de': '', 'en': ''}
  protected successtring: TranslatedText = {'de': '', 'en': ''}

  protected networking_question1: string[] = []
  protected networking_question2: string[] = []

  protected async setDataOnBoarding4() {
    try {
      this.errorstring = {de: '', en:''}
      this.successtring = {de: '', en:''}

      if(this.vcSettings.output != this.originalSettings.output || this.vcSettings.mic != this.originalSettings.mic || this.vcSettings.cam != this.originalSettings.cam){
        this.saveVCSettings();
      }

      if(this.type =="step"){
        this.$router.replace({name: "Welcome"})
      }

    } catch (e) {
      this.errorstring = networkErrorText(e as AppNetworkErrorOther)
    }
  }

  get outputDevices(): (MediaDeviceInfo)[] {
    return this.$store.getters['members/outputDevices']
  }

  get micDevices(): (MediaDeviceInfo)[] {
    return this.$store.getters['members/micDevices']
  }

  get camDevices(): (MediaDeviceInfo)[] {
    return this.$store.getters['members/camDevices']
  }

  protected vcSettings: JitsiVideoSettings = {
    output: '',
    mic: '',
    cam: ''
  }

  get originalSettings(): JitsiVideoSettings {
    return this.$store.getters['members/devicesPicked']
  }

  @Watch("originalSettings")
  protected initalSetSettings() {
    this.vcSettings = {...this.originalSettings}
  }

  protected async saveVCSettings() {
    this.$store.commit('members/setPickedDevices', {...this.vcSettings})
  }

  protected created() {
    this.initalSetSettings()
  }

}
</script>

<style scoped>

</style>