<template>
    <div :class="{
            'chat-area': true,
            'chat-type-chat': (chattype === 'chat'),
            'chat-type-questions': true
        }">
      <VideoChatList :chattype="chattype" :msgs="msgs" @reachedtop="scrollReachedTop"/>
      <div class="enter-area row">
        <div class="col">
          <div class="mb-2" v-if="textPlaceholder2 !== ''">
            <small class="">{{ textPlaceholder2 }}</small>
          </div>
          <div class="form-outline mb-2">
            <input
                type="text"
                class="form-control"
                spellcheck="true"
                autocomplete="off"
                :disabled="disable || disabledByQuestionCount"
                v-model.trim="currentText"
                @keydown="shiftC" @keyup="shiftC(false)" @keypress.enter="sendByEnter"
                :id="`enter-text-${chattype}`"
            />
            <label class="form-label" :for="`enter-text-${chattype}`">
              {{ textPlaceholder }}
            </label>
          </div>
          <div class="mb-2 d-flex">
            <small class="">
              {{ remainingChraracters }}
              {{ $store.getters.i18n({de: 'Zeichen zur Verfügung', en:'characters remaining'}) }}
            </small>
            <button :data-tracking-name=chattype class="ms-auto btn btn-primary" :disabled="disable || disabledByQuestionCount" type="button" @click.stop="send">{{ $store.getters.i18n({de: 'Senden', en: 'Send'}) }}</button>
          </div>
        </div>
      </div>
    </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import VideoChatList from "@/components/i/VideoChatList.vue"
import {Message, AgendaPoint} from "@/store/i/types";
import _ from "lodash";

@Component({
    components: {
        VideoChatList
    }
})
export default class VideoChatArea extends Vue {
    @Prop() protected chattype!: "chat"|"questions"

    protected currentText: string = ''
    protected disable: boolean = false
    protected joinedTime: any = ''
    protected maxmsgs = 20

    get msgs(): Message[] {
      let msgs = []
      if (this.chattype === "questions") {
        msgs = this.$store.state.i.questions
      } else {
        msgs = this.$store.state.i.publicchats
      }

      let cap = this.$store.state.i.currentagenda


      let msgTime = 0

      const neccmsgs = _.orderBy(
          _.filter(
              msgs,
              (m: Message) => m.ts * 1000 > msgTime && m.roomname === this.$store.state.i.mucname
          ),
          [
            (m: Message) => m.ts
          ],
          ["desc"]
      )

      return _.slice(neccmsgs, 0, _.min([this.maxmsgs, neccmsgs.length])).reverse()
    }

    protected async scrollReachedTop() {
      const ccount = this.msgs.length
      const oldest_chat = _.min([0, ..._.map(this.msgs, (m: Message) => m.ts)]) as number
      if (ccount > 0) {
        if (oldest_chat > 0) {
          await this.$store.dispatch("i/getMorePubchats", oldest_chat)
        }
      }
      this.maxmsgs += 5
    }

    get enteredRows(): number {
        let rowAmount = 1
        const testBreaks = /\n/g
        let cb = null

        while ((cb = testBreaks.exec(this.currentText)) !== null) {
            rowAmount++
        }

        return rowAmount
    }

    get usedCharacters() : number {
        const npoints = this.currentText.replace(/[\*\.\:\\\/\?\(\)\&\%\$\§\!\+\#,;]/g, "")
        return npoints.length
    }

    get remainingChraracters() : number {
        return 280 - this.usedCharacters
    }

    get textPlaceholder(): string|null {
        if (this.chattype === "questions") {
          const questcount = this.msgs.length
          return this.$store.getters.i18n({de: 'Ihre Frage', en: 'Your question'})
        }

      return this.$store.getters.i18n({de: 'Ihre Nachricht', en: 'Your message'})
    }
    get textPlaceholder2(): string|null {
        if (this.chattype === "questions") {
          return this.$store.getters.i18n({de: '', en: ''})
        }

      return this.$store.getters.i18n({de: 'Bitte beachten Sie, dass Ihre Nachricht für alle Teilnehmer sichtbar ist.', en: 'Please be advised that your message will be displayed to all other participants.'})
    }

    get disabledByQuestionCount(): boolean {
        if (!this.$store.state.i.connected) {
          return true
        }

        return false

       /* return (
            this.chattype === "questions" && this.msgs.length >= 5
        )*/
    }

    protected shiftDown: boolean = false

    protected shiftC(nd: boolean|Event) {
        if (typeof nd === "boolean") {
            this.shiftDown = nd
            return
        }
        switch ((<KeyboardEvent>nd).key.toLowerCase()) {
            case "shift":
                this.shiftDown = true
                break
            case "enter":
                break
            default:
                this.shiftDown = false
        }
    }

    protected sendByEnter(e: Event) {
        if (!this.shiftDown) {
            e.preventDefault()
            this.send(e)
        }
    }

    protected async send(e: Event) {
        if (
            !this.disabledByQuestionCount &&
            this.usedCharacters > 1 &&
            this.remainingChraracters >= 0
        ) {
            this.disable = true
            if (this.chattype === "questions") {
              await this.$store.dispatch('i/pushInteractionMessage', this.currentText)
            } else {
              await this.$store.dispatch('i/pushPublicChatMessage', this.currentText)
            }
            this.disable = false
            this.currentText = ''
        }
    }

    protected created() {
      this.joinedTime = new Date().getTime();
    }
}
</script>
