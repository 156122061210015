<template>
    <section class="clearfix">
      <div class="chat-message">
        <div v-if="!ismine" class="card bg-other rounded w-75 z-depth-0 mb-0">
          <div class="card-body p-2">
            <p class="card-text text-black">{{ m.body }}</p>
          </div>
        </div>
        <p v-if="!ismine" >{{getDate(m.ts)}}</p>

        <div  v-if="ismine" class="d-flex justify-content-end">
          <div class="card bg-primary rounded w-75 z-depth-0 mb-0">
            <div class="card-body p-2">
              <p class="card-text">{{ m.body }}</p>
            </div>
          </div>
        </div>
        <p v-if="ismine" class="d-flex justify-content-end">{{getDate(m.ts)}}</p>
      </div>
    </section>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {ChatContact, DMMessage, SupportMessage} from "@/store/i/types";
import {EveUser} from "@/store/types";
import {DateTime} from "luxon";
import _ from "lodash"

@Component
export default class ChatContactMessageSmall extends Vue {
    @Prop() protected m!: DMMessage|SupportMessage

    get user():ChatContact|EveUser|null {
        if (this.m && this.m.sender && this.m.sender !== "me" && this.m.sender !== "support") {
            const fuser = _.find(this.$store.state.i.contactschats, (c) => c.fbuid === (<DMMessage>this.m).sender)
            if (fuser) {
                return fuser
            }
            return null
        } else {
            return null
        }
    }

    get ismine(): boolean {
        if (this.m) {
          switch (this.m.sender) {
            case "support":
              return false
            case "me":
            case this.$store.state.config.fbuid:
              return true
            default:
              return <boolean>(this.user && this.user.fbuid === this.$store.state.config.fbuid)
          }
        }
        return false
    }

    get issupport(): boolean {
      if (this.m) {
        switch (this.m.sender) {
          case "support":
            return true
          default:
            return false
        }
      }
      return false
    }


    protected getDate(date : number) {

      const setM = DateTime.fromSeconds(date)
      const ndate = DateTime.fromJSDate(this.$store.state.tick)

      return setM
          .setLocale(this.$store.getters.i18n({de:'de',en:'en'}))
          .toLocaleString((DateTime.DATETIME_SHORT)) + this.$store.getters.i18n({de:' Uhr',en:' '})

    }


    protected mounted() {
        if (this.m && !this.m.seen && !this.ismine) {
            const vm = this
            const updatePL = {
                flag: 'SEEN',
                msgid: this.m?.uuid
            }
            window.setTimeout(() => {
                vm.$store.dispatch(this.issupport ? 'i/setSupportFlag' : 'i/setDMFlag', updatePL)
            }, 2000)
        }
    }
}
</script>

<style scoped lang="scss">

    .chat-room.small-chat .profile-photo.message-photo {
        margin-top:0;
    }

    .msgwrapper {
        max-width:90%;
    }

    .bg-other{
      background-color: #dddddd !important;
    }

</style>
