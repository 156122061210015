import {GetterTree} from "vuex";
import {RootState} from "@/store/types";
import {
    InteractionState,
    ChatContact,
    ConnectionNetworking,
    DMMessage,
    Message,
    SupportMessage,
    VotingBallot,
    AgendaPoint,
    InteractionAlert,
    ContactsNetworking,
    Meetings, RankingObject
} from "@/store/i/types";
import _ from "lodash";

const getters: GetterTree<InteractionState, RootState> = {
    questionsbyage: (state) : Message[] => {
        if (state.questions.length > 0) {
            return _.orderBy(
                state.questions,
                [
                    (q) => q.ts
                ],
                ['asc']
            )
        }
        return []
    },
    dmsbyuser: (state, getters, rootState) => (u: string) : DMMessage[] => {
        if (u !== '' && state.dmmessages.length > 0) {
            return _.sortBy(
                _.filter(
                    state.dmmessages,
                    (m:DMMessage) => {
                        return (
                            (m.sender === u && m.receiver === rootState.config.fbuid)
                            ||
                            (m.receiver === u && m.sender === rootState.config.fbuid)
                        )
                    }
                ),
                [(m:DMMessage) => m.ts],
                ['desc']
            )
        }

        return []
    },
    supportchats: (state) : SupportMessage[] => {
        if (state.supportmessages.length > 0) {
            return _.sortBy(
                state.supportmessages,
                [(m:SupportMessage) => m.ts],
                ['desc']
            )
        }
        return []
    },
    unreadchats: (state, getters, rootState): number => {
        const um = [
            ..._.filter(state.supportmessages, (m: SupportMessage) => !m.seen && m.receiver == 'me'),
            ..._.filter(state.dmmessages, (m: DMMessage) => !m.seen && m.receiver === rootState.config.fbuid)
        ]
        return um.length
    },
    unreadcontactschats: (state, getters, rootState): number => {
        const um = [
            ..._.filter(state.dmmessages, (m: DMMessage) => !m.seen && m.receiver === rootState.config.fbuid)
        ]
        return um.length
    },
    sortedContacts: (state, getters, rootState): ChatContact[] => {
        if (state.contactschats.length > 0) {
            return _.orderBy(
                _.filter(
                    state.contactschats,
                    (c:ChatContact) => (c.fbuid != rootState.config.fbuid)
                ),
                [
                    (cu: ChatContact) => {
                        const cu_msg: DMMessage[] = getters.dmsbyuser(cu.fbuid)
                        if (cu_msg.length > 0) {
                            const lastmsg = _.last(cu_msg)
                            if (lastmsg) {
                                return lastmsg.ts
                            }
                        }

                        return 1
                    }
                ],
                ['desc']
            )
        }
        return []
    },
    sortedContactsNetworking: (state, getters, rootState): ContactsNetworking[] => {
        return _.orderBy(
            _.filter(
                state.contacts_networking,
                (gb: ContactsNetworking) => gb.fbuid !== rootState.config.fbuid
            ), ["lastname", "firstname"], ["asc","asc"]
        )
    },
    sortedConnectionsNetworking: (state, getters, rootState): ConnectionNetworking[] => {
        return _.orderBy(
            _.filter(
                state.connections_networking,
                (gb: ConnectionNetworking) => gb.fbuid !== rootState.config.fbuid
            ), ["lastname", "firstname"], ["asc","asc"]
        )
    },
    sortedMeetings: (state, getters, rootState): Meetings[] => {
        return _.orderBy(
            _.filter(
                state.meetings,
                (gb: Meetings) => gb.fbuid !== rootState.config.fbuid
            ), ["lastname", "firstname"], ["asc","asc"]
        )
    },
    ballotsForVoting: (state) => (room: string, vuid: string): VotingBallot[] => {
        return _.filter(
            state.ballots,
            b => b.roomname === room && b.voteuid === vuid
        )
    },
    ballotsForVotingAnswer: (state, getters) => (room: string, vuid: string, ansuid: string): VotingBallot[] => {
        return _.uniqBy(
            _.filter(
                getters.ballotsForVoting(room, vuid),
                b => b.answer === ansuid
            ),
            b => b.fbuid
        )
    },
    myBallotsForVoting: (state, getters, rootState) => (room: string, vuid: string): VotingBallot[] => {
        return _.filter(
            getters.ballotsForVoting(room, vuid),
            b => b.forfbuid === rootState.config.fbuid
        )
    },
    myPointsObject: (state): RankingObject => {
        return {
            ranking_a: state.me.ranking_a,
            ranking_b: state.me.ranking_b,
            ranking_c: state.me.ranking_c,
            ranking_d: state.me.ranking_d,
            ranking_e: state.me.ranking_e,
            ranking_f: state.me.ranking_f,
            ranking_g: state.me.ranking_g,
            ranking_h: state.me.ranking_h,
            ranking_i: state.me.ranking_i,
            ranking_j: state.me.ranking_j,
            ranking_k: state.me.ranking_k,
            ranking_l: state.me.ranking_l,
            ranking_m: state.me.ranking_m,
        };
    },
    myPoints: (state) => {
        return state.me.ranking_a
            + state.me.ranking_b
            + state.me.ranking_c
            + state.me.ranking_d
            + state.me.ranking_e
            + state.me.ranking_f
            + state.me.ranking_g
            + state.me.ranking_h
            + state.me.ranking_i
            + state.me.ranking_j
            + state.me.ranking_k
            + state.me.ranking_l
            + state.me.ranking_m
    },
    orderedAgendaPoints: (state, getters, rootState) => {
        return _.orderBy(
            _.filter(
                state.agenda,
                ap => ap.dbstate > -1
            ),
            [
                ap => (new Date(ap.startdate)).getTime(),
                ap => (new Date(ap.enddate)).getTime(),
                ap =>  ap.eventtype

            ],
            [
                "asc", "asc", "desc"
            ]
        )
    },
    orderedMeetings: (state, getters, rootState) => {
        return _.orderBy(
            _.filter(
                state.meetings,
                ap => ap.dbstate > -1
            ),
            [
                ap => (new Date(ap.startdate)).getTime(),
                ap => (new Date(ap.enddate)).getTime(),
                ap =>  ap.status
            ],
            [
                "asc", "asc", "desc"
            ]
        )
    },
    orderedWorkshopsPoints: (state, getters, rootState) => {
        return _.orderBy(
            _.filter(
                state.agenda,
                ap => ap.dbstate > -1
            ),
            [
                ap => (new Date(ap.startdate)).getTime(),
                ap => (new Date(ap.enddate)).getTime()
            ],
            [
                "asc", "asc"
            ]
        )
    },
    orderedActiveToasts: (state, getters, rootState): InteractionAlert[] => {
        const needed_rooms: string[] = [...rootState.config.rooms]
        const ntime = new Date()
        ntime.setTime(rootState.tick.getTime())
        if (state.mucname && state.mucname !== "") {
            needed_rooms.push(state.mucname)
        }

        return _.orderBy(
            _.filter(
                state.toasts,
                (ta: InteractionAlert) => (
                    !ta.removed &&
                    !ta.queued &&
                    (!ta.validfrom || ta.validfrom < ntime) &&
                    (!ta.validto || ta.validto > ntime) &&
                    needed_rooms.some((rn: string) => rn === ta.validforroom)
                )
            ),
            [t => t.got ? t.got.getTime() : Date.now()],
            ["asc"]
        )
    },
    unhiddenToasts: (state, getters): InteractionAlert[] => {
        return _.filter(
            getters.orderedActiveToasts,
            (t) => !t.hidden && !t.removed && !t.queued
        )
    },
    currentAgendaPoint: (state, getters, rootState): AgendaPoint|undefined=> {
        if (!rootState.tick) {
            return undefined
        }
        const ntime = new Date()
        ntime.setTime(rootState.tick.getTime())

        return _.find(
            state.agenda,
            (a: AgendaPoint) => {
                return (
                    ntime.getTime() >= (new Date(a.startdate)).getTime() &&
                    ntime.getTime() <= (new Date(a.enddate)).getTime() &&
                    a.roomname == state.mucname &&
                    a.dbstate != -1
                )
            }
        )
    },
    forcedToPlenum: (state, getters, rootState): boolean => {
        if (!rootState.config.me.force_to_plenum) {
            return false
        }

        const cfagp = _.find(
            state.agenda,
            (a: AgendaPoint) => {
                if (!a || !a.force_plenum) {
                    return false
                }
                const apduration = {
                    s: new Date(a.startdate),
                    e: new Date(a.enddate)
                }

                if (typeof a.force_plenum_time === "string" && a.force_plenum_time !== "") {
                    apduration.s = new Date(a.force_plenum_time)
                }

                apduration.e.setTime(apduration.e.getTime() - (10 * 60 * 1000))

                return (
                    rootState.tick.getTime() >= apduration.s.getTime() &&
                    rootState.tick.getTime() <= apduration.e.getTime() &&
                    a.dbstate != -1 &&
                    a.roomname == "muc-livestream"
                )
            }
        )

        return !!(cfagp)
    },
   redirectToPlenum: (state, getters, rootState): boolean => {
        if (!rootState.config.me.redirect_to_plenum) {
            return false
        }

        const cfagp = _.find(
            state.agenda,
            (a: AgendaPoint) => {
                if (!a || !a.redirect_plenum) {
                    return false
                }
                const apduration = {
                    s: new Date(a.startdate),
                    e: new Date(a.enddate)
                }

                if (typeof a.redirect_plenum_time === "string" && a.redirect_plenum_time !== "") {
                    apduration.s = new Date(a.redirect_plenum_time)
                }

                apduration.e.setTime(apduration.e.getTime() - (10 * 60 * 1000))

                return (
                    rootState.tick.getTime() >= apduration.s.getTime() &&
                    rootState.tick.getTime() <= apduration.e.getTime() &&
                    a.dbstate != -1 &&
                    a.roomname == "muc-livestream"
                )
            }
        )

        return !!(cfagp)
    }
}

export default getters
