import {MutationTree} from "vuex";
import {LandingPageState, ProgramHighlight, Speaker, Ticket} from "@/store/landingpage/types";

const mutations: MutationTree<LandingPageState> = {
    clean(state) {
        state.tickets = []
    },

    setTickets(state, pl:Ticket[]) {
        state.tickets = pl;
    },
    setShopsAvailable(state, isAvailable: boolean) {
        state.shopsAvailable = isAvailable;
    },

    setProgramHighlights(state, entries: ProgramHighlight[]) {
        state.programHighlights = entries
    },

    setHighlightedSpeakers(state, entries: Speaker[]) {
        state.highlightedSpeakers = entries
    }
}

export default mutations;