import {GetterTree} from "vuex";
import {RootState, TranslatedText} from "@/store/types";

const getters: GetterTree<RootState, RootState> = {
    i18n: (state) => (t:TranslatedText) : string => {
        const setL = state.config.lang.substr(0,2)
        if(t.en){
            return t.en
        }else{
            if (t.hasOwnProperty(setL)) {
                return t[setL]
            } else if (t.hasOwnProperty('en')) {
                return t.en
            }
            return ''
        }
    }
}

export default getters