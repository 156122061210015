<template>
  <div class="">

    <div class="col-md-12 col-xl-12">

      <form @submit.prevent="setDataOnBoarding2">

        <div v-show="successtring.de !== ''" class="alert alert-success col-lg-12">
          {{ $store.getters.i18n(successtring) }}
        </div>

        <div v-show="errorstring.de !== ''" class="alert alert-danger mb-5">
          {{ $store.getters.i18n(errorstring) }}
        </div>


        <div class="mb-3">
          <b>First name:</b> {{ $store.state.i.me.firstname }}
        </div>
        <div class="mb-3">
          <b>Last name:</b> {{ $store.state.i.me.lastname }}
        </div>
        <div class="mb-4">
          <b>E-Mail adress:</b> {{ $store.state.i.me.email }}
        </div>

        <div class="d-flex align-items-center mb-2">
          <div class="me-auto form-check">
            <input class="form-check-input" v-model="$store.state.i.me.show_email" type="checkbox" name="show_email" id="show_email">
            <label class="form-check-label" for="show_email">
              Show E-Mail adress in my networking profile
            </label>
          </div>
        </div>

        <div class="mb-2">
          <label class="form-label" >Position</label>
          <input type="text" v-model="$store.state.i.me.position" class="form-control" />
        </div>

        <div class="mb-2">
          <label class="form-label" >Organization</label>
          <input type="text" v-model="$store.state.i.me.orgname" class="form-control" />
        </div>

        <div class="mb-2">
          <label class="form-label" >Phone</label>
          <input type="text" v-model="$store.state.i.me.phone" class="form-control" />
        </div>

        <div class="mb-4">
          <label class="form-label" >Country</label>
          <select class="form-control"  v-model="$store.state.i.me.country">
            <option v-for="(e, ei) in $store.state.i.me.countrylist" :value=ei>{{e}}</option>
          </select>

        </div>


        <div class="d-flex align-items-center mb-2">
          <div class="me-auto form-check">
            <input class="form-check-input" v-model="$store.state.i.me.show_vcard" type="checkbox" name="show_vcard" id="show_vcard">
            <label class="form-check-label" for="show_vcard">
              Show Vcard download
            </label>
          </div>
        </div>

        <div class="mb-2">
          <label class="form-label" >Twitter</label>
          <input type="text" v-model="$store.state.i.me.twitter" class="form-control" />
        </div>

        <div class="mb-2">
          <label class="form-label" >LinkedIn</label>
          <input type="text" v-model="$store.state.i.me.linkedin" class="form-control" />
        </div>

        <div class="mb-2">
          <label class="form-label" >Facebook</label>
          <input type="text" v-model="$store.state.i.me.facebook" class="form-control" />
        </div>

        <div class="mb-5">
          <label class="form-label" >CV (max. 400 characters)</label>
          <textarea v-model="$store.state.i.me.cv" class="form-control" />
        </div>



        <div class="d-flex align-items-center">
          <router-link :to="{name: 'Step1'}" class="btn btn-primary m-1" v-if="this.type == 'step'">Back</router-link>
          <button class="btn btn-primary m-1" type="submit">
            <span v-if="this.type == 'step'">Next</span>
            <span v-else>Save</span>
          </button>
        </div>


      </form>

    </div>

  </div>
</template>
<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {TranslatedText} from "@/store/types";
import {AppNetworkErrorOther, networkErrorText} from "@/crud";
import _ from 'lodash';

@Component({
  components: {},
})
export default class OnBoarding2 extends Vue {

  @Prop() protected type!: string|undefined

  protected errorstring: TranslatedText = {'de': '', 'en': ''}
  protected successtring: TranslatedText = {'de': '', 'en': ''}

  protected async setDataOnBoarding2() {
    try {

      this.errorstring = {de: '', en:''}
      this.successtring = {de: '', en:''}

      const dpr = await this.$store.dispatch("i/setDataOnBoarding2", {
            position: this.$store.state.i.me.position,
            orgname: this.$store.state.i.me.orgname,
            show_email: this.$store.state.i.me.show_email ? 1 : 0,
            phone: this.$store.state.i.me.phone,
            country: this.$store.state.i.me.country,
            show_vcard: this.$store.state.i.me.show_vcard ? 1 : 0,
            twitter: this.$store.state.i.me.twitter,
            facebook: this.$store.state.i.me.facebook,
            linkedin: this.$store.state.i.me.linkedin,
            cv: this.$store.state.i.me.cv,
          }
      )

      setTimeout(() => { this.reset_message()}, 2000);

      if (dpr.sv) {
        this.successtring = {
          de: "Data saved.",
          en: "Data saved."
        }
        if(this.type =="step"){
          if(this.$store.state.config.me.tn_type != 8){
            this.$router.replace({name: "Step3"})
          }else{
            this.$router.replace({name: "Step4"})
          }
        }
      } else {
        this.errorstring = {
          de: "Could not set data.",
          en: "Could not set data."
        }
      }
    } catch (e) {
      this.errorstring = networkErrorText(e as AppNetworkErrorOther)
    }
  }


  protected reset_message(){
    this.errorstring = {de: '', en:''}
    this.successtring = {de: '', en:''}
  }



}
</script>

<style scoped>

</style>