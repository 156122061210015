<template>
    <div :class="{'col-lg-4 row': type =='welcome', 'col-lg-6 row': type =='overview'}">
      <div class="col-5 mb-5">
        <a @click="navigateMember" class="pointer">
          <div class="circular_image">
            <img :src="`/files/members/${member.objvalueid}/foto.jpg?nocache=${(new Date()).getHours()}`" onerror="this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';">
          </div>
        </a>
      </div>
      <div class="col-7 mb-5 ipadfx2">
        <a @click="navigateMember" class="pointer">
          <div class="Name-Nachname mb-1">  {{ member.firstname }}    {{ member.lastname }}</div>
          <div class="Role copydetailxs mb-1 bold"> {{ title(member.position) }}</div>
          <div class="Role copydetailxs"> {{ title(member.orgname) }}</div>
        </a>
      </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import {MemberEntry} from "@/store/i/types";
import _ from "lodash";

@Component({
  components: {},
})
export default class SpeakerEntry extends Vue {
  @Prop() protected member!: MemberEntry
  @Prop() protected type: number | undefined;

  protected navigateMember() {
    this.$router.push({name: 'SpeakerDetail', params: { speakerid: `${this.member.objvalueid}` }})
  }

  protected title(title: string): string {
    title = _.replace(title, /&#34;/g, '"')
    title = _.replace(title, /&#39;/g, "'")
    return title
  }

}
</script>