import {ActionTree} from "vuex";
import {RootState} from "@/store/types";
import {
    MembersState,
    JitsiCallRequest
} from "@/store/member/types";
import crud from "@/crud";
import OfflineDB from "@/store/member/offlinedb";
import _ from "lodash";
import {getFireDB} from "@/firebaseConnections";

// @ts-ignore
import JitsiMeetJS from "@/vendor/jitsi/lib-jitsi-meet.min"
import firebase from "firebase/app";

const actions: ActionTree<MembersState, RootState> = {
    async populate({commit, dispatch}) {
        if (JitsiMeetJS.mediaDevices.isDeviceListAvailable()) {
            JitsiMeetJS.mediaDevices.enumerateDevices((dL: (MediaDeviceInfo)[]) => {
                commit("setMediaDevices", dL)
            })

            JitsiMeetJS.mediaDevices.addEventListener(JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED, () => {
                JitsiMeetJS.mediaDevices.enumerateDevices((dL: (MediaDeviceInfo)[]) => {
                    commit("setMediaDevices", dL)
                })
            })

            commit("setRTCSupport", JitsiMeetJS.isWebRtcSupported())
        }


        await OfflineDB.populateStore(commit)
        await dispatch("fetchCalls", true)
    },

    async empty({commit}) {
        commit("empty")
        await OfflineDB.empty()
        commit("empty")
    },

    async addCallListeners({state, rootState, commit, dispatch}) {
        const firedb = await getFireDB()
        const changeoffset = _.max([
            0,
            ..._.map(
                state.callHistory,
                (e) => e.defaulttimestamp
            )
        ]) as number
        const tref = firedb.ref(`/announcetouser/${rootState.config.fbuid}/calls`)
            .orderByChild("ts")
            .startAfter(changeoffset)

        const histChanges = (snapshot: firebase.database.DataSnapshot) => {
            const callobjvalueid = parseInt(snapshot.key as string, 10)
            const newdata = snapshot.val()
            const olddata = _.find(state.callHistory, (c: JitsiCallRequest) => c.objvalueid === callobjvalueid)

            if (olddata) {
                olddata.defaulttimestamp = newdata.ts
                if (newdata.connection_start) {
                    olddata.connection_start = newdata.connection_start
                }
                if (newdata.connection_end) {
                    olddata.connection_end = newdata.connection_end
                }
                commit("addCallToHistory", {...olddata})
            } else {
                dispatch("fetchCalls", false)
            }
        }

        tref.on("child_added", histChanges)
        tref.on("child_changed", histChanges)
    },




    async startCall({state, commit}, pl: number) {
        try {
            commit("setActiveCall", null)
            const scres = await crud("/members/startcall", {calling: pl})
            if (scres.call) {
                commit("addCallToHistory", scres.call)
                commit("setActiveCall", scres.call)
            }
        } catch (e) {
            throw e
        }
    },

    async startCallReceiver({state, commit}, pl: number) {
        try {
            commit("setActiveCall", null)
            const scres = await crud("/members/startcallreceiver", {calling: pl})
            if (scres.call) {
                const calldata = _.find(state.callHistory, (c: JitsiCallRequest) => c.objvalueid === pl)
                if (calldata) {
                    const combinedcall = {
                        ...calldata,
                        ...scres.call
                    }
                    commit("addCallToHistory", combinedcall)
                    commit("setActiveCall", combinedcall)
                } else {
                    commit("addCallToHistory", scres.call)
                    commit("setActiveCall", scres.call)
                }
            }
        } catch (e) {
            throw e
        }
    },

    async joinedCall({state, commit}, pl: number) {
        const fcall = _.find(state.callHistory, (c: JitsiCallRequest) => c.objvalueid === pl)
        if (fcall) {
            try {
                fcall.connection_start = (new Date()).toISOString()
                commit("addCallToHistory", fcall)
                const scres = await crud("/members/joincall", {callid: pl})
                if (scres.call) {
                    const calldata = _.find(state.callHistory, (c: JitsiCallRequest) => c.objvalueid === pl)
                    if (calldata) {
                        const combinedcall = {
                            ...calldata,
                            ...scres.call
                        }
                        commit("addCallToHistory", combinedcall)
                    } else {
                        commit("addCallToHistory", scres.call)
                    }
                }
            } catch (e) {

            }
        }
    },

    async endCall({state, commit}, pl: number) {
        commit("setActiveCall", undefined)
        const fcall = _.find(state.callHistory, (c: JitsiCallRequest) => c.objvalueid === pl)
        if (fcall) {
            try {
                fcall.connection_end = (new Date()).toISOString()
                commit("addCallToHistory", fcall)
                const scres = await crud("/members/endcall", {callid: pl})
                if (scres.call) {
                    commit("addCallToHistory", scres.call)
                }
            } catch (e) {

            }
        }
    },
    async endCallReceiver({state, commit}, pl: number) {
        commit("setActiveCall", undefined)
        const fcall = _.find(state.callHistory, (c: JitsiCallRequest) => c.objvalueid === pl)
        if (fcall) {
            try {
                fcall.connection_end = (new Date()).toISOString()
                commit("addCallToHistory", fcall)
                const scres = await crud("/members/endcallreceiver", {callid: pl})
                if (scres.call) {
                    commit("addCallToHistory", scres.call)
                }
            } catch (e) {

            }
        }
    },

    async fetchCalls({state, commit, dispatch}, pl = true) {
        try {
            const changeoffset = _.max([
                    0,
                    ..._.map(
                        state.callHistory,
                        (e) => e.defaulttimestamp
                    )
                ]) as number

            const fc = await crud('/members/getMyCalls', { changeoffset })
            if (fc.length > 0) {
                fc.forEach((c: JitsiCallRequest) => {
                    if (!state.currentCall || c.objvalueid !== state.currentCall.objvalueid) {
                        commit('addCallToHistory', c)
                    }
                })
            }
        } catch (e) {
            throw e
        }
    },

}

export default actions