<template>
  <div class="col">
    <JitsiCall type="member" v-if="$store.state.members.currentCall"/>

    <div class="row">
      <div class="col-lg-9">
        <p><input placeholder="Search title or speaker name" type="search" v-model.trim="searchtext"
                  class="form-control"></p>

        <div class="row mt-3">
          <div class="col-8">
              
              
            <a href="/files/gmf_programoverview.pdf" class="btn btn-primary m-0 float-start me-2" target="_blank"><i
                class="far fa-arrow-to-bottom"></i> Download full program</a>
              <a href="/files/gmf_siteplan.pdf" class="btn btn-primary m-0 float-start" target="_blank"><i
                      class="far fa-arrow-to-bottom"></i> Download site plan </a>
          </div>
          <div class="col-4">
            <a class="resetfilter btn btn-outline-primary float-end p-3" @click="clearFilter()"><i class="fal fa-trash"></i> Clear
              Filter</a>
          </div>
        </div>

        <h5 class="mt-3">Event type</h5>
        <span class="badge eventtype1 mt-1" @click="toggleEventTypeFilter('plenary_sessions')">
          <i :class="[!activeFilters.eventTypes.plenary_sessions ? 'fal fa-circle' : 'fas fa-check-circle']"></i>
          Plenary sessions
        </span>
        <span class="badge event-type-7 mt-1" @click="toggleEventTypeFilter('breakout_sessions')">
          <i :class="[!activeFilters.eventTypes.breakout_sessions ? 'fal fa-circle' : 'fas fa-check-circle']"></i>
          Breakout sessions
        </span>
        <span class="badge eventtype4 mt-1" @click="toggleEventTypeFilter('networking')">
          <i :class="[!activeFilters.eventTypes.networking ? 'fal fa-circle' : 'fas fa-check-circle']"></i>
          Networking
        </span>
        <span class="badge eventtype5 mt-1" @click="toggleEventTypeFilter('exhibitor_events')">
          <i :class="[!activeFilters.eventTypes.exhibitor_events ? 'fal fa-circle' : 'fas fa-check-circle']"></i>
          Exhibitors events
        </span>

        <h5 class="mt-3">Date</h5>
        <span class="badge day1 mt-1" @click="toggleDayFilter('day1')">
              <i :class="[!activeFilters.days.day1 ? 'fal fa-circle' : 'fas fa-check-circle']"></i>
              Monday, June 17
            </span>
        <span class="badge day2 mt-1" @click="toggleDayFilter('day2')">
              <i :class="[!activeFilters.days.day2 ? 'fal fa-circle' : 'fas fa-check-circle']"></i>
              Tuesday, June 18
            </span>

      </div>
    </div>


    <div class="mt-4" v-if="noDaysSelected || activeFilters.days.day1">
      <h4 class="font-weight-bold pb-3">MONDAY</h4>
      <div class="row">
          <div v-if="agendaPointsDay1.length == 0  && this.$route.name === 'Mediathek'">
              <p>No videos on demand available yet.</p>
          </div> 
          <AgendaEntry v-for="(ae, aeindex) in agendaPointsDay1" :key="`agp${aeindex}`" :ae="ae"/>
            <div v-if="this.$route.name === 'Calendar'">
              <h5 class="pb-2 font-weight-bold">Appointments</h5>
              <MeetingEntry v-for="(ae, aeindex) in meetings1" :key="`agp_1${aeindex}`" :ae="ae"/>
              <MeetingEntry v-for="(ae, aeindex) in meetings2" :key="`agp_2${aeindex}`" :ae="ae" @call="pickusercall"/>
              <MeetingEntry v-for="(ae, aeindex) in meetings3" :key="`agp_3${aeindex}`" :ae="ae"/>
              <MeetingEntry v-for="(ae, aeindex) in meetings4" :key="`agp_4${aeindex}`" :ae="ae"/>
            </div>
      </div>
    </div>

    <div class="mt-4" v-if="noDaysSelected || activeFilters.days.day2">
        <h4 class="font-weight-bold pb-3">TUESDAY</h4>

        <div class="row">
            <div v-if="agendaPointsDay2.length == 0  && this.$route.name === 'Mediathek'">
                <p>No videos on demand available yet.</p>
            </div>
            <AgendaEntry v-for="(ae, aeindex) in agendaPointsDay2" :key="`agp${aeindex}`" :ae="ae"/>
            <div v-if="this.$route.name === 'Calendar'">
              <h5 class="pb-2 font-weight-bold">Appointments</h5>

              <MeetingEntry v-for="(ae, aeindex) in meetings1_2" :key="`agp_1_2${aeindex}`" :ae="ae"/>
              <MeetingEntry v-for="(ae, aeindex) in meetings2_2" :key="`agp_2_2${aeindex}`" :ae="ae" @call="pickusercall"/>
              <MeetingEntry v-for="(ae, aeindex) in meetings3_2" :key="`agp_3_2${aeindex}`" :ae="ae"/>
              <MeetingEntry v-for="(ae, aeindex) in meetings4_2" :key="`agp_4_2${aeindex}`" :ae="ae"/>
            </div>
        </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import AgendaEntry from "@/components/AgendaEntry.vue"
import MeetingEntry from "@/components/MeetingEntry.vue"
import JitsiCall from "@/components/jitsi/JitsiCall.vue"
import {AgendaPoint, Meetings, RankingObject} from "@/store/i/types";
import _ from "lodash";
import {JitsiCallRequest} from "@/store/member/types";
import {NavigationGuardNext, Route} from "vue-router";

interface Days {
  day1: boolean;
  day2: boolean;
}

interface EventTypes {
  networking: boolean;
  exhibitor_events: boolean;
  plenary_sessions: boolean;
  breakout_sessions: boolean;
}

type EventTypeKey = keyof EventTypes;

const eventTypeMap: Record<EventTypeKey, number> = {
  networking: 4,
  exhibitor_events: 5,
  plenary_sessions:6,
  breakout_sessions:7
}

interface Filters {
  eventTypes: EventTypes;
  days: Days;
}

@Component({
  name: 'Agenda',
  components: {AgendaEntry, MeetingEntry, JitsiCall},
  beforeRouteLeave(to, from, next) {
    if ((this as any).navguard) {
      (this as any).navguard(to, from, next)
    } else {
      next()
    }
  }
})

export default class AgendaOverview extends Vue {


    
  @Prop() protected agendatype: string | undefined;

  protected activeTabName = 0
  protected searchtext: string = ''
  protected eventfilter = ''

  protected activeFilters: { eventTypes: EventTypes, days: Days } = {
    eventTypes: {
      networking: false,
      exhibitor_events: false,
      plenary_sessions: false,
      breakout_sessions: false
    },
    days: {
      day1: false,
      day2: false,
    }
  }

  protected active: string[] = []

  get noDaysSelected(): boolean {
    return Object.values(this.activeFilters.days).filter((v) => v).length === 0;
  }

  get agendaPointsDay1(): AgendaPoint[] {
    // alle items für den tag
    
      
    let items: AgendaPoint[] = _.filter(
        this.$store.getters['i/orderedAgendaPoints'],
        (ap: AgendaPoint) => ap.dbstate > -1 && (new Date(ap.startdate)).toDateString() == "Mon Jun 17 2024"
    )
      if(this.agendatype == "calendar") {

          items = _.filter(
              items,
              (ap: AgendaPoint) => _.has(this.$store.state.i.me.fav_agenda_items, ap.objvalueid) && new Date(ap.enddate).getTime() > new Date().getTime()
          )
      }

      if(this.agendatype == "video") {

          items = _.filter(
              items,
              (ap: AgendaPoint) => ap.dbstate > -1 && (new Date(ap.startdate)).toDateString() == "Mon Jun 17 2024" && ap.show_video

          )
      }


      return this.filterItems(items);
  }
  
  

  get agendaPointsDay2(): AgendaPoint[] {
    let items = _.filter(
        this.$store.getters['i/orderedAgendaPoints'],
        (ap: AgendaPoint) => ap.dbstate > -1 && (new Date(ap.startdate)).toDateString() == "Tue Jun 18 2024"
    )
      if(this.agendatype == "calendar") {
          items = _.filter(
              items,
              (ap: AgendaPoint) => _.has(this.$store.state.i.me.fav_agenda_items, ap.objvalueid) && new Date(ap.enddate).getTime() > new Date().getTime()
          )


      }

      if(this.agendatype == "video") {

          items = _.filter(
              items,
              (ap: AgendaPoint) => ap.dbstate > -1 && (new Date(ap.startdate)).toDateString() == "Tue Jun 18 2024" && ap.show_video

          )
      }
      
      
    return this.filterItems(items);
  }

  get meetings1(): Meetings[] {
    return _.filter(
        this.$store.getters['i/orderedMeetings'],
        (ap: Meetings) => ap.dbstate > -1 && ap.status == 1 && new Date(ap.enddate).getTime() > new Date().getTime()
            && (new Date(ap.startdate)).toDateString() == "Mon Jun 17 2024"
    );
  }

  get meetings2(): Meetings[] {
    return _.filter(
        this.$store.getters['i/orderedMeetings'],
        (ap: Meetings) => ap.dbstate > -1 && ap.status == 2 && new Date(ap.enddate).getTime() > new Date().getTime()
            && (new Date(ap.startdate)).toDateString() == "Mon Jun 17 2024"
    );
  }

  
  
  get meetings3(): Meetings[] {
    return _.filter(
        this.$store.getters['i/orderedMeetings'],
        (ap: Meetings) => ap.dbstate > -1 && ap.status == 3 && new Date(ap.enddate).getTime() > new Date().getTime()
            && (new Date(ap.startdate)).toDateString() == "Mon Jun 17 2024"
    );
  }

  get meetings4(): Meetings[] {
    return _.filter(
        this.$store.getters['i/orderedMeetings'],
        (ap: Meetings) => ap.dbstate > -1 && ap.status == 4 && new Date(ap.enddate).getTime() > new Date().getTime()
            && (new Date(ap.startdate)).toDateString() == "Mon Jun 17 2024"
    );
  } 
  
  get meetings1_2(): Meetings[] {
    return _.filter(
        this.$store.getters['i/orderedMeetings'],
        (ap: Meetings) => ap.dbstate > -1 && ap.status == 1 && new Date(ap.enddate).getTime() > new Date().getTime()
            && (new Date(ap.startdate)).toDateString() == "Tue Jun 18 2024"
    );
  }

  get meetings2_2(): Meetings[] {
    return _.filter(
        this.$store.getters['i/orderedMeetings'],
        (ap: Meetings) => ap.dbstate > -1 && ap.status == 2 && new Date(ap.enddate).getTime() > new Date().getTime()
            && (new Date(ap.startdate)).toDateString() == "Tue Jun 18 2024"
    );
  }

  get meetings3_2(): Meetings[] {
    return _.filter(
        this.$store.getters['i/orderedMeetings'],
        (ap: Meetings) => ap.dbstate > -1 && ap.status == 3 && new Date(ap.enddate).getTime() > new Date().getTime()
            && (new Date(ap.startdate)).toDateString() == "Tue Jun 18 2024"
    );
  }

  get meetings4_2(): Meetings[] {
    return _.filter(
        this.$store.getters['i/orderedMeetings'],
        (ap: Meetings) => ap.dbstate > -1 && ap.status == 4 && new Date(ap.enddate).getTime() > new Date().getTime()
            && (new Date(ap.startdate)).toDateString() == "Tue Jun 18 2024"
    );
  }

  protected filterItems(items: AgendaPoint[]) {
    const swords = _.map(
        _.words(this.searchtext),
        s => new RegExp(_.escapeRegExp(s), 'iu')
    );
    
    localStorage.setItem("TextFilter", this.searchtext)

    const activeEventTypeFilters: EventTypeKey[] = Object.entries(this.activeFilters.eventTypes)
        .filter(([_key, value]) => value).map(([key, _value]) => key as EventTypeKey);

    const lookForEventTypeNumber = activeEventTypeFilters.map((k) => eventTypeMap[k]);


    return _.filter(
        items,
        (item) => {
          return (swords.length === 0 || swords.some((r) => r.test(item.title + ' ' + item.speakers)))
              && (lookForEventTypeNumber.length === 0 || lookForEventTypeNumber.includes(item.eventtype))
        }
    )
  }

  protected clearFilter() {
    this.searchtext = ''
    this.activeFilters = {
      eventTypes: {
        networking: false,
        exhibitor_events: false,
        breakout_sessions: false,
        plenary_sessions: false
      },
      days: {
        day1: false,
        day2: false,
      }
    };

      localStorage.removeItem("DayFilter")
      localStorage.removeItem("EventFilter")
      localStorage.removeItem("TextFilter")

  }

  protected navguard(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
    if (this.$store.state.members.currentCall && !this.$store.getters['i/forcedToPlenum'] && !this.$store.getters['i/redirectToPlenum']) {
      const confirmres = window.confirm(this.$store.getters.i18n({
        de: "If you are leaving the booth now, your call will be ended.",
        en: "If you are leaving the booth now, your call will be ended."
      }))
      if (confirmres) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  }

  get currentCall(): JitsiCallRequest | undefined {
    return this.$store.state.members.currentCall
  }

  protected async pickusercall(cp: Meetings) {
    if (cp.fbuid !== this.$store.state.config.fbuid) {
      if (this.currentCall) {
        this.$store.commit("i/addToast", {
          header: {de: "Fehler", en: "Error"},
          msg: {
            de: 'Sie sind bereits im Gespräch.',
            en: 'You are already in a call.'
          },
          validforroom: "event-general",
          got: new Date(),
          hidden: false,
          showfor: 10000,
          alert_type: 'error'
        })
      } else {


        if (cp.receiver == this.$store.state.config.me.objvalueid) {
          let c = {
            "fbuid": cp.fbuid,
            "objvalueid": cp.sender,
            "gender": cp.gender,
            "title": cp.title,
            "firstname": cp.firstname,
            "lastname": cp.lastname,
            "orgname": cp.orgname
          };
          this.$store.commit("members/setContact", c)
          await this.$store.dispatch("members/startCall", cp.sender)
        } else {
          let c = {
            "fbuid": cp.fbuid,
            "objvalueid": cp.receiver,
            "gender": cp.gender,
            "title": cp.title,
            "firstname": cp.firstname,
            "lastname": cp.lastname,
            "orgname": cp.orgname
          };
          this.$store.commit("members/setContact", c)
          await this.$store.dispatch("members/startCall", cp.receiver)
        }


        this.$store.commit("i/addToast", {
          header: {de: "Hinweis", en: "Warning"},
          msg: {
            de: 'Bitte haben Sie einen Moment Geduld, bis Ihr Ansprechpartner Ihren Anruf entgegen nimmt. Wenn Sie das Gespräch beenden, ohne dass Ihr Ansprechpartner den Anruf entgegen genommen hat, wird dieser per Chat-Nachricht über Ihren Kontaktversuch informiert.',
            en: 'Bitte haben Sie einen Moment Geduld, bis Ihr Ansprechpartner Ihren Anruf entgegen nimmt. Wenn Sie das Gespräch beenden, ohne dass Ihr Ansprechpartner den Anruf entgegen genommen hat, wird dieser per Chat-Nachricht über Ihren Kontaktversuch informiert.',
          },
          validforroom: "event-general",
          got: new Date(),
          hidden: false,
          showfor: 10000,
          alert_type: 'error'
        })
      }
    }
  }

  protected created() {
      if (new Date().toDateString() == "Mon Jun 17 2024") {
        this.activeFilters.days.day1 = true;
      } else if (new Date().toDateString() == "Tue Jun 18 2024") {
        this.activeFilters.days.day2 = true;
      }
      
      if(localStorage.getItem('EventFilter')){

          var storedNamesEvent = JSON.parse(localStorage.EventFilter);

          var keys = Object.keys( storedNamesEvent );
          var values = Object.values( storedNamesEvent );
          
          for (let i = 0; i < values.length; i++){
              
              if(values[i]){
                  const k = keys[i] as keyof EventTypes;
                  this.activeFilters.eventTypes[k] = true                  
              }
          }
          
          
      }

      if(localStorage.getItem('DayFilter')){
          var storedNamesDays = JSON.parse(localStorage.DayFilter);
          
          var keys = Object.keys( storedNamesDays );
          var values = Object.values( storedNamesDays );

          for (let i = 0; i < values.length; i++){

              if(values[i]){
                  const k = keys[i] as keyof Days;
                  this.activeFilters.days[k] = true
              }
          }
      }

      if(localStorage.getItem('TextFilter')){
          var storedNamesText = localStorage.TextFilter;
          this.searchtext = storedNamesText
    
      }
  }

    protected toggleEventTypeFilter(type: keyof EventTypes) {
    this.activeFilters.eventTypes[type] = !this.activeFilters.eventTypes[type];
    
    localStorage.setItem("EventFilter", JSON.stringify(this.activeFilters.eventTypes))
  }

  protected toggleDayFilter(type: keyof Days) {
    this.activeFilters.days[type] = !this.activeFilters.days[type];
    localStorage.setItem("DayFilter", JSON.stringify(this.activeFilters.days))
  }
}
</script>
