<template>
  <div class="call-wrapper" v-show="connected">
    <div id="interlocutor" v-show="converence_joined" :class="{'card': true, 'card-big' : expanded}"  ref="interlocutorElement" :style="`--amplitude:${interlocutor ? interlocutor.audioLevel : 0};`">
      <div :class="{'ratio': true, 'ratio-16x9': true, 'novideo': !interlocutor || !interlocutor.hasVideo}">
        <JitsiFontAwesomeIcon icon="user" v-if="!interlocutor || !interlocutor.hasVideo" />
      </div>
      <JitsiFontAwesomeIcon icon="microphonemuted" v-if="interlocutor && interlocutor.isMuted" />
      <div class="card-body">
        <h5 v-if="!interlocutor && inContact">{{ $store.getters.i18n({de: `Warte auf ${inContact ? `${inContact.firstname} ${inContact.lastname}` : 'Teilnehmer'}`, en: `Waiting for ${inContact ? `${inContact.firstname} ${inContact.lastname}` : 'connection'}`}) }}</h5>
        <h5 v-if="interlocutor && inContact">{{inContact.firstname}} {{inContact.lastname}}</h5>
      </div>
    </div>
    <div id="me" class="card" ref="myElement" :style="`--amplitude:${me ? me.audioLevel : 0};`">
      <div :class="{'ratio': true, 'ratio-16x9': true, 'novideo': !me || !me.hasVideo}">
        <JitsiFontAwesomeIcon icon="user" v-if="!me || !me.hasVideo" />
      </div>
      <JitsiFontAwesomeIcon icon="microphonemuted" v-if="me && me.isMuted" />
      <div class="card-body">
        <h5>{{ $store.getters.i18n({de: "Ich", en: "Me"}) }}</h5>
      </div>
    </div>

    <div v-if="connected" id="calltoolbox">
      <div v-show="myQuality.connectionQuality < 50">
        <p>
          {{ $store.getters.i18n({de: "Verbindingsqualität", en: "Connection quality"}) }}:
          {{ myQuality.connectionQuality === 100 ? $store.getters.i18n({de: "sehr gut", en: "very good"}) : (myQuality.connectionQuality > 80 ? $store.getters.i18n({de: "gut", en: "good"}) : (myQuality.connectionQuality > 30 ? $store.getters.i18n({de: "ausreichend", en: "ok"}) : $store.getters.i18n({de: "schlecht", en: "poor"}))) }}
        </p>
      </div>
      <div>
        <button type="button" @click.prevent="toggleCamera" class="btn btn-dark" :title="$store.getters.i18n({de: `Kamera ${me && me.hasVideo ? 'deaktivieren':'aktivieren'}`, en:`Kamera ${me && me.hasVideo ? 'deaktivieren':'aktivieren'}`})">
          <JitsiFontAwesomeIcon :icon="`video${me && !me.hasVideo ? 'off':''}`" />
        </button>
        <button type="button" @click.prevent="endConnection" class="btn btn-primary" :title="$store.getters.i18n({de: 'Gespräch beenden', en: 'End Call'})">
          <JitsiFontAwesomeIcon icon="phone" />
        </button>
        <button type="button" @click.prevent="toggleMute" class="btn btn-dark" :title="$store.getters.i18n({de: `Audio ${me && me.isMuted ? 'entmuten':'muten'}`, en: `Audio ${me && me.isMuted ? 'entmuten':'muten'}`})">
          <JitsiFontAwesomeIcon :icon="`microphone${me && me.isMuted ? 'muted':''}`" />
        </button>
        <button type="button" @click.prevent="toggleExpanded" class="btn btn-dark" :title="$store.getters.i18n({de: `Window ${expanded ? 'Minimize':'Maximize'}`, en: `Window ${expanded ? 'Minimize':'Maximize'}`})">
          <JitsiFontAwesomeIcon :icon="`${expanded ? 'minimize':'maximize'}`" />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import _ from "lodash";

// @ts-ignore
import JitsiMeetJS from "@/vendor/jitsi/lib-jitsi-meet.min"
import {TranslatedText} from "@/store/types";
import {CallerPerson, MemberContactPerson, JitsiCallRequest, JitsiVideoSettings} from "@/store/member/types";
import JitsiFontAwesomeIcon from "@/components/jitsi/JitsiFontAwesomeIcon.vue";
import {objToFirebaseUID} from "@/firebaseConnections";
import rankingsMaxPoints from '@/ranking';


export interface JitsiConnectionQualityPL {
  download: number;
  upload: number;
  total: number;
}

export interface JitsiConnectionQualityTP {
  ip: string;
  localCandidateType: string;
  localip: string;
  networkType: string;
  p2p: boolean;
  remoteCandidateType: string;
  rtt: number;
  type: string;
}

export interface JitsiConnectionQuality {
  connectionQuality: number;
  packetLoss: JitsiConnectionQualityPL
  transport: JitsiConnectionQualityTP[]
}

export interface JitsiConfParticipant {
  juid: string|null;
  name: string;
  userid?: number;
  avatar?: string;
  hasMic: boolean;
  hasVideo: boolean;
  audioLevel: number;
  isMuted: boolean;
  volume: number;
  tracks: any[];
  iceConnection?: "active"|"inactive"|"interrupted"|"restoring";
}

@Component({
  components: {JitsiFontAwesomeIcon},
})
export default class JitsiCall extends Vue {

  @Prop() protected type!: string;

  protected errors: TranslatedText = {de: "", en: ""}

  protected settings = {
    serviceUrl: 'https://telko.eventit.ag/http-bind',
    hosts: {
      domain: 'telko.eventit.ag',
      muc: 'conference.telko.eventit.ag',
      anonymousdomain: 'telko.eventit.ag'
    },
    useStunTurn: true
  }

  protected me: null|JitsiConfParticipant = null
  protected myQuality: JitsiConnectionQuality = {
    connectionQuality: 100,
    packetLoss: {
      download: 0,
      upload: 0,
      total: 0
    },
    transport: []
  }
  protected interlocutor: null|JitsiConfParticipant = null

  protected expanded = true
  protected cansharedesktop = false
  protected connection: any = null
  protected room: any = null

  protected callstate!: JitsiCallRequest

  get jitsijwt(): string {
    return this.callstate.jwt as string
  }

  get roomname(): string {
    return this.callstate.roomname as string
  }

  get callid(): number {
    return this.callstate.objvalueid as number
  }

  protected connected = false
  protected converence_joined = false

  get devicesPicked(): JitsiVideoSettings {
    return this.$store.getters['members/devicesPicked']
  }

  protected confSettings = {
    openBridgeChannel: true,
    enableTalkWhileMuted: true,
    ignoreStartMuted: true,
    startSilent: false
  }

  protected videoOptions = {
    resolution: 720,
    constraints: {
      video: {
        aspectRatio: 16 / 9,
        height: {
          ideal: 720,
          max: 1080,
          min: 240
        },
      },
    },
    minFps: 15,
    maxFps: 30
  }

  protected jinitOptions = {
    useIPv6: false,
    desktopSharingChromeExtId: 'fwefwwww',
    desktopSharingChromeDisabled: false,
    desktopSharingChromeSources: ['window','screen'],
    desktopSharingFirefoxDisabled: false,
    disableAudioLevels: false,
    disableSimulcast: false,
    enableWindowOnErrorHandler: true,
    disableThirdPartyRequests: true,
    enableAnalyticsLogging: false,
    disableRtx: true,
    disableH264: false,
    preferH264: true,
    desktopSharingFrameRate: {
      min: 5,
      max: 15
    },
    p2p: {
      enabled: true,
      useStunTurn: true,
      stunServers: [
        {urls: 'stun:stun.t-online.de:3478'},
        {urls: 'stun:stun.1und1.de:3478'},
      ]
    }
  }

  protected async connectionEstablished() {
    this.connected = true
    this.room = this.connection.initJitsiConference(this.roomname, this.confSettings)
    this.room.setReceiverVideoConstraint(this.videoOptions.resolution)
    this.room.setSenderVideoConstraint(this.videoOptions.resolution)
    this.room.setDisplayName(`${this.$store.state.config.me.firstname} ${this.$store.state.config.me.lastname}`)
    this.room.on(
      JitsiMeetJS.events.conference.CONFERENCE_JOINED,
      () => {
        if (this.me) {
          const mytracks = [...this.me.tracks]
          mytracks.forEach(
            (t: any) => { this.room.addTrack(t) }
          )
          this.converence_joined = true
          this.me.juid = this.room.myUserId()
        }
      }
    )

    this.room.on(
      JitsiMeetJS.events.conference.CONFERENCE_ERROR,
      this.converenceError.bind(this)
    )

    this.room.on(
      JitsiMeetJS.events.conference.CONFERENCE_LEFT,
      () => {
        this.connected = false
        this.converence_joined = false
        this.interlocutor = null
        this.me = null
      }
    )

    this.room.on(
      JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED,
      (userID:string, displayName:string) => {
        if (this.interlocutor) {
          this.interlocutor.name = displayName
        }
      }
    )

    this.room.on(
      JitsiMeetJS.events.conference.USER_JOINED,
      (userID: string, user: any) => {
        this.interlocutor = {
          juid: userID,
          name: user.getDisplayName(),
          hasVideo: false,
          hasMic: false,
          audioLevel: 0,
          isMuted: false,
          volume: 1.0,
          tracks: [],
          iceConnection: user.getConnectionStatus()
        }

        const ntracks = user.getTracks()
        ntracks.forEach(
          (t: any) => { this.trackAdded(t) }
        )

        this.room.selectParticipant(userID)

        if (this.callstate.connection_start === '' || !this.callstate.connection_start) {
          this.$store.dispatch("members/joinedCall", this.callid)
          this.callstate.connection_start = (new Date()).toISOString()
        }
      }
    )

    this.room.on(
      JitsiMeetJS.events.conference.TRACK_ADDED,
      this.trackAdded.bind(this)
    )

    this.room.on(
      JitsiMeetJS.events.conference.TRACK_REMOVED,
      this.trackRemoved.bind(this)
    )

    this.room.on(
      JitsiMeetJS.events.conference.USER_LEFT,
      async (userID: string, user: any) => {
        if (this.interlocutor && this.interlocutor.juid === userID) {
          const trackstoremove = [...this.interlocutor.tracks]
          trackstoremove.forEach(
            (t: any) => {
              this.trackRemoved(t)
            }
          )
          this.interlocutor = null
        }
      }
    )

    this.room.on(JitsiMeetJS.events.conference.P2P_STATUS, (p2pstat: boolean) => {
      console.debug({P2PSTAT: p2pstat})
    })

    this.room.on(JitsiMeetJS.events.conference.USER_STATUS_CHANGED, (userID: string, status: string) => {
      console.debug({USER_STATUS_CHANGED: { userID, status }})
    })

    this.room.addEventListener(
        JitsiMeetJS.events.connectionQuality.LOCAL_STATS_UPDATED,
        (statsObject: any) => {
          const nqual: JitsiConnectionQuality = {
            connectionQuality: statsObject.codec.connectionQuality,
            packetLoss: {...statsObject.packetLoss},
            transport: [...statsObject.transport]
          }

          this.myQuality = nqual
        })

    this.room.on(JitsiMeetJS.events.conference.NO_AUDIO_INPUT, () => {
      this.$store.commit("i/addToast", {
        header: {de: "Warnung", en: "Warning"},
        msg: {
          de: "Kein Signal von Ihrem Mikrofon. Ist es eingeschaltet?",
          en: "No signal is coming from your microphone. Is it swiched on?"
        },
        validforroom: "event-general",
        got: new Date(),
        hidden: false,
        alert_type: 'error'
      })
    })

    this.room.on(JitsiMeetJS.events.conference.NOISY_MIC, () => {
      this.$store.commit("i/addToast", {
        header: {de: "Warnung", en: "Warning"},
        msg: {
          de: "Ihr Mikrofon scheint laut zu sein, oder Störgeräusche zu erzeugen.",
          en: "Your microphone appears to be loud or noisy."
        },
        validforroom: "event-general",
        got: new Date(),
        hidden: false,
        alert_type: 'error'
      })
    })

    this.room.on(JitsiMeetJS.events.conference.TALK_WHILE_MUTED, () => {
      this.$store.commit("i/addToast", {
        header: {de: "Warnung", en: "Warning"},
        msg: {
          de: "Ihr Mikrofon ist stumm. Versuchen Sie zu reden?",
          en: "Your microphone is set to muted. Are you trying to speak?"
        },
        validforroom: "event-general",
        got: new Date(),
        hidden: false,
        alert_type: 'error'
      })
    })

    this.setOutputDevice()

    try {
      await this.createLocalTracks()
    } catch (e) {
      console.error(e)
      this.$store.commit("i/addToast", {
        header: {de: "Fehler", en: "Error"},
        msg: {
          de: 'Konnte ihr Mikrofon nicht verwenden. Bitte erlauben Sie dem Browser, auf ihr Mikrofon zuzugreifen.',
          en: 'Could not use your microphone. Please allow the browser to use your microphone.'
        },
        validforroom: "event-general",
        got: new Date(),
        hidden: false,
        alert_type: 'error'
      })

      await this.endConnection()
      return
    }

    this.room.join()
  }

  protected trackAdded(track:any) {
    const juid = track.getParticipantId()

    let targetUser!: JitsiConfParticipant
    let targetHTMLParent!: HTMLElement
    let targetHTMLElement!: HTMLAudioElement|HTMLVideoElement

    if (this.me && juid === null) {
      targetUser = this.me
      targetHTMLParent = this.$refs.myElement as HTMLElement
    } else if (this.interlocutor && this.interlocutor.juid === juid) {
      targetUser = this.interlocutor
      targetHTMLParent = this.$refs.interlocutorElement as HTMLElement
    } else {
      console.error(`Track for other user added ${juid}`)
      return
    }

    if (track.getType() === "audio") {
      targetUser.isMuted = track.isMuted()

      track.addEventListener(
        JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, () => {
        targetUser.isMuted = track.isMuted()
      })

      track.addEventListener(
        JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED, (audioLevel:number) => {
        targetUser.audioLevel = audioLevel
      })

      targetUser.hasMic = true
      targetHTMLElement = document.createElement("audio")
    } else {
      targetHTMLElement = document.createElement("video")
      targetHTMLParent = targetHTMLParent.querySelector(".ratio") as HTMLElement
      targetUser.hasVideo = true

      track.addEventListener(
        JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, () => {
          targetUser.hasVideo = !track.isMuted()
        })
    }

    if (track.getType() !== "audio" || targetUser !== this.me) {
      targetHTMLElement.setAttribute("id", `${juid}${track.getType()}`)
      targetHTMLElement.setAttribute("autoplay", "1")
      targetHTMLElement.setAttribute("playsinline", "1")
      targetHTMLParent.append(targetHTMLElement)

      track.attach(targetHTMLElement)
    }

    targetUser.tracks.push(track)
  }

  protected trackRemoved(track:any) {
    const juid = track.getParticipantId()

    let targetUser!: JitsiConfParticipant
    let targetHTMLParent!: HTMLElement
    let targetHTMLElement!: HTMLElement
    if (this.me && juid === null && this.me.tracks.some((to: any) => to === track)) {
      targetUser = this.me
      targetHTMLParent = this.$refs.myElement as HTMLElement
      if (this.room && this.converence_joined) {
        this.room.removeTrack(track)
      }
    } else if (this.interlocutor && this.interlocutor.juid === juid && this.interlocutor.tracks.some((to: any) => to === track)) {
      targetUser = this.interlocutor
      targetHTMLParent = this.$refs.interlocutorElement as HTMLElement
    } else {
      console.debug(`Unknown track or user removed ${juid}`)
      return
    }

    if (track.getType() !== "audio") {
      targetHTMLParent = targetHTMLParent.querySelector(".ratio") as HTMLElement
      targetHTMLElement = targetHTMLParent.querySelector("video") as HTMLElement
    } else {
      targetHTMLElement = targetHTMLParent.querySelector("audio") as HTMLElement
    }

    if (targetHTMLParent && targetHTMLElement) {
      targetHTMLParent.removeChild(targetHTMLElement)
    }

    _.remove(targetUser.tracks, t => t === track)
    targetUser.tracks = [...targetUser.tracks]
  }

  protected converenceError(errortype:string[]) {
    errortype.forEach(e => {
      switch (e) {
        case "conference.authenticationRequired":
        case "conference.connectionError.notAllowed":
        case "conference.connectionError.membersOnly":
        case "conference.connectionError.accessDenied":
        case "conference.passwordRequired":
          this.$store.commit("i/addToast", {
            header: {de: "Fehler", en: "Error"},
            msg: {
              de: 'Ihre Autorisierung ist abgelaufen.',
              en: 'Authorization for the server failed.'
            },
            validforroom: "event-general",
            got: new Date(),
            hidden: false,
            alert_type: 'error'
          })
          this.connected = false
          this.converence_joined = false
          break
        case "conference.connectionError":
          this.$store.commit("i/addToast", {
            header: {de: "Fehler", en: "Error"},
            msg: {
              de: 'Verbindung mit dem Konferenzserver abgebrochen.',
              en: 'Connection to the conference server failed.'
            },
            validforroom: "event-general",
            got: new Date(),
            hidden: false,
            alert_type: 'error'
          })
          this.connected = false
          this.converence_joined = false
          break
        case "conference.incompatible_server_versions":
          this.$store.commit("i/addToast", {
            header: {de: "Fehler", en: "Error"},
            msg: {
              de: 'Es ist ein unerwarteter Fehler aufgetreten.',
              en: 'An unknown error occurred.'
            },
            validforroom: "event-general",
            got: new Date(),
            hidden: false,
            alert_type: 'error'
          })
          console.error('The lib and server version are incompatible')
          break
        case "conference.gracefulShutdown":
          this.$store.commit("i/addToast", {
            header: {de: "Fehler", en: "Error"},
            msg: {
              de: 'Der Server wird neu gestartet. Bitte versuchen Sie es später noch einmal.',
              en: 'The server is being restarted at the moment. Please try to reconnect.'
            },
            validforroom: "event-general",
            got: new Date(),
            hidden: false,
            alert_type: 'error'
          })
          break
        case "conference.iceFailed":
        case "conference.videobridgeNotAvailable":
        case "conference.offerAnswerFailed":
          this.$store.commit("i/addToast", {
            header: {de: "Fehler", en: "Error"},
            msg: {
              de: 'Bild und Ton können leider nicht übertragen werden. Sie können aber den Chat verwenden.',
              en: 'Audio and video could not be exchanged. You can use the chat to talk.'
            },
            validforroom: "event-general",
            got: new Date(),
            hidden: false,
            alert_type: 'error'
          })
          break
        default: break
      }
    })
  }

  protected async toggleCamera() {
    if (!this.me) {
      return
    }
    const videoTrack = _.find(this.me.tracks, t => t.getType() === "video")
    if (videoTrack) {
      if (videoTrack.isMuted()) {
        await videoTrack.unmute()
      } else {
        await videoTrack.mute()
      }

      this.me.hasVideo = !videoTrack.isMuted()
    } else {

    }
  }
  protected async toggleMute() {
    if (!this.me) {
      return
    }
    const audioTrack = _.find(this.me.tracks, t => t.getType() === "audio")
    if (audioTrack) {
      if (audioTrack.isMuted()) {
        await audioTrack.unmute()
      } else {
        await audioTrack.mute()
      }

      this.me.isMuted = audioTrack.isMuted()
    }
  }

  protected toggleExpanded() {
    this.expanded = !this.expanded
  }

  protected connectionFailed() {
    this.connected = false
  }

  protected connectionDisconnect() {
    this.connected = false
  }

  protected connect() {
    if (!this.connection) {
      this.init()
    } else {
      this.connection.connect()
    }
  }

  @Watch("devicesPicked.output")
  protected setOutputDevice() {
    if (this.devicesPicked.output !== '') {
      try {
        JitsiMeetJS.mediaDevices.setAudioOutputDevice(this.devicesPicked.output)
      } catch (e) {
        console.error(e)
      }
    }
  }

  @Watch("devicesPicked.mic")
  @Watch("devicesPicked.cam")
  protected async createLocalTracks() {
    const trackSettings: any = {
      ...this.videoOptions,
      devices: []
    }

    if (this.me) {
      const oldtracks = [...this.me.tracks]
      const desposeold: Promise<void>[] = []

      oldtracks.forEach(ot => {
        desposeold.push(ot.dispose())
      })

      try {
        await Promise.all(desposeold)
      } catch (e) {
        console.error('Error disposing local track', e)
      }

      this.me.tracks = []
    }

    if (this.$store.state.members.mediaDevices.length < 1) {
      trackSettings.devices.push('audio')
      trackSettings.devices.push('video')
    } else {
      if (this.$store.state.members.mediaDevices.some((d: MediaDeviceInfo) => d.kind === "videoinput")) {
        trackSettings.devices.push('video')
        if (this.devicesPicked.cam !== "") {
          trackSettings.cameraDeviceId = this.devicesPicked.cam
        }
      }
      if (this.$store.state.members.mediaDevices.some((d: MediaDeviceInfo) => d.kind === "audioinput")) {
        trackSettings.devices.push('audio')
        if (this.devicesPicked.mic) {
          trackSettings.micDeviceId = this.devicesPicked.mic
        }
      }
    }

    let tracks = await JitsiMeetJS.createLocalTracks(trackSettings)

    tracks.forEach(
      (t: any) => {
        this.trackAdded(t)
      }
    )
  }

  protected async init() {
    if (!JitsiMeetJS.mediaDevices.isDeviceListAvailable()) {
      this.$store.commit("i/addToast", {
        header: {de: "Fehler", en: "Error"},
        msg: {
          de: 'Keine Audio Geräte gefunden.',
          en: 'Keine Audio Geräte gefunden.'
        },
        validforroom: "event-general",
        got: new Date(),
        hidden: false,
        alert_type: 'error'
      })
      return
    }

    this.me = {
      juid: null,
      name: '',
      hasMic: false,
      hasVideo: false,
      audioLevel: 0,
      isMuted: false,
      volume: 1,
      tracks: []
    }

    if (this.$store.state.members.currentCall) {
      this.callstate = {
        ...this.$store.state.members.currentCall
      }
    }

    JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR)
    try {
      await JitsiMeetJS.init(this.jinitOptions)
    } catch (e) {
      console.error(e)
      return
    }

    this.cansharedesktop = JitsiMeetJS.isDesktopSharingEnabled()

    this.connection = new JitsiMeetJS.JitsiConnection(null, this.jitsijwt, this.settings)

    this.connection.addEventListener(
        JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
        this.connectionEstablished.bind(this)
    )
    this.connection.addEventListener(
        JitsiMeetJS.events.connection.CONNECTION_FAILED,
        this.connectionFailed.bind(this)
    )
    this.connection.addEventListener(
        JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
        this.connectionDisconnect.bind(this)
    )

    this.connect()
  }

  protected created() {
    this.init()
  }

  protected async endConnection() {
    const tracks_dispose: Promise<any>[] = []

    if (this.me) {
      const mytracks = [...this.me.tracks]
      mytracks.forEach(
        (t: any) => {
          this.trackRemoved(t)
          tracks_dispose.push(t.dispose())
        }
      )

      this.me = null
    }

    if (this.interlocutor) {
      const other_tracks = [...this.interlocutor.tracks]
      other_tracks.forEach(
        (t: any) => {
          this.trackRemoved(t)
          //tracks_dispose.push(t.dispose())
        }
      )

      this.interlocutor = null
    }

    try {
      await Promise.all(tracks_dispose)
    } catch (e) {
      console.error('Error disposing Track', e)
    }

    if (this.room) {
      try {
        await this.room.leave()
      } catch (e) {

      }
      this.room = null
      this.converence_joined = false
    }
    if (this.connection) {
      try {
        await this.connection.disconnect()
      } catch (e) {

      }

      this.connection = null
      this.connected = false
    }

    if (this.callid) {
      if(this.$store.state.i.me.tn_type != 26 && this.$store.state.i.me.tn_type != 33  && this.$store.state.i.me.ranking_g < rankingsMaxPoints.ranking_g){
        //ranking activity g - für die 1:1-Calls länger als 5 min je 3 Punkte
        if (this.$store.state.members.currentCall && this.callstate.connection_start){
          let start = (new Date(this.callstate.connection_start)).getTime();
          let end = (new Date()).getTime();
          let diff = Math.round((end - start) / 1000);
          if(diff > 300){
            let value = this.$store.state.i.me.ranking_g + 3;
            this.$store.dispatch('i/setDataRankingG', value)
          }
        }
      }

      switch (this.type) {
        case "member":
          if (this.$store.state.members.currentCall && !this.callstate.connection_start) {
            await this.$store.dispatch('i/sendmydm', {
              body: "I have been trying to reach you via video call.",
              ts: Date.now() / 1000,
              receiver: objToFirebaseUID(this.$store.state.members.currentCall.interlocutorid)
            })
          }
          await this.$store.dispatch("members/endCall", this.callid)
          break
        case "employee":
          await this.$store.dispatch("members/endCallReceiver", this.callid)
          break
        default: break
      }
    }
  }



  get inContact(): undefined|MemberContactPerson|CallerPerson {
    if (!this.$store.state.members.currentCall) {
      return undefined
    }

    switch (this.type) {
      case "member":
        return _.find(
            this.$store.state.members.contacts,
            (s: MemberContactPerson) => (
                `${this.$store.state.members.currentCall.interlocutorid}` === `${s.objvalueid}`
            )
        )
      case "employee":
        return {
          objvalueid: this.$store.state.members.currentCall.callerid,
          gender: this.$store.state.members.currentCall.gender,
          title: this.$store.state.members.currentCall.title,
          firstname: `${this.$store.state.members.currentCall.firstname}`,
          lastname: `${this.$store.state.members.currentCall.lastname}`,
        }
      default:
        return undefined
    }
  }

  protected async beforeDestroy() {
    await this.endConnection()
  }
}
</script>
