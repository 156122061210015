import {MutationTree} from "vuex";
import {
    InteractionState,
    ChatContact,
    ConnectionNetworking,
    DMMessage,
    InteractionAlert,
    Message,
    MultiUserRoom,
    SupportMessage,
    VotingBallot,
    VotingInteraction,
    AgendaPoint,
    MemberEntry,
    ContactsNetworking,
    Meetings,
    Partner,
    ImagePoint,
    Me,
} from "@/store/i/types";
import _ from "lodash";
import {tellServiceWorker} from "@/registerServiceWorker";
import OfflineDB from "@/store/i/offlinedb";
import {ObjUpdatePayload} from "@/store/types";

const mutations: MutationTree<InteractionState> = {
    clean(state) {
        state.connected = false
        state.historyCutoff = null
        state.toasts = []
        state.showsmallchat = false
        state.mucname = ""
        state.questions = []
        state.publicchats = []
        state.contactschats = []
        state.contacts_networking = []
        state.connections_networking = []
        state.meetings = []
        state.speakers = []
        state.partners = []
        state.rooms = []
        state.votings = []
        state.ballots = []
        state.dmmessages = []
        state.supportmessages = []
        state.agenda = []
        state.image = []
        state.videoFullscreen = false
    },
    setMucName(state, pl) {
        state.mucname = pl
    },
    setCurrentAgenda(state, pl) {
        state.currentagenda = pl
    },
    setConnectedState(state, pl:boolean) {
        state.connected = pl
    },
    roomUpdate(state, pl:MultiUserRoom) {
        OfflineDB.setRoom(pl)
        state.rooms = _.uniqBy([pl, ...state.rooms], r => r.roomname)
        tellServiceWorker('SET_ROOMCONFIGS', { roomconfigs: state.rooms })
    },
    addPublicMessage(state, pl:Message) {
        if (pl.uuid) {
            OfflineDB.publicchats.put({...pl}, pl.uuid)
        }
        state.publicchats = _.uniqBy([...state.publicchats, pl].reverse(), (m: Message) => `${m.uuid}`).reverse()
    },
    removeAPublicMessage(state, pl: string) {
        _.remove(state.publicchats, (pc: Message) => pc.uuid === pl)
        if (pl !== "") {
            OfflineDB.publicchats.where({uuid: pl}).delete()
        }
        state.publicchats = [...state.publicchats]
    },
    clearPublicMessages(state) {
        OfflineDB.publicchats.toCollection().delete()
        state.publicchats = []
    },
    addQuestion(state, pl:Message) {
        if (pl.uuid) {
            OfflineDB.questions.put({...pl}, pl.uuid)
        }
        state.questions.push(pl)
    },
    clearQuestions(state) {
        OfflineDB.questions.toCollection().delete()
        state.questions = []
    },
    addToast(state, pl:InteractionAlert) {
        if (
            pl.id &&
            !pl.alert_type &&
            !pl.queued &&
            (!pl.announce_confirm || pl.announce_confirm === "")
        ) {
            OfflineDB.toasts.put({...pl})
        }

        state.toasts = _.uniqBy([pl, ...state.toasts], (i: InteractionAlert) => (
            !_.isEmpty(i.uid) ? `${i.uid}` : `noid${Math.random()}`
        ))
    },
    removeToast(state, pl:InteractionAlert) {
        if (state.connected) {
            state.historyCutoff = Date.now()
            localStorage.setItem('historyCutoff', '' + state.historyCutoff)
        }

        const t = _.find(state.toasts, ot => ot === pl)
        if (t) {
            t.hidden = true
            if (
                t.id &&
                !t.alert_type &&
                (!t.announce_confirm || t.announce_confirm === "")
            ) {
                OfflineDB.toasts.put({...t})
            }
        }

        const unhidden = _.filter(state.toasts, t => !t.hidden)
        if (unhidden.length === 0) {
            // Alle versteckt, löschen
            state.toasts = []
        }
    },
    setVoting(state, pl:VotingInteraction) {
        OfflineDB.votings.put({...pl}, pl.uid)

        state.votings = _.uniqBy(
            [pl, ...state.votings],
            v => v.uid
        )
    },
    setBallot(state, pl:VotingBallot) {
        state.ballots = _.uniqBy(
            [pl, ...state.ballots],
            b => b.roomname + '|' + b.voteuid + '|' + b.answer + '|' + b.objvalueid
        )
    },
    hideChatbox(state) {
        state.showsmallchat = false
    },
    showChatbox(state, pl: boolean | ChatContact | "support" = true) {
        state.showsmallchat = pl
    },
    setChatboxContact(state, pl: ChatContact | "support") {
        state.showsmallchat = pl
    },
    addContactChat(state, pl:ChatContact) {
        OfflineDB.contactschats.put({...pl}, pl.fbuid)

        state.contactschats = _.uniqBy(
            [pl,...state.contactschats],
            (c) => c.fbuid
        )
    },
    removeContactChat(state, pl: string) {
        _.remove(state.contactschats, (c: ChatContact) => c.fbuid === pl)
        if (pl !== "") {
            OfflineDB.contactschats.where({uuid: pl}).delete()
        }
        state.contactschats = [...state.contactschats]
    },
    addDM(state, pl:DMMessage) {
        if (pl.uuid) {
            OfflineDB.dmmessages.put({...pl}, pl.uuid)
        }

        state.dmmessages = _.uniqBy(
            ([...state.dmmessages, pl]).reverse(), // Neueste behalten
            (dm) => {
                return (dm.uuid ? dm.uuid : dm.ts)
            }
        )
    },
    setDMFlag(state, pl:any) {
        const udm = _.find(
            state.dmmessages,
            (dm) => (dm.uuid === pl.msgid || (<any>dm).uid === pl.msgid)
        )

        if (udm) {
            udm.seen = true
            state.dmmessages = [...state.dmmessages]
            OfflineDB.dmmessages.put({...udm}, udm.uuid)
        }
    },
    unsetDMFlag(state, pl: any) {
        const udm = _.find(
            state.dmmessages,
            (dm) => (dm.uuid === pl.msgid || (<any>dm).uid === pl.msgid)
        )
        if (udm) {
            udm.seen = false
            state.dmmessages = [...state.dmmessages]
            OfflineDB.dmmessages.put({...udm}, udm.uuid)
        }
    },
    addSupportMessage(state, pl:SupportMessage) {
        if (pl.uuid) {
            OfflineDB.supportmessages.put({...pl}, pl.uuid)
        }

        state.supportmessages = _.uniqBy(
            ([...state.supportmessages, pl]).reverse(), // Neueste behalten
            (dm) => {
                return (dm.uuid ? dm.uuid : dm.ts)
            }
        ).reverse()
    },
    setSupportMessageFlag(state, pl:any) {
        const udm = _.find(
            state.supportmessages,
            (dm) => (dm.uuid === pl.msgid || (<any>dm).uid === pl.msgid)
        )

        if (udm) {
            udm.seen = true
            state.supportmessages = [...state.supportmessages]
            OfflineDB.supportmessages.put({...udm}, udm.uuid)
        }
    },
    unsetSupportMessageFlag(state, pl: any) {
        const udm = _.find(
            state.supportmessages,
            (dm) => (dm.uuid === pl.msgid || (<any>dm).uid === pl.msgid)
        )
        if (udm) {
            udm.seen = false
            state.supportmessages = [...state.supportmessages]
            OfflineDB.supportmessages.put({...udm}, udm.uuid)
        }
    },
    setFullscreen(state, pl:boolean) {
        state.videoFullscreen = pl
    },

    setAgendaPoint(state, pl:AgendaPoint) {
        OfflineDB.agendapoints.put({...pl}, pl.objvalueid)
        state.agenda = _.uniqBy(
            [pl, ...state.agenda],
            ap => `${ap.objvalueid}`
        )
    },
    setImagePoint(state, pl:ImagePoint) {
        OfflineDB.imagepoints.put({...pl}, pl.objvalueid)
        state.image = _.uniqBy(
            [pl, ...state.image],
            ap => `${ap.objvalueid}`
        )
    },
    setSpeaker(state, pl: MemberEntry) {
        OfflineDB.speakers.put({...pl}, pl.objvalueid)

        state.speakers = _.uniqBy(
            [pl, ...state.speakers],
            (e: MemberEntry) => `${e.objvalueid}`
        )
    },
    setPartner(state, pl: Partner) {
        OfflineDB.partners.put({...pl}, pl.objvalueid)

        state.partners = _.uniqBy(
            [pl, ...state.partners],
            (e: Partner) => `${e.objvalueid}`
        )
    },
    setContactsNetworking(state, pl:ContactsNetworking) {
        // @ts-ignore
        OfflineDB.contacts_networking.put({...pl}, pl.objvalueid)

        state.contacts_networking = _.uniqBy(
            [pl, ...state.contacts_networking],
            (e: ContactsNetworking) => `${e.objvalueid}`
        )
    },
    setConnectionsNetworking(state, pl:ConnectionNetworking) {
        // @ts-ignore
        OfflineDB.connections_networking.put({...pl}, pl.objvalueid)
        state.connections_networking = _.uniqBy(
            [pl,...state.connections_networking],
            (e: ConnectionNetworking) => `${e.objvalueid}`
        )
    },
    setMeetings(state, pl:Meetings) {
        // @ts-ignore
        OfflineDB.meetings.put({...pl}, pl.objvalueid)
        state.meetings = _.uniqBy(
            [pl,...state.meetings],
            (e: Meetings) => `${e.objvalueid}`
        )
    },
    add_me(state, pl: Me) {
        state.me = pl
    },
    add_me_offline(state, pl: Me) {
        // @ts-ignore
        OfflineDB.me.put({...pl}, pl.objvalueid)
    },
    add_onboarding1(state, pl: any) {
        state.me.accept_networking = pl.accept_networking
        state.me.networking_question1 = pl.networking_question1
        state.me.networking_question2 = pl.networking_question2
        state.me.networking_question3 = pl.networking_question3
    },
    add_onboarding2(state, pl: any) {
        state.me.position = pl.position
        state.me.orgname = pl.orgname
        state.me.show_email = pl.show_email
        state.me.phone = pl.phone
        state.me.country = pl.country
        state.me.show_vcard = pl.show_vcard
        state.me.twitter = pl.twitter
        state.me.facebook = pl.facebook
        state.me.linkedin = pl.linkedin
        state.me.xing = pl.xing
        state.me.cv = pl.cv
    },
    add_score_reaction(state, pl: any) {
        state.me.score_reaction = pl.score_reaction
    },
    add_fav_agenda_items(state, pl: any) {
        state.me.fav_agenda_items = pl
    },
    add_ranking_a(state, pl: number) {
        state.me.ranking_a = pl
    },
    add_ranking_b(state, pl: number) {
        state.me.ranking_b = pl
    },
    add_ranking_c(state, pl: number) {
        state.me.ranking_c = pl
    },
    add_ranking_d(state, pl: number) {
        state.me.ranking_d = pl
    },
    add_ranking_e(state, pl: number) {
        state.me.ranking_e = pl
    },
    add_ranking_f(state, pl: number) {
        state.me.ranking_f = pl
    },
    add_ranking_g(state, pl: number) {
        state.me.ranking_g = pl
    },
    add_ranking_h(state, pl: number) {
        state.me.ranking_h = pl
    }, 
    add_ranking_i(state, pl: number) {
        state.me.ranking_i = pl
    },
    add_ranking_j(state, pl: number) {
        state.me.ranking_j = pl
    },
    add_ranking_k(state, pl: number) {
        state.me.ranking_k = pl
    },
    add_ranking_l(state, pl: number) {
        state.me.ranking_l = pl
    },
    add_ranking_m(state, pl: number) {
        state.me.ranking_m = pl
    },
}

export default mutations