<template>
  <div class="">

    <div class="col-md-12 col-xl-12">

      <form @submit.prevent="setDataOnBoarding1">

        <div v-show="successtring.de !== ''" class="alert alert-success col-lg-12">
          {{ $store.getters.i18n(successtring) }}
        </div>

        <div v-show="errorstring.de !== ''" class="alert alert-danger mb-5">
          {{ $store.getters.i18n(errorstring) }}
        </div>

        <p>
          Welcome to the 2024 DW Global Media Forum conference platform. It is good to have you with us this #GMF24!
        </p>
        <p>
          Complete your networking profile to connect with media professionals from around the globe in just a couple of minutes.
        </p>
        <p>
          Once you have created your profile, you can use the matchmaking function to add new contacts based on the areas of interest you specify in your profile. You will automatically be shown contacts that match your own interests. You can also connect directly with other participants using the chat function.
        </p>
        <p>
          This networking tool is optional. If you do not want to participate in our networking features, simply do not check the box at the bottom of this page.
        </p>
        <p class="pb-5">
          By the way: You can add or change your details in your profile at any time.
        </p>

        <div class="d-flex align-items-center mb-4">
          <div class="me-auto form-check">
            <input class="form-check-input" v-model="$store.state.i.me.accept_networking" type="checkbox" name="accept_networking" id="accept_networking">
            <label class="form-check-label" for="accept_networking">
              Yes, I want to network.
            </label>
          </div>
        </div>

        <div v-if="$store.state.i.me.accept_networking">

          <div class="mb-4">
            <label class="form-check-label"><b>My expertise (max.5)</b></label>
            <div class="form-check" v-for="(e, ei) in $store.state.i.me.networkinglist"  >
              <input class="form-check-input" type="checkbox" v-model="networking_question1" :value=ei :disabled="disabled1(ei)">
              <label class="form-check-label">{{e}}</label>
            </div>
          </div>

          <div class="mb-4">
            <label class="form-check-label"><b>My interests (max.5)</b></label>
            <div class="form-check" v-for="(e, ei) in $store.state.i.me.networkinglist"  >
              <input class="form-check-input" type="checkbox" v-model="networking_question2" :value=ei :disabled="disabled2(ei)">
              <label class="form-check-label">{{e}}</label>
            </div>
          </div>

          <div class="mb-4">
            <label class="form-label" >My most-used social network</label>
            <input type="text" v-model="$store.state.i.me.networking_question3" class="form-control" />
          </div>

        </div>


          <div class="d-flex align-items-center">
            <button class="btn btn-primary m-1" type="submit">
              <span v-if="this.type == 'step'">Next</span>
              <span v-else>Save</span>
            </button>
          </div>

      </form>

    </div>

  </div>
</template>
<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {TranslatedText} from "@/store/types";
import {AppNetworkErrorOther, networkErrorText} from "@/crud";
import _ from 'lodash';

@Component({
  components: {},
})
export default class OnBoarding1 extends Vue {

  @Prop() protected type!: string|undefined

  protected errorstring: TranslatedText = {'de': '', 'en': ''}
  protected successtring: TranslatedText = {'de': '', 'en': ''}

  protected networking_question1: string[] = []
  protected networking_question2: string[] = []

  protected async setDataOnBoarding1() {
    try {

      this.errorstring = {de: '', en:''}
      this.successtring = {de: '', en:''}


      var networking1: {[key: number]: number} = {}
      var networking2: {[key: number]: number} = {}

      this.networking_question1.forEach(function (value, index){
        let val = Number(value)
        if(val){
          networking1[val] = val
        }
      });

      this.networking_question2.forEach(function (value, index){
        let val = Number(value)
        if(val){
          networking2[val] = val
        }
      });

      const dpr = await this.$store.dispatch("i/setDataOnBoarding1", {
            accept_networking: this.$store.state.i.me.accept_networking ? 1 : 0,
            networking_question1: networking1,
            networking_question2: networking2,
            networking_question3: this.$store.state.i.me.networking_question3
          }
      )

      setTimeout(() => { this.reset_message()}, 2000);

      if (dpr.sv) {
        this.successtring = {
          de: "Data saved.",
          en: "Data saved."
        }
        if (this.$store.state.i.me.accept_networking) {
          this.$store.dispatch("i/fetchAllContactsNetworking")
          this.$store.dispatch("i/changeListenersConnectionsNetworking", true)
          this.$store.dispatch("i/changeListenersMeetings", true)
          if(this.type =="step"){
            this.$router.replace({name: "Step2"})
          }
        }else{
          this.$store.dispatch("i/changeListenersConnectionsNetworking", false)
          this.$store.dispatch("i/changeListenersMeetings", false)
          if(this.type =="step"){
            this.$router.replace({name: "Welcome"})
          }
        }


      } else {
        this.errorstring = {
          de: "Could not set data.",
          en: "Could not set data."
        }
      }
    } catch (e) {
      this.errorstring = networkErrorText(e as AppNetworkErrorOther)
    }
  }


  protected reset_message(){
    this.errorstring = {de: '', en:''}
    this.successtring = {de: '', en:''}
  }

  protected created(){
    this.networking1()
    this.networking2()
  }

  @Watch("$store.state.i.me.networking_question1")
  protected networking1(){
    this.networking_question1 = _.keys(this.$store.state.i.me.networking_question1);
  }

  @Watch("$store.state.i.me.networking_question2")
  protected networking2(){
    this.networking_question2 = _.keys(this.$store.state.i.me.networking_question2);
  }

  protected disabled1(ei:any){
    return this.networking_question1.length >= 5 && !_.includes(this.networking_question1, ei);
  }
  protected disabled2(ei:any){
    return this.networking_question2.length >= 5 && !_.includes(this.networking_question2, ei);
  }

}
</script>

<style scoped>

</style>