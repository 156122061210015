<template>
  <div class="row mb-4"  v-if="VisibleContact">
    <div class="pointer col-md-1 col-4 mb-2" @click.prevent="navigate" >
      <div class="circular_image circular_image_small">
        <img :src="userimage" onerror="this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';" alt="">
      </div>
    </div>

    <div class="pointer col-md-4 col-8 mb-2" @click.prevent="navigate" >
      <div class="Name-Nachname mb-1 ipad-2">  {{ user.firstname }}    {{ user.lastname }}</div>
      <div class="Role copydetailxs mb-2 ipad-2 bold"> {{ title(user.orgname) }}</div>
    </div>

    <div class="col-md-7 col-12">


      <button class="btn btn-primary m-1" style="float:right" v-if="contact_type == 'contact'"  @click.prevent="remove">
        <span class="smartphone"><FontAwesomeIcon icon="user-xmark" /></span>
        <span class="nsmartphone">Remove contact</span>
      </button>

      <button class="btn btn-primary m-1" style="float:right" v-if="contact_type == 'contact'"  @click.prevent="meeting">
        <span class="smartphone"><FontAwesomeIcon icon="calendar-pen" /></span>
        <span class="nsmartphone">Appointment request</span>
      </button>

      <button class="btn btn-primary m-1" style="float:right" v-if="contact_type == 'contact'"  @click.prevent="call">
        <span class="smartphone"><FontAwesomeIcon icon="video" /></span>
        <span class="nsmartphone">Call</span>
      </button>

      <button class="btn btn-primary m-1" style="float:right" v-if="contact_type == 'contact'"  @click.prevent="chat">
        <span class="smartphone"><FontAwesomeIcon icon="message" /></span>
        <span class="nsmartphone">Chat</span>
      </button>

      <button class="btn btn-primary m-1" style="float:right" v-if="contact_type == 'received'" @click.prevent="block">
        <span class="smartphone"><FontAwesomeIcon icon="ban" /></span>
        <span class="nsmartphone">Block</span>
      </button>

      <button class="btn btn-primary m-1" style="float:right" v-if="contact_type == 'received'"  @click.prevent="decline">
        <span class="smartphone"><FontAwesomeIcon icon="user-xmark" /></span>
        <span class="nsmartphone">Reject</span>
      </button>

      <button class="btn btn-primary m-1" style="float:right" v-if="contact_type == 'received'"  @click.prevent="accept">
        <span class="smartphone"><FontAwesomeIcon icon="user-check" /></span>
        <span class="nsmartphone">Accept</span>
      </button>

      <span  v-if="contact_type == 'received' || contact_type == 'send'" >  {{ formatedDate }} CEST</span>

    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {ConnectionNetworking, ContactsNetworking} from "@/store/i/types";
import _ from "lodash";
import { DateTime } from "luxon";
import {getFireDB} from "@/firebaseConnections";

@Component
export default class NetworkingUserConnection extends Vue {
  @Prop() protected user!: ConnectionNetworking
  @Prop() protected contact_type!: string|undefined

  protected title(title: string): string {
    title = _.replace(title, /&#34;/g, '"')
    title = _.replace(title, /&#39;/g, "'")
    return title
  }

  protected chat() {
    if (this.user) {
      this.$emit('chat', this.user)
    }
  }

  protected call() {
    if (this.user) {
      this.$emit('call', this.user)
    }
  }

  protected navigate() {
    if (this.user) {
      this.$emit('navigate', this.user)
    }
  }

  protected meeting() {
    if (this.user) {
      this.$emit('meeting', this.user)
    }
  }

  protected remove() {
    if (this.user) {
      this.$emit('remove', this.user)
    }
  }

  protected accept() {
    if (this.user) {
      this.$emit('accept', this.user)
    }
  }

  protected decline() {
    if (this.user) {
      this.$emit('decline', this.user)
    }
  }

  protected block() {
    if (this.user) {
      this.$emit('block', this.user)
    }
  }


  get VisibleContact() {
    if(this.ContactEntry){return true}

    return false
  }

  get ContactEntry(): any {

    if (this.user) {
      if (this.user.receiver == this.$store.state.config.me.objvalueid) {
        return _.find(
            this.$store.state.i.contacts_networking,
            (e: ContactsNetworking) => `${e.objvalueid}` === `${this.user.sender}`  && e.accept_networking
        )
      }else{
        return _.find(
            this.$store.state.i.contacts_networking,
            (e: ContactsNetworking) => `${e.objvalueid}` === `${this.user.receiver}`  && e.accept_networking
        )
      }
    }
  }

  get formatedDate(): string {
    return DateTime.fromISO(this.user.request_datetime, {locale: 'en-us'}).monthLong+" "+DateTime.fromISO(this.user.request_datetime).toFormat('d, h:mm a')
  }

  get userimage(): string|null {
    if (this.user) {
      if (this.user.receiver == this.$store.state.config.me.objvalueid) {
        return '/files/members/'+ this.user.sender +'/foto.jpg'
      }else{
        return '/files/members/'+ this.user.receiver +'/foto.jpg'
      }
    }
    return '/projects/dw/gmf/assets/images/placeholder.jpg'
  }


}
</script>