/* eslint-disable no-console */

import { register } from 'register-service-worker'

export const tellServiceWorker = async (type: string, pl: any) => {
  if (typeof pl !== "object" || !pl) {
    pl = {}
  }
  if (type === '') {
    return
  }
  if (process.env.NODE_ENV === 'production' && navigator.serviceWorker && navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage({
      type: type,
      ...pl
    });
  } else {
    console.log(`Could not communicate ${type} to serviceworker`, pl)
  }
}

  register(`/service-worker.js`, {
    registrationOptions: { scope: '/' },
    ready () {
      console.log(
        'App is being served from cache by a service worker.'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated () {
      window.location.reload()
      console.log('New content is available; please refresh.')
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })

