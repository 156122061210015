<template>

  <div class="box">
    <div class="row g-3 mb-5">

      <h3>My Profile</h3>

      <div class="row rowtabs">
        <div class="col-md-6 col-lg-3 mb-1"><router-link :to="{name: 'Profile1'}" :class="{'btn highlight_tabs': true, 'btn-active' : this.$route.meta.tab == 1}">Networking</router-link></div>
        <div class="col-md-6 col-lg-3 mb-1"><router-link :to="{name: 'Profile2'}" :class="{'btn highlight_tabs': true, 'btn-active' : this.$route.meta.tab == 2}">My Profile</router-link></div>
        <div class="col-md-6 col-lg-3 mb-1"><router-link :to="{name: 'Profile3'}" :class="{'btn highlight_tabs': true, 'btn-active' : this.$route.meta.tab == 3}" v-if="$store.state.config.me.tn_type != 8" >Upload picture</router-link></div>
        <div class="col-md-6 col-lg-3 mb-1"><router-link :to="{name: 'Profile4'}" :class="{'btn highlight_tabs': true, 'btn-active' : this.$route.meta.tab == 4}">Video call settings</router-link></div>
      </div>

      <div v-show="this.$route.meta.tab === 1">
        <OnBoarding1/>
      </div>

      <div v-show="this.$route.meta.tab === 2">
        <OnBoarding2/>
      </div>

      <div v-show="this.$route.meta.tab === 3">
        <OnBoarding3/>
      </div>

      <div v-show="this.$route.meta.tab === 4">
        <OnBoarding4/>
      </div>


    </div>
  </div>

</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import OnBoarding1 from "@/components/i/OnBoarding1.vue"
import OnBoarding2 from "@/components/i/OnBoarding2.vue"
import OnBoarding3 from "@/components/i/OnBoarding3.vue"
import OnBoarding4 from "@/components/i/OnBoarding4.vue"



@Component({
  components: {OnBoarding1,OnBoarding2,OnBoarding3,OnBoarding4},
})
export default class Networking extends Vue {
  protected created() {

    let stage = 'Test';
    if(window.location.host == 'gmf-event.com'){
      stage = 'Production';
    }
    this.$gtm.trackEvent({
      pageCategory: 'Profile',
      PageName: 'My Profile',
      PageType: 'Webapp',
      Stage:stage,
    });
  }

}
</script>