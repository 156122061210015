<template>
  <div class="box">

    <div class="mainform g-3 pb-4">

      <h3>Page in frame</h3>
      <p>
        <a href="https://gmf-event.com/event/" target="_blank">
          Please log in via https://gmf-event.com/event/
        </a>
      </p>
      <p>
        <a class="btn btn-primary m-1" href="https://gmf-event.com/event/" target="_blank">
          To login
        </a>
      </p>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ErrorFrameset extends Vue {

  protected created() {

    let stage = 'Test';
    if(window.location.host == 'gmf-event.com'){
      stage = 'Production';
    }
    this.$gtm.trackEvent({
      pageCategory: 'Error',
      PageName: 'ErrorFrameset',
      PageType: 'Webapp',
      Stage:stage,
    });
  }

  protected mounted() {
    try {
      if (window.top) {
        window.top.location.href = "https://gmf-event.com/event/"
      }
    } catch (e) {
      console.warn("Auto redirect failed", e)
    }
  }
}
</script>
