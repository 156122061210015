<template>
  <div class="row mb-4">
    <div class="pointer col-md-1 col-4" @click.prevent="navigate" >
      <div class="circular_image circular_image_small">
        <img :src="`/files/members/${user.objvalueid}/foto.jpg`" onerror="this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';"  alt=""/>
      </div>
    </div>

    <div class="pointer col-md-4 col-8" @click.prevent="navigate" >
      <div class="Name-Nachname mb-1 ipad-2">  {{ user.firstname }}    {{ user.lastname }}</div>
      <div class="Role copydetailxs mb-2 ipad-2 bold"> {{ title(user.orgname) }}</div>
    </div>

    <div class="col-md-7 col-12">


      <span class="sm-r col-8">
        <span class="badge badge-pill sm-r">  {{ user.match }} %</span>

        <span class="bold mb-3 ms-2 pointer" @click="change_matching">
          Matching result
          <span class="ms-2 pb-2">
                <FontAwesomeIcon icon="arrow-down" v-show="!show_matching" />
                <FontAwesomeIcon icon="arrow-up" v-show="show_matching" />
          </span>
        </span>
      </span>

      <span class="sm-l col-4">
        <button class="btn btn-primary m-1" @click.prevent="create">
          <span class="smartphone"><FontAwesomeIcon icon="user-plus" /></span>
          <span class="nsmartphone">Send contact request</span>
        </button>
      </span>



      <div v-if="show_matching" class="sm-mt-4 col-12">
        <div class="mb-2">
          <b>My Expertise</b><br>
          <span v-for="(e, ei) in networking_question1"><span :title="[is_selected1(ei) ? 'Match' : 'No match']" :class="{'badge badge-no-match': true, 'badge-light' : is_selected1(ei)}">{{networkinglist[ei]}}</span></span>
        </div>
        <div class="mb-2">
          <b>My interests</b><br>
          <span v-for="(e, ei) in networking_question2"><span  :title="[is_selected2(ei) ? 'Match' : 'No match']" :class="{'badge badge-no-match': true, 'badge-light' : is_selected2(ei)}">{{networkinglist[ei]}}</span></span>
        </div>
        <div class="mb-2">
          <b>My Calendar</b><br>
          <span v-for="(e, ei) in fav_agenda_items"><span  :title="[is_selected3(ei) ? 'Match' : 'No match']" :class="{'badge badge-no-match': true, 'badge-light' : is_selected3(ei)}">{{title(AgendaEntryTitle(ei))}}</span></span>
          <p v-if="fav_agenda_items.length === 0">This participant did not bookmark any program items.</p>
        </div>
      </div>

    </div>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {AgendaPoint, ContactsNetworking} from "@/store/i/types";
import _ from "lodash";
import { DateTime } from "luxon";
import {getFireDB} from "@/firebaseConnections";

@Component
export default class NetworkingUserContact extends Vue {
  @Prop() protected user!: ContactsNetworking
  @Prop() protected contact_type!: string|undefined

  protected show_matching: boolean = false;

  protected change_matching() {
    this.show_matching = !this.show_matching;
  }

  protected navigate() {
    if (this.user) {
      this.$emit('navigate', this.user)
    }
  }

  protected title(title: string): string {
    title = _.replace(title, /&#34;/g, '"')
    title = _.replace(title, /&#39;/g, "'")
    return title
  }


  protected create() {
    if (this.user) {
      this.$emit('create', this.user)
    }
  }

  get networkinglist() {
    return this.$store.state.i.me.networkinglist;
  }

  get networking_question1() {

    if(this.user){
      return this.user.networking_question1
    }

    return 0
  }
  get networking_question2() {

    if(this.user){
      return this.user.networking_question2
    }

    return 0
  }

  get fav_agenda_items() {

    if(this.user){
      return this.user.fav_agenda_items
    }

    return 0
  }

  get AgendaEntry(): AgendaPoint[] {
    return _.filter(
        this.$store.getters['i/orderedAgendaPoints'],
        (ap: AgendaPoint) => ap.dbstate > -1
    )
  }

  protected AgendaEntryTitle(ei: string){

    let Entry = _.find(
        this.AgendaEntry,
        (e: AgendaPoint) => `${e.objvalueid}` === ei
    )

    if(Entry){
      return Entry.title
    }else{
      return ''
    }

  }

  protected is_selected1(ei: string){
    return _.includes(_.keys(this.$store.state.i.me.networking_question2), ei)
  }

  protected is_selected2(ei: string){
    return _.includes(_.keys(this.$store.state.i.me.networking_question1), ei)
  }

  protected is_selected3(ei: string){
    return _.includes(_.keys(this.$store.state.i.me.fav_agenda_items), ei)
  }

}
</script>