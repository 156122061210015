<template>
  <div class="box">
    <span class="headline cb-headlinenormalxl g-3"  v-if="!MeetingEntry">Not found</span>
    <div class="row g-3"  v-if="MeetingEntry">

      <div class="col">
        <h4><i class="fal fa-calendar-alt"></i>
          {{formatedDate(MeetingEntry.startdate,MeetingEntry.enddate)}} CEST
          <span v-if="MeetingEntry.status == 1">- Open</span>
          <span v-if="MeetingEntry.status == 2">- Accepted</span>
          <span v-if="MeetingEntry.status == 3">- Declined</span>
          <span v-if="MeetingEntry.status == 4">- Canceled</span>
        </h4>
        <h4 class="mb-3 mt-5 bold">{{title(MeetingEntry.meeting_title)}}</h4>
        <div class="row agenda_item_full">
          <div class="col-12">
            <span class="Sendung_Beschreibung_full"  v-html="MeetingEntry.long_summary">
            </span>
          </div>
        </div>
        <div class="row">
          <div class="mb-5 mt-5 col">
            <span class="headline_light text-center"></span><br>
            <button v-if="MeetingEntry.status != 2 && MeetingEntry.status != 4 && MeetingEntry.receiver == this.$store.state.config.me.objvalueid" class="btn btn-primary"  @click.prevent="acceptMeeting" >Accept</button>
            <button v-if="MeetingEntry.status != 3 && MeetingEntry.status != 4 && MeetingEntry.receiver == this.$store.state.config.me.objvalueid" class="btn btn-primary"  @click.prevent="declineMeeting" >Decline</button>
            <button v-if="MeetingEntry.status != 4 && MeetingEntry.sender == this.$store.state.config.me.objvalueid" class="btn btn-primary"  @click.prevent="removeMeeting" >Cancel</button>
          </div>
          <div class="mb-5 mt-5 col">

          </div>
          <div class="mb-5 mt-5 col">
           <span class="Role copydetailxs">
                <span class="headline_light text-center">ICS DOWNLOAD</span><br>
                  <br><br>
            </span>
          </div>

          <hr>


          <div>

            <div>
              <h3>Meeting partner</h3>
              <div class="row">
                <div class="col-md-4 row">
                  <div class="col-5 mb-5 no-gutters">
                    <a @click="navigateMember" class="pointer">
                      <div class="circular_image">
                        <img :src="userimage" onerror="this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';" alt="">
                      </div>
                    </a>
                  </div>
                  <div class="col-7 mb-5 text-left">
                    <a @click="navigateMember" class="pointer">
                      <span class="Name-Nachname">{{MeetingEntry.firstname}} {{MeetingEntry.lastname}}</span><br>
                      <span class="Role copydetailxs">{{MeetingEntry.orgname}}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

          </div>


        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import _ from "lodash";
import {NavigationGuardNext, Route} from "vue-router";
import {Meetings} from "@/store/i/types";
import {DateTime} from "luxon";
import {TranslatedText} from "@/store/types";
import {AppNetworkErrorOther, networkErrorText} from "@/crud";

@Component({
  components: {},
})
export default class MeetingDetail extends Vue {

  protected created() {
    let stage = 'Test';
    if(window.location.host == 'gmf-event.com'){
      stage = 'Production';
    }

    this.$gtm.trackEvent({
      pageCategory: 'Networking',
      PageName: 'Meeting',
      PageType: 'Webapp',
      Stage:stage,
    });
  }

  get MeetingEntry(): Meetings|undefined {
    return _.find(
        this.$store.state.i.meetings,
        (e: Meetings) => `${e.objvalueid}` === `${this.$route.params.meetingid}`
    )
  }

  protected navigateMember() {
    if (this.MeetingEntry) {
      if (this.MeetingEntry.receiver == this.$store.state.config.me.objvalueid) {
        this.$router.push({name: 'ContactDetail', params: { contactid: `${this.MeetingEntry.sender}` }})
      }else{
        this.$router.push({name: 'ContactDetail', params: { contactid: `${this.MeetingEntry.receiver}` }})
      }
    }
  }

  get userimage(): string|null {
    if (this.MeetingEntry) {
      if (this.MeetingEntry.receiver == this.$store.state.config.me.objvalueid) {
        return '/files/members/'+ this.MeetingEntry.sender +'/foto.jpg'
      }else{
        return '/files/members/'+ this.MeetingEntry.receiver +'/foto.jpg'
      }
    }
    return '/projects/dw/gmf/assets/images/placeholder.jpg'
  }

  protected title(title: string): string {
    title = _.replace(title, /&#34;/g, '"')
    title = _.replace(title, /&#39;/g, "'")
    return title
  }




  protected async generateFileICS(objvalueid: any) {
    window.location.href = '/projects/dw/gmf/sources/scripts/portal.php?&action=generateFileICS&objvalueid='+objvalueid;
  }


  protected formatedDate(startdate: string, enddate: string): string {
    return DateTime.fromISO(startdate, {locale: 'en-us'}).monthLong+" "+DateTime.fromISO(startdate).toFormat('d, h:mm a')+" - "+DateTime.fromISO(enddate).toFormat('h:mm a')
  }

  protected errorstring: TranslatedText = {'de': '', 'en': ''}

  protected async removeMeeting() {
    try {
      this.errorstring = {de: '', en:''}
      const dpr = await this.$store.dispatch("i/removeMeeting", this.MeetingEntry)
      setTimeout(() => { this.reset_message()}, 2000);
      if (!dpr) {
        this.errorstring = {
          de: "Could not remove Appointment.",
          en: "Could not remove Appointment."
        }
      }
    } catch (e) {
      this.errorstring = networkErrorText(e as AppNetworkErrorOther)
    }
  }

  protected async acceptMeeting() {
    try {
      this.errorstring = {de: '', en:''}
      const dpr = await this.$store.dispatch("i/acceptMeeting", this.MeetingEntry)
      setTimeout(() => { this.reset_message()}, 2000);
      if (!dpr) {
        this.errorstring = {
          de: "Could not accept Appointment.",
          en: "Could not accept Appointment."
        }
      }
    } catch (e) {
      this.errorstring = networkErrorText(e as AppNetworkErrorOther)
    }
  }

  protected async declineMeeting() {
    try {
      this.errorstring = {de: '', en:''}
      const dpr = await this.$store.dispatch("i/declineMeeting", this.MeetingEntry)
      setTimeout(() => { this.reset_message()}, 2000);
      if (!dpr) {
        this.errorstring = {
          de: "Could not decline Appointment.",
          en: "Could not decline Appointment."
        }
      }
    } catch (e) {
      this.errorstring = networkErrorText(e as AppNetworkErrorOther)
    }
  }

  protected reset_message(){
    this.errorstring = {de: '', en:''}
  }

}

</script>

<style scoped>

</style>