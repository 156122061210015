import { render, staticRenderFns } from "./PartnerOverview.vue?vue&type=template&id=282f86ce&scoped=true"
import script from "./PartnerOverview.vue?vue&type=script&lang=ts"
export * from "./PartnerOverview.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "282f86ce",
  null
  
)

export default component.exports