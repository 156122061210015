<template>
    <section id="bigchatbox" class="chat-room">
      <div v-if="!contact" class="scroll-element row d-block" ref="scrollelement">
        <p>
          <span v-if="$store.state.config.withsupportchat && $store.state.i.connected && showsupport" id="chatindicator" @click.prevent="toggleChatbox" class="btn btn-primary">
            Support Chat <span v-if="unread > 0" class="badge badge-danger float-right ms-3">{{ unread }}</span>
          </span>
        </p>

        <div class="z-depth-1 pb-0">
          <ul class="list-unstyled friend-list my-custom-scrollbar">
            <li class="p-8 pt-3 me-4" v-if="$store.getters['i/sortedContacts'].length !== 0">
                <input placeholder="Namen suchen" type="search" v-model.trim="searchtext" class="form-control">
            </li>
            <div class="me-4">
              <ChatContactUser
                  v-for="(u, index) in sortedContacts"
                  :key="'chatcontactuser' + index"
                  :user="u"
                  :selected="false"
                  :isSmall="true"
                  @pick="pickuser"
              />
            </div>
          </ul>
        </div>
      </div>

    <div v-if="contact" class="small-convo">


      <button @click.prevent="returnToList" type="button" class="btn  btn-primary mb-3">
        {{ $store.getters.i18n({de: 'Übersicht', en: 'Overview'}) }}
      </button>

        <div class="d-flex justify-content-between p-2 resize-y">
          <div class="heading d-flex justify-content-start">
            <div v-if="userimage" class="profile-photo me-4">
              <div class="circular_image circular_image_small">
                <img :src="userimage" onerror="this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';" alt="">
              </div>
              <span v-if="isonline" class="state"></span>
            </div>
            <div class="data">
              <p class="name mb-0 text-truncate" v-if="!isSupport">
                <strong>{{ contact.firstname }} {{ contact.lastname }}</strong><br>
                {{ title(contact.orgname) }}
              </p>
              <p class="name mb-0 text-truncate" v-if="isSupport">
                <strong>{{ $store.getters.i18n({de:'Support', en:'Support'}) }}</strong>
              </p>
              <p class="name mb-0" v-if="isSupport && showtext">
                  The Support Chat will be available from 8 a.m. to 6 p.m. on June 17 and 18. Until June 17, 8 am, a response may take up to 48h.
              </p>
              <p class="name mb-0" v-if="isSupport && showtext2">
                  The Support Chat will be available from 8 a.m. to 6 p.m. on June 17 and 18. From June 20 on the support chat is not available anymore.
              </p>
            </div>
          </div>
        </div>

        <ChatSmallMsgWrapper />

        <div class="form-group basic-textarea">
              <textarea class="form-control pl-2 my-0" ref="msgenterbox"
                        v-model="mymessage"
                        autocomplete="off"
                        id="enterbox"
                        @keydown.passive="writing"
                        @keypress.enter.prevent="sendmymessage"
                        rows="3"
                        :placeholder="$store.getters.i18n({de:'Ihre Nachricht',en:'Your message'})">
              </textarea>
        </div>

        <div class="mt-2">
          <div>
            <button :disabled="mymessage === '' || sending" @click.passive="sendmymessage" type="button" class="btn btn-primary">
              {{ $store.getters.i18n({de: 'Senden', en: 'Send'}) }}
            </button>
          </div>
        </div>

      </div>
    </section>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {SupportMessage, ChatContact} from "@/store/i/types";
import ChatContactMessageSmall from "@/components/i/ChatContactMessageSmall.vue";
import ChatContactUser from "@/components/i/ChatContactUser.vue";
import ChatSmallMsgWrapper from "@/components/i/ChatSmallMsgWrapper.vue";
import _ from "lodash";
import { DateTime } from "luxon";

import {eveMessageLib} from "@/dummyEveMessageLib";

@Component({
  components: { ChatContactMessageSmall, ChatContactUser, ChatSmallMsgWrapper }
})
export default class BigChat extends Vue {
  protected mymessage: string = ''
  protected sending: boolean = false
  protected switchedKeepalive: boolean = false
  protected searchtext:string = ''

  protected nowWriting: boolean = false
  protected stopWriting = _.debounce((vm:BigChat) => {
    vm.nowWriting = false
    if (vm.contact !== "support") {
      eveMessageLib.msg.send.announcetouser(
          'stopwriting',
          {
            objvalueid: (<ChatContact>vm.contact).objvalueid,
            gsuffix: "members"
          },
          {anonymous: true, persist:false},
          (res: boolean) => {
          }
      )
    } else {
      eveMessageLib.msg.send.announce(
          'cp.supportchat',
          'stopwriting',
          {anonymous: true, persist:false},
          (res: boolean) => {
          }
      )
    }

  },2000)

  protected writing(e:KeyboardEvent) {
    if (e.key.length > 1) {
      return
    }

    const vm = this

    if (!this.nowWriting) {
      this.nowWriting = true
      if (vm.contact !== "support") {
        eveMessageLib.msg.send.announcetouser(
            'startwriting',
            {
              objvalueid: (<ChatContact>vm.contact).objvalueid,
              gsuffix: "members"
            },
            {anonymous: true, persist:false},
            (res: boolean) => {
            }
        )
      } else {
        eveMessageLib.msg.send.announce(
            'cp.supportchat',
            'startwriting',
            {anonymous: true, persist:false},
            (res: boolean) => {
            }
        )
      }
    }

    this.stopWriting(vm)
  }

  protected toggleChatbox() {
    this.$store.commit(
        'i/showChatbox',
        (this.justSupportChat ? 'support' : true)
    )
  }

  get justSupportChat(): boolean {
    return true
  }

  get supportMessages(): SupportMessage[] {
    return this.$store.getters["i/supportchats"]
  }

  get unread(): number {
    const unread = _.filter(
        this.supportMessages,
        (um) => {
          return (
              !um.seen &&
              um.receiver == 'me'
          )
        }
    )
    return unread.length
  }

  get sortedContacts(): ChatContact[] {
    const sC = this.$store.getters['i/sortedContacts']
    if (sC.length > 0 && this.searchtext.length > 0) {
      const swords = _.map(
          _.words(this.searchtext),
          s => new RegExp(_.escapeRegExp(s),'iu')
      )
      return _.filter(
          sC,
          c => {
            for (let i = swords.length -1; i >= 0; i--) {
              if (!swords[i].test(c.firstname + ' ' + c.lastname)) {
                return false
              }
            }

            return true
          }
      )
    }
    return sC
  }

  get showBox(): boolean {
    return !!(this.$store.state.i.showsmallchat)
  }

  protected hideChatbox() {
    this.$store.commit('i/hideChatbox')
  }

  protected returnToList() {
    if (this.$store.state.config.withnetworking) {
      this.$store.commit('i/showChatbox')
    } else {
      this.hideChatbox()
    }
  }

  protected pickuser(fbuid: string) {
    const uc = _.find(
        this.$store.state.i.contactschats,
        (c) => c.fbuid === fbuid
    )
    if (uc) {
      this.$store.commit('i/setChatboxContact', uc)
    }
  }

  get contact(): false|ChatContact|"support" {
    if (this.$store.state.i.showsmallchat === true) {
      return false
    }
    return this.$store.state.i.showsmallchat
  }

  get isSupport(): boolean {
    return this.$store.state.i.showsmallchat === "support"
  }

  get userimage(): string|null {

    if (this.isSupport) {
      return '/projects/dw/gmf/assets/images/support-icon.jpg'
    }

    if (this.contact && this.contact !== "support") {
      return '/files/members/'+ this.contact.objvalueid +'/foto.jpg'
    }


    return '/projects/dw/gmf/assets/images/placeholder.jpg'
  }

  protected async sendmymessage() {
    if (this.sending) {
      return
    }
    this.sending = true
    if (this.contact && this.mymessage !== "") {
      try {
        if (this.contact === "support") {
          await this.$store.dispatch('i/sendmysupportmsg', {
            body: this.mymessage,
            ts: Date.now() / 1000,
            sender: "me",
            receiver: "support"
          })
        } else if (this.contact.objvalueid > 0) {
          await this.$store.dispatch('i/checkIfContactNeedsToBeAdded', {...this.contact})
          await this.$store.dispatch('i/sendmydm', {
            body: this.mymessage,
            ts: Date.now() / 1000,
            sender: this.$store.state.config.fbuid,
            receiver: this.contact.fbuid
          })
        } else {
          return
        }


        this.mymessage = ""

        await this.$nextTick();

        (<HTMLInputElement>this.$refs.msgenterbox).focus()
      } catch (e) {

      }
    }

    this.sending = false
  }

  get showtext(){
    var date = new Date("2022-06-20T08:00:00+02:00").getTime();
    var currenttime = this.$store.state.tick.getTime()
    if(currenttime < date){
      return 1
    }else{
      return 0
    }
  }

  get showtext2(){
    var date = new Date("2022-06-22T00:00:00+02:00").getTime();
    var currenttime = this.$store.state.tick.getTime()
    if(currenttime > date){
      return 1
    }else{
      return 0
    }
  }

  protected isonline: boolean = false

  @Watch("contact")
  protected async checkOnlineState() {
    if (this.contact) {
      let checkres: boolean|null = null
      switch (this.contact) {
        case "support":
          checkres = await this.$store.dispatch("i/checkSupportAvailable")
          break
        default:
          checkres = await this.$store.dispatch("i/checkUserOnlineState", this.contact.objvalueid)
          break
      }

      this.isonline = !!(checkres)
      window.setTimeout(this.checkOnlineState.bind(this), 1000)
    } else {
      this.isonline = false
    }
  }

  protected resizeMode: boolean = false
  protected startResize() {
    this.resizeMode = true
  }
  protected stopResize() {
    this.resizeMode = false
  }


  get showsupport(){
    return 1
  }


  protected title(title: string): string {
    title = _.replace(title, /&#34;/g, '"')
    title = _.replace(title, /&#39;/g, "'")
    return title
  }

}


</script>

<style scoped lang="scss">
    .my-custom-scrollbar {
      overflow-y: scroll;
      height:calc(100vh - 200px);
      margin-bottom: 1rem;
  }
</style>