<template>
  <div v-if="show">
    <div class="modal fade show" style="display: block;" aria-modal="true" role="dialog">
      <div class="modal-dialog modal-lg modal-fullscreen-md-down">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title">Appointment request to {{ContactEntry.firstname}} {{ContactEntry.lastname}}</h2>
            <button type="button" data-mdb-dismiss="modal" aria-label="Close" class="btn-close"  @click.prevent="close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="createMeeting(ContactEntry)">


              <div v-show="successtring.de !== ''" class="alert alert-success col-lg-12">
                {{ $store.getters.i18n(successtring) }}
              </div>

              <div v-show="errorstring.de !== ''" class="alert alert-danger mb-5">
                {{ $store.getters.i18n(errorstring) }}
              </div>

              <p>
                Timeslots marked as "possibly not available" show the timeslots when the person may already be in another appointment.
              </p>

              <div class="mb-4">
                <label class="form-label" >Date</label>
                <select class="form-control"  v-model=date>
                  <option v-for="(e, ei) in days" :value=e.key>{{e.name}}</option>
                </select>
              </div>

              <div class="mb-4">
                <label class="form-label" >Duration</label>
                <select class="form-control"  v-model=duration @change="change">
                  <option v-for="(e, ei) in durations" :value=e.key>{{e.name}}</option>
                </select>
              </div>

              <div class="mb-4">
                <label class="form-label" >Time</label>
                <select class="form-control"  v-model=time>
                  <option v-for="(e, ei) in times" :value=e.key>{{e.name}} {{getNameAvailable(e.key)}}</option>
                </select>
              </div>

              <div class="mb-2">
                <label class="form-label" >Subject</label>
                <input type="text" v-model="meeting_title" class="form-control" />
              </div>

              <div class="mb-5">
                <label class="form-label" >Message</label>
                <textarea v-model="long_summary" class="form-control" />
              </div>

              <div class="d-flex align-items-center">
                <button class="btn btn-primary m-1"  @click.prevent="close" >Cancel</button>
                <button class="btn btn-primary m-1" type="submit" :disabled="!date || !duration || !time || !meeting_title || !long_summary">Send request</button>
              </div>


            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {TranslatedText} from "@/store/types";

// @ts-ignore
import {Modal} from "mdb-ui-kit"
import {ContactsNetworking} from "@/store/i/types";
import {AppNetworkErrorOther, networkErrorText} from "@/crud";
import _ from "lodash";
import {objToFirebaseUID} from "@/firebaseConnections";
import { DateTime } from 'luxon';

@Component({})
export default class MeetingModal extends Vue {

  @Prop() protected connection: number | undefined
  @Prop() protected toggle_modal: boolean | undefined

  protected show:boolean = false
  protected meeting_title:string = ""
  protected long_summary:string = ""
  protected date:string = ""
  protected duration:string = ""
  protected time:string = ""
  protected meetings:[] = []
  protected meetings_loaded:boolean = false

  protected errorstring: TranslatedText = {'de': '', 'en': ''}
  protected successtring: TranslatedText = {'de': '', 'en': ''}


  /* Start Meetings  */

  @Watch("toggle_modal")
  protected check() {
    this.show = !!this.connection;
  }

  protected close() {
    this.date = '';
    this.duration = '';
    this.time = '';
    this.meeting_title = '';
    this.long_summary = '';
    this.show = false;
    this.meetings_loaded = false;
  }

  protected created() {
    this.check()
  }

  protected async createMeeting(ContactEntry: any) {
      try {
        let c =   {
          "objvalueid":  ContactEntry.objvalueid,
          "startdate":  this.date+" "+this.time,
          "duration":  this.duration,
          "meeting_title":  this.meeting_title,
          "long_summary":  this.long_summary,
        };

        this.errorstring = {de: '', en:''}
        this.successtring = {de: '', en:''}
        const dpr = await this.$store.dispatch("i/createMeeting", c)
        setTimeout(() => { this.reset_message()}, 2000);
        if (dpr) {
          this.successtring = {
            de: "Appointment request sent",
            en: "Appointment request sent"
          }

          let c =   {
            "fbuid":  ContactEntry.fbuid,
            "objvalueid":  ContactEntry.objvalueid,
            "gender":  ContactEntry.gender,
            "title":  ContactEntry.title,
            "firstname":  ContactEntry.firstname,
            "lastname": ContactEntry.lastname,
            "orgname": ContactEntry.orgname
          };
          await this.$store.dispatch('i/checkIfContactNeedsToBeAdded', {...c})
          await this.$store.dispatch('i/sendmydm', {
            body: "I sent you a request for an appointment",
            ts: Date.now() / 1000,
            sender: this.$store.state.config.fbuid,
            receiver: c.fbuid
          })
          this.close()
        } else {
          this.errorstring = {
            de: "Appointment request could not be created",
            en: "Appointment request could not be created"
          }
        }
      } catch (e) {
        this.errorstring = networkErrorText(e as AppNetworkErrorOther)
      }
  }

  protected reset_message(){
    this.errorstring = {de: '', en:''}
    this.successtring = {de: '', en:''}
  }


  get days() {

    return [
      {key: "17.06.2024", name: 'June 17'},
      {key: "18.06.2024", name: 'June 18'},
    ];

  }

  get durations() {

    return [
      {key: '1', name: '15 min'},
      {key: '2', name: '30 min'},
    ];

  }


  protected change(){
    this.time = ''
  }

  protected getNameAvailable(key: string){
    let date =  (new Date(DateTime.fromFormat(this.date +" "+ key, "dd.MM.yyyy hh:mm").toISO())).getTime();
    var show_text = false

    Object.entries(this.meetings).forEach(([key, val]) => {
      let startdate = (new Date(val["startdate"])).getTime();
      let enddate   = (new Date(val["enddate"])).getTime();
      if(date >= startdate && date < enddate){
        show_text = true;
        return true;
      }
    });

    if(show_text) {
      return "possibly not available";
    }else{
      return "";
    }

  }

  get times() {

    switch (this.duration) {
      case "1":
        return [
          {key:"09:00", name:'9:00 AM'},
          {key:"09:15", name:'9:15 AM'},
          {key:"09:30", name:'9:30 AM'},
          {key:"09:45", name:'9:45 AM'},
          {key:"10:00", name:'10:00 AM'},
          {key:"10:15", name:'10:15 AM'},
          {key:"10:30", name:'10:30 AM'},
          {key:"10:45", name:'10:45 AM'},
          {key:"11:00", name:'11:00 AM'},
          {key:"11:15", name:'11:15 AM'},
          {key:"11:30", name:'11:30 AM'},
          {key:"11:45", name:'11:45 AM'},
          {key:"12:00", name:'12:00 PM'},
          {key:"12:15", name:'12:15 PM'},
          {key:"12:30", name:'12:30 PM'},
          {key:"12:45", name:'12:45 PM'},
          {key:"13:00", name:'13:00 PM'},
          {key:"13:15", name:'13:15 PM'},
          {key:"13:30", name:'13:30 PM'},
          {key:"13:45", name:'13:45 PM'},
          {key:"14:00", name:'14:00 PM'},
          {key:"14:15", name:'14:15 PM'},
          {key:"14:30", name:'14:30 PM'},
          {key:"14:45", name:'14:45 PM'},
          {key:"15:00", name:'15:00 PM'},
          {key:"15:15", name:'15:15 PM'},
          {key:"15:30", name:'15:30 PM'},
          {key:"15:45", name:'15:45 PM'},
          {key:"16:00", name:'16:00 PM'},
          {key:"16:15", name:'16:15 PM'},
          {key:"16:30", name:'16:30 PM'},
          {key:"16:45", name:'16:45 PM'},
          {key:"17:00", name:'17:00 PM'},
          {key:"17:15", name:'17:15 PM'},
          {key:"17:30", name:'17:30 PM'},
          {key:"17:45", name:'17:45 PM'},
        ]
      case "2":
        return [
          {key:"09:00", name:'9:00 AM'},
          {key:"09:30", name:'9:30 AM'},
          {key:"10:00", name:'10:00 AM'},
          {key:"10:30", name:'10:30 AM'},
          {key:"11:00", name:'11:00 AM'},
          {key:"11:30", name:'11:30 AM'},
          {key:"12:00", name:'12:00 PM'},
          {key:"12:30", name:'12:30 PM'},
          {key:"13:00", name:'13:00 PM'},
          {key:"13:30", name:'13:30 PM'},
          {key:"14:00", name:'14:00 PM'},
          {key:"14:30", name:'14:30 PM'},
          {key:"15:00", name:'15:00 PM'},
          {key:"15:30", name:'15:30 PM'},
          {key:"16:00", name:'16:00 PM'},
          {key:"16:30", name:'16:30 PM'},
          {key:"17:00", name:'17:00 PM'},
          {key:"17:30", name:'17:30 PM'},
        ]
      default:
        return null;
    }


  }

  get ContactEntry(): ContactsNetworking|undefined {
    return _.find(
        this.$store.state.i.contacts_networking,
        (e: ContactsNetworking) => `${e.objvalueid}` === `${this.connection}`
    )
  }

  @Watch("date")
  @Watch("duration")
  protected async getSlotsNotAvailable() {
    if(this.ContactEntry && this.date && this.duration && !this.meetings_loaded){
      try {
        this.errorstring = {de: '', en:''}
        this.meetings = await this.$store.dispatch("i/getSlotsNotAvailable", this.ContactEntry.objvalueid)
        this.meetings_loaded = true
      } catch (e) {
        this.errorstring = networkErrorText(e as AppNetworkErrorOther)
      }
    }

  }


}
</script>