<template>
    <Loading v-if="loading" />
    <span v-else-if="error"></span>
    <GalleryV2 :images="images" v-else />
</template>



<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';
import {defineComponent, PropType} from "vue";
import Loading from "@/components/Loading.vue";
import axios from "axios";
import GalleryV2 from "@/components/GalleryV2.vue";

export default defineComponent({
    name: "GalleryV2Loader",
    components: {
        GalleryV2,
        Loading
    },
    props: {
        url: {
            type: String as PropType<string>,
            required: true
        }
    },
    data() {
        return {
            loading: true,
            error: false,
            images: []
        }
    },
    async mounted() {
        const response = await axios.get(this.url);
        this.loading = false;

        if (response.status !== 200) {
            this.error = true;
            console.error("Couldn't get image slide: Request not successful", response);
            return;
        }

        if (response.data.length < 1) {
            this.error = true;
            console.error("Couldn't get image slide: empty response");
            return;
        }

        this.images = response.data;
    }
})
</script>

<style scoped lang="scss">

</style>