import {GetterTree} from "vuex";
import {RootState} from "@/store/types";
import {MembersState, JitsiCallRequest, JitsiVideoSettings} from "@/store/member/types";
import _ from "lodash";

const getters: GetterTree<MembersState, RootState> = {
    outputDevices(state): (MediaDeviceInfo)[] {
        return _.filter(state.mediaDevices, (md) => md.kind === "audiooutput")
    },

    micDevices(state): (MediaDeviceInfo)[] {
        return _.filter(state.mediaDevices, (md) => md.kind === "audioinput")
    },

    camDevices(state): (MediaDeviceInfo)[] {
        return _.filter(state.mediaDevices, (md) => md.kind === "videoinput")
    },
    
    devicesPicked(state, getters): JitsiVideoSettings {
        const tsettings = state.pickedDevices

        if (tsettings.output === "") {
            if (getters.outputDevices.some((d: MediaDeviceInfo) => d.deviceId === "default")) {
                tsettings.output = "default"
            } else if (getters.outputDevices.length > 0) {
                tsettings.output = getters.outputDevices[0].deviceId
            }
        }

        if (
            tsettings.mic === "" ||
            !getters.micDevices.some((d: MediaDeviceInfo) => d.deviceId === tsettings.mic)
        ) {
            if (getters.micDevices.some((d: MediaDeviceInfo) => d.deviceId === "default")) {
                tsettings.mic = "default"
            } else if (getters.micDevices.length > 0) {
                tsettings.mic = getters.micDevices[0].deviceId
            }
        }

        if (
            tsettings.cam === "" ||
            !getters.camDevices.some((d: MediaDeviceInfo) => d.deviceId === tsettings.cam)
        ) {
            if (getters.camDevices.some((d: MediaDeviceInfo) => d.deviceId === "default")) {
                tsettings.cam = "default"
            } else if (getters.camDevices.length > 0) {
                tsettings.cam = getters.camDevices[0].deviceId
            }
        }
        
        return tsettings
    },

    incomingCalls: (state, getters, rootState) => (withended = false): JitsiCallRequest[] => {
        return _.filter(
            _.uniqBy(
                _.orderBy(
                        state.callHistory,
                    [
                        (c: JitsiCallRequest) => c.defaulttimestamp
                    ],
                    ["asc"]
                ).reverse(),
            (c: JitsiCallRequest) => `${c.callerid}`
            ).reverse(),
            (c: JitsiCallRequest) => (
                c.interlocutorid === rootState.config.me.objvalueid &&
                (withended || !c.connection_end)
            )
        )
    }
}

export default getters