<template>
  <div class="row">
    <div class="col">
      <form method="post" action="#" v-if="$store.state.i.connected" @submit.prevent="sendmyanswers">
        {{ answerhelper }}
        <ul class="voting-list">
          <li v-for="(a, index) in voting.answers" :key="'voting_' + a.uid + '_answer_' + index" :class="{'active': myselection.some(s => s === a.uid)}">
            <label>
              <input type="checkbox" :value="a.uid" name="myselection" v-model="myselection">
              {{ a.caption }}
              <FontAwesomeIcon v-show="!myselection.some(s => s === a.uid)" icon="circlecheckhollow" />
              <FontAwesomeIcon v-show="myselection.some(s => s === a.uid)" icon="circlecheck" />
            </label>
          </li>
        </ul>
        <div class="d-flex justify-content-center">
          <button type="submit" :disabled="restanswers < 0 || myselection.length < 1" class="btn btn-primary">
            {{ $store.getters.i18n({de:'Abstimmen',en:'Vote'}) }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {MultiUserRoom, VotingInteraction} from "@/store/i/types";
import _ from "lodash";

@Component
export default class VotingForm extends Vue {
  @Prop() public voting! : VotingInteraction;

  protected myselection: string[] = []

  get restanswers(): number {
    return this.voting.maxselections - this.myselection.length
  }
//
  get answerhelper(): string {
    if (this.restanswers < 0 && this.voting.maxselections === 1) {
      return this.$store.getters.i18n({
        de:`Sie haben zu viele Antworten gewählt. Sie dürfen nur eine wählen.`,
        en:`You chose too many answers. Only one is allowed.`
      })
    }
    if (this.restanswers < 0) {
      return this.$store.getters.i18n({
        de:`Sie haben zu viele Antworten gewählt. Sie dürfen nur ${this.voting.maxselections} wählen.`,
        en:`You chose too many answers. Only ${this.voting.maxselections} are allowed.`
      })
    }
    if (this.restanswers === 1 && this.myselection.length > 0) {
      return this.$store.getters.i18n({
        de:`Sie können noch eine weitere Antwort auswählen.`,
        en:`You can choose one more answer.`
      })
    }
    if (this.restanswers === 0) {
      return this.$store.getters.i18n({
        de:`Sie können keine weiteren Antworten auswählen.`,
        en:`You cannot choose any more answers.`
      })
    }
    if (this.restanswers === 1) {
      return this.$store.getters.i18n({
        de:`Sie können eine Antwort auswählen.`,
        en:`You can choose one answer.`
      })
    }
    if (this.myselection.length > 0) {
      return this.$store.getters.i18n({
        de:`Sie können ${this.voting.maxselections} Antworten auswählen. ${this.restanswers} Antworten noch übrig.`,
        en:`You can choose ${this.voting.maxselections} answers, ${this.restanswers} remain.`
      })
    }
    return this.$store.getters.i18n({
      de:`Sie können ${this.voting.maxselections} Antworten wählen.`,
      en:`You can choose ${this.voting.maxselections} answers.`
    })
  }

  protected async sendmyanswers() {
    if (this.restanswers >= 0 && this.myselection.length > 0) {
      const res = await Promise.all(
          _.map(
              this.myselection,
              s => this.$store.dispatch("i/castBallot", {
                roomname: this.voting.roomname,
                voteuid: this.voting.uid,
                answer: s
              })
          )
      )
    }
  }
}
</script>