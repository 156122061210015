<template>
    <div class="">

        <div class="col-md-12 col-xl-12">

            <div v-show="successtring.de !== ''" class="alert alert-success col-lg-12">
                {{ $store.getters.i18n(successtring) }}
            </div>

            <div v-show="errorstring.de !== ''" class="alert alert-danger mb-5">
                {{ $store.getters.i18n(errorstring) }}
            </div>

            <p>
                Please prepare a profile picture in 250x250px (max. 1 MB) and upload it here!
            </p>

            <p>
                <input type="file" name="import_file" @change="setDataOnBoarding3($event)" accept="image/jpeg">
            </p>

            <div class="circular_image circular_image_large mb-4">
                <img :src="`/files/members/${$store.state.config.me.objvalueid}/foto.jpg?nocache=${(new Date()).getTime()}`"  onerror="this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';" alt="">
            </div>

            <div v-if="this.type == 'step'"  class="d-flex align-items-center">
                <router-link :to="{name: 'Step2'}" class="btn btn-primary m-1">Back</router-link>
                <router-link :to="{name: 'Step4'}" class="btn btn-primary m-1">Next</router-link>
            </div>

        </div>

    </div>
</template>
<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {TranslatedText} from "@/store/types";
import {AppNetworkErrorOther, networkErrorText} from "@/crud";
import rankingsMaxPoints from '@/ranking';

@Component({
    components: {},
})
export default class OnBoarding3 extends Vue {

    @Prop() protected type!: string|undefined


    protected errorstring: TranslatedText = {'de': '', 'en': ''}
    protected successtring: TranslatedText = {'de': '', 'en': ''}

    protected file: any;

    protected async Next() {
        if(this.type =="step"){
            this.$router.replace({name: "Step4"})
        }
    }

    protected async setDataOnBoarding3(event: { target: { files: any[]; }; }) {
        this.file = event.target.files[0]

        if(this.file.size > 1000000){
            this.errorstring = {
                de: "Could not save image. Max. allowed file size is 1MB (Mbyte)",
                en: "Could not save image. Max. allowed file size is 1MB (Mbyte)"
            }
        }else{
            let formData = new FormData();
            formData.append('file', this.file);
            this.$store.dispatch("i/setDataOnBoarding3", formData);

        try {
            const dpr =  await this.$store.dispatch("i/setDataOnBoarding3", formData);
            setTimeout(() => { this.reset_message()}, 2000);

            if (dpr.sv) {
                this.successtring = {
                    de: "Image saved.",
                    en: "Image saved."
                }

                //ranking activity m - Bild hochgeladen 6 Punkte
                await this.$store.dispatch('i/setDataRankingM', rankingsMaxPoints.ranking_m)


            } else {
                this.errorstring = {
                    de: "Could not save image.",
                    en: "Could not save image."
                }
            }
        } catch (e) {
            this.errorstring = networkErrorText(e as AppNetworkErrorOther)
        }
    }

    

    }

    protected reset_message(){
        this.errorstring = {de: '', en:''}
        this.successtring = {de: '', en:''}
    }
}
</script>

<style scoped>

</style>
