import {Commit} from "vuex";
import Dexie from "dexie";
import {
    AgendaPoint,
    ImagePoint,
    InteractionAlert,
    Message,
    MultiUserRoom,
    DMMessage,
    SupportMessage,
    VotingBallot,
    VotingInteraction,
    ChatContact,
    MemberEntry,
    ContactsNetworking,
    ConnectionNetworking,
    Partner,
    Meetings,
    Me
} from "@/store/i/types";

export class InteractionDatabase extends Dexie {
    public agendapoints: Dexie.Table<AgendaPoint, number>
    public imagepoints: Dexie.Table<ImagePoint, number>
    public toasts: Dexie.Table<InteractionAlert, number>
    public questions: Dexie.Table<Message, string>
    public contactschats: Dexie.Table<ChatContact, string>
    public contacts_networking: Dexie.Table<ContactsNetworking, string>
    public connections_networking: Dexie.Table<ConnectionNetworking, string>
    public meetings: Dexie.Table<Meetings, string>
    public publicchats: Dexie.Table<Message, string>
    public speakers: Dexie.Table<MemberEntry, number>
    public partners: Dexie.Table<Partner, number>
    public rooms: Dexie.Table<MultiUserRoom, string>
    public dmmessages: Dexie.Table<DMMessage, string>
    public supportmessages: Dexie.Table<SupportMessage, string>
    public ballots: Dexie.Table<VotingBallot, number>
    public votings: Dexie.Table<VotingInteraction, string>
    public me: Dexie.Table<Me, string>

    public constructor() {
        super("InteractionDatabase")
        this.version(1).stores({
            agendapoints: "objvalueid",
            imagepoints: "objvalueid",
            toasts: "++id,uid,validforroom",
            questions: "uuid",
            contactschats: "fbuid,objvalueid",
            publicchats: "uuid",
            contacts_networking: "objvalueid",
            connections_networking: "objvalueid",
            meetings: "objvalueid",
            speakers: "objvalueid",
            partners: "objvalueid",
            me: "objvalueid",
            rooms: "roomname",
            dmmessages: "uuid",
            supportmessages: "uuid",
            ballots: "++id,voteuid,answer",
            votings: "uid"
        })
        this.agendapoints = this.table("agendapoints")
        this.imagepoints = this.table("imagepoints")
        this.toasts = this.table("toasts")
        this.questions = this.table("questions")
        this.contactschats = this.table("contactschats")
        this.contacts_networking = this.table("contacts_networking")
        this.connections_networking = this.table("connections_networking")
        this.meetings = this.table("meetings")
        this.publicchats = this.table("publicchats")
        this.speakers = this.table("speakers")
        this.partners = this.table("partners")
        this.rooms = this.table("rooms")
        this.dmmessages = this.table("dmmessages")
        this.supportmessages = this.table("supportmessages")
        this.ballots = this.table("ballots")
        this.votings = this.table("votings")
        this.me = this.table("me")
    }

    public async setRoom(room: MultiUserRoom) {
        return this.rooms.put({...room}, room.roomname)
    }

    public empty() {
        const me = this
        return Promise.all([
            me.rooms.toCollection().delete(),
            me.agendapoints.toCollection().delete(),
            me.imagepoints.toCollection().delete(),
            me.toasts.toCollection().delete(),
            me.questions.toCollection().delete(),
            me.contactschats.toCollection().delete(),
            me.contacts_networking.toCollection().delete(),
            me.connections_networking.toCollection().delete(),
            me.meetings.toCollection().delete(),
            me.publicchats.toCollection().delete(),
            me.dmmessages.toCollection().delete(),
            me.supportmessages.toCollection().delete(),
            me.ballots.toCollection().delete(),
            me.votings.toCollection().delete(),
            me.me.toCollection().delete()
        ])
    }

    public async populateStore(commit: Commit) {
        const me = this
        return Promise.all([
            (async () => {
                const orooms = await me.rooms.toCollection().toArray()
                if (orooms) {
                    orooms.forEach(r => { commit("roomUpdate", r) })
                }
            })(),
            (async () => {
                const oquestions = await me.questions.toCollection().toArray()
                if (oquestions) {
                    oquestions.forEach(q => { commit("addQuestion", q) })
                }
            })(),
            (async () => {
                const opupc = await me.publicchats.toCollection().toArray()
                if (opupc) {
                    opupc.forEach(c => { commit("addPublicMessage", c) })
                }
            })(),
            (async () => {
                const odms = await me.dmmessages.toCollection().toArray()
                if (odms) {
                    odms.forEach(dm => { commit("addDM", dm) })
                }
            })(),
            (async () => {
                const osupport = await me.supportmessages.toCollection().toArray()
                if (osupport) {
                    osupport.forEach(sup => { commit("addSupportMessage", sup) })
                }
            })(),
            (async () => {
                const occ = await me.contactschats.toCollection().toArray()
                if (occ) {
                    occ.forEach(sup => { commit("addContactChat", sup) })
                }
            })(),
            (async () => {
                const oagenda = await me.agendapoints.toCollection().toArray()
                if (oagenda) {
                    oagenda.forEach(ae => { commit("setAgendaPoint", ae) })
                }
            })(),
            (async () => {
                const oimage = await me.imagepoints.toCollection().toArray()
                if (oimage) {
                    oimage.forEach(ae => { commit("setImagePoint", ae) })
                }
            })(),
            (async () => {
                const os = await me.speakers.toCollection().toArray()
                if (os) {
                    os.forEach(e => { commit("setSpeaker", e) })
                }
            })(),
            (async () => {
                const op = await me.partners.toCollection().toArray()
                if (op) {
                    op.forEach(e => { commit("setPartner", e) })
                }
            })(),
            (async () => {
                const ocn = await me.contacts_networking.toCollection().toArray()
                if (ocn) {
                    ocn.forEach(e => { commit("setContactsNetworking", e) })
                }
            })(),
            (async () => {
                const ocn = await me.connections_networking.toCollection().toArray()
                if (ocn) {
                    ocn.forEach(e => { commit("setConnectionsNetworking", e) })
                }
            })(),
            (async () => {
                const om = await me.meetings.toCollection().toArray()
                if (om) {
                    om.forEach(e => { commit("setMeetings", e) })
                }
            })(),
            (async () => {
                const ov = await me.votings.toCollection().toArray()
                if (ov) {
                    ov.forEach(b => { commit("setVoting", b) })
                }
            })(),
            (async () => {
                const ovballots = await me.ballots.toCollection().toArray()
                if (ovballots) {
                    ovballots.forEach(b => { commit("setBallot", b) })
                }
            })(),
            (async () => {
                const toasts = await me.toasts.toCollection().toArray()
                if (toasts) {
                    toasts.forEach(b => { commit("addToast", b) })
                }
            })(),
            (async () => {
                const mydata = await me.me.toCollection().toArray()
                if (mydata) {
                    mydata.forEach(b => { commit("add_me", b) })
                }
            })()
        ])
    }

    public async deleteToast(t: InteractionAlert) {
        if (t.id) {
            t.removed = true
            await this.toasts.put(t, t.id)
        }
    }
}

const interactionoffline = new InteractionDatabase()

export default interactionoffline