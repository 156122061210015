import {RankingObject} from '@/store/i/types';

const rankingsMaxPoints: RankingObject = {
    ranking_a: 150,
    ranking_b: 35,
    ranking_c: 150,
    ranking_d: 25,
    ranking_e: 5,
    ranking_f: 25,
    ranking_g: 200,
    ranking_h: 0, // currently unused
    ranking_i: 3,
    ranking_j: 5,
    ranking_k: 5, // currently used in TEventApp.php
    ranking_l: 6, // currently used in TEventApp.php
    ranking_m: 6,
};

export default rankingsMaxPoints;
