<template>
  <div class="box">
    <span class="headline cb-headlinenormalxl g-3"  v-if="!SpeakerEntry">Not found</span>
    <div class="row g-3" v-if="SpeakerEntry">

      <div class="col-md-5 col-lg-3 mb-3 sm-center">
        <div class="circular_image_large">
          <img :src="`/files/members/${SpeakerEntry.objvalueid}/foto.jpg?nocache=${(new Date()).getHours()}`" width="250" onerror="this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';" alt="">
        </div>
      </div>

      <div class="col-md-7 col-lg-9 mb-3 text-left ipadfx">
        <h3 class="pb-3 pt-0"> {{SpeakerEntry.firstname}} {{SpeakerEntry.lastname}}</h3>
        <div v-if="SpeakerEntry.position">Position: {{SpeakerEntry.position}}</div>
        <div v-if="SpeakerEntry.orgname" class="mb-4">Organization: {{SpeakerEntry.orgname}}</div>


        <div class="row">
          <div class="col-12 col-md-5" v-if="SpeakerEntry.xing || SpeakerEntry.linkedin">
            <span class="Role copydetailxs"><br>
                <span class="headline_light text-center">Follow me</span>
              <br>
              <a :href=SpeakerEntry.xing class="buttonshare" target="_blank"><i class="fab fa-xing fa-2x"></i></a>
              <a :href=SpeakerEntry.linkedin class="buttonshare" target="_blank"><i class="fab fa-2x fa-linkedin-in"></i></a>
            </span>
            <br>
          </div>

          <span class="Role copydetailxs col-12 col-md-7">
            <br>
              <span class="headline_light text-center">Share on</span><br>
              <a class="a-link--external share buttonshare" @click="share('twitter','','en_us')" data-label="twitter"><i class="fab fa-2x fa-x-twitter pe-2"></i>
                    <span class="sr-only">X (Twitter)</span></a>
              <a class="a-link--external share buttonshare" @click="share('linkedin','','en_us')" data-label="linkedin"><i class="fab fa-2x fa-linkedin-in pe-2"></i>
                  <span class="sr-only">LinkedIn</span></a>
              <a class="a-link--external share buttonshare" @click="share('facebook','','en_us')" data-label="facebook"><i class="fab fa-2x fa-facebook-f"></i>
                  <span class="sr-only">Facebook</span></a>
          </span>
          <br>

        </div>

      </div>

      <hr>

      <div v-if="SpeakerEntry.cv">
        <h4 class="bold mb-3">Resume</h4>
        <div class="cv" v-html="SpeakerEntry.cv"></div>
        <hr>
      </div>



      <div v-if="Speakers.length > 0">
        <h4 class="bold mt-4 mb-4">My speaker program</h4>
        <AgendaEntry v-for="(ae, aeindex) in Speakers" :key="`agp1_${aeindex}`" :ae="ae" agendatype="entry"/>
        <hr>
      </div>

      <div v-if="Moderators.length > 0">
        <h4 class="bold mt-4 mb-4">My moderator  program</h4>
        <AgendaEntry v-for="(ae, aeindex) in Moderators" :key="`agp2_${aeindex}`" :ae="ae" agendatype="entry"/>
        <hr>
      </div>

      <div v-if="Artists.length > 0">
        <h4 class="bold mt-4 mb-4">My artist program</h4>

        <AgendaEntry v-for="(ae, aeindex) in Artists" :key="`agp3_${aeindex}`" :ae="ae" agendatype="entry"/>
        <hr>
      </div>


    </div>
  </div>
</template>


<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {MemberContactPerson} from "@/store/member/types";
import {AgendaPoint, MemberEntry} from "@/store/i/types";
import AgendaEntry from "@/components/AgendaEntry.vue";
import _ from "lodash";
import {DateTime} from "luxon";
import {AppNetworkErrorOther, networkErrorText} from "@/crud";
import {TranslatedText} from "@/store/types";

@Component({
  components: {AgendaEntry},
})
export default class SpeakerDetail extends Vue {

  protected created() {

    let stage = 'Test';
    if(window.location.host == 'gmf-event.com'){
      stage = 'Production';
    }
    this.$gtm.trackEvent({
      pageCategory: 'Speaker',
      PageName: 'Detailpage',
      PageType: 'Webapp',
      Stage:stage,
    });
  }

  protected title(title: string): string {
    title = _.replace(title, /&#34;/g, '"')
    title = _.replace(title, /&#39;/g, "'")
    return title
  }

  protected Include(objvalueid: any) {
    return _.has(this.$store.state.i.me.fav_agenda_items, objvalueid)
  }

  protected Exclude(objvalueid: any) {
    return !_.has(this.$store.state.i.me.fav_agenda_items, objvalueid)
  }

  protected errorstring: TranslatedText = {'de': '', 'en': ''}

  protected async setFav(objvalueid: any) {
    try {
      this.errorstring = {de: '', en:''}
      const dpr = await this.$store.dispatch("i/setDataFav", {objvalueid: objvalueid})
      if (dpr.sv) {
      } else {
        this.errorstring = {
          de: "Feld konnte nicht gesetzt werden.",
          en: "Could not set Field."
        }
      }
    } catch (e) {
      this.errorstring = networkErrorText(e as AppNetworkErrorOther)
    }
  }

  protected async unsetFav(objvalueid: any) {
    try {
      this.errorstring = {de: '', en:''}
      const dpr = await this.$store.dispatch("i/setDataUnFav", {objvalueid: objvalueid})
      if (dpr.sv) {
      } else {
        this.errorstring = {
          de: "Feld konnte nicht gesetzt werden.",
          en: "Could not set Field."
        }
      }
    } catch (e) {
      this.errorstring = networkErrorText(e as AppNetworkErrorOther)
    }
  }

  protected async generateFileICS(objvalueid: any) {
    window.location.href = '/projects/dw/gmf/sources/scripts/portal.php?&action=generateFileICS&objvalueid='+objvalueid;
  }

  get SpeakerEntry(): MemberEntry|undefined {
    return _.find(
        this.$store.state.i.speakers,
        (e: MemberEntry) => `${e.objvalueid}` === `${this.$route.params.speakerid}`
    )
  }

  get Speakers(): AgendaPoint[] {
    return _.filter(
        this.$store.getters['i/orderedAgendaPoints'],
        (ap: AgendaPoint) => ap.dbstate > -1 && _.has(ap.speakers, parseInt(this.$route.params.speakerid, 10))
    );
  }

  get Moderators(): AgendaPoint[] {
    return _.filter(
        this.$store.getters['i/orderedAgendaPoints'],
        (ap: AgendaPoint) => ap.dbstate > -1 && _.has(ap.moderators, parseInt(this.$route.params.speakerid, 10))
    );
  }

  get Artists(): AgendaPoint[] {
    return _.filter(
        this.$store.getters['i/orderedAgendaPoints'],
        (ap: AgendaPoint) => ap.dbstate > -1 && _.has(ap.artists, parseInt(this.$route.params.speakerid, 10))
    );
  }

  protected navigateAgenda(objvalueid: any) {
    this.$router.push({name: 'AgendaDetail', params: { agendaid: `${objvalueid}` }})
  }

  protected formatedDate(startdate: string, enddate: string): string {
    return DateTime.fromISO(startdate, {locale: 'en-us'}).monthLong+" "+DateTime.fromISO(startdate).toFormat('d, h:mm a')+" - "+DateTime.fromISO(enddate).toFormat('h:mm a')
  }

  protected share(action: any, text: any, language: any){
    const link = window.location.href;
    const domain = window.location.hostname;

    let appendLanguage = '';
    if(language === 'en_us') {
      appendLanguage = ((link.indexOf('?') !== -1) ? '&' : '?') + 'hl=en';
    }

    const encodedUrl = encodeURIComponent(link + appendLanguage);

    let subject = $(this).closest('ul').data('subject');
    let fulltext = text + " " + encodedUrl;

    if (action === "facebook") {
      window.open("https://www.facebook.com/sharer.php?u=" + encodedUrl);
    } else if (action === "twitter") {
      window.open("https://twitter.com/intent/tweet?source=webclient" + "&url=" + encodedUrl);
    } else if (action === "linkedin") {
      window.open("https://www.linkedin.com/sharing/share-offsite/?url=" + encodedUrl);
    }
  }

}

</script>

<style scoped>

</style>