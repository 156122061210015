<template>
    <li :class="{'active':selected, 'grey':selected, 'lighten-3':selected, 'px-3':!isSmall, 'py-2':true, 'readed':!unread}">
        <span @click.prevent="pickme" class="d-flex justify-content-between pointer">
            <div class="contact-image-wrapper">
                  <div class="circular_image circular_image_small">
                    <img :src="`/files/members/${user.objvalueid}/foto.jpg`" onerror="this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';" alt="">
                  </div>
              <span v-if="isonline" class="online-indecator"></span>
            </div>
            <div class="chat-dm-name text-small">
                <slot name="user">
                  <b>{{ user.firstname }} {{ user.lastname }}</b><br>
                  {{ title(user.orgname) }}<br>
                </slot>
                <p v-if="lastMessage" class="last-message m-0">
                    {{ lastMessage ? lastMessage : $store.getters.i18n({de:'Keine Nachricht', en:'No message'}) }}
                </p>
                <p v-if="isSmall && lastUserMessage" class="m-0">{{ lastUserMessage ? lastMessageMsgString : ' ' }}</p>
            </div>
            <div :class="{'chat-footer': true, 'chat-footer-small': isSmall}" v-if="lastUserMessage">
                <slot name="meta" :unread="unread" :is-supported="isSupported" :last-user-message="lastUserMessage" :last-message-msg-string="lastMessageMsgString">
                    <p v-if="!isSmall" class="mb-0">{{ lastUserMessage ? lastMessageMsgString : ' ' }}</p>
                    <span v-if="unread > 0" class="badge badge-danger float-right">{{ unread }}</span>
                </slot>
            </div>
        </span>
    </li>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {ChatContact,DMMessage} from "@/store/i/types";
import _ from "lodash";
import { DateTime } from "luxon";
import {getFireDB} from "@/firebaseConnections";

@Component
export default class ChatContactUser extends Vue {
    @Prop() protected user!: ChatContact|undefined
    @Prop() protected selected!: boolean|undefined
    @Prop() protected isSmall!: true|undefined
    @Prop({default: false}) protected isSupported!: boolean

    protected title(title: string): string {
      title = _.replace(title, /&#34;/g, '"')
      title = _.replace(title, /&#39;/g, "'")
      return title
    }

    protected pickme() {
        if (this.user) {
            this.$emit('pick', this.user.fbuid)
        }
    }

    get userfbuid(): string {
      return this.user && this.user.fbuid !== '' ? this.user.fbuid : ''
    }


    get lastUserMessage(): DMMessage|null|undefined {
        return _.last(this.userMessages)
    }

    get userMessages(): DMMessage[] {
        if (this.user) {
            return this.$store.getters['i/dmsbyuser'](this.user.fbuid)
        }

        return []
    }

    get lastMessageMsgString(): string {
        if (this.lastUserMessage && this.lastUserMessage.hasOwnProperty('ts') && typeof this.lastUserMessage.ts !== "undefined") {
            const setM = DateTime.fromSeconds(this.lastUserMessage.ts)
            const ndate = DateTime.fromJSDate(this.$store.state.tick)

            return setM
                .setLocale(this.$store.getters.i18n({de:'de',en:'en'}))
                .toLocaleString((DateTime.DATETIME_SHORT )) + this.$store.getters.i18n({de:' Uhr',en:' '})
        }
        return ''
    }



    get unread(): number {
        const unread = _.filter(
            this.userMessages,
            (um) => {
                return (
                    !um.seen &&
                    um.receiver === this.$store.state.config.fbuid
                )
            }
        )
        return unread.length
    }

    get lastMessage(): string|null {
        if (this.lastUserMessage) {
            return this.lastUserMessage.body
        }

        return null
    }

    protected isonline: boolean = false
    protected async addUseridEventlistener() {
      if (this.userfbuid !== '') {
        const fbdb = await getFireDB()
        fbdb.ref(`/presence/event-general/${this.userfbuid}`).on("child_added", () => {
          this.isonline = true
        })
        fbdb.ref(`/presence/event-general/${this.userfbuid}`).on("child_removed", () => {
          this.isonline = false
        })
      }
    }

    protected async removeUseridEventlistener(userid?: string) {
      if (!userid) {
        userid = this.userfbuid
      }
      const fbdb = await getFireDB()
      fbdb.ref(`/presence/event-general/${userid}`).off("child_added")
      fbdb.ref(`/presence/event-general/${userid}`).off("child_removed")
    }

    @Watch("userfbuid")
    protected async watchuser(nv: string, ov?: string) {
      if (ov) {
        await this.removeUseridEventlistener(ov)
      }
      return this.addUseridEventlistener()
    }

    protected online_int: null|number = null
    protected created() {
      this.addUseridEventlistener()
    }

    protected beforeDestroy() {

    }
}
</script>

<style scoped lang="scss">
    li {
        position:relative;
    }

    span.online-indecator {
      position: absolute;
      display: block;
      top: 65px;
      left: 60px;
      width:15px;
      height:15px;
      background-color:#3EBA48;
      border-radius:15px;
      border:1px solid #fff;
    }
    span.offline-indecator {
      position: absolute;
      display: block;
      bottom:7px;
      left:40px;
      width:15px;
      height:15px;
      background-color:#dc3545 ;
      border-radius:15px;
      border:1px solid #fff;
    }

    .chat-dm-name {
        flex-grow: 1;
        padding-left:16px;
    }

    .last-message {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        max-width:100%;
        max-height: 3.25em;
        //white-space: nowrap;
    }
</style>
