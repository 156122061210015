<template>
  <div :class="{'col-lg-4 col-md-6 col-12 mediathek':this.agendatype ==='video' || this.$route.name === 'Mediathek', 'col-lg-9 col-12':this.agendatype !='video' && this.$route.name != 'Mediathek'}">
  
    <div :class="`card mb-3 et${ae.eventtype}`">
     <div class="card-header" v-if="this.agendatype !='video' && this.$route.name != 'Mediathek'">
       <small class="text-muted" style="font-size:1.2rem; padding-right:5px;cursor:pointer;" title="Bookmark" @click="unsetFav" v-if="Include"><i class="fal fa-calendar-plus "></i></small>
       <small class="text-muted" style="font-size:1.2rem; padding-right:5px;cursor:pointer;" title="Bookmark" @click="setFav" v-if="Exclude"><i class="fal fa-calendar-minus "></i></small>
       <small class="text-muted" style="font-size:1.2rem; padding-right:5px;cursor:pointer;" title="ICS Download" @click="generateFileICS"><i class="fal fa-calendar-check "></i></small>
       <small style="font-size:1rem;">{{formatedFromTime}} - {{formatedToTime}} CEST</small>
  
     </div>
  
      <div class="card-body" :class="{'p-0':this.agendatype ==='video' || this.$route.name === 'Mediathek'}">
          
        <a @click="navigateAgenda" class="pointer">
            <img v-if="this.agendatype ==='video' || this.$route.name === 'Mediathek'" onerror="this.onerror=null;this.src='/projects/dw/gmf/assets/images/preview.jpg';" :src="`/files/agenda_items/${ae.objvalueid}/preview.jpg?nocache=${(new Date()).getMinutes()}`" alt="Preview-Image">
            
          <p class="card-title bold">{{title}}</p>
          <p v-if="this.agendatype !='video' && this.$route.name != 'Mediathek'" class="card-title" v-html="ae.subtitle"></p>
          <p class="card-text bold" v-if="ae.hosted_by && (this.agendatype !='video' && this.$route.name != 'Mediathek')">Hosted by: <span v-html="ae.hosted_by"></span></p>
        </a>
      </div>
  
      <div class="card-footer" v-if="(ae.room || ae.stream) && (this.agendatype !='video' && this.$route.name != 'Mediathek')">
        <small class="text-muted">
          <span v-if="ae.room"><i class="fal fa-map-marker-alt"></i> <span v-html="ae.room"></span><br></span>
          <span v-if="ae.stream"><i class="fal fa-signal-stream"></i> <span v-html="ae.stream"></span><br></span>
        </small>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import {AgendaPoint} from "@/store/i/types";
import {DateTime} from "luxon";
import _ from 'lodash';
import {AppNetworkErrorOther, networkErrorText} from "@/crud";
import {TranslatedText} from "@/store/types";

@Component({
  components: {},
})
export default class AgendaEntry extends Vue {
  @Prop() protected ae!: AgendaPoint;
  @Prop() protected type: number | undefined;
  @Prop() protected agendatype: string | undefined;
  
  

  protected navigateAgenda() {
    this.$router.push({name: 'AgendaDetail', params: { agendaid: `${this.ae.objvalueid}` }})
  }

  protected errorstring: TranslatedText = {'de': '', 'en': ''}

  get Include() {
    return _.has(this.$store.state.i.me.fav_agenda_items, this.ae.objvalueid)
  }

  get Exclude() {
    return !_.has(this.$store.state.i.me.fav_agenda_items, this.ae.objvalueid)
  }

  protected async setFav() {
    try {
      this.errorstring = {de: '', en:''}
      const dpr = await this.$store.dispatch("i/setDataFav", {objvalueid: this.ae.objvalueid})
      if (dpr.sv) {
      } else {
        this.errorstring = {
          de: "Feld konnte nicht gesetzt werden.",
          en: "Could not set Field."
        }
      }
    } catch (e) {
      this.errorstring = networkErrorText(e as AppNetworkErrorOther)
    }
  }

  protected async generateFileICS() {
    window.location.href = '/projects/dw/gmf/sources/scripts/portal.php?&action=generateFileICS&objvalueid='+this.ae.objvalueid;
  }

  protected async unsetFav() {
    try {
      this.errorstring = {de: '', en:''}
      const dpr = await this.$store.dispatch("i/setDataUnFav", {objvalueid: this.ae.objvalueid})
      if (dpr.sv) {
      } else {
        this.errorstring = {
          de: "Feld konnte nicht gesetzt werden.",
          en: "Could not set Field."
        }
      }
    } catch (e) {
      this.errorstring = networkErrorText(e as AppNetworkErrorOther)
    }
  }

  get formatedFromTime(): string {
      return DateTime.fromISO(this.ae.startdate, {locale: 'en-us'}).weekdayLong+', '+DateTime.fromISO(this.ae.startdate).toFormat('h:mm a')
  }

  get formatedToTime(): string {
    return  DateTime.fromISO(this.ae.enddate).toFormat('h:mm a')
  }

  get title(): string {
    let title = this.ae.title;
    title = _.replace(title, /&#34;/g, '"')
    title = _.replace(title, /&#39;/g, "'")
    return title
  }

}
</script>