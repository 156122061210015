<template>
  <div class="box coffeebar coffeebar-bg">
    <div class="row g-3 mb-5 mt-2 mb-2">

        <div class="col-lg-6">
          <div class="row dw-bg me-2">
              <h3 class="pb-1">Coffee bar</h3>
              <p>Get together for a virtual coffee with other conference attendees who are online right now! This is an open group chat.</p>
          </div>
          <div class="row dw-bg mt-2 me-2">
              <h4>Room chat</h4>
              <div id="publicchatwrapper2" class="dw-bg" v-if="room && room.withpublicchat">
                <VideoChatArea chattype="chat" />
              </div>
          </div>
        </div>

      <div class="col-lg-6 coffebar-bottom">
        <div class="row dw-bg">
          <h4>Reaction Game</h4>

          <p>Take part in our reaction game and collect points to increase your online activity score!<br>
            The highest online activity scores win a ticket to #GMF25.</p>

          <p>
            <small>
              Rules: You have 20 seconds to click on the logo as often as possible. But be careful, the logo moves faster and faster.
            </small>
          </p>
          <Competition/>
        </div>
      </div>


    </div>
  </div>
</template>

<script lang="ts">

import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {MultiUserRoom} from "@/store/i/types";
import Competition from "@/components/Competition.vue";
import VideoChatArea from "@/components/i/VideoChatArea.vue";
import _ from "lodash";
import {DateTime} from "luxon";


@Component({
  components: {VideoChatArea, Competition},
})
export default class Coffeebar extends Vue {

  get room() : MultiUserRoom|null|undefined {
    if (this.$store.state.i.rooms.length > 0) {
      return _.find(
          this.$store.state.i.rooms,
          (r: MultiUserRoom) => r.roomname === this.$store.state.i.mucname
      )
    }
    return null
  }

  @Watch("$route.meta.mucname")
  protected setRoom() {
    if (this.$route.meta) {
      this.$store.commit('i/setMucName', this.$route.meta.mucname)
      this.$store.dispatch('i/getHistory')
    }
  }

  protected created() {
    this.setRoom()

    let stage = 'Test';
    if(window.location.host == 'gmf-event.com'){
      stage = 'Production';
    }
    this.$gtm.trackEvent({
      pageCategory: 'Coffeebar',
      PageName: 'Coffeebar',
      PageType: 'Webapp',
      Stage:stage,
    });
  }
}
</script>

<style>
 
</style>