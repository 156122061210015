import {ActionTree} from "vuex";
import {RootState, FirebaseProjectConfig} from "@/store/types";
import {initFirebase, connectFirebase} from "@/firebaseConnections";
import crud, {AppNetworkErrorTokenNotSet} from "@/crud";
import offlinedb from "@/store/offlinedb";
import randomstop from "@/store/randomstop";

export const urlBase64ToUint8Array = (base64String: string) =>  {
    let padding = '='.repeat((4 - base64String.length % 4) % 4)
    let base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    let rawData = window.atob(base64);
    let outputArray = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
}

const actions: ActionTree<RootState, RootState> = {
    async populateInital({state, commit, dispatch}) {
        if (state.jwt === "") {
            return
        }

        try {
            const initpr = [
                dispatch("testToken"),
                offlinedb.getConfig()
            ]

            const allres = await Promise.all(initpr)

            if (!allres[0]) {
                commit('setToken', '')
            }

            if (allres[0] && !!(allres[1])) {
                if (allres[1].firebase) {
                    initFirebase(allres[1].firebase)
                }

                commit('setConfig', allres[1])
            } else if (!!(allres[1])) {
                await dispatch("logout")
            }

        } catch (e) {
            commit('setToken', '')
            console.error(e)
        }
    },
    async testToken({state, commit, dispatch}): Promise<boolean> {
        if (state.jwt === "") {
            return false
        }

        await randomstop()

        try {
            const tcheck = await crud('/login/testtoken')
            return !!(tcheck.tokencheck)
        } catch (e) {
            commit('setToken', '')
            console.error(e)
            return false
        }
    },
    async logout({commit, dispatch}) {
        commit('setToken', '')
        commit('clean', '')

        await dispatch("i/logoutFirebase")
    },
    async tradeForFirebaseToken({dispatch}) {
        try {
            const fbtres = await crud('/login/getFirebaseAccessToken')
            if (!!fbtres.fbat) {
                await connectFirebase(fbtres.fbat)
                await dispatch("i/addFirebaseListeners")
                await dispatch("members/addCallListeners")
            }
        } catch (e) {
            console.error(e)
        }
    },
    async login({state, commit, dispatch}, pl:any) {
        try {
            const loginres = await crud('/login', pl)
            if (loginres.jwt) {
                commit('setToken', loginres.jwt)
            }
            if (loginres.config) {
                try {
                    const tokenok = await dispatch("testToken")
                    if (!tokenok) {
                        throw new AppNetworkErrorTokenNotSet()
                    }
                } catch (e) {
                    await offlinedb.clearConfig()
                    await dispatch("i/empty")
                    await dispatch("members/empty")
                    throw e
                }

                if (loginres.config.firebase) {
                    initFirebase(loginres.config.firebase)
                }

                try {
                    const oldconfig = await offlinedb.getConfig()
                    if (oldconfig && loginres.config.fbuid !== oldconfig.fbuid) {
                        await offlinedb.clearConfig()
                        await dispatch("i/empty")
                        await dispatch("members/empty")
                    }
                } catch (e) {
                    console.warn(e)
                }

                try {
                    await offlinedb.setConfig(loginres.config)
                } catch (e) {

                }
                commit('setConfig', loginres.config)
            }

            return loginres
        } catch (e) {
            throw e
        }
    },

    async getSubscription() {
        if (process.env.NODE_ENV === 'production' && navigator.serviceWorker && navigator.serviceWorker.getRegistration) {
            try {
                const registration = await navigator.serviceWorker.getRegistration()
                if (!registration) {
                    return false
                }
                let subscription = await registration.pushManager.getSubscription()
                if (!subscription) {
                    const pubkeyres = await crud('/getVapidPublickey')
                    if (pubkeyres && pubkeyres.pubkey !== '') {
                        const convertedVapidKey = urlBase64ToUint8Array(pubkeyres.pubkey)
                        subscription = await registration.pushManager.subscribe({
                            userVisibleOnly: true,
                            applicationServerKey: convertedVapidKey
                        })

                        await crud('/savePushSubscription', {subscription})
                    } else {
                        throw new Error('Could not fetch pubkey')
                    }
                } else {
                    return subscription
                }
            } catch (e) {
                console.error(e)
            }
        }

        return false
    },


}

export default actions