<template>
    <div id="private-question-ia" class="mt-4 mb-4 mb-lg-0" v-if="isSelected">

      <h5 @click="toogle" class="mt-2">
        <span class="ms-2 pb-2">
              <FontAwesomeIcon icon="arrow-down" v-show="!show" />
              <FontAwesomeIcon icon="arrow-up" v-show="show" />
        </span>
        {{ $store.getters.i18n({de:'Stellen Sie hier Ihre Frage', en: 'Ask your question here'}) }}
      </h5>

      <div class="row mb-2 mt-4" v-show="show" >
        <div class="col">
          <div>
            {{ $store.getters.i18n({
            de:'Ihre Fragen sind nur für Sie, die Regie und den Speaker sichtbar. Bitte haben Sie Verständnis, dass nicht alle Fragen beantwortet werden können.',
            en:'Your questions are only visible to you, the editorial team and the speaker. Please be aware that not all questions can be answered.'
          }) }}
          </div>
        </div>
        <VideoChatArea chattype="questions" />
      </div>


    </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import VideoChatArea from "@/components/i/VideoChatArea.vue";
import FontAwesomeIcon from "@/components/FontAwesomeIcon.vue";
import {MultiUserRoom} from "@/store/i/types";
import _ from "lodash";

@Component({
    components: { VideoChatArea, FontAwesomeIcon }
})
export default class StreamQAWrapper extends Vue {


  protected show = true

  protected toogle() {
    this.show = !this.show;
  }

    get isSelected() : boolean {
        if (
            this.$store.state.i.rooms.length > 0
        ) {
            const uroom : MultiUserRoom|undefined = _.find(
                this.$store.state.i.rooms,
                (r: MultiUserRoom) => r.roomname === this.$store.state.i.mucname
            )
            return <boolean>(
                uroom !== null && typeof uroom === "object" &&
                uroom.interaction && uroom.interaction.type === "questions"
            )
        }
        return false
    }

    protected originalParent: null|HTMLElement = null

    @Watch("$store.state.i.videoFullscreen")
    protected async fullscreenhandle(nv: boolean) {
      const playertoattach = document.querySelector(".video-js")

      if (!playertoattach) {
        return
      }

      if (nv) {
        this.originalParent = this.$el.parentElement
        this.originalParent?.removeChild(this.$el)
        playertoattach.append(this.$el)
      } else {
        playertoattach.removeChild(this.$el)
        this.originalParent?.append(this.$el)
      }
    }

    protected mounted() {
      if (this.$store.state.i.videoFullscreen) {
        this.fullscreenhandle(true)
      }
    }
}
</script>