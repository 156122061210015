<template>
  <div class="vertical-nav">
    <button type="button" class="btn-close btn-close" aria-label="Close" @click.prevent="$store.commit('setSideNav', '')"></button>
    <div class="row m-0">
      <div class="col vertical-nav-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class VerticalNav extends Vue {
  protected mounted() {
    document.body.classList.add("noscroll")
  }

  protected beforeDestroy() {
    document.body.classList.remove("noscroll")
  }
}
</script>

<style scoped>

</style>