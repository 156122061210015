import {ActionTree} from "vuex";
import {LandingPageState, Ticket} from "@/store/landingpage/types";
import {RootState} from "@/store/types";
import randomstop from "@/store/randomstop";
import crud from "@/crud";
import {random} from "lodash";

const actions: ActionTree<LandingPageState, RootState> = {
    async populate({state, commit, dispatch, rootState}) {
        await Promise.all([
            dispatch('fetchTickets'),
            dispatch('fetchSpeakers'),
            dispatch('fetchProgramHighlights'),
        ]);
    },

    async fetchTickets({state, commit}) {

        try {
            await randomstop();
            const result = await crud('/public/getTickets')
            commit('setShopsAvailable', result.shopAvailable)
            if (result.entries) {
                commit("setTickets", result.entries)
            }
        } catch (e) {
            console.error("Problem fetching tickets", e)
        }
    },
    async fetchSpeakers({state, commit}) {
        try {
            await randomstop();
            const result = await crud('/public/getHighlightedSpeakers')
            if (result.entries) {
                commit("setHighlightedSpeakers", result.entries);
            }
        } catch (e) {
            console.error("Problem fetching speakers", e)
        }
    },

    async fetchProgramHighlights({state, commit}) {
        try {
            await randomstop();
            const result = await crud('/public/getProgramHighlights')
            if (result.entries) {
                commit("setProgramHighlights", result.entries);
            }
        } catch (e) {
            console.error("Problem fetching speakers", e)
        }
    }
}

export default actions