<template>
  <div class="box">
    <div class="row g-3 mb-5 highlights">
      <h3 class="pb-1">On demand</h3>

      <Agenda agendatype="video"/>


      <h3 class="pb-1">GMF talk</h3>
      <iframe class="col-12 col-md-9 col-lg-6" width="560" height="315" src="https://www.youtube-nocookie.com/embed/LgQcVZp0uSA?si=stwan5FHHutus4Qc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      
      <h3 class="pb-1">DW Global Media Forum 2023</h3>
      <iframe class="col-12 col-md-9 col-lg-6" width="560" height="315" src="https://www.youtube-nocookie.com/embed/videoseries?si=ejHfrAoF9IRPqrzr&amp;controls=0&amp;list=PLnRODqqz08xJcSJUoTuyP2uJmjb-nG9ZN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Agenda from "@/components/Agenda.vue"

@Component({
  components: {Agenda},
})
export default class Mediathek extends Vue {
  protected created() {

    let stage = 'Test';
    if(window.location.host == 'gmf-event.com'){
      stage = 'Production';
    }
    this.$gtm.trackEvent({
      pageCategory: 'Mediathek',
      PageName: 'Overview',
      PageType: 'Webapp',
      Stage:stage,
    });
  }
}
</script>
