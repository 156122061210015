<template>
  <div class="box streaming">
    <div class="row g-3">


      <h1 v-if="AgendaEntry">{{title(AgendaEntry.title)}}</h1>

      <div class="col-sm d-flex text-primary" v-if="video && video.type === 'stream' && videoMuted" @click="unmutebyalert">
        <div class="micon">
          <svg aria-hidden="true" focusable="false" data-icon="volume-mute" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M454.63 256l55.03-55.03c3.12-3.12 3.12-8.19 0-11.31l-11.31-11.31c-3.12-3.12-8.19-3.12-11.31 0L432 233.37l-55.03-55.03c-3.12-3.12-8.19-3.12-11.31 0l-11.31 11.31c-3.12 3.12-3.12 8.19 0 11.31L409.37 256l-55.03 55.03c-3.12 3.12-3.12 8.19 0 11.31l11.31 11.31c3.12 3.12 8.19 3.12 11.31 0L432 278.63l55.03 55.03c3.12 3.12 8.19 3.12 11.31 0l11.31-11.31c3.12-3.12 3.12-8.19 0-11.31L454.63 256zM231.81 64c-5.91 0-11.92 2.18-16.78 7.05L126.06 160H24c-13.26 0-24 10.74-24 24v144c0 13.25 10.74 24 24 24h102.06l88.97 88.95c4.87 4.87 10.88 7.05 16.78 7.05 12.33 0 24.19-9.52 24.19-24.02V88.02C256 73.51 244.13 64 231.81 64zM224 404.67l-75.32-75.3-9.37-9.37H32V192h107.31l9.37-9.37 75.32-75.3v297.34z" class=""></path></svg>
        </div>
        <div class="mtext">
          {{ $store.getters.i18n({
          de:'Das Video ist stummgeschaltet. Bitte heben Sie die Stummschaltung auf, um den Ton hören zu können.',
          en:'The video playback is muted. Please unmute to hear the audio.'
        }) }}
        </div>
      </div>

      <div class="col-sm popout-bar">
        <button @click="showhelpbox = !showhelpbox" id="streamhelpbutton" :disabled="showhelpbox" class="btn btn-dark btn-right" type="button">{{ $store.getters.i18n({de:'Hilfe', en:'Help' }) }}</button>
      </div>

    </div>

    <div class="row g-3">

      <div v-if="!removeforatick" :class=responsiveClassesCol>
        <div v-if="streamFrameSrc" :class="responsiveClasses" data-wrapperfor="frame">
          <iframe title="" class="embed-responsive-item" :src="streamFrameSrc" allow="autoplay; fullscreen" allowfullscreen></iframe>
        </div>
        <div v-if="placeholderImage && video && video.type === 'placeholder'" :class="responsiveClasses" data-wrapperfor="placeholder">
          <img class="plimg img-fluid" alt="Placeholder for stream" :src="placeholderImage" />
        </div>
        <div v-if="streamHlsSrc" :class="responsiveClasses" data-wrapperfor="videojs">
          <VideojsWrapper
              playerid="muclivestreamplayer"
              v-if="streamHlsSrc"
              :ssrc="streamHlsSrc"
              :splaceholder="placeholderImage"
              :sautostart="streamAutoplay"
              :sloop="streamLoop"
              :sautopip="streamAutoPIP"
              @player-ready="videojsready"
          />
        </div>
        <div v-if="videoVariants && videoVariants.length > 1" class="teaser-standard-button text-center clearfix mt-10">
          <p>
            <a v-for="(v, index) in videoVariants"
               :key="'variantLink' + index"
               @click.prevent="selectedVideo = index"
               href="#" :class="{'btn btn-primary':selectedVideo === index, 'btn btn-secondary': selectedVideo !== index, 'm-2':true}">
              {{ variantName(v) }}
            </a>
          </p>
        </div>

        <StreamQAWrapper />
        <VotingArea />

        <div id="pp-detail" class="mt-4 mb-4 mb-lg-0" v-if="AgendaEntry">

          <div class="row mb-2 mt-4">
            <div class="cv" v-html="AgendaEntry.long_summary"></div>
          </div>

          <div class="row mb-2 mt-4">
            <div class="col-4" v-if="Speakers.length > 0">
              <span><b>Speakers</b></span><br><br>
              <div v-for="(ae, aeindex) in Speakers">
                {{ae.firstname}} {{ae.lastname}}
              </div>
            </div>
            <div class="col-4" v-if="Moderators.length > 0">
              <span><b>Moderator</b></span><br><br>
              <div v-for="(ae, aeindex) in Moderators">
                {{ae.firstname}} {{ae.lastname}}
              </div>
            </div>
            <div class="col-4" v-if="Artists.length > 0">
              <span><b>Artists</b></span><br><br>
              <div v-for="(ae, aeindex) in Artists">
                {{ae.firstname}} {{ae.lastname}}
              </div>
            </div>
          </div>

        </div>
      </div>
      <div id="publicchatwrapper" v-if="room && room.withpublicchat" class="col-lg-4">
        <VideoChatArea chattype="chat" />
      </div>
    </div>

    <div id="streamhelpbox" v-show="showhelpbox" class="share p-4" @click.stop="showhelpbox = !showhelpbox">
      <button type="button" class="btn-close" aria-label="Close"></button>
      <h4>
        {{ $store.getters.i18n({de:'Hilfe', en: 'Help'}) }}
      </h4>
      {{ $store.getters.i18n({
      de:'Wenn Sie Probleme bei der Anzeige haben, dann prüfen Sie bitte zunächst:',
      en: 'If you are having problems with displaying the stream, please check this first:'
    }) }}
      <ul>
        <li>{{ $store.getters.i18n({de:'Nutzen Sie Mozilla Firefox, Google Chrome, Microsoft Edge oder Apple Safari als Browser?', en: 'Are you using Mozilla Firefox or Google Chrome in the latest version?'}) }}</li>
        <li>{{ $store.getters.i18n({de:'Ist Ihr Audiogerät mit dem Laptop/PC verbunden?', en: 'Are your speakerboxes connected to your pc or laptop?'}) }}</li>
        <li>{{ $store.getters.i18n({de:'Haben Sie eine stabile Internetverbindung?', en: 'Do you have a stable Internet connection?'}) }}</li>
        <li>{{ $store.getters.i18n({de:'Bitte stellen Sie sicher, dass Videodienste nicht blockiert werden.', en: 'Please make sure none of the video providers are blocked in your network.'}) }}</li>
      </ul>
<!--      {{ $store.getters.i18n({de:'Dennoch Probleme? Dann wenden Sie sich an unseren Support Chat.', en: 'Otherwise contact us at:'}) }}-->
    </div>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {AgendaPoint, InteractionAlert, MemberEntry, MultiUserRoom, VideoVariant} from "@/store/i/types";
import VideojsWrapper from "@/components/VideojsWrapper.vue";
import VideoChatArea from "@/components/i/VideoChatArea.vue";
import StreamQAWrapper from "@/components/i/StreamQAWrapper.vue";
import VotingArea from "@/components/i/VotingArea.vue";
import videojs from 'video.js';
import _ from "lodash";
import {DateTime} from "luxon";
import {NavigationGuardNext, Route} from "vue-router";
import rankingsMaxPoints from '@/ranking';

@Component({
  components: { VideojsWrapper, VideoChatArea, StreamQAWrapper, VotingArea },
  beforeRouteLeave(to, from, next) {
    if ((this as any).navguard) {
      (this as any).navguard(to, from, next)
    } else {
      next()
    }
  }
})
export default class Streaming extends Vue {
  protected selectedVideo: number = 0

  protected showhelpbox: boolean = false

  protected removeforatick: boolean = false

  protected videoMuted: boolean = false
  protected videoPlaying: boolean = false

  protected playerinst: videojs.Player|undefined = undefined
  protected start_view: any;

  get room() : MultiUserRoom|null|undefined {
    if (this.$store.state.i.rooms.length > 0) {
      return _.find(
          this.$store.state.i.rooms,
          (r: MultiUserRoom) => r.roomname === this.$store.state.i.mucname
      )
    }
    return null
  }

  get videoVariants() : VideoVariant[]|null {
    if (this.room && this.room.video) {
      return _.filter(
          this.room.video,
          v => !!(v.enabled)
      )
    }
    return null
  }

  @Watch('videoVariants')
  protected calcVariant(nV:undefined|null|VideoVariant[], oV?:undefined|null|VideoVariant[]) {
    if (
        ((nV && nV.length > 1) &&
            (!oV || oV.length < 2)) ||
        (nV && oV && nV.length > 1 && nV.length !== oV.length)
    ) {
      this.selectedVideo = 0
      for (let i = nV.length -1; i >= 0; i--) {
        if (nV[i].islang && nV[i].islang === this.$store.state.config.lang) {
          this.selectedVideo = i
        }
      }
    }
  }
  /*
  @Watch('$store.state.config.lang')
  protected forceCalcVariant() {
      this.calcVariant(this.videoVariants)
  }
  */
  get video(): VideoVariant|null {
    if (this.videoVariants) {
      return this.videoVariants[this.selectedVideo]
    }
    return null
  }

  get responsiveClasses(): string {
    return 'ratio ratio-' + (
        this.video ? this.video.ratio.replace(/by/, "x") : '16x9'
    )
  }

  get responsiveClassesCol(): string {
    if (this.room && this.room.withpublicchat) {
      return 'col-lg-8';
    }
    return 'col';
  }

  get streamFrameSrc(): string|null {
    if (this.video && this.video.type === "embed") {
      return this.video.path
    }
    return null
  }

  @Watch("streamHlsSrc")
  @Watch("streamFrameSrc")
  protected async switchFrameOrVideoType() {
    this.removeforatick = true
    await this.$nextTick()
    this.removeforatick = false
  }

  get streamHlsSrc(): string|null {
    if (this.video && this.video.type === "stream") {
      return this.video.path
    }
    return null
  }

  get streamAutoplay(): true|undefined {
    if (this.video && this.video.type === "stream" && this.video.autostart) {
      return true
    }
    return undefined
  }

  get streamLoop(): true|undefined {
    if (this.video && this.video.type === "stream" && this.video.loop) {
      return true
    }
    return undefined
  }

  get streamAutoPIP(): boolean {
    if (this.video && this.video.type === "stream" && this.video.autopip) {
      return true
    }
    return false
  }

  get placeholderImage(): string|null {
    if (this.video && this.video.placeholder_image && this.video.placeholder_image !== "") {
      return this.video.placeholder_image
    }
    return null
  }

  protected variantName(v: VideoVariant): string {
    const vname = {
      de: v.name,
      en: v.name_en
    }

    if (typeof vname.de !== "string" || vname.de === '') {
      vname.de = vname.en
    } else if (typeof vname.en !== "string" || vname.en === '') {
      vname.en = vname.de
    }

    return this.$store.getters.i18n(vname)
  }

  protected unmutebyalert(e: MouseEvent) {
    if (this.playerinst) {
      this.playerinst.muted(false)
      this.playerinst.volume(1)
    }
  }

  protected testifmuted() {
    if (this.playerinst) {
      this.videoMuted = this.playerinst.muted() || this.playerinst.volume() < 0.05
    }
  }

  @Watch("$store.state.tick")
  protected currentAgendaPoint() {

    let c = this.$store.state.i.currentagenda
    const cap: AgendaPoint|undefined = this.$store.getters['i/currentAgendaPoint']

    if(c?.objvalueid != cap?.objvalueid){
      this.$store.commit('i/setCurrentAgenda', cap)
    }

    if (
        cap && cap.roomname != "muc-livestream" && this.$store.state.config.me.no_force_plenum != 1
    ) {
      this.$router.push({name: "Welcome"})
    }

    if (
        cap && cap.needs_idd &&
        !this.$store.state.i.toasts.some((t: InteractionAlert) => t.announce_confirm && t.announce_confirm !== "")
    ) {

      let current_time = DateTime.fromISO(cap.date_idd)
      const diffmin = Math.round(DateTime.fromJSDate(this.$store.state.tick).diff(current_time).as('seconds'))
      if (diffmin === 1) {
        this.$store.commit(
            "i/addToast",
            {
              header: {de: 'IDD Zeiten', en: 'IDD Times'},
              msg: {de: 'Bitte bestätigen Sie uns, dass Sie dem Live-Stream noch folgen!', en: 'Please confirm that you are still watching.'},
              validforroom: "event-general",
              got: new Date(),
              hidden: false,
              showfor: 300 * 1000, // 300 sek ( 5 min)
              announce_confirm: `watching-${cap.objvalueid}`
            }
        )
      }
    }

  }


  @Watch("videoPlaying")
  @Watch("$route.name")
  protected matchAddressPlaystate(nv: string|boolean) {
    switch (typeof nv) {
      case "boolean":
        // Playstate changed
        switch (this.$route.name) {
          case "Stage":
          case "StagePlaying":
            this.$router.replace({name: (nv ? 'StagePlaying' : 'Stage')})
            break
          case "Workshop":
          case "WorkshopPlaying":
            this.$router.replace({name: (nv ? 'WorkshopPlaying' : 'Workshop')})
            break
          default:
            break
        }

        return
      case "string":
        // Nav changed
        if (this.playerinst) {

          if (this.videoPlaying) {
            switch (nv) {
              case "Stage":
                this.$router.replace({name: 'StagePlaying'})
                break
              case "Workshop":
                this.$router.replace({name: 'WorkshopPlaying'})
                break
              default:
                break
            }
          }

          if (!this.videoPlaying) {
            switch (nv) {
              case "StagePlaying":
              case "WorkshopPlaying":
                this.playerinst.play()
                break
              default:
                break
            }
          }

        }

        return
      default:
        return
    }
  }

  protected videojsready(pi?: videojs.Player) {
    this.playerinst = pi
    if (this.playerinst) {
      const vm = this
      this.$store.commit("i/setFullscreen", this.playerinst.isFullscreen())
      this.playerinst.on("volumechange", () => { vm.testifmuted() })
      this.playerinst.on("fullscreenchange", () => {
        if (vm.playerinst) {
          vm.$store.commit("i/setFullscreen", vm.playerinst.isFullscreen())
        }
      })
      this.playerinst.on("play", () => {
        if (vm.playerinst) {
          vm.videoPlaying = true
          vm.testifmuted()
        }
      })
      this.playerinst.on("pause", () => {
        if (vm.playerinst) {
          vm.videoPlaying = false
        }
      })
      this.playerinst.on("abort", () => {
        if (vm.playerinst) {
          vm.videoPlaying = false
        }
      })
    }
  }

  protected navguard(to: Route, from: Route, next:NavigationGuardNext<Vue>) {
    if (this.$store.getters['i/forcedToPlenum'] && (this.$route.name == "Stage" || this.$route.name == "StagePlaying")) {
      next(false)
      this.$store.commit("i/addToast", {
        header: {de: "Plenum", en: "Plenum"},
        msg: {
          de: 'Sie können das Plenum aktuell nicht verlassen.',
          en: 'You can not leave the stream at this time.'
        },
        validforroom: "event-general",
        got: new Date(),
        hidden: false,
        alert_type: 'error'
      })
    } else {
/*
      if(this.$store.state.i.me.tn_type != 26 && this.$store.state.i.me.tn_type != 33){
        //ranking activity h - für das Anschauen des Livestreams länger als 1 min 1 Punkte


        let date = new Date()
        //nur 10-18:30 Uhr CEST
        if(date.toDateString() === 'Mon Jun 17 2024' && (date.getTime() >= 1687161600 && date.getTime() <= 1687192200)){
            let end_view  = this.$store.state.tick.getTime();
            let diff = Math.round((end_view - this.start_view) / 1000);

            if(diff > 60 && this.$store.state.i.me.ranking_h < rankingsMaxPoints.ranking_h){
                let value = this.$store.state.i.me.ranking_h + 1;
                this.$store.dispatch('i/setDataRankingH', value)
            }              
        }
          
        //nur 10-17:45 Uhr CEST
        if(date.toDateString() === 'Tue Jun 18 2024' && (date.getTime() >= 1687248000 && date.getTime() <= 1687275900)){
    
            let end_view2  = this.$store.state.tick.getTime();
            let diff2 = Math.round((end_view2 - this.start_view) / 1000);
  
            if(diff2 > 60 && this.$store.state.i.me.ranking_e < rankingsMaxPoints.ranking_e){
                let value = this.$store.state.i.me.ranking_e + 1;
                this.$store.dispatch('i/setDataRankingE', value)
            }
        }

      }

*/
      next()
    }
  }

  protected mounted() {
    if (!this.$store.state.i.connected) {
      this.$store.dispatch("i/loadRoomconfViaFallback")
    }
  }

  protected created(){
    this.setRoom()

    this.start_view = this.$store.state.tick.getTime();

    let stage = 'Test';
    if(window.location.host == 'gmf-event.com'){
      stage = 'Production';
    }
      this.$gtm.trackEvent({
        pageCategory: 'Streaming',
        PageName: 'Streaming',
        PageType: 'Webapp',
        Stage:stage,
      });
  }

  @Watch("$route.meta.mucname")
  protected setRoom() {
    if (this.$route.meta) {
      this.$store.commit('i/setMucName', this.$route.meta.mucname)
      this.$store.dispatch('i/getHistory')
    }
  }

  get AgendaEntry(): AgendaPoint|undefined {
    return _.find(
        this.$store.state.i.agenda,
        (ap: AgendaPoint) => ap.dbstate > -1 && ap.now_live
    )
  }

  get Moderators(): MemberEntry[] {
    return _.filter(
        this.$store.state.i.speakers,
        (ap: MemberEntry) => ap.dbstate > -1 && _.has(this.mod, ap.objvalueid) && ap.is_visible
    );
  }

  get mod(): any {
    if(this.AgendaEntry){
      return this.AgendaEntry.moderators
    }else{
      return 0
    }
  }


  get Speakers(): MemberEntry[] {
    return _.filter(
        this.$store.state.i.speakers,
        (ap: MemberEntry) => ap.dbstate > -1 && _.has(this.spe, ap.objvalueid) && ap.is_visible
    );
  }

  get spe(): any {
    if(this.AgendaEntry){
      return this.AgendaEntry.speakers
    }else{
      return 0
    }
  }


  get Artists(): MemberEntry[] {
    return _.filter(
        this.$store.state.i.speakers,
        (ap: MemberEntry) => ap.dbstate > -1 && _.has(this.art, ap.objvalueid) && ap.is_visible
    );
  }

  get art(): any {
    if(this.AgendaEntry){
      return this.AgendaEntry.artists
    }else{
      return 0
    }
  }

  protected title(title: string): string {
    title = _.replace(title, /&#34;/g, '"')
    title = _.replace(title, /&#39;/g, "'")
    return title
  }

}
</script>

<style scoped>

</style>
