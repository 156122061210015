var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('footer',[_c('div',{staticClass:"m-1 row g-3"},[_vm._m(0),_c('div',{staticClass:"col-6 col-md-6 col-lg-3"},[_c('ul',{staticClass:"col list-unstyled mt-0"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('li',[_c('a',{staticClass:"group-link link-light text-decoration-none",attrs:{"href":"#","target":"_blank"},on:{"click":function($event){$event.preventDefault();return _vm.showAccessibilityModal.apply(null, arguments)}}},[_vm._v(" Accessabilty ")])])])]),_vm._m(5)]),_c('br'),_vm._m(6),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-6 col-md-6 col-lg-3"},[_c('ul',{staticClass:"col list-unstyled mt-0"},[_c('li',[_c('h2',{staticClass:"group-header"},[_vm._v("About")])]),_c('li',[_c('a',{staticClass:"group-link link-light text-decoration-none",attrs:{"href":"https://www.dw.com/en/gmf/s-43101535","target":"_blank"}},[_vm._v(" Who we are ")])]),_c('li',[_c('a',{staticClass:"group-link link-light text-decoration-none",attrs:{"href":"https://www.dw.com/en/contact/s-4155","target":"_blank"}},[_vm._v(" Press ")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('h2',{staticClass:"group-header"},[_vm._v("Service")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('a',{staticClass:"group-link link-light text-decoration-none",attrs:{"href":"https://p.dw.com/p/3iRF7/","target":"_blank"}},[_vm._v(" Newsletter ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('a',{staticClass:"group-link link-light text-decoration-none",attrs:{"href":"/files/footer/faqs.pdf","target":"_blank"}},[_vm._v(" FAQ (open PDF) ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',[_c('a',{staticClass:"group-link link-light text-decoration-none",attrs:{"href":"/contact/","target":"_blank"}},[_vm._v(" Contact ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-6 col-md-6 col-lg-3"},[_c('ul',{staticClass:"col list-unstyled mt-0"},[_c('li',{staticClass:"group-header"},[_vm._v("Location")]),_c('li',[_c('a',{staticClass:"group-link link-light text-decoration-none",attrs:{"href":"https://goo.gl/maps/1i5Y5GZ8yDzx7ZRP8","target":"_blank"}},[_vm._v(" World Conference Center Bonn (WCCB) "),_c('br'),_vm._v(" Platz der Vereinten Nationen 2 "),_c('br'),_vm._v(" 53113 Bonn "),_c('br'),_vm._v(" Germany (open in Google Maps) ")])]),_c('li',[_c('a',{staticClass:"group-link link-light text-decoration-none",attrs:{"href":"/files/footer/arrival.pdf","target":"_blank"}},[_vm._v(" Arrival Description (open PDF) ")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"m-1 row g-3 pt-0"},[_c('div',{staticClass:"col mt-0"},[_c('h2',{staticClass:"group-header"},[_vm._v("Follow us on")]),_c('a',{staticClass:"social-link social-link-twitter link-light text-decoration-none",attrs:{"href":"https://twitter.com/DW_GMF","target":"_blank"}},[_c('i',{staticClass:"fab fa-2x fa-x-twitter"}),_c('span',{staticClass:"sr-only"},[_vm._v("X (Twitter)")])]),_c('a',{staticClass:"social-link social-link-linkedin link-light text-decoration-none",attrs:{"href":"https://www.linkedin.com/showcase/5088224/","target":"_blank"}},[_c('i',{staticClass:"fab fa-2x fa-linkedin-in"}),_c('span',{staticClass:"sr-only"},[_vm._v("LinkedIn")])]),_c('a',{staticClass:"social-link social-link-facebook link-light text-decoration-none",attrs:{"href":"https://www.facebook.com/dw.gmf","target":"_blank"}},[_c('i',{staticClass:"fab fa-2x fa-facebook-f"}),_c('span',{staticClass:"sr-only"},[_vm._v("Facebook")])]),_c('a',{staticClass:"social-link social-link-youtube link-light text-decoration-none",attrs:{"href":"https://www.youtube.com/c/DWGlobalMediaForum/featured","target":"_blank"}},[_c('i',{staticClass:"fab fa-2x fa-youtube"}),_c('span',{staticClass:"sr-only"},[_vm._v("Youtube")])]),_c('a',{staticClass:"social-link social-link-contact link-light text-decoration-none",attrs:{"href":"https://p.dw.com/p/3iRF7","target":"_blank"}},[_c('i',{staticClass:"far fa-2x fa-envelope-open"})])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"m-1 row row-mb g-3"},[_c('div',{staticClass:"mt-0 standard-link col-12 copyright_padding"},[_c('span',[_vm._v("© 2024 Deutsche Welle")]),_c('a',{staticClass:"group-link link-light text-decoration-none",attrs:{"href":"/files/privacy_policy.pdf","target":"_blank"}},[_vm._v(" Privacy Policy (open PDF) ")]),_c('a',{staticClass:"group-link link-light text-decoration-none",attrs:{"href":"https://www.dw.com/en/legal-notice/a-15718492","target":"_blank"}},[_vm._v(" Legal Notice ")]),_c('a',{staticClass:"group-link link-light text-decoration-none",attrs:{"href":"/files/footer/ticketshop_gtc.pdf","target":"_blank"}},[_vm._v(" Ticketshop GTC (open PDF) ")])])])
}]

export { render, staticRenderFns }