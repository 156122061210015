<template>
  <div class="box">
    <div class="row g-3 mb-5 photos mt-2 mb-2">
      <h3 class="pb-1">DW Freedom of Speech Award</h3>
      
         <div class="cv">
            <p>
              Since 2015, DW has awarded the Freedom of Speech Award to spotlight restricted press freedom of the world and honor the exceptional work of journalists and human rights defenders.<br>
              Past honorees include Óscar Martínez, editor-in-chief of online outlet El Faro in El Salvador (2023), and Ukrainian war reporters Evgeniy Maloletka and Mstyslav Chernov (2022), who won the "Best Documentary" award at the 2024 Oscars for "20 Days in Mariupol".</p>
        </div>
      
        <GalleryV2Loader :url="'/webapp/imageslider/'" class="light-gray"/>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import GalleryV2Loader from "@/components/GalleryV2Loader.vue";

@Component({
    components: {
        GalleryV2Loader
    }
})

export default class PhotoExhibition extends Vue {

    protected created() {

    let stage = 'Test';
    if(window.location.host == 'gmf-event.com'){
      stage = 'Production';
    }
    this.$gtm.trackEvent({
      pageCategory: 'PhotoExhibition',
      PageName: 'Overview',
      PageType: 'Webapp',
      Stage:stage,
    });
  }
}
</script>