<template>
  <div :class="{'toast': true, 'show': inline, 'border-primary': inline}" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
      <strong class="me-auto">{{ header ? header : '' }}&nbsp;</strong>
      <small v-if="inline" class="text-muted">{{ styledDate }}</small>
      <button v-if="!inline" type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" @click.prevent="hidenow"></button>
    </div>
    <div class="toast-body" :class="{'toast-body':true, 'linked-toast-body':link}" @click.stop="openLink">
      <slot></slot>
      <div class="mt-2 pt-2 border-top delete-button" v-if="inline">
        <button type="button" class="btn btn-primary" @click.prevent="hidenow">{{ $store.getters.i18n({de: 'löschen', en:'delete'}) }}</button>
      </div>
      <div class="mt-2 pt-2 border-top confirm d-flex" v-if="t.announce_confirm && t.announce_confirm !== ''">
        <div>
          <div>
            <strong>{{ $store.getters.i18n({
                de: 'Sie haben 5 Minuten Zeit',
                en: 'You have 5 Minutes'
            }) }}</strong>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="1" id="confirmedCheck" v-model="confirmedCheck" />
            <label class="form-check-label" for="confirmedCheck">
              {{ styledDate }}
            </label>
          </div>
        </div>
        <button type="button" class="ms-auto btn btn-primary" :disabled="!confirmedCheck" @click.prevent="hidenow">{{ $store.getters.i18n({de: 'Abschicken', en:'Confirm'}) }}</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import { InteractionAlert } from "@/store/i/types";
import {DateTime} from "luxon";

// @ts-ignore
import {Toast} from "mdb-ui-kit"

@Component
export default class ToastAlert extends Vue {
  @Prop() public t!: InteractionAlert;
  @Prop() public inline!: boolean;

  protected confirmedCheck = false

  protected toastinst: null|Toast = null

  get header(): string|null {
    if (this.t && this.t.hasOwnProperty('header') && typeof this.t.header !== "undefined") {
      return this.$store.getters.i18n(this.t.header)
    }
    return null
  }

  get date(): Date {
    if (this.t && this.t.hasOwnProperty('got') && typeof this.t.got !== "undefined") {
      return this.t.got
    } else {
      return new Date()
    }
  }

  get styledDate(): string {
    const ldate = DateTime.fromJSDate(this.date)
    return `${ldate.setLocale(this.$store.getters.i18n({de: 'de-de', en:'en'})).toLocaleString(DateTime.TIME_24_SIMPLE)}${this.$store.getters.i18n({de: ' Uhr', en:''})}`
  }

  get link(): string|null {
    if (this.t && this.t.hasOwnProperty('link') && typeof this.t.link !== "undefined") {
      return this.$store.getters.i18n(this.t.link)
    }
    return null
  }

  protected openLink() {
    if (this.link) {
      this.$emit('dismissed', this.t)
      window.location.href = this.link
    }
  }

  protected hidenow() {
    if (this.toastinst) {
      this.toastinst.hide()
    } else {
      this.$emit('dismissed', this.t)
    }
  }

  protected washidden = false
  protected gottime = new Date()

  @Watch("$store.state.tick")
  protected seeIfToHide() {
    if (!this.inline && this.t && this.t.showfor && this.toastinst && !this.washidden && ((this.$store.state.tick.getTime() - this.gottime.getTime()) > this.t.showfor)) {
      this.washidden = true
      this.toastinst.hide()
    }
  }

  protected inittoast() {
    if (this.toastinst) {
      this.toastinst.dispose()
      this.toastinst = null
    }
    const vm = this
    if (!this.inline) {
      this.toastinst = new Toast(this.$el, {
        animation:true,
        autohide: false
      })

      this.washidden = !(typeof this.t.showfor === "number" && this.t.showfor > 0)
      this.gottime = new Date()

      this.toastinst.show()
      this.$el.addEventListener("hidden.bs.toast", () => {
        if (vm.confirmedCheck && vm.t.announce_confirm && vm.t.announce_confirm !== "") {
          vm.$store.dispatch("i/announce", {nsp: vm.t.announce_confirm, ev:"confirmed"})
        }
        vm.$emit('dismissed', vm.t)
      }, {passive:true})
    }
  }

  protected mounted() {
    this.inittoast()
  }

  protected beforeDestroy() {
    if (!this.inline && this.toastinst) {
      this.toastinst.dispose()
    }
  }
}
</script>

<style scoped lang="scss">
.linked-toast-body {
  cursor:pointer;
}
</style>