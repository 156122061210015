<template>
  <div class="">
    <div class="mainform g-3 pb-4">
      <h3>My Networking</h3>
      <OnBoarding1 type="step"/>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import OnBoarding1 from "@/components/i/OnBoarding1.vue"

@Component({
  components: {OnBoarding1},
})
export default class Step1 extends Vue {
  protected created() {

    let stage = 'Test';
    if(window.location.host == 'gmf-event.com'){
      stage = 'Production';
    }
    this.$gtm.trackEvent({
      pageCategory: 'Networking',
      PageType: 'Webapp',
      PageName: 'My Networking',
      Stage:stage,
    });
  }
}
</script>