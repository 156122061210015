import Dexie from "dexie";
import {FirebaseProjectConfig} from "@/store/types";

export class HostConfDatabase extends Dexie {
    public hostconf: Dexie.Table<FirebaseProjectConfig, string>
    
    public constructor() {
        super("HostConfDatabase")
        this.version(1).stores({
            hostconf: "fbuid"
        })
        this.hostconf = this.table("hostconf")
    }

    public async getConfig(): Promise<false|FirebaseProjectConfig> {
        const countconfs = await this.hostconf.toCollection().count()
            if (countconfs > 0) {
            return (this.hostconf.toCollection().last() as Promise<FirebaseProjectConfig>)
        }
        return false
    }

    public async setConfig(config: FirebaseProjectConfig) {
        return this.hostconf.put(config, config.fbuid)
    }

    public async clearConfig() {
        return this.hostconf.toCollection().delete()
    }
}

const hostconfoffline = new HostConfDatabase()

export default hostconfoffline