<template>
  <svg class="banner-smartphone" version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       :viewBox="viewBox"
       xml:space="preserve">
<g id="Grafik">
	<g>
		<defs>
			<rect id="SVGID_1_" y="0" width="375" height="345"/>
		</defs>
    <clipPath id="SVGID_00000133524037860800719520000015410495955829056947_">
			<use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
		</clipPath>
    <g style="clip-path:url(#SVGID_00000133524037860800719520000015410495955829056947_);">
			<path class="st1" d="M-244,505.4c-33.5,69-58.3,141.9-73.6,217l-187.7-38.1c18.6-90.9,48.5-179,89.1-262.5l0,0
				c70.9-146.3,173.2-275.2,299.5-377.6L3.8,193.6l212.3,262.9c-65.8,53.3-119,120.4-156,196.5L-244,505.4z"/>
      <path class="st2" d="M266.5,45.5l-65.2-180.1C86-93.1-21.5-32.7-116.8,44.3l-75.9-94C-87-135.1,32.2-202.2,160-248.3
				c26.1-9.4,52.6-18.1,79.6-25.8c251.3-72.5,519.2-62.2,764.2,29.1l-42.1,113.4L895.1,47.9C809.3,16,719.3-2.9,627.9-8l-18.7,337.8
				c-64.2-3.6-128.5,3.7-190.3,21.4c-12.7,3.6-25.1,7.8-37.3,12.2L266.5,45.5"/>
      <path class="st3" d="M266.5,45.5l115.2,318c-60,21.6-115.9,53-165.6,93L3.8,193.6L-116.8,44.3C-21.5-32.7,86-93.1,201.2-134.6
				L266.5,45.5"/>
		</g>
	</g>
</g>
    <g id="Schrift">
      <text transform="matrix(1 0 0 1 26.07 68.9)" class="st4 st5 st6 st7">Dear {{ $store.state.config.me.firstname }} {{ $store.state.config.me.lastname }},</text>
      <text transform="matrix(1 0 0 1 26.07 102.89)" class="st4 st5 st6">Thank you for your participation</text>
      <text transform="matrix(1 0 0 1 26.07 136.88)" class="st4 st5 st6">in this year's Global Media Forum.</text>
      <router-link :to="{name: 'Welcome'}" v-if="ImageEntry">
        <iframe src="https://player.vimeo.com/video/953122031?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="GMF24_Trailer_UT"></iframe>
      </router-link>
</g>
</svg>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {AgendaPoint} from "@/store/i/types";
import _ from "lodash";

@Component({
  components: {},
})
export default class BannerMobile extends Vue {
  get ImageEntry(): AgendaPoint|undefined {
    return _.find(
        this.$store.state.i.image,
        (ap: AgendaPoint) => ap.dbstate > -1 && ap.now_live
    )
  }

  get viewBox(): string {
    if(this.ImageEntry){
      return "0 0 375 390"
    }else{
      return "0 0 375 345"
    }
  }
}
</script>

<style scoped type="text/css">
.st0{clip-path:url(#SVGID_00000116224952535925583590000017636198880280715427_);}
.st1{fill:#E84E1B;}
.st2{fill:#D60B51;}
.st3{fill:#A21D45;}
.st4{fill:#FFFFFF;}
.st5{font-family:'DWTheAntiquaB-W6',sans-serif;}
.st6{font-size:24px;}
.st7{letter-spacing:-1;}
</style>