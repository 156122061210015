<template>
  <div v-if="voting" id="stream-voting-area"
       :class="{
          'text-center': true,
          'inline-voting-area': !onlyvotingresult,
          'w-100': onlyvotingresult,
          'd-flex': onlyvotingresult,
          'flex-column': onlyvotingresult,
          'justify-content-center': onlyvotingresult,
          'align-items-center': onlyvotingresult,
          'ivoted': ivoted,
          'mb-4': true,
          'mb-lg-0': true
    }">
    <div class="row mb-4" v-if="!hidetitle && !ivoted">
      <div class="col text-center">
        <h5 @click="toogle" v-if="!onlyvotingresult" class="mt-2">
            <span class="ms-2 pb-2">
              <FontAwesomeIcon icon="arrow-down" v-show="!show" />
              <FontAwesomeIcon icon="arrow-up" v-show="show" />
            </span>
          {{ voting.question }}
        </h5>
        <div v-else class="big-question">{{ voting.question }}</div>
      </div>
    </div>
    <div v-show="show" >
    <div class="row" v-if="hasDeadline && !ivoted" v-show="!hidetitle">
      <div v-if="!deadlineReached" class="col">
        {{ $store.getters.i18n({de:'Das Voting endet ' + toDeadline,en:'Voting ends ' + toDeadline}) }}.
      </div>
      <div v-else class="col">
        {{ $store.getters.i18n({de:'Das Voting ist beendet.',en:'The voting has ended.'}) }}
      </div>
    </div>

      <VotingForm :voting="voting" v-if="!onlyvotingresult && votemode === 'vote' && !deadlineReached" />
      <div class="row" v-if="ivoted">
        <div class="col">
          <h3>{{ $store.getters.i18n({de:'Danke für Ihre Abstimmung.',en:'Thank you for voting.'}) }}</h3>
        </div>
      </div>
    </div>

  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import VotingForm from "@/components/i/VotingForm.vue";
import FontAwesomeIcon from "@/components/FontAwesomeIcon.vue";
import _ from "lodash";
import { DateTime } from "luxon"
import {MultiUserRoom, RoomInteraction, VotingBallot, VotingInteraction} from "@/store/i/types";

@Component({
  components: { VotingForm, FontAwesomeIcon }
})
export default class VotingArea extends Vue {


  protected show = true

  protected toogle() {
    this.show = !this.show;
  }

  protected onlyvotingresult = false
  protected hidetitle: boolean = false

  protected votemode: "vote"|"results" = "vote"

  get room(): MultiUserRoom | null | undefined {
    if (this.$store.state.i.rooms.length > 0) {
      return _.find(
          this.$store.state.i.rooms,
          r => r.roomname === this.$store.state.i.mucname
      )
    }
    return null
  }

  get ivoted(): boolean {
    return (
        !this.onlyvotingresult &&
        this.votemode === "results"
    )
  }

  get voting(): VotingInteraction|false {
    if (
        this.room && this.room.interaction &&
        this.room.interaction.type === "voting" && this.room.interaction.voting !== ""
    ) {
      const cvote = _.find(
          this.$store.state.i.votings,
          v => v.uid === <string>(<RoomInteraction>(<MultiUserRoom>this.room).interaction).voting
      )

      if (cvote) {
        return cvote
      }
    }

    this.votemode = "vote"

    return false
  }

  get voteuid(): string {
    if (this.voting) {
      return this.voting.uid
    }
    return ''
  }

  get myanswers(): VotingBallot[] {
    if (this.voting) {
      return this.$store.getters["i/myBallotsForVoting"](this.voting.roomname, this.voting.uid)
    }

    return []
  }

  get vdata(): number[] {
    if (!this.voting) {
      return []
    }
    return _.map(
        this.voting.answers,
        a => this.$store.getters["i/ballotsForVotingAnswer"](
            (<VotingInteraction>this.voting).roomname,
            (<VotingInteraction>this.voting).uid,
            a.uid
        ).length
    )
  }

  get maxVoteCount(): number {
    return <number>_.max([0,...this.vdata])
  }

  get voteSum(): number {
    return <number>_.sum([0,...this.vdata])
  }

  protected percentageForAnswer(auid: string): string {
    if (this.voteSum < 1 || !this.voting) {
      return ""
    }
    const votecountA = this.$store.getters["i/ballotsForVotingAnswer"](
        (<VotingInteraction>this.voting).roomname,
        (<VotingInteraction>this.voting).uid,
        auid
    ).length

    return Math.round(votecountA * 100 / this.voteSum) + "%"
  }

  get hasDeadline(): boolean {
    return !!(
        this.room && this.room.interaction && this.room.interaction.type === "voting" &&
        this.room.interaction.votingduedate && this.room.interaction.votingduedate !== ""
    )
  }

  get deadlineReached(): boolean {
    if (
        this.hasDeadline && this.room && this.room.interaction && this.room.interaction.type === "voting" &&
        this.room.interaction.votingduedate && this.room.interaction.votingduedate !== ""
    ) {
      return DateTime.fromISO(this.room.interaction.votingduedate) < DateTime.fromJSDate(this.$store.state.tick)
    }
    return false
  }

  get toDeadline(): string {
    if (
        this.hasDeadline && this.room && this.room.interaction && this.room.interaction.type === "voting" &&
        this.room.interaction.votingduedate && this.room.interaction.votingduedate !== ""
    ) {
      const vdd = DateTime.fromISO(this.room.interaction.votingduedate)
      const vsecs = Math.round(vdd.diff(DateTime.fromJSDate(this.$store.state.tick), "seconds").seconds)

      if (vsecs <= 30) {
        return this.$store.getters.i18n({de:`in ${vsecs} Sekunden`,en:`in ${vsecs} seconds`})
      }

      return <string>(vdd.setLocale(this.$store.getters.i18n({de:'de',en:'en'})).toRelative({ base: DateTime.fromJSDate(this.$store.state.tick) }))
    }
    return ''
  }

  @Watch("voteuid")
  protected resettovote() {
    this.votemode = "vote"
  }

  @Watch("myanswers")
  protected resettovotebycast(nv: VotingBallot[]) {
    this.votemode = nv.length > 0 ? "results" : "vote"
  }

  protected togglegreenkey(e:Event) {
    switch ((<KeyboardEvent>e).key) {
      case "g":
        if (!document.body.style.background || document.body.style.background === "") {
          document.body.style.background = '#0F0'
        } else {
          document.body.style.background = ''
        }
        break
      case "t":
        this.hidetitle = !this.hidetitle
        this.redrawChart()
      default:
        break
    }
  }

  protected originalParent: null|HTMLElement = null

  @Watch("$store.state.i.videoFullscreen")
  protected async fullscreenhandle(nv: boolean) {
    const playertoattach = document.querySelector(".video-js")

    if (!playertoattach) {
      return
    }

    if (nv) {
      this.originalParent = this.$el.parentElement
      this.originalParent?.removeChild(this.$el)
      playertoattach.append(this.$el)
    } else {
      playertoattach.removeChild(this.$el)
      this.originalParent?.append(this.$el)
    }
  }

  protected labelscut: boolean = false

  protected changedlabelscut(nv:boolean) {
    this.labelscut = nv
  }

  protected redrawChart() {
    // @ts-ignore
    this.$refs.vres.drawchart()
  }

  protected mounted() {
    if (this.onlyvotingresult) {
      document.body.addEventListener("keyup", this.togglegreenkey, {passive:true})
      //window.addEventListener("resize", this.redrawChart, {passive:true})
    }

    if (this.$store.state.i.videoFullscreen) {
      this.fullscreenhandle(true)
    }
  }

  protected beforeDestroy() {
    if (this.onlyvotingresult) {
      document.body.removeEventListener("keyup", this.togglegreenkey)
      //window.removeEventListener("resize", this.redrawChart)
    }
  }

  protected created() {
    this.votemode = this.myanswers.length > 0 ? "results" : "vote"
  }
}
</script>
