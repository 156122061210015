<template>
  <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" :class="`svg-icon-inline icon-${icon}`" :viewBox="viewBox">
    <path v-if="icon === 'phone'" fill="currentColor" d="M -75.496683,251.7445 -18.92814,342.25416 C -12.776311,352.08295 -0.40194274,356.11346 10.34608,351.87082 L 123.48317,306.61598 C 133.38266,302.65619 139.53449,292.54456 138.40312,281.93796 L 130.62494,204.01479 C 210.31588,175.23554 299.90631,174.59915 381.22359,204.01479 L 373.44541,281.93796 C 372.38475,292.61527 378.46587,302.65619 388.36537,306.61598 L 501.50245,351.87082 C 512.39189,356.11346 524.76626,352.08295 530.91809,342.25416 L 587.48664,251.7445 C 593.35562,242.33998 592.08283,230.03632 584.09252,222.04601 403.00248,40.955965 109.27032,40.673123 -72.10257,222.04601 -80.022166,229.96561 -81.43638,242.26927 -75.496683,251.7445 Z" class="">
    </path>
    <path v-if="icon === 'microphone'" fill="currentColor" d="M176 352c53.02 0 96-42.98 96-96V96c0-53.02-42.98-96-96-96S80 42.98 80 96v160c0 53.02 42.98 96 96 96zm160-160h-16c-8.84 0-16 7.16-16 16v48c0 74.8-64.49 134.82-140.79 127.38C96.71 376.89 48 317.11 48 250.3V208c0-8.84-7.16-16-16-16H16c-8.84 0-16 7.16-16 16v40.16c0 89.64 63.97 169.55 152 181.69V464H96c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16h160c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16h-56v-33.77C285.71 418.47 352 344.9 352 256v-48c0-8.84-7.16-16-16-16z" class="">
    </path>
    <path v-if="icon === 'microphonemuted'" fill="currentColor" d="M633.82 458.1l-157.8-121.96C488.61 312.13 496 285.01 496 256v-48c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 16v48c0 17.92-3.96 34.8-10.72 50.2l-26.55-20.52c3.1-9.4 5.28-19.22 5.28-29.67V96c0-53.02-42.98-96-96-96s-96 42.98-96 96v45.36L45.47 3.37C38.49-2.05 28.43-.8 23.01 6.18L3.37 31.45C-2.05 38.42-.8 48.47 6.18 53.9l588.36 454.73c6.98 5.43 17.03 4.17 22.46-2.81l19.64-25.27c5.41-6.97 4.16-17.02-2.82-22.45zM400 464h-56v-33.77c11.66-1.6 22.85-4.54 33.67-8.31l-50.11-38.73c-6.71.4-13.41.87-20.35.2-55.85-5.45-98.74-48.63-111.18-101.85L144 241.31v6.85c0 89.64 63.97 169.55 152 181.69V464h-56c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16h160c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16z" class="">
    </path>
    <path v-if="icon === 'video'" fill="currentColor" d="M336.2 64H47.8C21.4 64 0 85.4 0 111.8v288.4C0 426.6 21.4 448 47.8 448h288.4c26.4 0 47.8-21.4 47.8-47.8V111.8c0-26.4-21.4-47.8-47.8-47.8zm189.4 37.7L416 177.3v157.4l109.6 75.5c21.2 14.6 50.4-.3 50.4-25.8V127.5c0-25.4-29.1-40.4-50.4-25.8z" class="">
    </path>
    <path v-if="icon === 'videooff'" fill="currentColor" d="M633.8 458.1l-55-42.5c15.4-1.4 29.2-13.7 29.2-31.1v-257c0-25.5-29.1-40.4-50.4-25.8L448 177.3v137.2l-32-24.7v-178c0-26.4-21.4-47.8-47.8-47.8H123.9L45.5 3.4C38.5-2 28.5-.8 23 6.2L3.4 31.4c-5.4 7-4.2 17 2.8 22.4L42.7 82 416 370.6l178.5 138c7 5.4 17 4.2 22.5-2.8l19.6-25.3c5.5-6.9 4.2-17-2.8-22.4zM32 400.2c0 26.4 21.4 47.8 47.8 47.8h288.4c11.2 0 21.4-4 29.6-10.5L32 154.7v245.5z" class="">
    </path>
    <path v-if="icon === 'user'" fill="currentColor" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z" class="">
    </path>
    <path v-if="icon === 'maximize'" fill="currentColor" d="M416 224c17.69 0 32-14.31 32-32V64c0-4.164-.8477-8.312-2.463-12.22c-3.242-7.84-9.479-14.08-17.32-17.32C424.3 32.85 420.2 32 416 32h-128c-17.69 0-32 14.31-32 32s14.31 32 32 32h50.75L224 210.8L109.3 96H160c17.69 0 32-14.31 32-32S177.7 32 160 32H32C27.84 32 23.69 32.85 19.78 34.46C11.94 37.7 5.705 43.94 2.463 51.78C.8477 55.69 0 59.84 0 64v128c0 17.69 14.31 32 32 32s32-14.31 32-32V141.3L178.8 256L64 370.8V320c0-17.69-14.31-32-32-32s-32 14.31-32 32v128c0 4.164 .8477 8.312 2.461 12.22c3.242 7.84 9.48 14.08 17.32 17.32C23.69 479.2 27.84 480 32 480h128c17.69 0 32-14.31 32-32s-14.31-32-32-32H109.3L224 301.3L338.8 416H288c-17.69 0-32 14.31-32 32s14.31 32 32 32h128c4.164 0 8.312-.8477 12.22-2.461c7.84-3.242 14.08-9.48 17.32-17.32C447.2 456.3 448 452.2 448 448v-128c0-17.69-14.31-32-32-32s-32 14.31-32 32v50.75L269.3 256L384 141.3V192C384 209.7 398.3 224 416 224z" class="">
    </path>
    <path v-if="icon === 'minimize'" fill="currentColor" d="M204.2 290.5C200.3 288.8 196.2 288 192 288H64c-17.69 0-32 14.31-32 32s14.31 32 32 32h50.75l-105.4 105.4c-12.5 12.5-12.5 32.75 0 45.25s32.75 12.5 45.25 0L160 397.3V448c0 17.69 14.31 32 32 32s32-14.31 32-32v-128c0-4.164-.8477-8.312-2.465-12.22C218.3 299.9 212.1 293.7 204.2 290.5zM307.8 221.5C311.7 223.2 315.8 224 320 224h128c17.69 0 32-14.31 32-32s-14.31-32-32-32h-50.75l105.4-105.4c12.5-12.5 12.5-32.75 0-45.25c-12.49-12.49-32.74-12.51-45.25 0L352 114.8V64c0-17.69-14.31-32-32-32s-32 14.31-32 32v128c0 4.164 .8477 8.312 2.463 12.22C293.7 212.1 299.9 218.3 307.8 221.5zM192 32C174.3 32 160 46.31 160 64v50.75L54.63 9.375c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25L114.8 160H64C46.31 160 32 174.3 32 192s14.31 32 32 32h128c4.164 0 8.312-.8477 12.22-2.465C212.1 218.3 218.3 212.1 221.5 204.2C223.2 200.3 224 196.2 224 192V64C224 46.31 209.7 32 192 32zM397.3 352H448c17.69 0 32-14.31 32-32s-14.31-32-32-32h-128c-4.164 0-8.312 .8477-12.22 2.461c-7.84 3.242-14.08 9.48-17.32 17.32C288.8 311.7 288 315.8 288 320v128c0 17.69 14.31 32 32 32s32-14.31 32-32v-50.75l105.4 105.4c12.5 12.5 32.75 12.5 45.25 0c12.49-12.49 12.51-32.74 0-45.25L397.3 352z" class="">
    </path>
  </svg>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';

@Component({})
export default class JitsiFontAwesomeIcon extends Vue {
  @Prop() protected icon!: string;

  get viewBox(): string|null {
    switch (this.icon) {
      case "phone":
      case "minimize":
        return "0 0 512 512"
      case "microphone":
        return "0 0 352 512"
      case "microphonemuted":
      case "videooff":
        return "0 0 640 512"
      case "video":
        return "0 0 576 512"
      case "user":
      case "maximize":
        return "0 0 448 512"
      default: return null
    }
  }
}
</script>