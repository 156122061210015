<template>
    <div id="toastswrapper" v-show="ias.length > 0" :class="{ 'opened':ias.length > 0 }">
        <ToastAlert v-for="(t, index) in ias" :t="t" :key="'tw' + index" @dismissed="dismissed">
            {{ $store.getters.i18n(t.msg) }}
        </ToastAlert>
    </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ToastAlert from "@/components/i/ToastAlert.vue";
import {InteractionAlert} from "@/store/i/types";

@Component({
    components: { ToastAlert }
})
export default class ToastDisplay extends Vue {
  protected dismissed(ia: InteractionAlert) {
      this.$store.commit('i/removeToast', ia)
  }

  protected originalParent: null|HTMLElement = null

  protected ias: InteractionAlert[] = []

  get unhiddenToasts(): InteractionAlert[] {
    return this.$store.getters['i/unhiddenToasts']
  }

  @Watch("unhiddenToasts")
  protected regreshias(nv: InteractionAlert[], ov?: InteractionAlert[]) {
    if (this.unhiddenToasts.length === 0) {
      this.ias = []
    } else {
      this.unhiddenToasts.forEach((uht: InteractionAlert) => {
        if (!this.ias.some((ia: InteractionAlert) => ia === uht)) {
          this.ias.push(uht)
        }
      })
    }
  }

  @Watch("$store.state.i.videoFullscreen")
  protected async fullscreenhandle(nv: boolean) {
    const playertoattach = document.querySelector(".video-js")

    if (!playertoattach) {
      return
    }

    if (nv) {
      this.originalParent = this.$el.parentElement
      this.originalParent?.removeChild(this.$el)
      playertoattach.append(this.$el)
    } else {
      playertoattach.removeChild(this.$el)
      this.originalParent?.append(this.$el)
    }
  }
}
</script>