<template>
  <div class="box">
    <span class="headline cb-headlinenormalxl g-3"  v-if="!PartnerEntry">Not found</span>
    <div class="row g-3" v-if="PartnerEntry">

      <div class="col-md-5 col-lg-3 mb-3 sm-center">
        <div class="">
          <img :src="`/files/exhibitor/${PartnerEntry.objvalueid}/logo.jpg?nocache=${(new Date()).getHours()}`" width="250" onerror="this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';" alt="">
        </div>
      </div>

      <div class="col-md-7 col-lg-9 mb-3 text-left ipadfx">
        <h3 class="pb-3 pt-0"> {{PartnerEntry.title}}</h3>
        <div v-if="PartnerEntry.email">E-Mail: <a :href="`mailto:${PartnerEntry.email}`" >{{PartnerEntry.email}}</a></div>
        <div v-if="PartnerEntry.website">Website: <a :href="`${PartnerEntry.website}`" >{{PartnerEntry.website}}</a></div>


        <div class="row">
          <div class="col-12" v-if="PartnerEntry.linkedin || PartnerEntry.twitter || PartnerEntry.facebook">
            <span class="Role copydetailxs"><br>
                <span class="headline_light text-center">FIND US ON</span>
              <br>
              <a  v-if="PartnerEntry.twitter" :href=PartnerEntry.twitter class="buttonshare" target="_blank"><i class="fab fa-2x fa-x-twitter"></i>
                    <span class="sr-only">X (Twitter)</span></a>
              <a  v-if="PartnerEntry.linkedin" :href=PartnerEntry.linkedin class="buttonshare" target="_blank"><i class="fab fa-2x fa-linkedin-in"></i>
                  <span class="sr-only">LinkedIn</span></a>
              <a  v-if="PartnerEntry.facebook" :href=PartnerEntry.facebook class="buttonshare" target="_blank"><i class="fab fa-2x fa-facebook-f"></i>
                  <span class="sr-only">Facebook</span></a>
            </span>
            <br>
          </div>
        </div>

      </div>

      <hr>

      <div v-if="PartnerEntry.description">
        <h4 class="bold mb-3">About us</h4>
        <div class="cv" v-html="PartnerEntry.description"></div>
        <hr>
      </div>


      <div v-if="Program.length > 0">
        <h4 class="bold mt-4 mb-4">Program</h4>
        <AgendaEntry v-for="(ae, aeindex) in Program" :key="`agp${aeindex}`" :ae="ae" agendatype="entry"/>
        <hr>
      </div>

    </div>
  </div>
</template>


<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {AgendaPoint, Partner} from "@/store/i/types";
import AgendaEntry from "@/components/AgendaEntry.vue";
import _ from "lodash";
import {DateTime} from "luxon";
import {AppNetworkErrorOther, networkErrorText} from "@/crud";
import {TranslatedText} from "@/store/types";

@Component({
  components: {AgendaEntry},
})
export default class SpeakerDetail extends Vue {

  protected created() {

    let stage = 'Test';
    if(window.location.host == 'gmf-event.com'){
      stage = 'Production';
    }
    this.$gtm.trackEvent({
      pageCategory: 'Partner',
      PageName: 'Detailpage',
      PageType: 'Webapp',
      Stage:stage,
    });
  }

  get PartnerEntry(): Partner|undefined {
    return _.find(
        this.$store.state.i.partners,
        (e: Partner) => `${e.objvalueid}` === `${this.$route.params.partnerid}`
    )
  }

  get Program(): AgendaPoint[] {
      return _.filter(
          this.$store.getters['i/orderedAgendaPoints'],
          (ap: AgendaPoint) => ap.dbstate > -1 && _.has(this.agenda, ap.objvalueid)
      );
  }


  get agenda(): any {
    if(this.PartnerEntry){
      return this.PartnerEntry.agenda
    }else{
      return 0
    }
  }

}

</script>

<style scoped>

</style>