<template>
  <footer>
    <div class="m-1 row g-3">
      <div class="col-6 col-md-6 col-lg-3">
        <ul class="col list-unstyled mt-0">
          <li><h2 class="group-header">About</h2></li>
          <li>
            <a href="https://www.dw.com/en/gmf/s-43101535" class="group-link link-light text-decoration-none" target="_blank">
              Who we are
            </a>
          </li>
          <li>
            <a href="https://www.dw.com/en/contact/s-4155" class="group-link link-light text-decoration-none" target="_blank">
              Press
            </a>
          </li>
        </ul>
      </div>
      <div class="col-6 col-md-6 col-lg-3">
        <ul class="col list-unstyled mt-0">
          <li><h2 class="group-header">Service</h2></li>
          <li>
            <a href="https://p.dw.com/p/3iRF7/" class="group-link link-light text-decoration-none" target="_blank">
              Newsletter
            </a>
          </li>
          <li>
            <a href="/files/footer/faqs.pdf" class="group-link link-light text-decoration-none" target="_blank">
              FAQ (open PDF)
            </a>
          </li>
          <li>
            <a href="/contact/" class="group-link link-light text-decoration-none" target="_blank">
              Contact
            </a>
          </li>
          <li>
            <a href="#" class="group-link link-light text-decoration-none" target="_blank" @click.prevent="showAccessibilityModal">
              Accessabilty
            </a>
          </li>
        </ul>
      </div>
      <div class="col-6 col-md-6 col-lg-3">
        <ul class="col list-unstyled mt-0">
          <li class="group-header">Location</li>
          <li>
            <a href="https://goo.gl/maps/1i5Y5GZ8yDzx7ZRP8" class="group-link link-light text-decoration-none" target="_blank">
              World Conference Center Bonn (WCCB) <br>
              Platz der Vereinten Nationen 2 <br>
              53113 Bonn <br>
              Germany (open in Google Maps)
            </a>
          </li>
          <li>
            <a href="/files/footer/arrival.pdf" class="group-link link-light text-decoration-none"
               target="_blank">
              Arrival Description (open PDF)
            </a>
          </li>
        </ul>
      </div>


    </div><br>


    <div class="m-1 row g-3 pt-0">
      <div class="col mt-0">
        <h2 class="group-header">Follow us on</h2>
        <a href="https://twitter.com/DW_GMF" class="social-link social-link-twitter link-light text-decoration-none" target="_blank">
          <i class="fab fa-2x fa-x-twitter"></i>
          <span class="sr-only">X (Twitter)</span>
        </a>
        <a href="https://www.linkedin.com/showcase/5088224/" class="social-link social-link-linkedin link-light text-decoration-none" target="_blank">
          <i class="fab fa-2x fa-linkedin-in"></i>
          <span class="sr-only">LinkedIn</span>
        </a>
        <a href="https://www.facebook.com/dw.gmf" class="social-link social-link-facebook link-light text-decoration-none" target="_blank">
          <i class="fab fa-2x fa-facebook-f"></i>
          <span class="sr-only">Facebook</span>
        </a>
        <a href="https://www.youtube.com/c/DWGlobalMediaForum/featured" class="social-link social-link-youtube link-light text-decoration-none" target="_blank">
          <i class="fab fa-2x fa-youtube"></i>
          <span class="sr-only">Youtube</span>
        </a>
        <a href="https://p.dw.com/p/3iRF7" class="social-link social-link-contact link-light text-decoration-none" target="_blank">
          <i class="far fa-2x fa-envelope-open"></i>
        </a>
      </div>
    </div>

    <div class="m-1 row row-mb g-3">
      <div class="mt-0 standard-link col-12 copyright_padding">
        <span>© 2024 Deutsche Welle</span>
        <a href="/files/privacy_policy.pdf" class="group-link link-light text-decoration-none" target="_blank">
          Privacy Policy (open PDF)
        </a>
        <a href="https://www.dw.com/en/legal-notice/a-15718492" class="group-link link-light text-decoration-none" target="_blank">
          Legal Notice
        </a>

          <a href="/files/footer/ticketshop_gtc.pdf" class="group-link link-light text-decoration-none" target="_blank">
            Ticketshop GTC (open PDF)
          </a>
      </div>
    </div>
  </footer>
</template>


<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import AccessibilityModal from "@/components/AccessiblityModal.vue";

@Component({
  components: {AccessibilityModal},
})
export default class Footer extends Vue {
  showAccessibilityModal() {
    this.$root.$emit('openAccessibilityModal');
  }
}

</script>