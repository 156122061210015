<template>
  <VerticalNav>
    <div class="row scroll-header">
      <div class="col mt-4">
        <p style="font-size: 28px">
          {{ $store.getters.i18n({de: 'Mitteilungen', en: 'Notifications'}) }}
        </p>
      </div>
    </div>

    <div class="scroll-element row d-block" ref="scrollelement">
      <div class="row mb-2 align-self-start" v-for="(n,ni) in sortedNotifications" :key="`notification${ni}`">
        <div class="col">
          <ToastAlert :inline="true" :t="n" @dismissed="removeToast">
            {{ $store.getters.i18n(n.msg) }}
          </ToastAlert>
        </div>
      </div>
    </div>

  </VerticalNav>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import ToastAlert from "@/components/i/ToastAlert.vue";
import {InteractionAlert} from "@/store/i/types";
import OfflineDB from "@/store/i/offlinedb";
import {DateTime} from "luxon";
import _ from "lodash";

@Component({
  components: {ToastAlert},
})
export default class NotificationVerticalNav extends Vue {
  protected archiveNotifications: InteractionAlert[] = []

  @Watch("$store.state.sidenav")
  @Watch("$store.state.i.toasts")
  protected async readnotifications() {
    if (this.$store.state.sidenav === "notifications") {
      this.archiveNotifications = await OfflineDB.toasts.toCollection().toArray() as InteractionAlert[]
      _.remove(
          this.archiveNotifications,
          (ta: InteractionAlert) => (
              !!(ta.removed) ||
              !!(ta.queued) ||
              (ta.validfrom && ta.validfrom > this.$store.state.tick)
          )
      )
      if (this.archiveNotifications.length > 0) {
        const olderthanaday = _.filter(
          this.archiveNotifications,
          (n: InteractionAlert) => DateTime.fromJSDate(n.got ? n.got : new Date()).diffNow().as('hours') <= -24
        )
        if (olderthanaday.length > 0) {
          olderthanaday.forEach(
            (t: InteractionAlert) => { this.removeToast(t) }
          )
        }
      }
    } else {
      this.archiveNotifications = []
    }
  }

  protected async removeToast(t: InteractionAlert) {
    await OfflineDB.deleteToast(t)
    _.remove(
      this.archiveNotifications,
      (to: InteractionAlert) => to === t
    )

    this.archiveNotifications = [...this.archiveNotifications]
  }

  get sortedNotifications(): InteractionAlert[] {
    return _.orderBy(
      this.archiveNotifications,
      [(n: InteractionAlert) => n.got?.getTime()],
        ["desc"]
    )
  }

  protected created() {
    this.readnotifications()
  }

  protected correctScroll: boolean = true

  @Watch('archiveNotifications')
  protected async scrollBottom() {
    // Runterscrollen
    const scrollelement = this.$refs.scrollelement as HTMLElement

    if (this.correctScroll) {
      scrollelement.removeEventListener('scroll', this.lookIfToHaltAutoscroll)
      await this.$nextTick()
      scrollelement.scrollTop = scrollelement.scrollHeight - scrollelement.clientHeight
      await this.$nextTick()
      scrollelement.addEventListener('scroll', this.lookIfToHaltAutoscroll, {passive:true})
    }
  }

  protected lookIfToHaltAutoscroll() {
    const pos = this.$el.scrollTop
    const scrollelement = this.$refs.scrollelement as HTMLElement
    const desiredPos = scrollelement.scrollHeight - scrollelement.clientHeight
    this.correctScroll = pos >= desiredPos - 20
  }

  protected mounted() {
    this.scrollBottom()
    const scrollelement = this.$refs.scrollelement as HTMLElement
    window.addEventListener('resize', this.scrollBottom, {passive:true})
    scrollelement.addEventListener('scroll', this.lookIfToHaltAutoscroll, {passive:true})
  }

  protected beforeDestroy() {
    const scrollelement = this.$refs.scrollelement as HTMLElement
    window.removeEventListener('resize', this.scrollBottom)
    scrollelement.removeEventListener('scroll', this.lookIfToHaltAutoscroll)
  }
}
</script>
