<template>

  <div class="box">
    <div class="row g-3 mb-5">

      <h3>Networking</h3>

      <MeetingModal :connection="connection" :toggle_modal="toggle_modal" />
      <JitsiCall type="member" v-if="$store.state.members.currentCall" />

      <div class="row">
        <div class="col-lg-12">
          <p>
            <b>Get together for a virtual coffee with other conference attendees who are online right now! This is an open group chat.</b>
          </p>
          
          <p>
            Take part in our reaction game and collect points to increase your online activity score!<br>
            The highest online activity scores win a ticket to #GMF25.
          </p>
          
          <p class="pb-2">
            <router-link :to="{name: 'Coffeebar'}" class="btn btn-danger col-lg-3">
              <i class="fa-light fa-mug-hot"></i> Coffee bar
            </router-link>
          </p>

        </div>
      </div>
          
      <div class="row" v-if="!$store.state.i.me.accept_networking">
        <div class="col-lg-12">
        <p><b>
            Complete your networking profile to connect with media professionals from around the globe in just a couple of minutes. Good to have you with us this #GMF24!
        </b>          
          </p>
          <p>
            <router-link :to="{name: 'Profile'}" class="btn btn-primary col-lg-3 fw-normal"> 
              To my profile
            </router-link>
          </p>
        </div>
      </div>

      <p v-if="$store.state.i.me.accept_networking">
        <b>Connect with more than 1,200 media professionals from around the globe!</b>
      </p> 
      <p v-if="$store.state.i.me.accept_networking" class="mt-0">
        How? Simply create your own profile and use the matchmaking function to add new contacts based on the areas of interest you specify in your profile. You will automatically be shown contacts that match your own interests. You can also connect directly with other participants using the chat function. So chat away, schedule video calls or arrange to meet up in Bonn in person on June 17 or 18. We hope you have some memorable conversations!
      </p>

      <div class="row rowtabs"  v-if="$store.state.i.me.accept_networking">
          <div class="col-md-6 col-lg-2 mb-1"><router-link :to="{name: 'Networking1'}" :class="{'btn highlight_tabs': true, 'btn-active' : this.$route.meta.tab == 1}">My matches</router-link></div>
          <div class="col-md-6 col-lg-3 mb-1"><router-link :to="{name: 'Networking2'}" :class="{'btn highlight_tabs': true, 'btn-active' : this.$route.meta.tab == 2}">Participant search</router-link></div>
          <div class="col-md-6 col-lg-2 mb-1"><router-link :to="{name: 'Networking3'}" :class="{'btn highlight_tabs': true, 'btn-active' : this.$route.meta.tab == 3}">My contacts</router-link></div>
          <div class="col-md-6 col-lg-2 mb-1"><router-link :to="{name: 'Networking4'}" :class="{'btn highlight_tabs': true, 'btn-active' : this.$route.meta.tab == 4}">Contact requests</router-link></div>
      </div>


      <div v-show="successtring.de !== ''" class="alert alert-success col-lg-12">
        {{ $store.getters.i18n(successtring) }}
      </div>


      <div v-show="errorstring.de !== ''" class="alert alert-danger col-lg-12">
        {{ $store.getters.i18n(errorstring) }}
      </div>


      <div v-if="$store.state.i.me.accept_networking">

        <div v-show="this.$route.meta.tab === 1">

          <span class="col-lg-12 headline cb-headlinenormalxl mb-3">My matches</span>

          <NetworkingUserContact v-for="(u, index) in MatchesFilter" :key="'NetworkingUserContact1_' + index" :user="u" contact_type="matches" @navigate="navigate_from_contacts" @create="createConnection"/>

          <p v-if="Matches.length > number_members1" class="Text pointer" @click="showmore1">Load more...</p>

        </div>

        <div v-show="this.$route.meta.tab === 2">

          <span class="col-lg-12 headline cb-headlinenormalxl mb-3">Participant search</span>

          <p><input placeholder="Search..." type="search" v-model.trim="searchtext2" class="form-control"></p>

          <NetworkingUserContact v-for="(u, index) in MembersFilter" :key="'NetworkingUserContact2_' + index" :user="u" contact_type="members" @navigate="navigate_from_contacts" @create="createConnection"/>

          <p v-if="Members.length > number_members2" class="Text pointer" @click="showmore2">Load more...</p>

        </div>


        <div v-show="this.$route.meta.tab === 3">

          <span class="col-lg-12 headline cb-headlinenormalxl mb-3">My contacts</span>

          <p><input placeholder="Search..." type="search" v-model.trim="searchtext3" class="form-control"></p>

          <NetworkingUserConnection v-for="(u, index) in Contacts" :key="'NetworkingUserConnection1_' + index" :user="u" contact_type="contact" @navigate="navigate_from_connections" @remove="removeConnection" @chat="pickuserchat" @call="pickusercall" @meeting="meeting"/>

        </div>


        <div v-show="this.$route.meta.tab === 4">

          <span class="col-lg-12 headline cb-headlinenormalxl mb-3" v-if="Received.length > 0">Requests received</span>
          <NetworkingUserConnection v-for="(u, index) in Received" :key="'NetworkingUserConnection2_' + index" :user="u" contact_type="received" @navigate="navigate_from_connections" @accept="acceptConnection" @decline="declineConnection" @block="blockConnection"/>

          <span class="col-lg-12 headline cb-headlinenormalxl mb-3" v-if="Send.length > 0">Requests sent</span>
          <NetworkingUserConnection v-for="(u, index) in Send" :key="'NetworkingUserConnection3_' + index" :user="u" contact_type="send" @navigate="navigate_from_connections"/>

        </div>

      </div>

    </div>
  </div>

</template>

<script lang="ts">
import {Component, Vue, Watch} from 'vue-property-decorator';
import JitsiCall from "@/components/jitsi/JitsiCall.vue";
import NetworkingUserContact from "@/components/i/NetworkingUserContact.vue";
import NetworkingUserConnection from "@/components/i/NetworkingUserConnection.vue";
import MeetingModal from "@/components/i/MeetingModal.vue";
import _ from "lodash";
import {JitsiCallRequest, JitsiVideoSettings} from "@/store/member/types";
import {TranslatedText} from "@/store/types";
import {NavigationGuardNext, Route} from "vue-router";
import {AppNetworkErrorOther, networkErrorText} from "@/crud";
import {ConnectionNetworking, ContactsNetworking, Meetings} from "@/store/i/types";

@Component({
  components: {JitsiCall, NetworkingUserConnection, NetworkingUserContact ,MeetingModal},
  beforeRouteLeave(to, from, next) {
    if ((this as any).navguard) {
      (this as any).navguard(to, from, next)
    } else {
      next()
    }
  }
})
export default class Networking extends Vue {


  protected errorstring: TranslatedText = {'de': '', 'en': ''}
  protected successtring: TranslatedText = {'de': '', 'en': ''}

  protected navigate_from_contacts(cp: ContactsNetworking) {
    this.$router.push({name: 'ContactDetail', params: { contactid: `${cp.objvalueid}` }})
  }

  protected navigate_from_connections(cp: ConnectionNetworking) {
    if (cp.receiver == this.$store.state.config.me.objvalueid) {
      this.$router.push({name: 'ContactDetail', params: { contactid: `${cp.sender}` }})
    }else{
      this.$router.push({name: 'ContactDetail', params: { contactid: `${cp.receiver}` }})
    }
  }

  /* Start Connections Networking  */

  protected async createConnection(c: ContactsNetworking) {
    try {
      this.errorstring = {de: '', en:''}
      this.successtring = {de: '', en:''}
      const dpr = await this.$store.dispatch("i/createConnection", c)
      setTimeout(() => { this.reset_message()}, 2000);
      if (dpr) {
        this.successtring = {
          de: "Contact request sent",
          en: "Contact request sent"
        }
      } else {
        this.errorstring = {
          de: "Contact request could not be created",
          en: "Contact request could not be created"
        }
      }
    } catch (e) {
      this.errorstring = networkErrorText(e as AppNetworkErrorOther)
    }
  }


  protected async removeConnection(c: ConnectionNetworking) {
    try {
      this.errorstring = {de: '', en:''}
      this.successtring = {de: '', en:''}
      const dpr = await this.$store.dispatch("i/removeConnection", c)
      setTimeout(() => { this.reset_message()}, 2000);
      if (dpr) {
        this.successtring = {
          de: "Contact removed",
          en: "Contact removed"
        }
      } else {
        this.errorstring = {
          de: "Could not remove contact.",
          en: "Could not remove contact."
        }
      }
    } catch (e) {
      this.errorstring = networkErrorText(e as AppNetworkErrorOther)
    }
  }

  protected async acceptConnection(c: ConnectionNetworking) {
    try {
      this.errorstring = {de: '', en:''}
      this.successtring = {de: '', en:''}
      const dpr = await this.$store.dispatch("i/acceptConnection", c)
      setTimeout(() => { this.reset_message()}, 2000);
      if (dpr) {
        this.successtring = {
          de: "Contact request accepted",
          en: "Contact request accepted"
        }
      } else {
        this.errorstring = {
          de: "Could not accept contact.",
          en: "Could not accept contact."
        }
      }
    } catch (e) {
      this.errorstring = networkErrorText(e as AppNetworkErrorOther)
    }
  }

  protected async declineConnection(c: ConnectionNetworking) {
    try {
      this.errorstring = {de: '', en:''}
      this.successtring = {de: '', en:''}
      const dpr = await this.$store.dispatch("i/declineConnection", c)
      setTimeout(() => { this.reset_message()}, 2000);
      if (dpr) {
        this.successtring = {
          de: "Contact request declined",
          en: "Contact request declined"
        }
      } else {
        this.errorstring = {
          de: "Could not decline contact.",
          en: "Could not decline contact."
        }
      }
    } catch (e) {
      this.errorstring = networkErrorText(e as AppNetworkErrorOther)
    }
  }

  protected async blockConnection(c: ConnectionNetworking) {
    try {
      this.errorstring = {de: '', en:''}
      this.successtring = {de: '', en:''}
      const dpr = await this.$store.dispatch("i/blockConnection", c)
      setTimeout(() => { this.reset_message()}, 2000);
      if (dpr) {
        this.successtring = {
          de: "Contact request blocked",
          en: "Contact request blocked"
        }
      } else {
        this.errorstring = {
          de: "Could not block contact.",
          en: "Could not block contact."
        }
      }
    } catch (e) {
      this.errorstring = networkErrorText(e as AppNetworkErrorOther)
    }
  }

  protected reset_message(){
    this.errorstring = {de: '', en:''}
    this.successtring = {de: '', en:''}
  }


  /* Start List Members Networking*/

  get ContactsNetworking(): ContactsNetworking[] {

    const arr: number[] = []

    var sC = _.filter(
        this.$store.getters['i/sortedConnectionsNetworking'],
        (c: ConnectionNetworking) => c.dbstate != -1
    )

    sC.forEach(
        (ap: ConnectionNetworking) => {
          arr.push(ap.receiver)
          arr.push(ap.sender)
        }
    )

    var filter = _.filter(
        this.$store.getters['i/sortedContactsNetworking'],
        (c: ContactsNetworking) => c.accept_networking
    )

    var excludes = _(filter)
        .keyBy('objvalueid')
        .at(arr)
        .value();

    filter =  _.differenceWith(filter, excludes, _.isEqual);

    if(!this.$store.state.config.me.is_test){
      filter = _.filter(
          filter,
          (c: ContactsNetworking) => !c.is_test
      )
    }

    return filter;

  }

  protected number_members1:number = 20

  protected showmore1(){
    this.number_members1 = this.number_members1 + 20;
  }

  get Matches(): ContactsNetworking[] {

    var filter = this.ContactsNetworking;

    filter = _.orderBy(
        filter, ["match"], ["desc"]
    )

    return filter;

  }


  get MatchesFilter(): ContactsNetworking[] {
    var filter = this.Matches;
    filter = _.take(filter, this.number_members1)
    return filter;
  }


  protected number_members2:number = 20
  protected searchtext2:string = ''

  protected showmore2(){
    this.number_members2 = this.number_members2 + 20;
  }

  get Members(): ContactsNetworking[] {

    var filter = this.ContactsNetworking;

    if (filter.length > 0 && this.searchtext2.length > 0) {
      const swords = _.map(
          _.words(this.searchtext2),
          s => new RegExp(_.escapeRegExp(s),'iu')
      )
      return _.filter(
          filter,
          c => {
            for (let i = swords.length -1; i >= 0; i--) {
              if (!swords[i].test(c.firstname + ' ' + c.lastname)) {
                return false
              }
            }

            return true
          }
      )
    }

    return filter;
  }

  get MembersFilter(): ContactsNetworking[] {
    var filter = this.Members;
    filter = _.take(filter, this.number_members2)
    return filter;
  }


  protected searchtext3:string = ''

  get Contacts(): ConnectionNetworking[] {

    var filter = _.filter(
        this.$store.getters['i/sortedConnectionsNetworking'],
        (c: ConnectionNetworking) => c.dbstate != -1 && c.status == 2
    )

    if (filter.length > 0 && this.searchtext3.length > 0) {
      const swords = _.map(
          _.words(this.searchtext3),
          s => new RegExp(_.escapeRegExp(s),'iu')
      )
      return _.filter(
          filter,
          c => {
            for (let i = swords.length -1; i >= 0; i--) {
              if (!swords[i].test(c.firstname + ' ' + c.lastname)) {
                return false
              }
            }

            return true
          }
      )
    }

    return filter;
  }

  get Received(): ConnectionNetworking[] {
    return _.filter(
        this.$store.getters['i/sortedConnectionsNetworking'],
        (c: ConnectionNetworking) => c.dbstate != -1 && c.status == 1 && c.receiver == this.$store.state.config.me.objvalueid
    );
  }

  get Send(): ConnectionNetworking[] {
    return _.filter(
        this.$store.getters['i/sortedConnectionsNetworking'],
        (c: ConnectionNetworking) => c.dbstate != -1 && c.status == 1 && c.sender == this.$store.state.config.me.objvalueid
    );
  }



  protected created() {

    this.handleListeners()

    let stage = 'Test';
    if(window.location.host == 'gmf-event.com'){
      stage = 'Production';
    }

    this.$gtm.trackEvent({
      pageCategory: 'Networking',
      PageName: this.$route.name,
      PageType: 'Webapp',
      Stage:stage,
    });
  }

  @Watch("$store.state.i.connected")
  @Watch("$store.state.i.me.accept_networking")
  protected async handleListeners() {
    if (this.$store.state.i.me.accept_networking) {
      this.$store.dispatch("i/changeListenersContactsNetworking", true)
    }else{
      this.$store.dispatch("i/changeListenersContactsNetworking", false)
    }
  }

  protected beforeDestroy() {
    this.$store.dispatch("i/changeListenersContactsNetworking", false)
  }

  get currentCall(): JitsiCallRequest|undefined {
    return this.$store.state.members.currentCall
  }

  protected navguard(to: Route, from: Route, next:NavigationGuardNext<Vue>) {
    if (this.$store.state.members.currentCall && !this.$store.getters['i/forcedToPlenum'] && !this.$store.getters['i/redirectToPlenum']) {
      const confirmres = window.confirm(this.$store.getters.i18n({
        de: "If you are leaving the booth now, your call will be ended.",
        en: "If you are leaving the booth now, your call will be ended."
      }))
      if (confirmres) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  }

  protected pickuserchat(cp: ConnectionNetworking) {
    const uc = _.find(
        this.$store.state.i.contacts_networking,
        (c) => c.fbuid === cp.fbuid
    )
    if (uc) {
      this.$store.commit('OpenSideNav', 'chat')
      this.$store.commit('i/setChatboxContact', uc)
    }
  }

  protected async pickusercall(cp: ConnectionNetworking) {
    if (cp.fbuid !== this.$store.state.config.fbuid) {
      if (this.currentCall) {
        this.$store.commit("i/addToast", {
          header: {de: "Fehler", en: "Error"},
          msg: {
            de: 'Sie sind bereits im Gespräch.',
            en: 'You are already in a call.'
          },
          validforroom: "event-general",
          got: new Date(),
          hidden: false,
          showfor: 10000,
          alert_type: 'error'
        })
      } else {


        if (cp.receiver == this.$store.state.config.me.objvalueid) {
          let c =   {
            "fbuid":  cp.fbuid,
            "objvalueid":  cp.sender,
            "firstname":  cp.firstname,
            "lastname": cp.lastname,
            "orgname": cp.orgname
          };
          this.$store.commit("members/setContact", c)
          await this.$store.dispatch("members/startCall", cp.sender)
        }else{
          let c =   {
            "fbuid":  cp.fbuid,
            "objvalueid":  cp.receiver,
            "firstname":  cp.firstname,
            "lastname": cp.lastname,
            "orgname": cp.orgname
          };
          this.$store.commit("members/setContact", c)
          await this.$store.dispatch("members/startCall", cp.receiver)
        }


        this.$store.commit("i/addToast", {
          header: {de: "Hinweis", en: "Warning"},
          msg: {
            de: 'Bitte haben Sie einen Moment Geduld, bis Ihr Ansprechpartner Ihren Anruf entgegen nimmt. Wenn Sie das Gespräch beenden, ohne dass Ihr Ansprechpartner den Anruf entgegen genommen hat, wird dieser per Chat-Nachricht über Ihren Kontaktversuch informiert.',
            en: 'Bitte haben Sie einen Moment Geduld, bis Ihr Ansprechpartner Ihren Anruf entgegen nimmt. Wenn Sie das Gespräch beenden, ohne dass Ihr Ansprechpartner den Anruf entgegen genommen hat, wird dieser per Chat-Nachricht über Ihren Kontaktversuch informiert.',
          },
          validforroom: "event-general",
          got: new Date(),
          hidden: false,
          showfor: 10000,
          alert_type: 'error'
        })
      }
    }
  }

  protected connection: number = 0
  protected toggle_modal: boolean = false

  protected meeting(cp: any) {
    this.toggle_modal = !this.toggle_modal;
    if (cp.receiver == this.$store.state.config.me.objvalueid) {
      this.connection = cp.sender;
    }else{
      this.connection = cp.receiver;
    }
  }



}
</script>