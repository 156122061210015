<template>

  <div class="box">
    <div class="row g-3 mb-5">
      <h3>Video calls</h3>
      <JitsiCall type="employee" v-if="$store.state.members.currentCall" />
      <div class="row">



        <div class="col-lg-12">

          <p>
            Manage your networking requests for one-on-one video calls. Click "Join" to enter a call.
          </p>
          <p>
            <b>Note:</b> Please close all applications that currently have access to your camera and/or microphone before joining the call.
          </p>
          <p>
            The Support chat will be available from 8 a.m. to 6 p.m. on both June 17 and 18. If you have questions beforehand, a response may take up to 48 hours.
          </p>

          


          <div class="row mb-4 mt-4" v-for="(cp, cindex) in incomingCalls" :key="`contact_${cindex}`" >

            <div class="col-1">
              <div class="circular_image circular_image_small">
                <img :src="`/files/members/${cp.objvalueid}/foto.jpg`" onerror="this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';"  alt=""/>
              </div>
            </div>

            <div class="col-4">
              <div class="Name-Nachname mb-1">  {{ cp.firstname }}    {{ cp.lastname }}</div>
            </div>

            <div class="col-7">
              <button class="btn btn-primary m-1" v-if="!cp.connection_end" @click.stop="joincall(cp)">Join Call</button>
              <button class="btn btn-primary m-1" @click.stop="pickContactChat(cp)">Chat</button>
            </div>
          </div>

        </div>

          <!--
          <h2 class="">Anfragen 1:1 Chat</h2>
          <p>Verwalten Sie in der folgenden Übersicht Ihre Anfragen für den Chat. Mit Klick auf den Namen des Teilnehmers gehen Sie in den Chat mit diesem.</p>
          <ul class="list-unstyled friend-list my-custom-scrollbar">
            <li class="p-8 pt-3" v-if="$store.getters['i/sortedContacts'].length !== 0"><input placeholder="Namen suchen" type="search" v-model.trim="searchtext" class="form-control"></li>
            <ChatContactUser v-for="(u, index) in sortedContacts" :key="'chatcontactuser' + index" :user="u" :selected="false" :isSmall="true" @pick="pickuser"/>
          </ul>
          -->



      </div>
    </div>
  </div>



</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import JitsiCall from "@/components/jitsi/JitsiCall.vue";
import ChatContactUser from "@/components/i/ChatContactUser.vue";
import _ from "lodash";
import {JitsiCallRequest, JitsiVideoSettings} from "@/store/member/types";
import {TranslatedText} from "@/store/types";
import {NavigationGuardNext, Route} from "vue-router";
import {AppNetworkErrorOther, networkErrorText} from "@/crud";
import {ChatContact} from "@/store/i/types";
import {objToFirebaseUID} from "@/firebaseConnections";

@Component({
  components: {JitsiCall, ChatContactUser},
  beforeRouteLeave(to, from, next) {
    if ((this as any).navguard) {
      (this as any).navguard(to, from, next)
    } else {
      next()
    }
  }
})
export default class VideoCallModal extends Vue {


  get currentCall(): JitsiCallRequest|undefined {
    return this.$store.state.members.currentCall
  }

  protected async joincall(cp: JitsiCallRequest) {
    if (this.currentCall) {
      this.$store.commit("i/addToast", {
        header: {de: "Fehler", en: "Error"},
        msg: {
          de: 'Sie sind bereits im Gespräch.',
          en: 'You are already in a call.'
        },
        validforroom: "event-general",
        got: new Date(),
        hidden: false,
        alert_type: 'error'
      })
    } else {
      await this.$store.dispatch("members/startCallReceiver", cp.objvalueid)
    }

  }
  protected async pickContactChat(cp: JitsiCallRequest) {

    this.$store.dispatch("i/openContactChat", {
      fbuid: objToFirebaseUID(cp.callerid),
      objvalueid: cp.callerid,
      firstname: cp.firstname,
      lastname: cp.lastname
    })
  }

  get incomingCalls(): JitsiCallRequest[] {
    return this.$store.getters["members/incomingCalls"](false)
  }

  get stringForCalls(): string {
    const callsnotended = this.$store.getters["members/incomingCalls"](false)

    if (callsnotended.length < 1) {
      return ''
    } else if (callsnotended.length === 1) {
      return this.$store.getters.i18n({
        de: 'Sie haben einen Anruf'
      })
    } else {
      return this.$store.getters.i18n({
        de: `Sie haben ${callsnotended.length} Anrufe`
      })
    }
  }

  get stringForChats(): string {
    if (this.$store.getters['i/unreadcontactschats'] < 1) {
      return ''
    } else if (this.$store.getters['i/unreadcontactschats'] === 1) {
      return this.$store.getters.i18n({
        de: 'Sie haben eine ungelesene Chat-Nachricht'
      })
    } else {
      return this.$store.getters.i18n({
        de: `Sie haben ${this.$store.getters['i/unreadcontactschats']} ungelesene Chat-Nachrichten`
      })
    }
  }

  protected navguard(to: Route, from: Route, next:NavigationGuardNext<Vue>) {
    if (this.$store.state.members.currentCall && !this.$store.getters['i/forcedToPlenum'] && !this.$store.getters['i/redirectToPlenum']) {
      const confirmres = window.confirm(this.$store.getters.i18n({
        de: "If you are leaving the booth now, your call will be ended.",
        en: "If you are leaving the booth now, your call will be ended."
      }))
      if (confirmres) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  }


  protected errorstring: TranslatedText = {'de': '', 'en': ''}

  protected pickuser(fbuid: string) {
    const uc = _.find(
        this.$store.state.i.contactschats,
        (c) => c.fbuid === fbuid
    )
    if (uc) {
      this.$store.commit('OpenSideNav', 'chat')
      this.$store.commit('i/setChatboxContact', uc)
    }
  }

  protected searchtext:string = ''

  get sortedContacts(): ChatContact[] {
    const sC = this.$store.getters['i/sortedContacts']
    if (sC.length > 0 && this.searchtext.length > 0) {
      const swords = _.map(
          _.words(this.searchtext),
          s => new RegExp(_.escapeRegExp(s),'iu')
      )
      return _.filter(
          sC,
          c => {
            for (let i = swords.length -1; i >= 0; i--) {
              if (!swords[i].test(c.firstname + ' ' + c.lastname)) {
                return false
              }
            }

            return true
          }
      )
    }
    return sC
  }

  protected created() {

    let stage = 'Test';
    if(window.location.host == 'gmf-event.com'){
      stage = 'Production';
    }
    this.$gtm.trackEvent({
      pageCategory: 'Networking',
      PageName: 'Videocalls',
      PageType: 'Webapp',
      Stage:stage,
    });
  }

}
</script>


<style scoped lang="scss">
li {
  position:relative;
}


.chat-dm-name {
  flex-grow: 1;
  padding-left:16px;
}
</style>