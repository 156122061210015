import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

let connectpromise: Promise<firebase.auth.UserCredential> = new Promise(() => {});

export const getFireDB = async () => {
    await connectpromise
    return firebase.database()
}

export const initFirebase = (config: Object) => firebase.initializeApp(config)

export const connectFirebase = async (token: string): Promise<firebase.auth.UserCredential> => {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
    connectpromise = firebase.auth().signInWithCustomToken(token)
    return connectpromise
}

export const logoutFirebase = () => firebase.auth().signOut()

export const objToFirebaseUID = (objvalueid: number, gsuffix = "members") => `dw-gmf-${gsuffix}-${objvalueid}`