<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">

       <router-link :to="{name: 'Welcome'}" :class="{'navbar-brand logo': true}">
        <img src="/projects/dw/gmf/assets/images/gmf-logo-xs-2021-rgb.svg" alt="dw-logo" class="GMF-Logo-XS-2021-RGB" border="0">
       </router-link>


      <div class="collapse navbar-collapse" id="navbarToggler"  v-if="hasNavigation" >
        <ul class="navbar-nav me-auto" v-if="isLoggedIn">

          <li class="nav-item">
            <router-link :to="{name: 'Welcome'}" :class="{'nav-link home': true}">
              <i class="fal fa-2x fa-home"></i>
              <span class="sr-only">Home</span>
            </router-link>
          </li>

          <li class="nav-item">
            <router-link :to="{name: 'AgendaOverview'}" :class="{'nav-link': true}">Program</router-link>
          </li>

          <li class="nav-item">
            <router-link :to="{name: 'SpeakerOverview'}" :class="{'nav-link': true}">Speakers</router-link>
          </li>

          <li class="nav-item">
            <router-link :to="{name: 'Mediathek'}" :class="{'nav-link': true}">On demand</router-link>
          </li>

          <li class="nav-item">
            <router-link :to="{name: 'Networking1'}" :class="{'nav-link': true}">Networking</router-link>
          </li>

          <li class="nav-item">
            <router-link :to="{name: 'PartnerOverview'}" :class="{'nav-link': true}">Partners</router-link>
          </li>

<!--          <li class="nav-item">-->
<!--            <router-link :to="{name: 'PhotoExhibition'}" :class="{'nav-link': true}">Photo exhibition</router-link>-->
<!--          </li>
          -->


        </ul>
        <ul class="navbar-nav me-auto" v-else>
          <li class="nav-item">
            <router-link :to="{name: 'Welcome'}" :class="{'nav-link home': true}">
              <i class="fal fa-2x fa-home"></i>
              <span class="sr-only">Home</span>
            </router-link>
          </li>
          <li class="nav-item">
            <a class="nav-item nav-link" href="/speakers/">Speakers <span class="sr-only">(current)</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-item nav-link" href="/program/">Program</a>
          </li>
          <li class="nav-item" v-if='$store.getters["landingpage/shopsAvailable"]'>
            <a class="nav-item nav-link" href="/tickets/">Tickets</a>
          </li>

          <li class="nav-item" v-if='$store.getters["landingpage/shopsAvailable"]'>
            <a class="nav-item nav-link" href="/press-accreditation/">Press accreditation</a>
          </li>
        </ul>
      </div>


      <ul class="navbar-nav navbar-icons ms-auto"  v-if="hasNavigation && isLoggedIn" >
        <li class="nav-item me-3" v-if="!$store.state.i.connected">
          <a ref="connectionstatus" :title="$store.getters.i18n({de: 'Sie sind offline', en: 'Offline'})" href="#" class="nav-link text-danger">
            <i class="fas fa-2x fa-wifi-slash"></i>
            <span class="sr-only">Offline</span>
          </a>
        </li>

        <li class="nav-item me-3">
          <router-link :to="{name: 'Networking4'}" :class="{'nav-link': true}" title="Contact requests">
            <i class="fal fa-2x fa-user-friends"></i>
            <span class="sr-only">Contact requests</span>
            <div class="badge rounded-pill badge-notification bg-dark" v-show="Received.length > 0">
              {{ Received.length }}
            </div>
          </router-link>
        </li>


        <li class="nav-item me-3">
          <router-link :to="{name: 'Status'}" class="nav-link call-icon" title="Video calls">
            <i class="fal fa-2x fa-video"></i>
            <span class="sr-only">Video calls</span>
            <div class="badge rounded-pill badge-notification bg-dark" v-show="callAmount > 0">
              {{ callAmount }}
            </div>
          </router-link>
        </li>

        <li class="nav-item me-3">
          <a @click.prevent="$store.commit('setSideNav', 'chat')" href="#" class="nav-link" title="Chat">
            <i class="fal fa-2x fa-comment"></i>
            <span class="sr-only">Chat</span>
            <div class="badge rounded-pill badge-notification bg-dark" v-show="$store.getters['i/unreadchats'] > 0">
              {{ $store.getters['i/unreadchats'] }}
            </div>
          </a>
        </li>

        <li class="nav-item me-3 mt-2">
          <button class="btn btn-outline-primary dropdown-toggle nsmartphone" @click.prevent="ChangeNavi">
            {{ $store.state.config.me.firstname }} {{ $store.state.config.me.lastname }}
          </button>
          <a class="smartphone nav-link pointer"  @click.prevent="ChangeNavi"> <i class="fal fa-user-circle fa-2x"></i></a>
          <div class="dropdown-menu dropdown-menu-profile show" v-show=isDropdownActive @click.prevent="ChangeNavi">
            <router-link :to="{name: 'Profile1'}" class="dropdown-item">My Profile</router-link>
            <router-link :to="{name: 'Calendar'}" class="dropdown-item">My Calendar</router-link>
            <router-link :to="{name: 'Help'}" class="dropdown-item">Support</router-link>
            <router-link :to="{name: 'Logout'}" class="dropdown-item">Logout</router-link>
          </div>
        </li>

      </ul>

      <div v-if='hasNavigation && !isLoggedIn && $store.getters["landingpage/loginAvailable"]'>
        <a class="btn btn-primary next float-end" href="/event/login/">
          Login
        </a>
      </div>

      <button v-if="hasNavigation" class="navbar-toggler" type="button" data-mdb-toggle="collapse" data-mdb-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false">
        <i class="fal fa-2x fa-bars"></i>
        <span class="sr-only">Toggle navigation</span>
      </button>


    </div>
  </nav>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import _ from "lodash";
// @ts-ignore
import {Popover} from "mdb-ui-kit"
import {AgendaPoint, ConnectionNetworking} from "@/store/i/types";
import Mediathek from "@/views/Mediathek.vue";

@Component({
  components: {Mediathek},
})
export default class HorizontalNav extends Vue {


  protected isDropdownActive:boolean = false

  protected ChangeNavi(){
    this.isDropdownActive = !this.isDropdownActive;
  }


  get routeName(): string {
    return this.$route.name as string
  }

  get hasNavigation(): boolean {
    return !(["Login", "Step1", "Step2", "Step3", "Step4", "404", "FramesetError"].some(n => n === this.$route.name))
  }

  get isLoggedIn(): boolean {
    return this.$store.state.config.me.objvalueid !== -1;
  }

  get Received(): ConnectionNetworking[] {
    return _.filter(
        this.$store.getters['i/sortedConnectionsNetworking'],
        (c: ConnectionNetworking) => c.dbstate != -1 && c.status == 1 && c.receiver == this.$store.state.config.me.objvalueid
    );
  }

  get callAmount(): number {
    return this.$store.getters['members/incomingCalls'](false).length
  }

  get Live1(): any {
    return _.find(
        this.$store.state.i.agenda,
        (ap: AgendaPoint) => ap.dbstate > -1 && ap.now_live && !ap.is_break
    )
  }


}
</script>