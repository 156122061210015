import {MutationTree} from "vuex";
import {
    MemberContactPerson,
    MembersState,
    JitsiCallRequest,
    JitsiVideoSettings
} from "@/store/member/types";
import OfflineDB from "@/store/member/offlinedb";
import _ from "lodash";

const mutations: MutationTree<MembersState> = {
    empty(state) {
        state.contacts = []
        state.callHistory = []
    },
    setContact(state, pl: MemberContactPerson) {
        OfflineDB.contacts.put({...pl}, pl.objvalueid)
        state.contacts = _.uniqBy(
            [pl, ...state.contacts],
            (e: MemberContactPerson) => `${e.objvalueid}`
        )
    },
    addCallToHistory(state, pl: JitsiCallRequest) {
        const callcopy = {...pl}
        if (callcopy.jwt) {
            delete callcopy.jwt
        }

        OfflineDB.callHistory.put({
            ...callcopy,
            connection_end: !_.isEmpty(callcopy.connection_end) ? callcopy.connection_end : (new Date()).toISOString()
        }, pl.objvalueid)
        state.callHistory = _.uniqBy(
            [callcopy, ...state.callHistory],
            (cr: JitsiCallRequest) => `${cr.objvalueid}`
        )
    },
    setActiveCall(state, pl: JitsiCallRequest|undefined) {
        state.currentCall = pl
    },
    setMediaDevices(state, pl:(MediaDeviceInfo)[]) {
        state.mediaDevices = pl
    },
    setPickedDevices(state, pl: JitsiVideoSettings) {
        OfflineDB.setCallSettings({...pl})
        state.pickedDevices = pl
    },
    setRTCSupport(state, pl: boolean) {
        state.rtcSupport = pl
    }

}

export default mutations