import {Commit} from "vuex";
import Dexie from "dexie";
import {
    MemberContactPerson,
    JitsiCallRequest,
    JitsiVideoSettings
} from "@/store/member/types";

export class MemberDatabase extends Dexie {
    public contacts: Dexie.Table<MemberContactPerson, number>
    public callHistory: Dexie.Table<JitsiCallRequest, number>
    public callsettings: Dexie.Table<JitsiVideoSettings, number>

    public constructor() {
        super("MemberDatabase")
        this.version(3).stores({
            contacts: "objvalueid",
            callHistory: "objvalueid,callerid,interlocutorid",
            callsettings: "id++"
        })
        this.contacts = this.table("contacts")
        this.callHistory = this.table("callHistory")
        this.callsettings = this.table("callsettings")
    }

    public empty() {
        const me = this
        return Promise.all([
            me.contacts.toCollection().delete(),
            me.callHistory.toCollection().delete(),
        ])
    }

    public async setCallSettings(newSettings: JitsiVideoSettings) {
        if (newSettings.id) {
            return this.callsettings.put(newSettings, newSettings.id)
        } else {
            await this.callsettings.toCollection().delete()
            return this.callsettings.put(newSettings)
        }
    }

    public async getCallSettings(): Promise<JitsiVideoSettings> {
        const nvs = await this.callsettings.toCollection().last()
        if (nvs) {
            return nvs
        } else {
            return {
                output: '',
                mic: '',
                cam: ''
            }
        }
    }

    public async populateStore(commit: Commit) {
        const me = this
        return Promise.all([
            (async () => {
                const ocp = await me.contacts.toCollection().toArray()
                if (ocp) {
                    ocp.forEach(c => { commit("setContact", c) })
                }
            })(),
            (async () => {
                const ocp = await me.callHistory.toCollection().toArray()
                if (ocp) {
                    ocp.forEach(c => { commit("addCallToHistory", c) })
                }
            })(),
            (async () => {
                const vsettings = await me.getCallSettings()
                commit("setPickedDevices", vsettings)
            })()
        ])
    }
}

const membersoffline = new MemberDatabase()

export default membersoffline