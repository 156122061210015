import {ActionTree} from "vuex";
import {ObjUpdatePayload, RootState, TranslatedText} from "@/store/types";
import {
    InteractionState,
    Message,
    DMMessage,
    MultiUserRoom,
    SupportMessage,
    VotingBallot,
    VotingInteraction,
    ChatContact,
    ConnectionNetworking,
    AgendaPoint,
    ImagePoint,
    InteractionAlert,
    MemberEntry, ContactsNetworking, Meetings, Partner
} from "@/store/i/types";
import crud from "@/crud";
import OfflineDB from "@/store/i/offlinedb"
import _ from "lodash"
import randomstop from "@/store/randomstop";
import firebase from "firebase/app";
import {getFireDB, logoutFirebase} from "@/firebaseConnections";
import rankingsMaxPoints from '@/ranking';

declare var __version__: number;

export const roomlist = [
    "muc-livestream",
    "muc-workshop",
    "muc-cafe",
]

interface RoomToJoinTraceID {
    [key: string]: string
}
export const roomjointraceids: RoomToJoinTraceID = {}

let mycafeJoinref!: firebase.database.ThenableReference
let mypresenceref!: firebase.database.ThenableReference

let listenaddedres!: any
export const fblistenersattached = new Promise(resolve => { listenaddedres = resolve })

const actions: ActionTree<InteractionState, RootState> = {
    async populate({commit}): Promise<void> {
        try {
            await OfflineDB.populateStore(commit)
        } catch (e) {

        }
    },

    async empty({commit}) {
        commit("clean")
        await OfflineDB.empty()
        commit("clean")
    },

    async logoutFirebase({state, dispatch}) {
        if (state.connected) {
            try {
                if (mypresenceref) {
                    await mypresenceref.remove()
                }
                await logoutFirebase()
            } catch (e) {
                console.debug(e)
            }
        }
    },

    async addFirebaseListeners({state, commit, dispatch, rootState}) {
        const firedb = await getFireDB()
        const myconnectionref = firedb.ref(`/presence/event-general/${rootState.config.fbuid}`)
        const connectedRef = firedb.ref(".info/connected")
        connectedRef.on("value", (snapshot) => {
            if (snapshot.val() === true) {
                mypresenceref = myconnectionref.push({ts: Date.now() / 1000})
                mypresenceref.onDisconnect().remove()
                commit('setConnectedState', true)
            } else {
                commit('setConnectedState', false)
            }
        })

        for (let ri = 0; ri < roomlist.length; ri++) {
            dispatch("roomListeners", roomlist[ri])
        }

        await dispatch("getMyFormData")
        await dispatch("chatListeners")
        await dispatch("notificationsListeners")
        await dispatch("changeListenersAgenda")
        await dispatch("changeListenersPartner")
        await dispatch("changeListenerImage")
        await dispatch("fetchSpeakers")

        if(state.me.accept_networking){
            await dispatch("changeListenersContactsNetworking", true)
            await dispatch("changeListenersConnectionsNetworking", true)
            await dispatch("changeListenersMeetings", true)
        }

        listenaddedres()

        //commit("setConnectedState", true)
    },
    async roomListeners({state, commit, dispatch}, pl: string) {
        // Diese allgemeinen Listener arbeiten die ganze Zeit.
        // Die Listener, die ich nur in dem Raum brauche, wenn ich da bin, stehen in addInRoomlisteners

        if (pl === state.mucname) {
            dispatch("addInRoomlisteners", pl)
        }

        const firedb = await getFireDB()

        const handleRoomUpdate = (snapshot: firebase.database.DataSnapshot) => {
            const roomconf = snapshot.val()
            roomconf.roomname = pl
            commit("roomUpdate", roomconf)
        }

        const currentRoomitem = _.find(state.rooms, (r: MultiUserRoom) => r.roomname === pl)
        const lastchange_snapshot = await firedb.ref(`/rooms/${pl}/ts`).get()
        if (!currentRoomitem || !lastchange_snapshot || currentRoomitem.ts < (lastchange_snapshot.val() as number)) {
            firedb.ref(`/rooms/${pl}`)
                .get()
                .then(handleRoomUpdate)
                .catch(e => { console.error(e) })
        }

        firedb.ref(`/rooms/${pl}`).on("value", handleRoomUpdate)

        const handleVotingUpdate = (snapshot: firebase.database.DataSnapshot) => {
            const voting_config = snapshot.val()
            voting_config.uid = snapshot.key as string
            voting_config.roomname = pl
            commit("setVoting", voting_config)
        }

        const votings = _.filter(state.votings, (v: VotingInteraction) => v.roomname === pl)
        const lastchange_voting = _.max([
            0,
            ..._.map(
                votings,
                (v: VotingInteraction) => v.ts
            )
        ]) as number

        firedb.ref(`/votings/${pl}`)
            .orderByChild('ts')
            .startAfter(lastchange_voting)
            .on("child_added", handleVotingUpdate)

        firedb.ref(`/votings/${pl}`)
            .on("child_changed", handleVotingUpdate)
    },
    async chatListeners({state, rootState, commit, dispatch}) {
        const firedb = await getFireDB()

        const firebaseProcessChat = async (snapshot: firebase.database.DataSnapshot) => {
            const firedb = await getFireDB()
            try {
                const chatuid = snapshot.key as string
                const data_chatindex = snapshot.val()
                const data_fullchat = (await firedb.ref(`/chatmsg/${chatuid}`).get()).val()

                const takeseenflag = data_chatindex.sender === rootState.config.fbuid ?
                    data_chatindex.receiver :
                    rootState.config.fbuid

                const seenflag = !!(
                    data_fullchat.flags && data_fullchat.flags[takeseenflag] && !!(data_fullchat.flags[takeseenflag].seen)
                )

                const ndm: DMMessage = {
                    uuid: chatuid,
                    sender: data_chatindex.sender,
                    receiver: data_chatindex.receiver,
                    ts: data_fullchat.ts,
                    body: data_fullchat.msg,
                    seen: seenflag
                }

                const otheruserfbuid = ndm.sender !== rootState.config.fbuid ? ndm.sender : ndm.receiver
                const userfromdb = await OfflineDB.contactschats.where({fbuid: otheruserfbuid}).last()
                if (!userfromdb) {
                    try {
                        const userfetch: ChatContact|false = await dispatch("getUserinfoForFirebaseuid", otheruserfbuid)
                        if (userfetch !== false) {
                            userfetch.fbuid = otheruserfbuid
                            await dispatch("checkIfContactNeedsToBeAdded", userfetch)
                        }
                    } catch (e) {
                        console.error(`Unable to fetch ${otheruserfbuid}`, e)
                    }

                    /*
                    if (userfetch.status >= 200 && userfetch.status < 400 && !!(userfetch.data.result)) {
                        newuser = userfetch.data.result
                        newuser.fbuid = otheruserfbuid
                        await chatofflinedb.contact.put(newuser, otheruserfbuid)
                    }
                     */
                }

                commit("addDM", ndm)
            } catch (e) {
                console.error(e)
            }
        }
        const firebaseProcessSupportChat = async (snapshot: firebase.database.DataSnapshot) => {
            const data: any = snapshot.val()
            const stateNe: SupportMessage = {
                uuid: snapshot.key as string,
                ts: data.ts,
                body: data.msg,
                sender: data.sender === rootState.config.fbuid ? "me": "support",
                receiver: data.receiver === rootState.config.fbuid ? "me": "support",
                seen: data.seen
            }

            commit("addSupportMessage", stateNe)
        }

        firedb.ref(`/chats/${rootState.config.fbuid}`)
            .orderByChild('ts')
            .startAfter(_.max([0, ..._.map(state.dmmessages, dm => dm.ts)]) as number)
            .on("child_added", firebaseProcessChat)

        firedb.ref(`/supportchat/${rootState.config.fbuid}`)
            .orderByChild('ts')
            .startAfter(_.max([0, ..._.map(state.supportmessages, sm => sm.ts)]) as number, 'ts')
            .on("child_added", firebaseProcessSupportChat)
    },

    async notificationsListeners({state, rootState, dispatch}) {
        const firedb = await getFireDB()
        const nrooms = [...rootState.config.rooms]

        nrooms.forEach((rn: string) => {
            const timecuttoff = _.max([
                0,
                ..._.map(
                    _.filter(state.toasts, (ia: InteractionAlert) => ia.validforroom === rn),
                    (ia: InteractionAlert) => ia.ts ? ia.ts : 0
                )
            ]) as number

            firedb.ref(`/modannounce/msg/${rn}`)
                .orderByChild('ts')
                .startAt(timecuttoff)
                .on("child_added", (snapshot) => { dispatch("gotNotification", {roomname: rn, snapshot}) })
        })

    },

    async gotNotification({commit}, pl: any) {
        const roomname = pl.roomname as string
        const snapshot = pl.snapshot as firebase.database.DataSnapshot
        const nuid = snapshot.key as string

        const hasnotification = await OfflineDB.toasts.where({uid: nuid}).first()

        const data = snapshot.val()
        const nalert: InteractionAlert = {
            uid: nuid,
            header: {de: data.header ? data.header : ''},
            msg: {de: data.text ? data.text : ''},
            ts: data.ts,
            validforroom: roomname,
            validfrom: new Date(),
            removed: false,
            queued: true,
            got: new Date(),
            link: data.link ? {de: data.link} : undefined,
            hidden: false,
            showfor: data.showfor
        }

        if (nalert.validfrom && data.validstart) {
            nalert.validfrom.setTime(data.validstart * 1000)
            if (nalert.got && nalert.got.getTime() < nalert.validfrom.getTime()) {
                nalert.got.setTime(nalert.validfrom.getTime())
                nalert.queued = true
            }
        }

        if (typeof data.validend === "number") {
            if (data.validend > 0) {
                if (data.validend * 1000 < Date.now()) {
                    return
                } else {
                    nalert.validto = new Date()
                    nalert.validto.setTime(data.validend * 1000)
                }
            }
        }

        if (hasnotification) {
            nalert.id = hasnotification.id
            nalert.removed = hasnotification.removed
            nalert.hidden = hasnotification.hidden
            nalert.got = hasnotification.got
        } else {
            //const nt = await OfflineDB.toasts.put(nalert)
            //nalert.id = nt
        }

        commit("addToast", nalert)
    },

    async testQueuedNotifications({state, rootState, commit}) {
        const needed_rooms = [...rootState.config.rooms]
        if (state.mucname && state.mucname !== "") {
            needed_rooms.push(state.mucname)
        }

        const qn = _.filter(
            state.toasts,
            (t: InteractionAlert) => (
                t.queued &&
                (
                    t.validfrom && t.validfrom.getTime() < Date.now() &&
                    (!t.validto || t.validto.getTime() > Date.now())
                ) &&
                needed_rooms.some((r: string) => r === t.validforroom)
            )
        ) as InteractionAlert[]

        let nid!: number

        for (let ti = 0; ti < qn.length; ti++) {
            qn[ti].queued = false
            qn[ti].got = new Date()

            try {
                nid = await OfflineDB.toasts.put({...qn[ti]})
                qn[ti].id = nid
            } catch (e) {

            }

            commit("addToast", qn[ti])
        }
    },

    async addInRoomlisteners({state, commit, dispatch}, pl: string) {
        await fblistenersattached

        const firedb = await getFireDB()

        const handlePubChat = async (snapshot: firebase.database.DataSnapshot) => {
            const msguuid = snapshot.key as string
            const data = snapshot.val()
            const nmsg: Message = {
                uuid: msguuid,
                body: data.msg,
                roomname: pl,
                ts: data.ts,
                sender: data.sender
            }

            commit("addPublicMessage", nmsg)
        }

        const maxpubchatts = _.max([
            0,
            ..._.map(
                _.filter(state.publicchats, (pc: Message) => pc.roomname === pl),
                (pc: Message) => pc.ts
            )
        ]) as number

        firedb.ref(`/pubchats/${pl}`)
            .orderByChild('ts')
            .startAfter(maxpubchatts)
            .limitToLast(20)
            .on("child_added", handlePubChat)

        firedb.ref(`/pubchats/${pl}`).on("child_changed", handlePubChat)
        firedb.ref(`/pubchats/${pl}`).on("child_removed", (snapshot) => {
            commit("removeAPublicMessage", snapshot.key as string)
        })


        const timecuttoff_notify = _.max([
            0,
            ..._.map(
                _.filter(state.toasts, (ia: InteractionAlert) => ia.validforroom === pl),
                (ia: InteractionAlert) => ia.ts ? ia.ts : 0
            )
        ]) as number

        firedb.ref(`/modannounce/msg/${pl}`)
            .orderByChild('ts')
            .startAt(timecuttoff_notify)
            .on("child_added", (snapshot) => { dispatch("gotNotification", {roomname: pl, snapshot}) })
    },

    async removeInRoomlisteners({}, pl: string) {
        const firedb = await getFireDB()

        firedb.ref(`/pubchats/${pl}`).off("child_added")
        firedb.ref(`/pubchats/${pl}`).off("child_changed")
        firedb.ref(`/pubchats/${pl}`).off("child_removed")
        firedb.ref(`/modannounce/msg/${pl}`).off("child_added")
    },

    async loadRoomconfViaFallback({state, commit}) {
        await new Promise(resolve => { window.setTimeout(resolve, 3000) })

        const maxts = _.max([
            0,
            ..._.map(
                state.rooms,
                (r: MultiUserRoom) => r.ts * 1000
            )
        ]) as number

        if (Date.now() - maxts < 1000 * 60 * 3) {
            return
        }

        try {
            const roomconfs = await crud("/i/roomConfFallback")
            if (state.rooms.length === 0 || !state.connected) {
                roomconfs.forEach(
                    (r: MultiUserRoom) => {
                        commit("roomUpdate", r)
                    }
                )
            }
        } catch (e) {
            console.warn("Could not get room conf fallback", e)
        }
    },

    async changeListenersAgenda({state, commit, dispatch}) {
        const handleChangeAgenda = async (snapshot: firebase.database.DataSnapshot) => {
            const data = snapshot.val()
            data.objvalueid = parseInt(snapshot.key as string, 10)

            let newdata = data
            const olddata = _.find(state.agenda, (a: AgendaPoint) => a.objvalueid === data.objvalueid)
            if (olddata) {
                newdata = {
                    ...olddata,
                    ...data
                }
                commit("setAgendaPoint", newdata)
            }else{
                await dispatch("fetchAgenda")
            }

        }

        await dispatch("fetchAgenda")
        let newest_change = _.max([
            0,
            ..._.map(
                state.agenda,
                (a: AgendaPoint) => a.defaulttimestamp
            )
        ]) as number

        const firedb = await getFireDB()
        firedb.ref(`/eveobj/agenda_items`)
            .orderByChild("defaulttimestamp")
            .startAfter(newest_change)
            .on("child_added", handleChangeAgenda)

        firedb.ref(`/eveobj/agenda_items`).on("child_changed", handleChangeAgenda)
    },

    async changeListenersPartner({state, commit, dispatch}) {
        const handleChangePartner = async (snapshot: firebase.database.DataSnapshot) => {
            const data = snapshot.val()
            data.objvalueid = parseInt(snapshot.key as string, 10)

            let newdata = data
            const olddata = _.find(state.partners, (a: Partner) => a.objvalueid === data.objvalueid)
            if (olddata) {
                newdata = {
                    ...olddata,
                    ...data
                }
                commit("setPartner", newdata)
            }else{
                await dispatch("fetchPartner")
            }

        }

        await dispatch("fetchPartner")
        let newest_change = _.max([
            0,
            ..._.map(
                state.partners,
                (a: Partner) => a.defaulttimestamp
            )
        ]) as number

        const firedb = await getFireDB()
        firedb.ref(`/eveobj/exhibitor`)
            .orderByChild("defaulttimestamp")
            .startAfter(newest_change)
            .on("child_added", handleChangePartner)

        firedb.ref(`/eveobj/exhibitor`).on("child_changed", handleChangePartner)
    },


    async changeListenerImage({state, commit, dispatch}) {
        const handleChangeImage = async (snapshot: firebase.database.DataSnapshot) => {
            const data = snapshot.val()
            data.objvalueid = parseInt(snapshot.key as string, 10)

            let newdata = data
            const olddata = _.find(state.image, (a: ImagePoint) => a.objvalueid === data.objvalueid)
            if (olddata) {
                newdata = {
                    ...olddata,
                    ...data
                }

                commit("setImagePoint", newdata)
            }else{
                await dispatch("fetchImages")
            }

        }

        await dispatch("fetchImages")
        let newest_change = _.max([
            0,
            ..._.map(
                state.image,
                (a: ImagePoint) => a.defaulttimestamp
            )
        ]) as number
        const firedb = await getFireDB()
        firedb.ref(`/eveobj/images`)
            .orderByChild("defaulttimestamp")
            .startAfter(newest_change)
            .on("child_added", handleChangeImage)

        firedb.ref(`/eveobj/images`).on("child_changed", handleChangeImage)
    },


    async changeListenersContactsNetworking({state, commit, dispatch}, pl:boolean) {
        const handleChangeMember = async (snapshot: firebase.database.DataSnapshot) => {
            const data = snapshot.val()
            data.objvalueid = parseInt(snapshot.key as string, 10)

            let newdata = data
            const olddata = _.find(state.contacts_networking, (a: ContactsNetworking) => a.objvalueid === data.objvalueid)
            if (olddata) {
                newdata = {
                    ...olddata,
                    ...data
                }
                commit("setContactsNetworking", newdata)
            }else{
                await dispatch("fetchContactsNetworking")
            }

        }

        const firedb = await getFireDB()
        if (pl) {
            await dispatch("fetchContactsNetworking")
            let newest_change = _.max([
                0,
                ..._.map(
                    state.contacts_networking,
                    (a: ChatContact) => a.defaulttimestamp
                )
            ]) as number
            firedb.ref(`/eveobj/members`).orderByChild("defaulttimestamp").startAfter(newest_change).on("child_added", handleChangeMember)
            firedb.ref(`/eveobj/members`).on("child_changed", handleChangeMember)
        } else {
            // Raus
            firedb.ref(`/eveobj/members`).off("child_added")
            firedb.ref(`/eveobj/members`).off("child_changed")
        }
    },

    async changeListenersConnectionsNetworking({state, commit, dispatch, rootState}, pl:boolean) {
        const handleChangeNetworking = async (snapshot: firebase.database.DataSnapshot) => {
            const data = snapshot.val()
            data.objvalueid = parseInt(snapshot.key as string, 10)

            let newdata = data
            const olddata = _.find(state.connections_networking, (a: ConnectionNetworking) => a.objvalueid === data.objvalueid)
            if (olddata) {
                newdata = {
                    ...olddata,
                    ...data
                }
                commit("setConnectionsNetworking", newdata)
            }else{
                if(newdata.status && newdata.objvalueid && newdata.receiver && newdata.sender && (newdata.receiver == rootState.config.me.objvalueid || newdata.sender == rootState.config.me.objvalueid)){
                    await dispatch("fetchConnectionsNetworking")
                }
            }

        }


        const firedb = await getFireDB()
        if (pl) {
            await dispatch("fetchConnectionsNetworking")
            let newest_change = _.max([
                0,
                ..._.map(
                    state.connections_networking,
                    (a: ConnectionNetworking) => a.defaulttimestamp
                )
            ]) as number
            firedb.ref(`/eveobj/networking`).orderByChild("defaulttimestamp").startAfter(newest_change).on("child_added", handleChangeNetworking)
            firedb.ref(`/eveobj/networking`).on("child_changed", handleChangeNetworking)
        } else {
            // Raus
            firedb.ref(`/eveobj/networking`).off("child_added")
            firedb.ref(`/eveobj/networking`).off("child_changed")
        }
    },
    async changeListenersMeetings({state, commit, dispatch, rootState}, pl:boolean) {
        const handleChangeMeetings = async (snapshot: firebase.database.DataSnapshot) => {
            const data = snapshot.val()
            data.objvalueid = parseInt(snapshot.key as string, 10)

            let newdata = data
            const olddata = _.find(state.meetings, (a: Meetings) => a.objvalueid === data.objvalueid)
            if (olddata) {
                newdata = {
                    ...olddata,
                    ...data
                }
                commit("setMeetings", newdata)
            }else{
                if(newdata.status && newdata.objvalueid && newdata.receiver && newdata.sender && (newdata.receiver == rootState.config.me.objvalueid || newdata.sender == rootState.config.me.objvalueid)){
                    await dispatch("fetchMeetings")
                }
            }

        }


        const firedb = await getFireDB()
        if (pl) {
            await dispatch("fetchMeetings")
            let newest_change = _.max([
                0,
                ..._.map(
                    state.meetings,
                    (a: Meetings) => a.defaulttimestamp
                )
            ]) as number
            firedb.ref(`/eveobj/meetings`).orderByChild("defaulttimestamp").startAfter(newest_change).on("child_added", handleChangeMeetings)
            firedb.ref(`/eveobj/meetings`).on("child_changed", handleChangeMeetings)
        } else {
            // Raus
            firedb.ref(`/eveobj/meetings`).off("child_added")
            firedb.ref(`/eveobj/meetings`).off("child_changed")
        }
    },

    async announce({state, rootState}, pl:any) {
        const firedb = await getFireDB()

        const pushobj: any = {
            ts: Date.now() / 1000,
            user: rootState.config.fbuid
        }

        const forRoom = pl.ev === 'ijoined' || pl.ev === 'ileft'
        const forJoin = pl.ev === 'ijoined'

        if (forRoom) {
            if (forJoin && roomlist.some((r: string) => r === pl.nsp)) {
                pushobj.type = "playback"
            } else if (!forJoin) {
                if (!!roomjointraceids[pl.nsp as string] && roomjointraceids[pl.nsp as string] !== '') {
                    const leaveset = firedb
                        .ref(`/trace/${pl.nsp}/${roomjointraceids[pl.nsp as string]}/end`)
                        .set(firebase.database.ServerValue.TIMESTAMP)

                    leaveset.catch(e => console.error(e))

                    delete roomjointraceids[pl.nsp as string]

                    return Promise.race([
                        leaveset,
                        new Promise(resolve => { window.setTimeout(resolve, 1000) })
                    ])
                }
            }
        } else {
            pushobj.ev = pl.ev
        }

        const pushpromise = firedb.ref(`/trace/${pl.nsp}`).push(pushobj)

        pushpromise.then((ref: firebase.database.Reference) => {
            if (forJoin) {
                firedb.ref(`/trace/${pl.nsp}/${ref.key as string}/end`).onDisconnect().set(firebase.database.ServerValue.TIMESTAMP)
                roomjointraceids[pl.nsp as string] = ref.key as string
            }
        })

        // Wenn Firebase nicht verfügbar ist, geht es nach dem Timeout weiter.
        return Promise.race([
            pushpromise,
            new Promise(resolve => { window.setTimeout(resolve, 1000) })
        ])
    },
    async endAllAnnounce({dispatch}) {
        const useroomjointrace: string[] = Object.keys(roomjointraceids)

        for (let ri = 0; ri < useroomjointrace.length; ri++) {
            await dispatch("announce", {
                ev: "ileft",
                nsp: useroomjointrace[ri]
            })
        }
    },
    async getUserinfoForFirebaseuid({}, pl: string) {
        return crud('/i/getUserObjForFBUID', {fbuid: pl})
    },
    async forceLogout() {
        //console.debug("TODO: Replace")
    },


    async openContactChat({commit}, pl:any) {
        try {
            const nc: ChatContact = {
                fbuid: pl.fbuid,
                defaulttimestamp: pl.defaulttimestamp,
                objvalueid: pl.objvalueid,
                gender: pl.gender,
                title: pl.title,
                firstname: pl.firstname,
                lastname: pl.lastname,
                orgname: pl.orgname,
                country: pl.country
            }

            commit('OpenSideNav', 'chat',{ root: true })
            commit('setChatboxContact', nc)
        } catch (e) {
            throw e
        }
    },

    async checkIfContactNeedsToBeAdded({state,commit}, pl: ChatContact) {
        if (!state.contactschats.some((c: ChatContact) => c.fbuid === pl.fbuid)) {
            commit("addContactChat", pl)
        }
    },

    async sendmydm({commit, rootState}, pl: DMMessage) {
        const firedb = await getFireDB()
        const nchatres = await firedb.ref(`/chatmsg`).push({
            msg: pl.body,
            ts: pl.ts,
            receiver: pl.receiver,
            sender: rootState.config.fbuid
        })

        const chatobj = {
            ts: pl.ts,
            receiver: pl.receiver,
            sender: rootState.config.fbuid
        }

        await Promise.all([
            firedb.ref(`/chats/${chatobj.receiver}/${nchatres.key}`).set(chatobj),
            firedb.ref(`/chats/${chatobj.sender}/${nchatres.key}`).set(chatobj)
        ])
    },

    async sendmysupportmsg({rootState,commit}, pl: SupportMessage) {
        const firedb = await getFireDB()
        await firedb.ref(`/supportstatus/${rootState.config.fbuid}`).set({
            change: pl.ts,
            changeby: rootState.config.fbuid,
            status: "waiting for answer"
        })

        const nuid = await firedb.ref(`/supportchat/${rootState.config.fbuid}`).push({
            msg: pl.body,
            ts: pl.ts,
            sender: rootState.config.fbuid,
            receiver: "support",
            seen: false
        })
        pl.uuid = nuid.key as string

        //commit("addSupportMessage", {...pl})
    },

    async setSupportFlag({rootState, commit}, pl: any) {
        if (pl.msgid && pl.msgid !== "") {
            const firedb = await getFireDB()
            await firedb.ref(`/supportchat/${rootState.config.fbuid}/${pl.msgid}/seen`).set(true)
            commit("setSupportMessageFlag", pl)
        }
    },

    async setDMFlag({rootState, commit}, pl: any) {
        if (pl.msgid && pl.msgid !== "") {
            const firedb = await getFireDB()
            await firedb.ref(`/chatmsg/${pl.msgid}/flags/${rootState.config.fbuid}`).set({seen: true, ts: Date.now() / 1000})
            commit("setDMFlag", pl)
        }
    },

    async pushPublicChatMessage({state,commit, rootState}, pl: string) {
        if (!state.mucname) {
            return false
        }

        //ranking activity a - für die Nachrichten im Raumchat je 3 Punkte
        if(state.me.tn_type != 26 && state.me.tn_type != 33 )   {
            if(state.me.ranking_a < rankingsMaxPoints.ranking_a && new Date().toDateString() === 'Mon Jun 17 2024'){
                let value = state.me.ranking_a + 3;
                await crud('/setDataRanking', { fieldname: 'ranking_a', fieldvalue: value })
                commit("add_ranking_a", value)
                commit("add_me_offline", state.me)
            }else if(state.me.ranking_c < rankingsMaxPoints.ranking_c && new Date().toDateString() === 'Tue Jun 18 2024'){
                let value = state.me.ranking_c + 3;
                await crud('/setDataRanking', { fieldname: 'ranking_c', fieldvalue: value })
                commit("add_ranking_c", value)
                commit("add_me_offline", state.me)
            }else{
                let value = state.me.ranking_a + 3;
                await crud('/setDataRanking', { fieldname: 'ranking_a', fieldvalue: value })
                commit("add_ranking_a", value)
                commit("add_me_offline", state.me)
            }
        }

        const firedb = await getFireDB()

        try {
            const npc = await firedb.ref(`/pubchats/${state.mucname}`).push({
                msg: pl,
                sender: rootState.config.fbuid,
                ts: Date.now() / 1000
            })

            await crud("/i/enrichuser", {
                type: "pubchats",
                uid: npc.key,
                roomname: state.mucname
            })

        } catch (e) {
            console.error(e)
            return false
        }
    },

    async pushInteractionMessage({state,commit,rootState}, pl: string) {
        if (!state.mucname) {
            return false
        }

        const firedb = await getFireDB()

        try {
            const npc = await firedb.ref(`/questions/${state.mucname}`).push({
                msg: pl,
                sender: rootState.config.fbuid,
                ts: Date.now() / 1000
            })

            await crud("/i/enrichuser", {
                type: "questions",
                uid: npc.key,
                roomname: state.mucname
            })

            const q = {
                uuid: npc.key,
                roomname: state.mucname,
                body: pl,
                sender: {
                    obj: {
                        ...rootState.config.me
                    }
                },
                ts: Date.now() / 1000
            }
            commit("addQuestion", q)

        } catch (e) {
            console.error(e)
            return false
        }
    },

    async castBallot({state, commit, rootState}, pl: VotingBallot) : Promise<false|VotingBallot> {
        if (!state.mucname) {
            return false
        }

        const firedb = await getFireDB()

        pl.ts = Date.now() / 1000

        const nballot: VotingBallot = {
            ...pl,
            forfbuid: rootState.config.fbuid
        }

        try {
            const ref_res = await firedb.ref(`/voteballot/${pl.voteuid}/${pl.answer}/${rootState.config.fbuid}`).push({
                ts: pl.ts
            })

            if (ref_res.key) {
                nballot.fbuid = ref_res.key
            }
        } catch (e) {
            console.error(e)
            return false
        }

        OfflineDB.ballots.put(nballot)
        commit("setBallot", nballot)

        return nballot
    },

    async setPresence({rootState}, pl: string): Promise<firebase.database.ThenableReference> {
        const firedb = await getFireDB()
        const myPresenceRef = firedb.ref(`/presence/${pl}/${rootState.config.fbuid}`).push({ts: Date.now() / 1000}) as firebase.database.ThenableReference
        myPresenceRef.onDisconnect().remove()
        // @ts-ignore
        return myPresenceRef
    },


    async getMorePubchats({state, commit}, pl: number) {
        const firedb = await getFireDB()

        const handlePubChat = async (snapshot: firebase.database.DataSnapshot) => {
            const msguuid = snapshot.key as string
            const data = snapshot.val()
            const nmsg: Message = {
                uuid: msguuid,
                body: data.msg,
                roomname: state.mucname,
                ts: data.ts,
                sender: data.sender
            }

            commit("addPublicMessage", nmsg)
        }

        firedb.ref(`/pubchats/${state.mucname}`)
            .orderByChild('ts')
            .endBefore(pl)
            .limitToLast(5)
            .on("child_added", handlePubChat)
    },

    async checkSupportAvailable({}) {
        // TODO: Change
    },

    async checkUserOnlineState({}) {
        // TODO: Change
    },

    async getHistory({}) {
        // TODO: Change
    },

    async createMeeting({state,commit},pl:any) {
        try {
            //ranking activity e - Für das Anfragen der ersten 20 Terminanfragen je 10 Punkte
            /*if(state.me.ranking_e < 200 && state.me.tn_type != 26 && state.me.tn_type != 33){
                var value = state.me.ranking_e + 10;
                await crud('/setDataRanking', { fieldname: 'ranking_e', fieldvalue: value })
                commit("add_ranking_e", value)
                commit("add_me_offline", state.me)
            }*/
            const res = await crud('/createMeeting', pl)
            return res
        } catch (e) {
            throw e
        }
    },
    async removeMeeting({commit},pl:any) {
        try {
            const res = await crud('/removeMeeting', pl)
            return res
        } catch (e) {
            throw e
        }
    },
    async acceptMeeting({state,commit},pl:any) {
        try {
            //ranking activity f - für das Annehmen der ersten 5 Terminanfragen je 5 Punkte
            if(state.me.ranking_f < rankingsMaxPoints.ranking_f && state.me.tn_type != 26 && state.me.tn_type != 33){
                var value = state.me.ranking_f + 5;
                await crud('/setDataRanking', { fieldname: 'ranking_f', fieldvalue: value })
                commit("add_ranking_f", value)
                commit("add_me_offline", state.me)
            }
            const res = await crud('/acceptMeeting', pl)
            return res
        } catch (e) {
            throw e
        }
    },
    async declineMeeting({commit},pl:any) {
        try {
            const res = await crud('/declineMeeting', pl)
            return res
        } catch (e) {
            throw e
        }
    },

    async createConnection({state,commit},pl:any) {
        try {
            //ranking activity c - Für die ersten 5 Kontaktanfragen im Networking je 5 Punkte
           /* if(state.me.ranking_c < 25 && state.me.tn_type != 26 && state.me.tn_type != 33){
                var value = state.me.ranking_c + 5;
                await crud('/setDataRanking', { fieldname: 'ranking_c', fieldvalue: value })
                commit("add_ranking_c", value)
                commit("add_me_offline", state.me)
            }*/
            const res = await crud('/createConnection', pl)
            return res
        } catch (e) {
            throw e
        }
    },
    async removeConnection({commit},pl:any) {
        try {
            const res = await crud('/removeConnection', pl)
            return res
        } catch (e) {
            throw e
        }
    },
    async acceptConnection({state,commit},pl:any) {
        try {
            //ranking activity d - für das Akzeptieren der ersten 5 Kontaktanfragen je 5 Punkte
            if(state.me.ranking_d < rankingsMaxPoints.ranking_d && state.me.tn_type != 26 && state.me.tn_type != 33){
                var value = state.me.ranking_d + 5;
                await crud('/setDataRanking', { fieldname: 'ranking_d', fieldvalue: value })
                commit("add_ranking_d", value)
                commit("add_me_offline", state.me)
            }
            const res = await crud('/acceptConnection', pl)
            return res
        } catch (e) {
            throw e
        }
    },
    async declineConnection({commit},pl:any) {
        try {
            const res = await crud('/declineConnection', pl)
            return res
        } catch (e) {
            throw e
        }
    },
    async blockConnection({commit},pl:any) {
        try {
            const res = await crud('/blockConnection', pl)
            return res
        } catch (e) {
            throw e
        }
    },
    async fetchSpeakers({state, commit}) {
        try {
            let changeoffset = 0
            if (state.speakers.length > 0) {
                changeoffset = _.max(
                    _.map(
                        state.speakers,
                        (e) => (new Date(e.defaulttimestamp)).getTime()
                    ) as number[]
                ) as number
            }

            const fe = await crud('/getSpeakers', { changeoffset })
            if (fe.speakers) {
                fe.speakers.forEach(
                    (e: MemberEntry) => {
                        commit("setSpeaker", e)
                    }
                )
            }
        } catch (e) {

        }
    },

    async fetchAgenda({state, commit}) {
        let newest_change = _.max([
            0,
            ..._.map(
                state.agenda,
                (a: AgendaPoint) => a.defaulttimestamp
            )
        ]) as number

        try {
            await randomstop()

            const lae = await crud('/getAgendaEntries', { changeoffset: newest_change })
            if (lae.entries) {
                lae.entries.forEach(
                    (ae: AgendaPoint) => {
                        commit("setAgendaPoint", ae)
                    }
                )
            }
        } catch (e) {
            console.error("Problem fetching agenda", e)
        }
    },
    async fetchPartner({state, commit}) {
        let newest_change = _.max([
            0,
            ..._.map(
                state.partners,
                (a: Partner) => a.defaulttimestamp
            )
        ]) as number

        try {
            await randomstop()

            const lae = await crud('/getPartners', { changeoffset: newest_change })
            if (lae.entries) {
                lae.entries.forEach(
                    (ae: Partner) => {
                        commit("setPartner", ae)
                    }
                )
            }
        } catch (e) {
            console.error("Problem fetching agenda", e)
        }
    },
    async fetchImages({state, commit}) {
        let newest_change = _.max([
            0,
            ..._.map(
                state.image,
                (a: ImagePoint) => a.defaulttimestamp
            )
        ]) as number

        try {
            await randomstop()

            const lae = await crud('/getImageEntries', { changeoffset: newest_change })
            if (lae.entries) {
                lae.entries.forEach(
                    (ae: ImagePoint) => {
                        commit("setImagePoint", ae)
                    }
                )
            }
        } catch (e) {
            console.error("Problem fetching agenda", e)
        }
    },
    async fetchContactsNetworking({state, commit}) {
        let newest_change = _.max([
            0,
            ..._.map(
                state.contacts_networking,
                (a: ChatContact) => a.defaulttimestamp
            )
        ]) as number

        try {
            await randomstop()

            const lae = await crud('/getContactsNetworkingEntries', { changeoffset: newest_change })
            if (lae.entries) {
                lae.entries.forEach(
                    (ae: ChatContact) => {
                        commit("setContactsNetworking", ae)
                    }
                )
            }
        } catch (e) {
            console.error("Problem fetching contacts_networking", e)
        }
    },
    async fetchAllContactsNetworking({state, commit}) {

        try {
            await randomstop()

            const lae = await crud('/getContactsNetworkingEntries', { changeoffset: 0 })
            if (lae.entries) {
                lae.entries.forEach(
                    (ae: ChatContact) => {
                        commit("setContactsNetworking", ae)
                    }
                )
            }
        } catch (e) {
            console.error("Problem fetching contacts_networking", e)
        }
    },

    async fetchConnectionsNetworking({state, commit}) {
        let newest_change = _.max([
            0,
            ..._.map(
                state.connections_networking,
                (a: ConnectionNetworking) => a.defaulttimestamp
            )
        ]) as number

        try {
            await randomstop()
            const lae = await crud('/getConnectionsNetworkingEntries', { changeoffset: newest_change })
            if (lae.entries) {
                lae.entries.forEach(
                    (ae: ConnectionNetworking) => {
                        commit("setConnectionsNetworking", ae)
                    }
                )
            }
        } catch (e) {
            console.error("Problem fetching connections_networking", e)
        }
    },

    async fetchMeetings({state, commit}) {
        let newest_change = _.max([
            0,
            ..._.map(
                state.meetings,
                (a: Meetings) => a.defaulttimestamp
            )
        ]) as number

        try {
            await randomstop()
            const lae = await crud('/getMeetingsEntries', { changeoffset: newest_change })
            if (lae.entries) {
                lae.entries.forEach(
                    (ae: Meetings) => {
                        commit("setMeetings", ae)
                    }
                )
            }
        } catch (e) {
            console.error("Problem fetching meetings", e)
        }
    },

    async getSlotsNotAvailable({commit},pl:number) {

        try {
            const lae = await crud('/getSlotsNotAvailable', { objvalueid: pl })
            return lae.entries
        } catch (e) {
            console.error("Problem get meetings", e)
        }
    },

    async SetFormData({state,commit},pl:any) {
        commit("add_me",pl)
        commit("add_me_offline", state.me)
    },

    async setDataOnBoarding1({state,commit},pl:any) {
        try {
            const res = await crud('/setDataOnBoarding1', pl)
            commit("add_onboarding1",pl)
            commit("add_me_offline", state.me)
            return res
        } catch (e) {
            throw e
        }
    },
    async setDataOnBoarding2({state,commit},pl:any) {
        try {
            const res = await crud('/setDataOnBoarding2', pl)
            commit("add_onboarding2",pl)
            commit("add_me_offline", state.me)
            return res
        } catch (e) {
            throw e
        }
    },
    async setDataOnBoarding3({commit},pl:any) {
        try {
            const res = await crud('/setDataOnBoarding3', pl)
            return res
        } catch (e) {
            throw e
        }
    },
    async setDataCompetition({commit},pl:any) {
        try {
            const res = await crud('/setDataCompetition', pl)
            commit("add_score_reaction",pl)
            return res
        } catch (e) {
            throw e
        }
    },

    async setDataFav({state, commit},pl:any) {
        try {
            const res = await crud('/setDataFav', pl)
            if(res.sv){
                commit("add_fav_agenda_items", res.data)
                //ranking activity b - Für das Merken der ersten 7 PP je 5 Punkte
                if(state.me.ranking_b < rankingsMaxPoints.ranking_b && state.me.tn_type != 26 && state.me.tn_type != 33){
                    var value = state.me.ranking_b + 5;
                    await crud('/setDataRanking', { fieldname: 'ranking_b', fieldvalue: value })
                    commit("add_ranking_b", value)
                }
                commit("add_me_offline", state.me)
            }
            return res
        } catch (e) {
            throw e
        }
    },
    async setDataUnFav({state,commit},pl:any) {
        try {
            const res = await crud('/setDataUnFav', pl)
            if(res.sv){
                commit("add_fav_agenda_items", res.data)
                commit("add_me_offline", state.me)
            }
            return res
        } catch (e) {
            throw e
        }
    },

    async getMyFormData({state, commit, dispatch}) {
        try {
            let changeoffset = 0
            if (state.me.defaulttimestamp > 0) {
                changeoffset = (new Date(state.me.defaulttimestamp)).getTime()
            }
            const fe = await crud('/getMyFormData', { changeoffset })
            if (fe.formdata) {
                fe.formdata.forEach(
                    (pl:any) =>  dispatch("SetFormData", pl)
                )
            }
        } catch (e) {

        }
    },
    async setDataRankingE({state, commit},value:number) {
        try {
            await crud('/setDataRanking', { fieldname: 'ranking_e', fieldvalue: value })
            commit("add_ranking_e", value)
            commit("add_me_offline", state.me)
        } catch (e) {
            throw e
        }
    },
    async setDataRankingG({state, commit},value:number) {
        try {
            await crud('/setDataRanking', { fieldname: 'ranking_g', fieldvalue: value })
            commit("add_ranking_g", value)
            commit("add_me_offline", state.me)
        } catch (e) {
            throw e
        }
    },
    async setDataRankingH({state, commit},value:number) {
        try {
            await crud('/setDataRanking', { fieldname: 'ranking_h', fieldvalue: value })
            commit("add_ranking_h", value)
            commit("add_me_offline", state.me)
        } catch (e) {
            throw e
        }
    },
    async setDataRankingI({state, commit},value:number) {
        try {
            await crud('/setDataRanking', { fieldname: 'ranking_i', fieldvalue: value })
            commit("add_ranking_i", value)
        } catch (e) {
            throw e
        }
    },

    async setDataRankingJ({state, commit},value:number) {
        try {
            await crud('/setDataRanking', { fieldname: 'ranking_j', fieldvalue: value })
            commit("add_ranking_j", value)
        } catch (e) {
            throw e
        }
    },    
    
 
    async setDataRankingM({state, commit},value:number) {
        try {
            await crud('/setDataRanking', { fieldname: 'ranking_m', fieldvalue: value })
            commit("add_ranking_m", value)
        } catch (e) {
            throw e
        }
    },

}

export default actions
