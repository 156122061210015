<template>
  <div class="box">
    <div class="row g-3 mb-5 partners">
      <h3>Partners</h3>
      <div class="row">
        <p>
          <input placeholder="Search..." type="search" v-model.trim="searchtext" class="form-control">
        </p>

        <h4 v-if="orderedPartners1.length > 0">MAIN PARTNERS</h4>
        <PartnerEntry v-for="(e, ei) in orderedPartners1" :key="`opartner1${ei}`" :partner="e" />

        <h4 v-if="orderedPartners2.length > 0" class="mt-4">CO-HOSTED BY</h4>
        <PartnerEntry v-for="(e, ei) in orderedPartners2" :key="`opartner2${ei}`" :partner="e" />

        <h4 v-if="orderedPartners3.length > 0" class="mt-4">PUBLIC PARTNERS</h4>
        <PartnerEntry v-for="(e, ei) in orderedPartners3" :key="`opartner3${ei}`" :partner="e" />

        <h4 v-if="orderedPartners5.length > 0" class="mt-4">MEDIA PARTNER</h4>
        <PartnerEntry v-for="(e, ei) in orderedPartners5" :key="`opartner4${ei}`" :partner="e" />

        <h4 v-if="orderedPartners4.length > 0" class="mt-4">PARTNERS</h4>
        <PartnerEntry v-for="(e, ei) in orderedPartners4" :key="`opartner5${ei}`" :partner="e" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import PartnerEntry from "@/components/PartnerEntry.vue";
import _ from "lodash";
import {Partner} from "@/store/i/types";

@Component({
  components: {PartnerEntry},
})
export default class PartnerOverview extends Vue {

  protected created() {

    let stage = 'Test';
    if(window.location.host == 'gmf-event.com'){
      stage = 'Production';
    }
    this.$gtm.trackEvent({
      pageCategory: 'Partner',
      PageName: 'Overview',
      PageType: 'Webapp',
      Stage:stage,
    });
  }

  protected searchtext:string = ''

  get orderedPartners(): Partner[] {

    var filter = _.filter(
        this.$store.state.i.partners,
        (e: Partner) => e.dbstate > -1
    )

    if (filter.length > 0 && this.searchtext.length > 0) {
      const swords = _.map(
          _.words(this.searchtext),
          s => new RegExp(_.escapeRegExp(s),'iu')
      )
      return _.filter(
          filter,
          c => {
            for (let i = swords.length -1; i >= 0; i--) {
              if (!swords[i].test(c.title)) {
                return false
              }
            }

            return true
          }
      )
    }

    return filter
  }

  get orderedPartners1(): Partner[] {
    let filter =  _.filter(
        this.orderedPartners,
        (e: Partner) => e.dbstate > -1 && e.category == 1
    )

    return _.orderBy(filter,
        [p => p.objvalueid],
        ["asc"]
    )

  }

  get orderedPartners2(): Partner[] {
    let filter =  _.filter(
        this.orderedPartners,
        (e: Partner) => e.dbstate > -1 && e.category == 2
    )

    return _.orderBy(filter,
        [p => p.objvalueid],
        ["asc"]
    )

  }

  get orderedPartners3(): Partner[] {
    let filter =  _.filter(
        this.orderedPartners,
        (e: Partner) => e.dbstate > -1 && e.category == 3
    )

    return _.orderBy(filter,
        [p => p.objvalueid],
        ["asc"]
    )

  }

  get orderedPartners4(): Partner[] {
    let filter =  _.filter(
        this.orderedPartners,
        (e: Partner) => e.dbstate > -1 && e.category == 4
    )

    return _.orderBy(filter,
        [p => p.objvalueid],
        ["asc"]
    )

  }

  get orderedPartners5(): Partner[] {
    let filter =  _.filter(
        this.orderedPartners,
        (e: Partner) => e.dbstate > -1 && e.category == 5
    )

    return _.orderBy(filter,
        [p => p.title.toLowerCase()],
        ["asc"]
    )

  }



}
</script>

<style scoped>

</style>