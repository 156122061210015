<template>
  <div class="">
    <div class="">
      <Banner/>
    </div>

    <div class="box_header" v-if="ImageEntry">
      <div class="row g-3 pt-0">
        <div class="col-md-12">
          <div class="cv m-2" v-html="replace(ImageEntry.long_summary)"></div>
        </div>
      </div>
    </div>

    <div class="box2 pb-0">
      <div class="row g-3 pb-3" v-if="$store.state.i.me.accept_networking">
          <div class="col-lg-3 calendar g-0">
          <p class="headline cb-headlinenormalxl mb-4">My calendar</p><br/>
          <router-link :to="{name: 'Calendar'}">
            <i class="fal fa-calendar-alt"></i>
          </router-link>
        </div>

        <div class="col-lg-9 g-0">
          <p class="headline cb-headlinenormalxl mb-4">Networking</p><br/>
          <div class="row">
            <ContactEntry v-for="(e, ei) in Matches" :key="`omember${ei}`" :member="e"/>
          </div>

          <span class="Text float-end mb-4">
            <router-link :to="{name: 'Networking1'}">See all matches <i class="fal fa-chevron-right"></i></router-link>
          </span>
        </div>
      </div>

      <div class="row g-3" v-if="!$store.state.i.me.accept_networking">

        <p>
          Here you can see your best networking matches. Go to your profile and take part in the networking
        </p>

        <p>
          <router-link :to="{name: 'Profile'}" class="btn btn-primary">
            Go to my profile
          </router-link>
        </p>

      </div>

    </div>

    
    <div class="Rectangle">
      <span class="headline g-3" id="highlights">Program Highlights</span>
      <div class="row g-3">
        <div class="col-md-6 col-lg-6">

          <div :class="{'row agenda_item mb-4 ': true, 'highlight' : aeindex == 0}"
               v-for="(ae, aeindex) in orderedAgenda" :key="`agp1_${aeindex}`" :ae="ae">
            <div class="col-12 white"><a @click="navigateAgenda(ae.objvalueid)" class="pointer">
              <b>{{ title(ae.title) }}</b>
              <p v-if="ae.subtitle">{{ ae.subtitle }}</p>
            </a>
            </div>
          </div>

        </div>

        <div class="col-md-6 col-lg-6"><img src="/files/landingpage/highlight_xl.png"
                                            class="img-fluid" alt="Highlights"></div>

        <span class="Text white mt-4">
          <router-link :to="{name: 'AgendaOverview'}">See full program <i class="fal fa-chevron-right"></i></router-link>
        </span>
      </div>
    </div>

    <div class="box">
      <span class="headline cb-headlinenormalxl g-3 mb-4">Speakers</span>
      <div class="row g-3">
        <SpeakerEntry v-for="(e, ei) in orderedSpeakers" :key="`omember${ei}`" :member="e" type="welcome"/>

        <span class="Text">
          <router-link :to="{name: 'SpeakerOverview'}">See all speakers <i class="fal fa-chevron-right"></i></router-link>
        </span>
      </div>
    </div>
    
    <div class="box2">
      <span class="headline cb-headlinenormalxl g-3">Activity ranking</span>
      <div class="row g-3">
        <p class="">
          It’s no fun without a little bit of competition: Take part and earn points for activities on this conference platform – bookmarking your program highlights, hanging out at our virtual coffee bar, or scheduling networking calls. As soon as you have successfully completed one of the challenges, the corresponding icon will appear in green at the bottom of the page. The three winners of the activity ranking will receive a free on-site ticket to the DW Global Media Forum 2025! They will be announced here and by email on June 30, 2024.
        </p>

        <h5>Leader board</h5>
        <ContactEntry v-for="(e, ei) in Ranking" :key="`nmember${ei}`" :numberkey="ei" :member="e" type="ranking"/>

        <p class="bold" v-if="$store.state.i.me.tn_type == 26 || $store.state.i.me.tn_type == 33 || $store.state.i.me.tn_type == 37">
          DW employees cannot participate in the activity challenge.
        </p>

        <div  v-if="$store.state.i.me.tn_type != 26 && $store.state.i.me.tn_type != 33 && $store.state.i.me.tn_type != 37">
          <p class="bold">
            Your XP: {{ MyPoints }}
          </p>
          <p class="bold" v-if="MyRank > 0">
            Your position: {{ MyRank }}
          </p>

          <h6 class="font-weight-bold pb-2" v-if="$store.state.i.me.tn_type != 26 || $store.state.i.me.tn_type != 33 ||  $store.state.i.me.tn_type != 37">
            Activities to earn XP:
          </h6>
          <div class="row activities" v-if="$store.state.i.me.tn_type != 26 || $store.state.i.me.tn_type != 33 ||  $store.state.i.me.tn_type != 37">
            <div class="col text-center" :class="{'alreadyDone':($store.state.i.me.ranking_e >= 5), 'notDone':($store.state.i.me.ranking_e < 5)}">
              <i class="fa-regular fa-gamepad-modern fa-2x"></i><br/>
              <div class="badge badge-pill">{{$store.state.i.me.ranking_e}}</div>
            </div>

            <div class="col text-center" :class="{'alreadyDone':($store.state.i.me.ranking_a + $store.state.i.me.ranking_c >= 300), 'notDone':($store.state.i.me.ranking_a + $store.state.i.me.ranking_c < 300)}">
              <i class="far fa-comment-alt fa-2x"></i><br/>
              <div class="badge badge-pill">{{ $store.state.i.me.ranking_a + $store.state.i.me.ranking_c }}</div>
            </div>

            <div class="col text-center" :class="{'alreadyDone':($store.state.i.me.ranking_g >= 200),'notDone':($store.state.i.me.ranking_g < 200)}">
              <i class="far fa-video fa-2x"></i><br/>
              <div class="badge badge-pill">{{ $store.state.i.me.ranking_g }}</div>
            </div>

            <div class="col text-center" :class="{'alreadyDone':($store.state.i.me.ranking_d >= 25),'notDone':($store.state.i.me.ranking_d < 25)}">
              <i class="far fa-user-friends fa-2x"></i><br/>
              <div class="badge badge-pill">{{ $store.state.i.me.ranking_d }}</div>
            </div>

            <div class="col text-center" :class="{'alreadyDone':($store.state.i.me.ranking_f >= 25),'notDone':($store.state.i.me.ranking_f < 25)}">
              <i class="far fa-calendar-alt fa-2x"></i><br/>
              <div class="badge badge-pill">{{ $store.state.i.me.ranking_f }}</div>
            </div>

            <div class="col text-center" :class="{'alreadyDone':($store.state.i.me.ranking_b >= 35),'notDone':($store.state.i.me.ranking_b < 35)}">
              <i class="far fa-calendar-star fa-2x"></i><br/>
              <div class="badge badge-pill">{{ $store.state.i.me.ranking_b }}</div>
            </div>

            <div class="col text-center" :class="{'alreadyDone':($store.state.i.me.ranking_m + $store.state.i.me.ranking_l + $store.state.i.me.ranking_k >= 17),'notDone':($store.state.i.me.ranking_m + $store.state.i.me.ranking_l + $store.state.i.me.ranking_k < 17)}">
              <i class="far fa-user-circle fa-2x"></i><br/>
              <div class="badge badge-pill">{{ $store.state.i.me.ranking_m + $store.state.i.me.ranking_l + $store.state.i.me.ranking_k }}</div>
            </div>

            <div class="col text-center" :class="{'alreadyDone':($store.state.i.me.ranking_i + $store.state.i.me.ranking_j >= 8),'notDone':($store.state.i.me.ranking_i + $store.state.i.me.ranking_j < 8)}">
              <i class="far fa-door-open fa-2x"></i><br/>
              <div class="badge badge-pill">{{$store.state.i.me.ranking_i + $store.state.i.me.ranking_j}}</div>
            </div>

            <div class="col-4"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="box">
      <span class="headline cb-headlinenormalxl g-3 mb-4">Coffee bar and reaction game</span>
      <div class="row g-3 pt-0">
          <p>
            Get together for a virtual coffee with other conference attendees who are online right now! This is an open group chat.
          </p>
          <p>
              Take part in our reaction game and collect points to increase your online activity score! The highest online activity scores win a ticket to #GMF25.
          </p>
          <p>
            <router-link :to="{name: 'Coffeebar'}" class="btn btn-danger col-lg-3">
              <i class="fa-light fa-mug-hot"></i> Coffee bar
            </router-link>
          </p>
      </div>
    </div>
    
    <GalleryV2Loader :url="'/webapp/imageslider2/'+new Date().getMinutes()" class="light-gray" :add-container="true">
        Impressions
    </GalleryV2Loader>
      
    <div class="box" v-if="OnDemand.length > 0">
      <span class="headline cb-headlinenormalxl g-3 mb-4">ON DEMAND</span>
      <div class="row g-3">
          <div v-if="OnDemand.length == 0">
              <p>No videos on demand available yet.</p>
          </div>
        <AgendaEntry v-for="(ae, aeindex) in OnDemand" :key="`agp2_${aeindex}`" :ae="ae" agendatype="video"/>

          <span class="Text">
          <router-link :to="{name: 'Mediathek'}">See all on demand <i class="fal fa-chevron-right"></i></router-link>
        </span>
      </div>
    </div>

    <div class="box2">
      <span class="headline cb-headlinenormalxl g-3 mt-1 mb-5">Main Partners</span>
      <div class="row g-3 pt-1">
        <img src="/files/landingpage/partners_1.png"
             class="img-fluid mt-0"
             alt="Parter Logos of GMF - Federal foreign office and the state government of North Rhine-Westphalia"
        >
      </div>
      <span class="headline cb-headlinenormalxl g-3 mt-3 mb-5">Co-Hosted By</span>
      <div class="row g-3 pt-1">
        <img src="/files/landingpage/partners_2.png"
             class="img-fluid mt-0"
             alt="Logo of the Foundation for International Dialogue of the Savongs Bank in Bonn"
        >
      </div>
      <span class="headline cb-headlinenormalxl g-3 mt-3 mb-5">Public Partners</span>
      <div class="row g-3 pt-1">
        <img src="/files/landingpage/partners_3.png"
             class="img-fluid mt-0"
             alt="Public partners of GMF - Federal Ministry for Economic Cooperation and Development; Freude Joy Joie Bonn; Federal Government Commissioner for Culture and the Media"
        >
      </div>
      <br>
      <div class="row g-3 mt-2">
          <span class="Text">
            <router-link :to="{name: 'PartnerOverview'}">See all partners <i class="fal fa-chevron-right"></i></router-link>
          </span>
      </div>
    </div>

  </div>
</template>

<script lang="ts">
import {Component, Vue, Watch} from 'vue-property-decorator';
import _, {forEach} from "lodash";
import {TranslatedText} from "@/store/types";
import {AgendaPoint, ContactsNetworking, ConnectionNetworking, MemberEntry, Meetings} from "@/store/i/types";
import SpeakerEntry from "@/components/SpeakerEntry.vue";
import ContactEntry from "@/components/ContactEntry.vue";
import Banner from "@/components/Banner.vue";
import BannerMobile from "@/components/BannerMobile.vue";
import AgendaEntry from "@/components/AgendaEntry.vue";
import MeetingEntry from "@/components/MeetingEntry.vue";
import axios from "axios";
import rankingsMaxPoints from "@/ranking";
import GalleryV2Loader from "@/components/GalleryV2Loader.vue";

@Component({
  components: {
    GalleryV2Loader,
      // VueConfetti,
    MeetingEntry, SpeakerEntry, ContactEntry, AgendaEntry, Banner, BannerMobile
  },
})
export default class Welcome extends Vue {
  protected ranking_place: number = 0;
    
  protected created() {

    let stage = 'Test';
    if (window.location.host == 'gmf-event.com') {
      stage = 'Production';
    }
    this.$gtm.trackEvent({
      pageCategory: 'Home',
      PageName: 'Home',
      PageType: 'Webapp',
      Stage: stage,
    });
    
    this.ranking_place = 0
  }

    

   get MyRank(){
      if(this.$store.state.i.me.objvalueid > 0){
        this.MyPosition(this.$store.state.i.me.objvalueid).then((meta) => {
          this.ranking_place = meta;
        });
      }
      
      
      return this.ranking_place;
   }
  
  get shownavi() {
    return 1
  }

  protected errorstring: TranslatedText = {'de': '', 'en': ''}

  protected title(title: string): string {
    title = _.replace(title, /&#34;/g, '"')
    title = _.replace(title, /&#39;/g, "'")
    return title
  }

  protected share(action: any, text: any, language: any) {
    const link = window.location.href;
    const domain = window.location.hostname;

    let appendLanguage = '';
    if (language === 'en_us') {
      appendLanguage = ((link.indexOf('?') !== -1) ? '&' : '?') + 'hl=en';
    }

    const encodedUrl = encodeURIComponent(link + appendLanguage);

    let subject = $(this).closest('ul').data('subject');
    let fulltext = text + " " + encodedUrl;

    if (action === "facebook") {
      window.open("http://www.facebook.com/sharer.php?u=" + encodedUrl);
    } else if (action === "twitter") {
      window.open("https://twitter.com/intent/tweet?source=webclient" + "&url=" + encodedUrl);
    } else if (action === "linkedin") {
      window.open("https://www.linkedin.com/sharing/share-offsite/?url=" + encodedUrl);
    }
  }

  get orderedSpeakers(): MemberEntry[] {

    var filter = _.filter(
        this.$store.state.i.speakers,
        (e: MemberEntry) => e.dbstate > -1 && e.tn_type == 8 && e.highlight && e.is_visible
    )

    filter = _.orderBy(filter,
        [user => user.lastname.toLowerCase()],
        ["asc"]
    )

    filter = _.take(filter, 3)

    return filter;

  }

  get orderedAgenda(): AgendaPoint[] {
    var filter = _.filter(
        this.$store.getters['i/orderedAgendaPoints'],
        (ap: AgendaPoint) => ap.dbstate > -1 && ap.highlight
    )

    filter = _.take(filter, 3)

    return filter;
  }

  get MyAgenda(): AgendaPoint[] {

    var filter = _.filter(
        this.$store.getters['i/orderedAgendaPoints'],
        (ap: AgendaPoint) => ap.dbstate > -1 && _.has(this.$store.state.i.me.fav_agenda_items, ap.objvalueid) && new Date(ap.enddate).getTime() > new Date().getTime()
    )

    filter = _.take(filter, 3)

    return filter;
  }

  get OnDemand(): AgendaPoint[] {

    var filter = _.filter(
        this.$store.getters['i/orderedAgendaPoints'],
        (ap: AgendaPoint) => ap.dbstate > -1 && ap.show_video
    )

    filter = _.take(filter, 3)

    return filter;
  }

  get MyMeetings(): Meetings[] {
    var filter = _.filter(
        this.$store.getters['i/orderedMeetings'],
        (ap: Meetings) => ap.dbstate > -1 && ap.status == 2 && new Date(ap.enddate).getTime() > new Date().getTime()
    );

    filter = _.take(filter, 3)

    return filter;
  }

  get ContactsNetworking(): ContactsNetworking[] {

    const arr: number[] = []

    var sC = _.filter(
        this.$store.getters['i/sortedConnectionsNetworking'],
        (c: ConnectionNetworking) => c.dbstate != -1
    )

    sC.forEach(
        (ap: ConnectionNetworking) => {
          arr.push(ap.receiver)
          arr.push(ap.sender)
        }
    )

    var filter = _.filter(
        this.$store.getters['i/sortedContactsNetworking'],
        (c: ContactsNetworking) => c.accept_networking
    )

    var excludes = _(filter)
        .keyBy('objvalueid')
        .at(arr)
        .value();

    filter = _.differenceWith(filter, excludes, _.isEqual);

    return filter;

  }

  get Matches(): ContactsNetworking[] {

    var filter = this.ContactsNetworking;

    filter = _.orderBy(
        filter, ["match"], ["desc"]
    )

    if (!this.$store.state.config.me.is_test) {
      filter = _.filter(
          filter,
          (c: ContactsNetworking) => !c.is_test
      )
    }

    filter = _.take(filter, 2)

    return filter;

  }

  protected navigateAgenda(objvalueid: any) {
    this.$router.push({name: 'AgendaDetail', params: {agendaid: objvalueid}})
  }

  get AgendaEntry(): any {
    return _.find(
        this.$store.state.i.agenda,
        (ap: AgendaPoint) => ap.dbstate > -1 && ap.now_live
    )
  }


  get ImageEntry(): AgendaPoint | undefined {
    return _.find(
        this.$store.state.i.image,
        (ap: AgendaPoint) => ap.dbstate > -1 && ap.now_live
    )
  }

  get Moderators(): MemberEntry[] {
    return _.filter(
        this.$store.state.i.speakers,
        (ap: MemberEntry) => ap.dbstate > -1 && _.has(this.mod, ap.objvalueid) && ap.is_visible
    );
  }

  get mod(): any {
    if (this.AgendaEntry) {
      return this.AgendaEntry.moderators
    } else {
      return 0
    }
  }


  get Speakers(): MemberEntry[] {
    return _.filter(
        this.$store.state.i.speakers,
        (ap: MemberEntry) => ap.dbstate > -1 && _.has(this.spe, ap.objvalueid) && ap.is_visible
    );
  }

  get spe(): any {
    if (this.AgendaEntry) {
      return this.AgendaEntry.speakers
    } else {
      return 0
    }
  }


  get Artists(): MemberEntry[] {
    return _.filter(
        this.$store.state.i.speakers,
        (ap: MemberEntry) => ap.dbstate > -1 && _.has(this.art, ap.objvalueid) && ap.is_visible
    );
  }

  get art(): any {
    if (this.AgendaEntry) {
      return this.AgendaEntry.artists
    } else {
      return 0
    }
  }

  protected getSpeakers() {

    let table = "<div class=\"row mb-2 mt-4\">";

    if(this.Speakers.length > 0){
      table += "<div class=\"col-4\">";
      _.forEach(this.Speakers, function (user, i) {
        if (user.objvalueid && !i) {
          table += " <span><b>Speakers</b><br></span><br>";
        }
        if (user.objvalueid) {
          table += user.firstname + " " + user.lastname + "<br>";
        }
      });
      table += "</div>";
    }
    if(this.Moderators.length > 0){
      table += "<div class=\"col-4\">";
      _.forEach(this.Moderators, function (user, i) {
        if (user.objvalueid && !i) {
          table += " <span><b>Moderator</b><br></span><br>";
        }
        if (user.objvalueid) {
          table += user.firstname + " " + user.lastname + "<br>";
        }
      });
      table += "</div>";
    }
    
    if(this.Artists.length > 0){
      table += "<div class=\"col-4\">";
      _.forEach(this.Artists, function (user, i) {
        if (user.objvalueid && !i) {
          table += " <span><b>Artists</b><br></span><br>";
        }
        if (user.objvalueid) {
          table += user.firstname + " " + user.lastname + "<br>";
        }
      });
      table += "</div>";
    }


    table += "</div>";

    return table

  }

  protected replace(title: string): string {
    let title1 = this.AgendaEntry ? this.AgendaEntry.title : '';
    let speaker1 = this.AgendaEntry ? this.getSpeakers() : '';

    title = _.replace(title, /&#34;/g, '"')
    title = _.replace(title, /&#39;/g, "'")
    title = _.replace(title, '#LIVE#', title1)
    title = _.replace(title, '#SPEAKER_LIVE#', speaker1)

    return title;
  }


  @Watch("$store.state.i.connected")
  @Watch("$store.state.i.me.accept_networking")
  protected async handleListeners() {
    if (this.$store.state.i.me.accept_networking) {
      this.$store.dispatch("i/changeListenersContactsNetworking", true)
    } else {
      this.$store.dispatch("i/changeListenersContactsNetworking", false)
    }
  }

  get Ranking(): ContactsNetworking[] {

    var filter = _.filter(
        this.$store.state.i.contacts_networking,
        (c: ContactsNetworking) => c.accept_networking && c.tn_type != 37 && c.tn_type != 26 && c.tn_type != 33 && c.ranking > 0
    )

    filter = _.orderBy(
        filter, ["ranking"], ["desc"]
    )

    filter = _.take(filter, 3)

    return filter;

  }

  get MyPoints(): number {
      return this.$store.getters["i/myPoints"];
  }
  
  async MyPosition(userid: number) {
      const response = await axios.get('/getmyranking/' + userid);
      if (response.status === 200) {
          return response.data
          
      }
      
      return 0;
  }

  protected beforeDestroy() {
    this.$store.dispatch("i/changeListenersContactsNetworking", false)
  }

}
</script>
