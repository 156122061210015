<template>
  <div class="box">

    <div class="mainform g-3 pb-4">

      <h3>Login</h3>


        <div v-show="errorstring.de !== ''" class="alert alert-danger mb-5">
          {{ $store.getters.i18n(errorstring) }}
        </div>

       

        <div>
        <form @submit.prevent="loginattmp" class="form-horizontal">
          <div class="row">
            <div class="col-sm-12 position-relative formitem_parent">
              <label class="form-label" for="musername">E-mail address</label>
              <input type="email" required autocomplete="off" @focusin="emptyError" v-model.trim="username" id="musername" class="form-control" />
            </div>

            <div class="col-sm-12 position-relative formitem_parent">
              <label class="form-label" for="mpassword">Password</label>
              <input type="password" required autocomplete="off" @focusin="emptyError" v-model.trim="password" id="mpassword" class="form-control" />
            </div>
          </div>

          <div class="row mt-4 clearfix noprint">
            <div class="col d-flex justify-content-start flex-row-reverse">
              <button class="btn btn-primary m-2" :disabled="username.length < 2 || password.length < 5" type="submit">
                Login
              </button>
              <a href="/eve/interfaces/modules/registration/index.php?mprefix=dw&pprefix=gmf&regname=webapp&label=lost_password" target="_blank" class="btn btn-primary m-2 prev lostpw">
                Forgot password
              </a>
            </div>
          </div>

        </form>

        

           </div> 
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import {TranslatedText} from "@/store/types";
import crud, {AppNetworkErrorOther, networkErrorText} from "@/crud";
import rankingsMaxPoints from '@/ranking';

@Component({
  components: {},
})
export default class Login extends Vue {

  protected username = ""
  protected password = ""
  protected errorstring: TranslatedText = {'de': '', 'en': ''}
  protected loginstart: null|Date = null

  protected userid = 0
  protected usergsuffix = "members"



  protected emptyError() {
    this.errorstring = {'de': '', 'en': ''}
  }


  @Watch("$route.name")
  protected navigation() {
     if (this.$route.name === "Login") {
      this.loginstart = null
    }
  }
    
  protected async loginattmp() {
    try {
      this.emptyError()
      const loginres = await this.$store.dispatch("login", {u: this.username, p:this.password})


        if (loginres.login) {

          
          
        // if (!this.$store.state.i.me.is_test) {
        //     this.errorstring = {'de': 'The event page is not open yet.', 'en': 'The event page is not open yet.'}
        //     return false
        //
        // }
          
        let date = new Date();
        
        //ranking activity i - Login VA-Tag 1 -> 3 Punkte - VA-Tag 2 -> 5 Punkte
        if(date.toDateString() === 'Mon Jun 17 2024') {
            if (this.$store.state.i.me.ranking_i < rankingsMaxPoints.ranking_i) {
                await this.$store.dispatch('i/setDataRankingI', 3)
            }
        }else if(date.toDateString() === 'Tue Jun 18 2024'){
            if(this.$store.state.i.me.ranking_j < rankingsMaxPoints.ranking_j){
                await this.$store.dispatch('i/setDataRankingJ', 5)
            }            
        }
        
        if (loginres.show_onboarding) {
          this.$router.replace({name: "Step1"})
        } else {
          this.$router.replace({name: "Welcome"})
        }
      } else {
        this.errorstring = {'de': 'Bitte überprüfen Sie Ihre Zugangsdaten', 'en': 'Please validate your input'}
      }
    } catch (e) {
      this.errorstring = networkErrorText(e as AppNetworkErrorOther)
    }


  }



  protected created() {
    this.navigation()

    let stage = 'Test';
    if(window.location.host == 'gmf-event.com'){
      stage = 'Production';
    }

    this.$gtm.trackEvent({
      pageCategory: 'Login',
      PageName: this.$route.name,
      PageType: 'Webapp',
      Stage: stage,
    });
  }
}
</script>
