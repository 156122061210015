<template>
  <div class="box">
    <div class="mainform g-3 pb-4">
      <h3>Upload picture</h3>
      <OnBoarding3 type="step"/>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import OnBoarding3 from "@/components/i/OnBoarding3.vue"

@Component({
  components: {OnBoarding3},
})
export default class Step1 extends Vue {
  protected created() {

    let stage = 'Test';
    if(window.location.host == 'gmf-event.com'){
      stage = 'Production';
    }
    this.$gtm.trackEvent({
      pageCategory: 'Networking',
      PageName: 'Picture Upload',
      PageType: 'Webapp',
      Stage:stage,
    });
  }
}
</script>