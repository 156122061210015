import {GetterTree} from "vuex";
import {LandingPageState, ProgramHighlight, Speaker, Ticket} from "@/store/landingpage/types";
import {RootState} from "@/store/types";

const getters: GetterTree<LandingPageState, RootState> = {
    tickets(state): Ticket[] {
        return state.tickets
    },
    shopsAvailable(state): boolean {
        return state.shopsAvailable;
    },
    loginAvailable(state): boolean {
        return Date.now() > Date.parse("2024-06-01T00:00:00.0000+01:00");
    },
    programHighlights(state): ProgramHighlight[] {
        return state.programHighlights;
    },
    highlightedSpeakers(state): Speaker[] {
        return state.highlightedSpeakers;
    }
}

export default getters;