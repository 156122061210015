import {LandingPageState} from "@/store/landingpage/types";
import {Module} from "vuex";
import {RootState} from "@/store/types";
import actions from "@/store/landingpage/actions";
import mutations from "@/store/landingpage/mutations";
import getters from "@/store/landingpage/getters";

const landingpage: Module<LandingPageState, RootState> = {
    namespaced: true,
    state: {
        tickets: [],
        shopsAvailable: false,
        programHighlights: [],
        highlightedSpeakers: []
    },
    actions,
    mutations,
    getters
}

export default landingpage;