import { Module } from 'vuex';
import {MembersState} from "@/store/member/types";
import {RootState} from "@/store/types";
import mutations from "@/store/member/mutations";
import actions from "@/store/member/actions";
import getters from "@/store/member/getters";

const members: Module<MembersState, RootState> = {
    namespaced: true,
    state: {
        contacts: [],
        currentCall: undefined,
        callHistory: [],
        mediaDevices: [],
        pickedDevices: {
            output: '',
            mic: '',
            cam: ''
        },
        rtcSupport: false
    },
    actions,
    mutations,
    getters
}

export default members