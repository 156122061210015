<template>
  <div class="box">
    <div class="row g-3 mb-5 highlights">
      <h3>My Calendar</h3>
      <Agenda  agendatype="calendar"/>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import Agenda from "@/components/Agenda.vue"

@Component({
  components: {Agenda},
})
export default class Calendar extends Vue {
  protected created() {
    let stage = 'Test';
    if(window.location.host == 'gmf-event.com'){
      stage = 'Production';
    }

    this.$gtm.trackEvent({
      pageCategory: 'Program',
      PageName: 'MyCalendarPage',
      PageType: 'Webapp',
      Stage:stage,
    });
  }
}
</script>