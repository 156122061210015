<template>
  <div class="box">

    <div class="mainform g-3 pb-4">
      <h3>{{ $store.getters.i18n({de: 'Seite nicht gefunden', en: 'Page not found'}) }}</h3>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import {TranslatedText} from "@/store/types";
import {networkErrorText} from "@/crud";

@Component({
  components: {},
})
export default class Error404 extends Vue {
  protected created() {

    let stage = 'Test';
    if(window.location.host == 'gmf-event.com'){
      stage = 'Production';
    }
    this.$gtm.trackEvent({
      pageCategory: 'Error',
      PageName: 'Error404',
      PageType: 'Webapp',
      Stage:stage,
    });
  }
}
</script>
