// @ts-ignore

import C2 from 'vue-class-component';

C2.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
])

import {Component as C1} from 'vue-property-decorator';
C1.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
])


import Vue from 'vue'
import App from './App.vue'
import VueGtm from '@gtm-support/vue2-gtm';
import './registerServiceWorker'
import router from './router'
import store from './store'
import HorizontalNav from "@/components/HorizontalNav.vue";
import VerticalNav from "@/components/VerticalNav.vue";
import FontAwesomeIcon from "@/components/FontAwesomeIcon.vue";
import $ from "jquery";
// @ts-ignore
import VueConfetti from 'vue-confetti';
(window as any).$ = $

Vue.component('HorizontalNav', HorizontalNav)
Vue.component('VerticalNav', VerticalNav)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)

Vue.use(VueConfetti);

Vue.use(VueGtm, {
  id: "GTM-PBLMQ79", // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
  queryParams: {
    pageCategory: "",
    PageName: "",
    Stage: "",
  },
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
