<template>
  <video
      :id="playerid"
      :class="`video-js vjs-default-skin vjs-${pprefix}-skin vjs-big-play-centered`"
      controls playsinline
      :loop="sloop"
      :autoplay="sautostart" :muted="sautostart"
      preload="auto"
      :poster="splaceholder"
      data-setup="{}">
    <source :src="ssrc" :type="videofiletype">
    <p class="vjs-no-js">
      {{ $store.getters.i18n({de:'Ihr Browser unterstützt kein HTML-Video.',en:'Your browser does not support HTML-Video.'}) }}
    </p>
  </video>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import videojs from 'video.js';
import _ from "lodash";

export interface VideoPlayerPosition {
  position: number;
  duration: number;
  positionPercentage: number;
}

@Component
export default class VideojsWrapper extends Vue {
  @Prop() protected playerid!: string;
  @Prop() protected ssrc!: string;
  @Prop() protected splaceholder!: string;
  @Prop() protected sautostart!: boolean;
  @Prop() protected sautopip!: boolean;
  @Prop() protected sloop!: boolean;
  @Prop({default: 0}) protected watchtime!: number;
  @Prop({default: 'application/x-mpegurl'}) protected videofiletype!: string;

  protected notplayed: boolean = true
  protected isplaying: boolean = false
  protected isfullscreen: boolean = false

  public duration = 0
  public position = 0
  public positionPercentage = .0

  protected playerposelement: null|HTMLElement = null

  protected playerinst: videojs.Player|null|undefined = undefined

  get pprefix(): string {
    if (!this.$store.state.config.project) {
      return ''
    }

    switch (typeof this.$store.state.config.project) {
      case "object":
        return this.$store.state.config.project.pprefix
      case "string":
        const projectparts:string[] = this.$store.state.config.project.split('.')
        if (!projectparts || projectparts.length < 2) {
          return ''
        }
        return projectparts[1]
      default: return ''
    }
  }

  @Watch("ssrc")
  protected async updatePlayerSrc() {
    await this.$nextTick()

    if (!this.playerinst) {
      return
    }

    const vm = this
    this.playerinst.src({
      src: vm.ssrc,
      type: this.videofiletype
    })

    await this.$nextTick()
    this.playerinst.play()
  }

  @Watch("splaceholder")
  protected async updatePosterSrc() {
    await this.$nextTick()

    if (!this.playerinst) {
      return
    }

    this.playerinst.poster(this.splaceholder)
  }

  @Watch("sloop")
  protected async setLoop() {
    await this.$nextTick()

    if (!this.playerinst) {
      return
    }

    this.playerinst.loop(this.sloop)
  }

  protected autopipd: boolean = false;

  @Watch('$store.state.visible')
  protected async autoPictureInPicture() {
    if (this.playerinst) {
      if (this.$store.state.visible && this.autopipd) {
        // Ausschalten
        if ((<any>this.playerinst).isInPictureInPicture()) {
          try {
            await (<any>this.playerinst).exitPictureInPicture()
          } catch (e) {
          }
        }
        this.autopipd = false
        return
      }

      if (
          !this.$store.state.visible &&
          this.sautopip &&
          this.isplaying &&
          !this.isfullscreen &&
          !(<any>this.playerinst).isInPictureInPicture()
      ) {
        try {
          await (<any>this.playerinst).requestPictureInPicture()
          this.autopipd = (<any>this.playerinst).isInPictureInPicture()
        } catch (e) {
          this.autopipd = false
        }
      }
    }
  }

  protected async mounted() {
    const vm = this

    this.playerinst = videojs(this.$el, {
      fluid: true,
      autoplay: vm.sautostart,
      muted: vm.sautostart,
      loop: vm.sloop
    }, this.playerReady)
  }

  protected positionRecalc() {
    if (this.playerinst && this.duration > 0) {
      this.position = this.playerinst.currentTime()
      this.positionPercentage = Math.round(this.position / this.duration * 100) / 100
      this.$emit("playerPositionChanged", {
        duration: this.duration,
        position: this.position,
        positionPercentage: this.positionPercentage
      })
    }
  }

  protected handlePlayerPosElement() {
    if (this.playerinst) {
      const playerdom = this.playerinst.contentEl() as HTMLElement
      if (!this.playerposelement && this.notplayed && this.watchtime > 0 && this.duration > 0) {
        this.playerposelement = document.createElement("DIV")
        const percentage = this.watchtime / this.duration * 100
        this.playerposelement.setAttribute("class", `maxplaypos${percentage >= 95 ? ' completed':''}`)
        this.playerposelement.setAttribute("style", `width:${percentage}%;`)
        playerdom.append(this.playerposelement)
      } else if (this.playerposelement !== null) {
        playerdom.removeChild(this.playerposelement)
        this.playerposelement = null
      }
    }
  }

  protected async playerReady() {
    if (!this.playerinst) return

    const vm = this

    this.$emit('player-ready', this.playerinst)

    this.playerinst.on("fullscreenchange", () => {
      if (vm.playerinst) {
        vm.isfullscreen = vm.playerinst.isFullscreen()
        vm.$emit('fullscreenchange', vm.isfullscreen)
      }
    })

    this.playerinst.on("durationchange", () => {
      if (vm.playerinst) {
        vm.duration = vm.playerinst.duration()
        vm.positionRecalc()
        vm.handlePlayerPosElement()
      }
    })

    this.playerinst.on("timeupdate", () => {
      if (vm.playerinst) {
        vm.positionRecalc()
      }
    })

    this.playerinst.on("loadedmetadata", () => {
      if (vm.playerinst) {
        vm.duration = vm.playerinst.duration()
        if (vm.watchtime && vm.watchtime > 0 && vm.position === 0) {
          const percentage = vm.watchtime / vm.duration * 100
          if (percentage < 95) {
            vm.playerinst.currentTime(vm.watchtime)
          }
        }
      }
    })

    this.playerinst.on("canplay", () => {
      if (vm.playerinst) {
        vm.$emit('canPlay', vm.playerinst)
      }
    })

    this.playerinst.on("error", (e) => {
      if (vm.playerinst) {
        vm.$emit('playerError', e)
      }
    })

    this.playerinst.on("play", () => {
      if (vm.playerinst) {
        vm.isplaying = true
        vm.$emit('playbackPlayed', vm)
      }
    })

    this.playerinst.one("play", () => {
      if (vm.playerinst) {
        this.notplayed = false
        vm.handlePlayerPosElement()
        vm.$emit('playbackStarted', vm)
      }
    })

    this.playerinst.on("seeking", () => {
      if (vm.playerinst) {
        vm.$emit('playbackSeeking', vm)
        vm.positionRecalc()
      }
    })

    this.playerinst.on("pause", () => {
      if (vm.playerinst) {
        vm.isplaying = false
        vm.$emit('playbackPaused', vm)
      }
    })

    this.playerinst.on("abort", () => {
      if (vm.playerinst) {
        vm.isplaying = false
      }
    })

    this.playerinst.on("ended", () => {
      if (vm.playerinst) {
        vm.isplaying = false
        vm.$emit('playbackEnded', vm)
      }
    })

    this.playerinst.fluid(true)
  }

  protected beforeDestroy() {
    this.$emit('player-ready', undefined)
    videojs(this.$el).dispose()
  }
}
</script>

<style lang="scss">

// Text, icons, hover states
$primary-foreground-color: #fff !default;

// Control backgrounds (control bar, big play, menus)
$primary-background-color: #00325b;
$primary-background-transparency: 0.9;

// Hover states, slider backgrounds
$secondary-background-color: #1A9FDE;
$secondary-background-transparency: 0.5 !default;

// Avoiding helvetica: issue #376
$text-font-family: inherit;

// Using the '--' naming for component-specific styles
$big-play-button--border-size: 0.1em !default;
$big-play-button--width: 2em !default;
$big-play-button--line-height: 1.8em !default;
$big-play-button--height: $big-play-button--width;
$big-play-button--transparency: 0.8 !default;

@import "node_modules/video.js/src/css/video-js";
.video-js {
  position: absolute;
  top:0;
  left:0;

  .vjs-control-bar {
    bottom: 0px;
  }

  &.vjs-fullscreen {
    .vjs-control-bar {
      bottom: 0px;
    }
  }

  .vjs-big-play-button {
    border-radius: 0;
  }

  .maxplaypos {
    position:absolute;
    bottom:0;
    left:0;
    height:.5rem;
    z-index:4;
    background: $primary-background-color;

    &.completed {
      background: $secondary-background-color;
    }
  }
}

</style>