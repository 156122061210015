<template>
  <div :class="{'chat-entry':true}">
    <div class="voteless-time" v-if="chattype === 'questions'"><small class="">{{ dateString }}</small></div>
    <div class="chatmeta" v-if="chattype === 'chat'">
      <small class="chatmeta--name">{{ uName }}<span class="chatmeta--company">{{ uCompany }}</span></small>
      <small class="">{{ dateString }}</small>
    </div>
    <div class="" v-html="filteredBody"></div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Message, MessageSender } from "@/store/i/types";
import { DateTime } from "luxon"

@Component
export default class VideoChatEntry extends Vue {
  @Prop() public cE! : Message | undefined;
  @Prop() protected chattype!: "chat"|"questions"

  protected settingsToggled : boolean = false

  get user() : any {
    if (this.cE && typeof this.cE.sender !== "string") {
      return this.cE.sender
    }
    return undefined
  }

  get msgIsFromMe() : boolean {
    if (this.cE) {
      if (typeof this.cE.sender !== "string") {
        return this.cE.sender.fbuid === this.$store.state.config.fbuid
      } else {
        return this.cE.sender === this.$store.state.config.fbuid
      }
    }
    return false
  }

  get hasVotes(): boolean {
    return false
  }

  get filteredBody() : string {
    if (this.cE && this.cE.body) {
      return this.cE.body
    }

    return ''
  }

  get date(): Date {
    const d = new Date()
    if (this.cE && this.cE.hasOwnProperty('ts') && typeof this.cE.ts !== "undefined") {
      d.setTime(this.cE.ts * 1000)
    }
    return d
  }

  get dateString() : string {
    const setDT = DateTime.fromJSDate(this.date)
    return setDT.setLocale(this.$store.getters.i18n({de:'de',en:'en'})).toLocaleString(DateTime.DATETIME_SHORT) + this.$store.getters.i18n({de:' Uhr',en:' '})
  }

  get uName() : string {
    if (this.msgIsFromMe) {
      return this.$store.getters.i18n({de: 'Ich', en: 'Me'})
    }

    if (this.cE && typeof this.cE.sender === "string" && (/-cp-/).test(this.cE.sender)) {
      return this.$store.getters.i18n({de: 'Event Team', en: 'Event Team'})
    }

    if (this.user) {
      let userarray: string[] = []
      if (this.user.gender) {
        switch (this.user.gender) {
          case 1:
            userarray.push(this.$store.getters.i18n({de: 'Herr', en: 'Mr.'}))
            break;
          case 2:
            userarray.push(this.$store.getters.i18n({de: 'Frau', en: 'Ms.'}))
            break;
          default:
            break;
        }
      }

      if (this.user.firstname) {
        userarray.push(this.user.firstname)
      }
      if (this.user.lastname) {
        userarray.push(this.user.lastname)
      }

      return `${userarray.join(" ")}`
    }

    return 'anonym'
  }

  get uCompany() : string {
    if (this.user) {
      let userarray: string[] = []

      if (this.user.orgname) {
        userarray.push(" -")
        userarray.push(this.user.orgname)
      }

      return `${userarray.join(" ")}`
    }

    return ''
  }

  protected hideUser() {}

  protected vote(v:string) {

  }
}
</script>
