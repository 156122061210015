export const eveMessageLib = {
    connection: {
        on(...args: any[]) {
            console.debug(`Replace me connection.on`, args);
        },
        off(...args: any[]) {
            console.debug(`Replace me connection.off`, args);
        },
        once(...args: any[]) {
            console.debug(`Replace me connection.once`, args);
        },
        connect(...args: any[]) {
            console.debug(`Replace me connection.connect`, args);
        },
        clean() {
            console.debug(`Replace me connection.clean`);
        },
        disconnect() {
            console.debug(`Replace me connection.disconnect`);
        }
    },
    msg: {
        send: {
            joinroom(...args: any[]) {
                console.debug(`Replace me msg.send.joinroom`, args);
            },
            leaveroom(...args: any[]) {
                console.debug(`Replace me msg.send.leaveroom`, args);
            },
            registerdebug(...args: any[]) {
                console.debug(`Replace me msg.send.registerdebug`, args);
            },
            readhistory(...args: any[]) {
                console.debug(`Replace me msg.send.readhistory`, args);
            },
            announce(...args: any[]) {
                console.debug(`Replace me msg.send.readhistory`, args);
            },
            announcetouser(...args: any[]) {
                console.debug(`Replace me msg.send.announcetouser`, args);
            },
            supportchat(...args: any[]) {
                console.debug(`Replace me msg.send.supportchat`, args);
            },
            chat(...args: any[]) {
                console.debug(`Replace me msg.send.chat`, args);
            },
            flagmsg(...args: any[]) {
                console.debug(`Replace me msg.send.flagmsg`, args);
            },
            supportavailable(...args: any[]) {
                console.debug(`Replace me msg.send.supportavailable`, args);
            },
            isconnected(...args: any[]) {
                console.debug(`Replace me msg.send.isconnected`, args);
            },
            ask(...args: any[]) {
                console.debug(`Replace me msg.send.ask`, args);
            },
            vote(...args: any[]) {
                console.debug(`Replace me msg.send.vote`, args);
            }
        },
        on(...args: any[]) {
            console.debug(`Replace me msg.on`, args);
        },
        off(...args: any[]) {
            console.debug(`Replace me msg.off`, args);
        },
        once(...args: any[]) {
            console.debug(`Replace me msg.once`, args);
        }
    }
}