<template>
  <div class="box">
    <div class="row g-3 mb-5 highlights">
      <h3 class="pb-1">Program</h3>

      <Agenda agendatype="agenda"/>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Agenda from "@/components/Agenda.vue"

@Component({
  components: {Agenda},
})
export default class AgendaOverview extends Vue {
  protected created() {

    let stage = 'Test';
    if(window.location.host == 'gmf-event.com'){
      stage = 'Production';
    }
    this.$gtm.trackEvent({
      pageCategory: 'Program',
      PageName: 'Overview',
      PageType: 'Webapp',
      Stage:stage,
    });
  }
}
</script>
