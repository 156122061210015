<template>
  <div class="row" :class="{'col-lg-4 col-md-6 col-12':this.$route.name === 'Welcome','col-lg-6':this.$route.name != 'Welcome'}">
    <div class="col-5 mb-5" v-if="this.$route.name !='Welcome'">
      <a @click="navigateMember" class="pointer">
        <div class="circular_image">
          <img :src="`/files/members/${member.objvalueid}/foto.jpg`" onerror="this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';" alt="Contact Image">
        </div>
      </a>
    </div>
    <div class="mb-5 ipadfx2" :class="{'col':this.$route.name === 'Welcome','col-7':this.$route.name != 'Welcome'}">
      <a @click="navigateMember" class="pointer">
        <div class="Name-Nachname mb-1"><span v-if="type === 'ranking'">{{position_ranking(numberkey)}}.</span> {{ member.firstname }}    {{ member.lastname }}</div>
        <div class="Role copydetailxs mb-1 bold"> {{ title(member.position) }}</div>
        <div class="Role copydetailxs"> {{ title(member.orgname) }}</div>
        <div class="Role copydetailxs badge badge-pill" v-if="type != 'ranking'">  {{ member.match }} %</div>
        <div class="Role copydetailxs badge badge-pill" v-if="type === 'ranking'">  {{ member.ranking }}</div>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import {ContactsNetworking} from "@/store/i/types";
import _ from "lodash";

@Component({
  components: {},
})
export default class ContactEntry extends Vue {
  @Prop() protected member!: ContactsNetworking
  @Prop() protected type: string | undefined;
  @Prop() protected numberkey: number | undefined;

  protected navigateMember() {
    this.$router.push({name: 'ContactDetail', params: { contactid: `${this.member.objvalueid}` }})
  }


  protected position_ranking(keyvalue: number) {
    return keyvalue + 1;
  }

  protected title(title: string): string {
    title = _.replace(title, /&#34;/g, '"')
    title = _.replace(title, /&#39;/g, "'")
    return title
  }

}
</script>