<template>
  <div class="box">
    <div class="row g-3 mb-5">
      <h3>All speakers</h3>
      <div class="row">
        <SpeakerEntry v-for="(e, ei) in orderedSpeakers" :key="`omember${ei}`" :member="e" type="overview"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import SpeakerEntry from "@/components/SpeakerEntry.vue";
import _ from "lodash";
import {MemberEntry} from "@/store/i/types";

@Component({
  components: {SpeakerEntry},
})
export default class SpeakerOverview extends Vue {

  protected created() {

    this.$store.dispatch("i/fetchSpeakers")


    let stage = 'Test';
    if(window.location.host == 'gmf-event.com'){
      stage = 'Production';
    }
    this.$gtm.trackEvent({
      pageCategory: 'Speaker',
      PageName: 'Overview',
      PageType: 'Webapp',
      Stage:stage,
    });
  }

  get orderedSpeakers(): MemberEntry[] {

    var filter = _.filter(
        this.$store.state.i.speakers,
        (e: MemberEntry) => e.dbstate > -1 && e.tn_type == 8 && e.is_visible
    )

    return _.orderBy(filter,
        [user => user.lastname.toLowerCase()],
        ["asc"]
    )
  }

}
</script>

<style scoped>

</style>