<template>
  <div class="banner" style="--banner-image-xl-url: url('/files/landingpage/header_welcome_xl.png'); --banner-image-xs-url: url('/files/landingpage/header_welcome_xs.png')">
    <div class="banner-text">
      <span>Dear {{ $store.state.config.me.firstname }} {{ $store.state.config.me.lastname }},</span>
      <br>
      <span>Thank you for your participation</span>
      <br>
      <span>in this year's Global Media Forum.</span>
    </div>
    <div class="image-container" v-if="ImageEntry" >
      <router-link :to="{name: 'Welcome'}" class="banner-image-entry">
        <iframe src="https://player.vimeo.com/video/953122031?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="GMF24_Trailer_UT"></iframe>
      </router-link>
    </div>
  </div> 
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {AgendaPoint} from "@/store/i/types";
import _ from "lodash";

@Component({
  components: {},
})
export default class Banner extends Vue {
  get ImageEntry(): AgendaPoint | undefined {
    return _.find(
        this.$store.state.i.image,
        (ap: AgendaPoint) => ap.dbstate > -1 && ap.now_live
    )
  }

  get viewBox(): string {
    if (this.ImageEntry) {
      return "0 0 1440 460"
    } else {
      return "0 0 1440 432"
    }
  }

}
</script>

<style scoped type="text/scss">
.banner {
  background-size: cover;
  background-position: left center;
  background-image: var(--banner-image-xl-url);
  display:flex;
  min-height: 432px;

  .banner-text {
    font-family: 'DWTheAntiquaB', serif;
    font-size: 2rem;
    line-height: 35pt;
    color: white;
    flex-shrink: 0;
    flex-basis: auto;

    padding-top: 184px;
    padding-left: 163px;
  }
  .image-container {
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: auto;
    position: relative;
    margin: 0 1rem;

    .banner-image-entry {
      aspect-ratio: 700/360;
      max-width: 700px;
      width: 100%;
      display: block;

      position: absolute;
      right: 0;
      top: 50%;
      translate: 0 -50%;

      img {
        height: 100%;
        width: 100%;
      }
    }
  }
}
@media (max-width: 1000px) {
  .banner {
    flex-direction: column;
    background-image: var(--banner-image-xs-url);

    .banner-text {
      padding-top: 100px;
      padding-left: 50px;
    }
  }
  .banner:has(.image-container) {
    height: 600px;

    .image-container {
      margin: 1rem 0 !important;

      .banner-image-entry {

        top: 0 !important;
        right: 50% !important;
        translate: 50% 0 !important;
      }
    }
  }
}

</style>