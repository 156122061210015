<template>
    <div class="my-custom-scrollbar2">
        <div class="card-body p-3">
            <div class="chat-message">
                <ChatContactMessageSmall
                        v-for="(msg, index) in convo"
                        :key="'smallmsg' + index"
                        :m="msg"
                />
            </div>
        </div>
        <transition name="popin">
          <div class="write-indecator" v-show="otherpersonwriting">{{ contact.firstname ? contact.firstname : $store.getters.i18n({de:'Support',en:'Support'}) }} {{ $store.getters.i18n({de:'schreibt',en:'is writing'}) }}</div>
        </transition>
    </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {DMMessage, ChatContact, SupportMessage} from "@/store/i/types";
import ChatContactMessageSmall from "@/components/i/ChatContactMessageSmall.vue";
import _ from "lodash";
import { DateTime } from "luxon";

import {eveMessageLib} from "@/dummyEveMessageLib";

@Component({
    components: { ChatContactMessageSmall }
})
export default class ChatSmallMsgWrapper extends Vue {
    protected otherpersonwriting = false
    protected writingCallback:any = null

    protected created() {
        const vm = this
      /*
        this.writingCallback = eveMessageLib.msg.on('announcetouser', (req: any) => {
          if (
            (vm.contact === "support" && (req.user.cpuserid > 0)) ||
            (vm.contact !== "support" && req.user.objvalueid === (<ChatContact>vm.contact).objvalueid)
          ) {
            switch (req.ev) {
              case "stopwriting":
                vm.otherpersonwriting = false
                break
              case "startwriting":
                vm.otherpersonwriting = true
                break
              default:break
            }
          }
        })
       */
    }

    get contact(): false|ChatContact|"support" {
        if (this.$store.state.i.showsmallchat === true) {
            return false
        }
        return this.$store.state.i.showsmallchat
    }

    get convo(): (DMMessage|SupportMessage)[] {
        if (this.contact) {
          switch (this.contact) {
            case "support":
              return this.$store.getters['i/supportchats']
            default:
              return this.$store.getters['i/dmsbyuser'](this.contact.fbuid)
          }
        }
        return []
    }


    protected correctScroll: boolean = true

    @Watch('otherpersonwriting')
    @Watch('convo')
    protected async scrollBottom(e?:Event) {
        // Runterscrollen
        if (this.correctScroll) {
            this.$el.removeEventListener('scroll', this.lookIfToHaltAutoscroll.bind(this))
            await this.$nextTick()
            this.$el.scrollTo(0, (<HTMLElement>this.$el).scrollHeight)
            await this.$nextTick()
            this.$el.addEventListener('scroll', this.lookIfToHaltAutoscroll.bind(this), {passive:true})
        } else {
          alert("Nee")
        }
    }

    protected lookIfToHaltAutoscroll() {
        const pos = this.$el.scrollTop
        const desiredPos = (<HTMLElement>this.$el).scrollHeight - (<HTMLElement>this.$el).clientHeight
        this.correctScroll = pos >= desiredPos - 20
    }

    protected mounted() {
        this.scrollBottom()
        window.addEventListener('resize', this.scrollBottom.bind(this), {passive:true})
        this.$el.addEventListener('scroll', this.lookIfToHaltAutoscroll.bind(this), {passive:true})
    }

    protected beforeDestroy() {
        //this.writingCallback()
        window.removeEventListener('resize', this.scrollBottom)
        this.$el.removeEventListener('scroll', this.lookIfToHaltAutoscroll)
    }

}
</script>

<style scoped lang="scss">

     .my-custom-scrollbar2 {
       overflow-y: scroll;
       height:calc(100vh - 380px);
       margin-bottom: 1rem;
     }

    .write-indecator {
      color:#000;
    }

    .popin-enter-active, .popin-leave-active {
        transition: opacity .5s;
        opacity:1;
    }
    .popin-enter, .popin-leave-to {
        opacity: 0;
    }
</style>