<template>
  <div v-if="show">
    <div class="modal fade show" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title">
              Accessablity
            </h2>
            <button type="button" aria-label="Close" class="btn-close" @click.prevent="close"></button>
          </div>
          <div class="modal-body">
            <div>
              <h6 class="fw-bold">Hide images and animations</h6>
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" id="toggle-contrast"
                       @change="toggleHideImages()"
                       :checked="accessibilityOptions.hideImages"
                >
                <label class="form-check-label" for="toggle-contrast">Change mode</label>
              </div>
            </div>
            <div class="mt-2">
              <h6 class="fw-bold">Underline links</h6>
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" id="toggle-underline-link"
                       @change="toggleUnderlineLink()"
                       :checked="accessibilityOptions.underlineLink"
                >
                <label class="form-check-label" for="toggle-contrast">Change mode</label>
              </div>
            </div>
            <div class="mt-2">
              <h6 class="fw-bold">Font-Size</h6>
              <div class=''>
                <input class="form-custom-range w-100" type="range" min="-1" max="2" step="1"
                       @change="setFontSize"
                       :value="accessibilityOptions.fontSize"
                >
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

interface AccessibilityOptions {
  hideImages: boolean;
  underlineLink: boolean;
  fontSize: number;
}

interface AccessibilityModalData {
  show: boolean;
  accessibilityOptions: AccessibilityOptions|null;
}

export default defineComponent({
  name: 'AccessibilityModal',
  data(): AccessibilityModalData {
    return {
      show: false,
      accessibilityOptions: null
    }
  },
  mounted() {
    this.$root.$on('openAccessibilityModal', () => {
      this.show = true
    })
    this.$root.$on('closeAccessibilityModal', () => {
      this.show = false
    })
    this.accessibilityOptions = this.getAccessibiltyOptions();
    this.applyAccessibilityOptions();
  },
  methods: {
    close() {
      this.$root.$emit('closeAccessibilityModal');
    },
    toggleHideImages(){
      if (!this.accessibilityOptions) {
        return
      }

      this.accessibilityOptions.hideImages = !this.accessibilityOptions.hideImages;
      this.applyAccessibilityOptions();
    },
    toggleUnderlineLink() {

      if (!this.accessibilityOptions) {
        return
      }
      this.accessibilityOptions.underlineLink = !this.accessibilityOptions.underlineLink;
      this.applyAccessibilityOptions();
    },
    setFontSize(ev: Event) {
      if (!ev.target) {
        return;
      }
      if (!this.accessibilityOptions) {
        return
      }

      // @ts-ignore
      const value = ev.target.value;
      this.accessibilityOptions.fontSize = parseInt(value);
      this.applyAccessibilityOptions();
    },
    applyAccessibilityOptions() {
      if (!this.accessibilityOptions) {
        return
      }
      console.log(this.accessibilityOptions);

      document.body.classList.toggle('hide-images', this.accessibilityOptions.hideImages);
      document.body.classList.toggle('underline-link', this.accessibilityOptions.underlineLink);

      const value = (this.accessibilityOptions.fontSize * 0.1666) + 1
      document.body.style.setProperty('--desired-font-size', `${value}em`);
      document.body.style.setProperty('--font-scale', `${value}`);

      this.saveAccessibiltyOptions()
    },
    saveAccessibiltyOptions() {
      localStorage.setItem('accessibility-options', JSON.stringify(this.accessibilityOptions));
    },
    getAccessibiltyOptions(): AccessibilityOptions {
      const defaultOptions: AccessibilityOptions = {
        hideImages: false,
        underlineLink: false,
        fontSize: 0
      }

      const storageValue = localStorage.getItem('accessibility-options');
      if (!storageValue) {
        return defaultOptions;
      }

      const storedOptions = JSON.parse(storageValue);
      return {...defaultOptions, ...storedOptions};
    }
  }
})
</script>

<style scoped lang="scss">

</style>