import {MutationTree} from "vuex";
import {
    RootState,
    FirebaseProjectConfig
} from "@/store/types";

const mutations: MutationTree<RootState> = {
    tick(state) {
        state.tick = new Date()
    },
    clean(state) {
        state.zweifapassed = false
        state.loginpassed = false
        state.dppassed = false
    },
    changeVisibilityState(state) {
        state.visible = document.visibilityState === 'visible'
    },
    changeOnlineState(state, pl:boolean) {
        state.isonline = pl
    },
    setConfig(state, pl:FirebaseProjectConfig) {
        state.config = pl
    },
    setToken(state, pl:string) {
        state.jwt = pl
        if (pl === "") {
            sessionStorage.removeItem("jwt")
        } else {
            sessionStorage.setItem("jwt", pl)
        }
    },
    setSideNav(state, pl: ""|"notifications"|"chat") {
        state.sidenav = state.sidenav === pl ? "" : pl
    },
    OpenSideNav(state, pl: ""|"notifications"|"chat") {
        state.sidenav = pl
    },
}

export default mutations