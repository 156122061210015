var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.voting)?_c('div',{class:{
        'text-center': true,
        'inline-voting-area': !_vm.onlyvotingresult,
        'w-100': _vm.onlyvotingresult,
        'd-flex': _vm.onlyvotingresult,
        'flex-column': _vm.onlyvotingresult,
        'justify-content-center': _vm.onlyvotingresult,
        'align-items-center': _vm.onlyvotingresult,
        'ivoted': _vm.ivoted,
        'mb-4': true,
        'mb-lg-0': true
  },attrs:{"id":"stream-voting-area"}},[(!_vm.hidetitle && !_vm.ivoted)?_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col text-center"},[(!_vm.onlyvotingresult)?_c('h5',{staticClass:"mt-2",on:{"click":_vm.toogle}},[_c('span',{staticClass:"ms-2 pb-2"},[_c('FontAwesomeIcon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.show),expression:"!show"}],attrs:{"icon":"arrow-down"}}),_c('FontAwesomeIcon',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],attrs:{"icon":"arrow-up"}})],1),_vm._v(" "+_vm._s(_vm.voting.question)+" ")]):_c('div',{staticClass:"big-question"},[_vm._v(_vm._s(_vm.voting.question))])])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}]},[(_vm.hasDeadline && !_vm.ivoted)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hidetitle),expression:"!hidetitle"}],staticClass:"row"},[(!_vm.deadlineReached)?_c('div',{staticClass:"col"},[_vm._v(" "+_vm._s(_vm.$store.getters.i18n({de:'Das Voting endet ' + _vm.toDeadline,en:'Voting ends ' + _vm.toDeadline}))+". ")]):_c('div',{staticClass:"col"},[_vm._v(" "+_vm._s(_vm.$store.getters.i18n({de:'Das Voting ist beendet.',en:'The voting has ended.'}))+" ")])]):_vm._e(),(!_vm.onlyvotingresult && _vm.votemode === 'vote' && !_vm.deadlineReached)?_c('VotingForm',{attrs:{"voting":_vm.voting}}):_vm._e(),(_vm.ivoted)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h3',[_vm._v(_vm._s(_vm.$store.getters.i18n({de:'Danke für Ihre Abstimmung.',en:'Thank you for voting.'})))])])]):_vm._e()],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }