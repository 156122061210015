<template>
  <div class="box">
    <div class="row g-3 mb-5">
      <h3>Help</h3>
      <div class="col-md-12 col-xl-12">

        <p>
          Welcome to the Global Media Forum 2024!
        </p>

        <p>
          You can access all relevant conference areas via the start page – find the live program, networking options and online workshops right there. Using the"speech bubble" at the upper right-hand corner next to your name, you can follow up with your individual chats any time. Feel free to have a look around!
        </p>

        <p>
          If you have any technical questions or problems, please feel free to contact our support chat.
        </p>

        <p>
            The Support chat will be available from 8 a.m. to 6 p.m. on both June 17 and 18. If you have questions beforehand, a response may take up to 48 hours.
        </p>

        <p>
          We look forward to a successful digital event with you!
        </p>

        <p>
            <span v-if="$store.state.config.withsupportchat && $store.state.i.connected && showsupport" id="chatindicator" @click.prevent="toggleChatbox" class="btn btn-primary me-3">
              Support Chat <span v-if="unread > 0" class="badge badge-danger float-right ms-3">{{ unread }}</span>
            </span>
        </p>
      </div>

    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import {SupportMessage} from "@/store/i/types";
import _ from "lodash";

@Component({
  components: {},
})
export default class Help extends Vue {

  protected created() {

    let stage = 'Test';
    if(window.location.host == 'gmf-event.com'){
      stage = 'Production';
    }
    this.$gtm.trackEvent({
      pageCategory: 'Help',
      PageName: 'Help',
      PageType: 'Webapp',
      Stage:stage,
    });
  }

  protected toggleChatbox(){
    this.$store.commit('OpenSideNav','chat')
    this.$store.commit('i/showChatbox','support')
  }

  get supportMessages(): SupportMessage[] {
    return this.$store.getters["i/supportchats"]
  }

  get unread(): number {
    const unread = _.filter(
        this.supportMessages,
        (um) => {
          return (
              !um.seen &&
              um.receiver == 'me'
          )
        }
    )
    return unread.length
  }


  get showsupport(){
    return 1
  }

}
</script>

<style scoped>

</style>