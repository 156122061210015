<template>
  <VerticalNav>
    <div class="row scroll-header">
      <div class="col mt-4">
        <p style="font-size: 28px">
          {{ $store.getters.i18n({de: 'Chat', en: 'Chat'}) }}
        </p>
      </div>
    </div>
    <BigChat/>
  </VerticalNav>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import BigChat from "@/components/i/BigChat.vue";

@Component({
  components: {BigChat},
})
export default class ChatVerticalNav extends Vue {
}
</script>
