<template>
  <div id="app" :class="{'side-nav-closed': $store.state.sidenav === '', 'side-nav-opened': $store.state.sidenav !== ''}">
    <NotificationVerticalNav v-if="$store.state.sidenav === 'notifications'" />
    <ChatVerticalNav v-if="$store.state.sidenav === 'chat'" />
    <ToastDisplay />
    <div class="maincontent">
      <HorizontalNav/>
      <router-view/>
      <Footer/>
    </div>
    <AccessibilityModal />

  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import NotificationVerticalNav from "@/components/i/NotificationVerticalNav.vue";
import ChatVerticalNav from "@/components/i/ChatVerticalNav.vue";
import ToastDisplay from "@/components/i/ToastDisplay.vue";
import Footer from "@/components/Footer.vue";

// @ts-ignore
import {Input, Collapse} from "mdb-ui-kit"
import {RankingObject} from '@/store/i/types';
import rankingsMaxPoints from '@/ranking';
import AccessibilityModal from "@/components/AccessiblityModal.vue";

@Component({
  components: {AccessibilityModal, ChatVerticalNav,NotificationVerticalNav,ToastDisplay, Footer}
})
export default class App extends Vue {
  $confetti: any;

  // track if we had confetti for this before
  protected trackConfettiPerRanking: Record<keyof RankingObject, boolean> = {
    ranking_a: false,
    ranking_b: false,
    ranking_c: false,
    ranking_d: false,
    ranking_e: false,
    ranking_f: false,
    ranking_g: false,
    ranking_h: false,
    ranking_i: false,
    ranking_j: false,
    ranking_k: false,
    ranking_l: false,
    ranking_m: false,
  }
  get hasNavigation(): boolean {
    return !(["Login", "Step1", "Step2", "Step3", "Step4", "404", "FramesetError"].some(n => n === this.$route.name))
  }

  protected mainnav!: Collapse

  @Watch("$route")
  protected togglebynav() {
    if (this.mainnav) {
      this.mainnav.hide()
    }
  }

  protected preventInitialConfetti: boolean = true;
  protected confettiActive: boolean = false;

  get myPointsObject(): number {
    return this.$store.getters["i/myPointsObject"];
  }

  @Watch('myPointsObject')
  protected showConfetti(newRankingValues: RankingObject) {
    let showRanking = false;
    if(this.$store.state.i.me.tn_type == 26 || this.$store.state.i.me.tn_type == 33 || this.$store.state.i.me.tn_type == 37){
        this.preventInitialConfetti = false;
        return;
    } 
    
    Object.entries(newRankingValues).forEach((entry) => {
      const k = entry[0] as keyof RankingObject;
      const v = entry[1] as number;
      const maxPointsReached = rankingsMaxPoints[k] <= v && !this.trackConfettiPerRanking[k];

      if (maxPointsReached) {
        this.trackConfettiPerRanking[k] = true;
        showRanking = true;
      }
    })

    if(this.preventInitialConfetti) {
      this.preventInitialConfetti = false;
      return;
    }

    if(this.confettiActive) {
      return;
    }

    if(!showRanking) {
      return;
    }

    this.$confetti.start();
    this.confettiActive = true;

    setTimeout(() => {
      this.$confetti.stop();
      this.confettiActive = false;
    }, 3000)
  }

  public async updateBootstrap() {
    document.querySelectorAll('.form-outline').forEach((formOutline) => {
      new Input(formOutline).init();
    })
    document.querySelectorAll('.collapse').forEach((collapsable) => {
      const tcollapse = new Collapse(collapsable, {toggle: false})
      if (collapsable.classList.contains("navbar-collapse")) {
        this.mainnav = tcollapse
      }
    })
  }

  @Watch("$store.state.tick")
  protected logoutAtSessionEnd() {
    if (this.$store.state.jwt !== '' && this.$route.meta && this.$route.meta.needsauth && this.$store.state.config.session_end && this.$store.state.config.session_end !== "") {
      if (this.$store.state.tick.getTime() >= (new Date(this.$store.state.config.session_end)).getTime()) {
        this.$store.dispatch("i/forceLogout")
      }
    }
  }

  @Watch("$store.state.jwt")
  @Watch("$store.state.config.fbuid")
  protected async changedJWT() {
    console.log('Changed JWT');
    this.$store.dispatch('landingpage/populate')
    if (this.$store.state.jwt !== '' && this.$store.state.config.fbuid !== '') {
      console.log('Valid JWT');
      await Promise.all([
        this.$store.dispatch('i/populate'),
        this.$store.dispatch('members/populate')
      ])
      await this.$store.dispatch("tradeForFirebaseToken")
    }
  }

  get isForcedToPlenum(): boolean {
    return this.$store.getters['i/forcedToPlenum']
  }

  @Watch("isForcedToPlenum")
  @Watch("$route.meta.needsauth")
  @Watch("$route.name")
  protected forceToPlenum() {
    if (
        this.$route.meta && this.$route.meta.needsauth &&
        this.isForcedToPlenum &&
        this.$route.name !== "Stage" && this.$route.name !== "StagePlaying" && this.$route.name !== "OnBoarding"
    ) {
      this.$router.push({name: "Stage"})
    }
  }

  get isredirectToPlenum(): boolean {
    return this.$store.getters['i/redirectToPlenum']
  }

  @Watch("isredirectToPlenum")
  @Watch("$route.meta.needsauth")
  protected redirectToPlenum() {
    if (
        this.$route.meta && this.$route.meta.needsauth &&
        this.isredirectToPlenum &&
        this.$route.name !== "Stage" && this.$route.name !== "StagePlaying" && this.$route.name !== "OnBoarding"
    ) {
      this.$router.push({name: "Stage"})
    }
  }

  protected updated() {
    this.updateBootstrap()
  }

  protected created() {
    this.changedJWT()
  }

  protected async mounted() {
    await new Promise(resolve => { window.setTimeout(resolve, 1000) })
    await this.updateBootstrap()
  }
}
</script>

<style lang="scss">
@import "styles/app";
</style>
