<template>
    <div class="col-sm-6 col-md-3 linked-partner-logo pointer mb-2">
      <div class="card" @click="navigatepartner">
        <img :src="`/files/exhibitor/${partner.objvalueid}/logo.jpg?nocache=${(new Date()).getHours()}`" class="" onerror="this.onerror=null;this.src='/projects/dw/gmf/assets/images/placeholder.jpg';" style="width:100%;height:100%;" alt=""/>
        </div>
      </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import {Partner} from "@/store/i/types";

@Component({
  components: {},
})
export default class PartnerEntry extends Vue {
  @Prop() protected partner!: Partner

  protected navigatepartner() {
    this.$router.push({name: 'PartnerDetail', params: { partnerid: `${this.partner.objvalueid}` }})
  }
}
</script>